import React, { useState } from 'react'
import TestinomialCard from 'Components/Cards/TestinomialCard'
import LeftArrow from 'assets/icons/TestinomialIcons/LeftArrow'
import RightArrow from 'assets/icons/TestinomialIcons/RightArrow'
import testinomial1 from 'assets/images/testinomial1.png'
import testinomial2 from 'assets/images/testinomial2.png'
import testinomial3 from 'assets/images/testinomial3.png'
import { FormattedMessage } from 'react-intl'

const cardData = [
  {
    name: 'Nullam eu leo',
    image: testinomial1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu leo eget metus facilisis varius. Nulla facilisi. Nullam eu leo eget metus facilisis varius. Nulla facilisi.',
  },
  {
    name: 'Pellentesque congue',
    image: testinomial2,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu leo eget metus facilisis varius. Nulla facilisi. Nullam eu leo eget metus facilisis varius. Nulla facilisi.',
  },
  {
    name: 'Aenean congue',
    image: testinomial3,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu leo eget metus facilisis varius. Nulla facilisi. Nullam eu leo eget metus facilisis varius. Nulla facilisi.',
  },
]

const TestinomialCarousel = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <div className="p-2">
      <p className="font-bold text-4xl text-center">
        <FormattedMessage id="reviews.title" />
      </p>
      <div className=" my-8 relative">
        {true ? (
          <>
            {/* no available review yet */}
            <div>
              <p className=" text-gray-500 text-2xl text-center">
                <FormattedMessage id="reviews.none" />
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="flex space-x-4 justify-center sm:space-x-0 ">
              {cardData.map((e, index) => (
                <TestinomialCard key={index} name={e.name} image={e.image} text={e.text} selected={1 == index} />
              ))}
            </div>
            <button
              onClick={() => {
                if (selectedIndex == 0) return setSelectedIndex(cardData.length - 1)
                else return setSelectedIndex(selectedIndex - 1)
              }}
              className="sm:block md:hidden lg:hidden xl:hidden xxl:hidden absolute left-1 top-[50%] z-20 ">
              <LeftArrow />
            </button>

            <button
              onClick={() => {
                if (selectedIndex == cardData.length - 1) return setSelectedIndex(0)
                else return setSelectedIndex(selectedIndex + 1)
              }}
              className="sm:block  md:hidden lg:hidden xl:hidden xxl:hidden absolute right-1 top-[50%] z-20">
              <RightArrow />
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default TestinomialCarousel
