import React from "react";

function ComprehensiveReport({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="72"
      height="69"
      viewBox="0 0 72 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.3794 34.5762C64.3955 28.938 61.9654 14.5954 68.1162 2.33057"
        stroke="#105E26"
        strokeWidth="6.57555"
      />
      <path
        d="M26.1899 65.2416C21.7936 65.2394 11.0008 63.331 3 55.7146"
        stroke="#105E26"
        strokeWidth="6.57555"
      />
      <path
        d="M43.8998 45.3705C36.3224 39.0195 21.0808 21.454 20.7344 2"
        stroke="#105E26"
        strokeWidth="6.57555"
      />
    </svg>
  );
}

export default ComprehensiveReport;
