import { cities, cityOptions } from 'Resources/Data/cities'
import {
  capitalizeAllFirstLetter,
  capitalizeFirstLetter,
} from './index'

export const cleanQuery = (params) => {
  const obj = {}
  for (const [key, value] of params.entries()) {
    if (!value || !key) continue

    obj[key] = value.toLowerCase()
  }
  const newObj = Object.keys(obj)
    .sort()
    .reduce((newObj, key) => {
      newObj[key] = obj[key]
      return newObj
    }, {})
  return newObj
}

export const refactorQuery = (params) => {
  return new URLSearchParams(cleanQuery(new URLSearchParams(params)))
}

export const GetSearchQuery = (params) => {
  const orderToQuery = [
    'make',
    'model',
    'body',
    'year',
    'gear',
    'origin',
  ]
  const parsedParams = []
  for (const key of orderToQuery) {
    if (!params.has(key)) continue

    parsedParams.push(params.get(key))
  }

  if (!parsedParams.length) parsedParams.push('All')

  let queryString = parsedParams
    .map((item) => capitalizeAllFirstLetter(item))
    .join(' ')

  if (params.has('condition')) {
    const conditionStr = `(${capitalizeFirstLetter(
      params.get('condition')
    )})`
    queryString = queryString.concat(' ', conditionStr)
  }

  let additionalString = ''
  if (params.has('location')) {
    const LocationStr = capitalizeAllFirstLetter(
      params.get('location')
    )

    additionalString = additionalString.concat(' ', LocationStr)
  }

  if (params.has('distance')) {
    const distanceStr = `(~${capitalizeFirstLetter(
      params.get('distance')
    )})`

    additionalString = additionalString.concat(' ', distanceStr)
  }

  return !!additionalString
    ? queryString.concat(' -', additionalString)
    : queryString
}

export const translateSearchQuery = (params, intl = null) => {
  // check for main paramaters
  const orderToQuery = [
    'make',
    'model',
    'body',
    'year',
    'gear',
    'origin',
  ]
  const orderNoTranslation = {
    make: true,
    model: true,
    body: true,
    year: true,
    gear: false,
    origin: false,
  }
  const parsedParams = []
  for (const key of orderToQuery) {
    if (!params.has(key)) continue

    parsedParams.push(params.get(key))
  }

  // if no paramaters, return all

  const onceNoLength = !parsedParams.length

  if (!parsedParams.length) {
    parsedParams.push(
      intl.formatMessage({
        id: 'all',
      })
    )
  }

  // turn to string

  let queryString = parsedParams.map((item) => {
    if (!orderNoTranslation.hasOwnProperty(item))
      return capitalizeAllFirstLetter(item)
    if (orderNoTranslation[item])
      return capitalizeAllFirstLetter(item)
    return intl.formatMessage({ id: item.toLowerCase() })
  })

  if (params.has('condition')) {
    if (
      !(
        onceNoLength &&
        params.get('condition').toLowerCase() === 'all'
      )
    ) {
      const conditionStr = `(${intl.formatMessage({
        id: params.get('condition').toLowerCase(),
      })})`
      queryString.push(conditionStr)
    }
  }

  let additionalString = []

  if (params.has('location')) {
    const foundLocation = cities.find(
      (c) =>
        c.label.toLowerCase() === params.get('location').toLowerCase()
    )
    const locationStr =
      intl.locale === 'vn'
        ? foundLocation.vnLabel
        : foundLocation.label
    additionalString.push(locationStr)
  }

  if (params.has('distance')) {
    const distanceStr = `(~${capitalizeFirstLetter(
      params.get('distance')
    )})`
    additionalString.push(distanceStr)
  }

  return additionalString.length > 0
    ? queryString.join(' ').concat(' - ', additionalString.join(' '))
    : queryString.join(' ')
}

export const translateSearchObject = (title, intl = null) => {
  // title = Honda (New)
  // return Honda (Mới)
  if (!title) return title
  const condition = title.includes('(New)') ? 'new' : 'used'

  const conditionStr = `(${intl.formatMessage({
    id: condition,
  })})`

  console.log('condition', condition)

  const newTitle =
    condition === 'new'
      ? title.replace('(New)', conditionStr)
      : title.replace('(Used)', conditionStr)

  return newTitle
}
