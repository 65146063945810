import React from "react";

function QuotationMark({ fill = "#ffffff", ...rest }) {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.42858 15.7143C1.8074 15.7143 2.17093 15.5638 2.43878 15.296C2.70664 15.0281 2.85715 14.6646 2.85715 14.2858V7.14288C2.85715 6.00606 3.30868 4.91618 4.11226 4.11226C4.91615 3.30868 6.00606 2.85715 7.14288 2.85715H14.2858C14.7963 2.85715 15.2679 2.58483 15.523 2.14286C15.7781 1.70089 15.7781 1.15625 15.523 0.714288C15.2679 0.272327 14.7963 0 14.2858 0H7.14288C5.24842 0 3.43176 0.752557 2.09217 2.09217C0.752573 3.43178 0 5.24842 0 7.14288V14.2858C0 14.6646 0.150515 15.0281 0.418368 15.296C0.686222 15.5638 1.04975 15.7143 1.42858 15.7143Z" fill="black"/>
    <path d="M32.8595 0H25.7166C25.2061 0 24.7345 0.272319 24.4794 0.714288C24.2243 1.15626 24.2243 1.7009 24.4794 2.14286C24.7345 2.58482 25.2061 2.85715 25.7166 2.85715H32.8595C33.9963 2.85715 35.0862 3.30868 35.8901 4.11226C36.6937 4.91615 37.1452 6.00606 37.1452 7.14288V14.2858C37.1452 14.7963 37.4176 15.2679 37.8595 15.523C38.3015 15.7781 38.8461 15.7781 39.2881 15.523C39.7301 15.2679 40.0024 14.7963 40.0024 14.2858V7.14288C40.0024 5.24842 39.2498 3.43176 37.9102 2.09217C36.5706 0.752574 34.754 0 32.8595 0Z" fill="black"/>
    <path d="M7.14288 40.0001H14.2858C14.7963 40.0001 15.2679 39.7278 15.523 39.2858C15.7781 38.8438 15.7781 38.2992 15.523 37.8572C15.2679 37.4153 14.7963 37.1429 14.2858 37.1429H7.14288C6.00606 37.1429 4.91618 36.6914 4.11226 35.8878C3.30868 35.0839 2.85715 33.994 2.85715 32.8572V25.7143C2.85715 25.2038 2.58483 24.7322 2.14286 24.4771C1.70089 24.222 1.15625 24.222 0.714288 24.4771C0.272327 24.7322 0 25.2038 0 25.7143V32.8572C0 34.7517 0.752557 36.5683 2.09217 37.9079C3.43178 39.2475 5.24842 40.0001 7.14288 40.0001Z" fill="black"/>
    <path d="M38.5738 24.2858C38.195 24.2858 37.8315 24.4363 37.5636 24.7041C37.2958 24.972 37.1452 25.3355 37.1452 25.7143V32.8572C37.1452 33.994 36.6937 35.0839 35.8901 35.8878C35.0862 36.6914 33.9963 37.1429 32.8595 37.1429H25.7166C25.2061 37.1429 24.7345 37.4153 24.4794 37.8572C24.2243 38.2992 24.2243 38.8438 24.4794 39.2858C24.7345 39.7278 25.2061 40.0001 25.7166 40.0001H32.8595C34.754 40.0001 36.5706 39.2475 37.9102 37.9079C39.2498 36.5683 40.0024 34.7517 40.0024 32.8572V25.7143C40.0024 25.3355 39.8519 24.972 39.584 24.7041C39.3162 24.4363 38.9526 24.2858 38.5738 24.2858Z" fill="black"/>
    <path d="M20.0515 5.68091C21.0373 5.68091 21.8337 6.4759 21.8337 7.45618V9.43562C21.9228 9.44727 22.0064 9.46003 22.0955 9.47279C22.6858 9.56044 24.4457 9.84282 25.1698 10.0253C26.072 10.2661 26.7013 11.2342 26.4563 12.1829C26.2168 13.1315 25.2477 13.7085 24.2898 13.4699C23.7719 13.2869 22.1957 13.076 21.572 12.9873C19.7842 12.721 18.2526 12.8541 17.2 13.2536C16.182 13.6419 15.7593 14.1911 15.6473 14.8069C15.5393 15.4005 15.6211 15.739 15.7192 15.9498C15.8244 16.1717 16.0299 16.4213 16.4393 16.6932C17.3448 17.2868 18.726 17.6862 20.5194 18.1689L20.6753 18.2132C22.257 18.6404 24.1951 19.1675 25.632 20.1161C26.4173 20.6376 27.158 21.3422 27.6147 22.3186C28.077 23.3061 28.1772 24.4156 27.9656 25.5474C27.5757 27.7054 26.072 29.1146 24.3065 29.8469C23.5491 30.1575 22.7137 30.3572 21.8337 30.4571V32.3101C21.8337 33.292 21.0373 34.0853 20.0515 34.0853C19.0658 34.0853 18.2694 33.292 18.2694 32.3101V30.3739C18.2471 30.3739 18.2192 30.3184 18.1969 30.3628H18.1858C16.8325 30.152 14.5975 29.5695 13.0899 28.9037C12.1904 28.5043 11.7855 27.4558 12.1854 26.557C12.5853 25.6639 13.6384 25.2589 14.4878 25.6583C15.7019 26.1743 17.6177 26.6791 18.726 26.8511C20.5082 27.1174 21.973 26.962 22.9643 26.5626C23.9055 26.1798 24.3344 25.625 24.4569 24.9593C24.5627 24.3657 24.4847 24.0273 24.3845 23.8165C24.2787 23.5946 24.0726 23.3449 23.666 23.0731C22.7582 22.4795 21.377 22.08 19.5837 21.5974L19.4278 21.553C17.8461 21.1258 15.9068 20.5988 14.4688 19.6501C13.683 19.1286 12.9467 18.4241 12.4895 17.4477C12.0256 16.4602 11.9248 15.3506 12.1403 14.1689C12.5346 12.0109 14.0951 10.6345 15.928 9.93769C16.6531 9.66197 17.4395 9.48055 18.2694 9.38291V7.45618C18.2694 6.4759 19.0658 5.68091 20.0515 5.68091Z" fill="black"/>
    </svg>
    
  );
}

export default QuotationMark;
