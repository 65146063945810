import React from "react";

function ThumbsUpIcon({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#105E26" />
      <path
        d="M36.8732 25.2796C36.5409 25.6135 36.1214 25.8473 35.6627 25.9541C36.2583 26.4298 36.6052 27.1506 36.6052 27.913C36.609 28.5809 36.3452 29.2225 35.8733 29.6952C35.4013 30.1678 34.76 30.4321 34.0918 30.4295H33.7808C34.332 30.9054 34.6482 31.5983 34.6463 32.3267C34.6498 32.9934 34.3863 33.6338 33.9148 34.1053C33.4434 34.5767 32.8029 34.8402 32.1363 34.8367H17.3984C17.246 34.8365 17.0942 34.819 16.9458 34.7845C16.9919 34.7136 17.0382 34.6396 17.0782 34.5659V34.5656C17.2935 34.1743 17.4057 33.7345 17.4045 33.2876V21.2293C17.4086 20.8892 17.3436 20.5517 17.2136 20.2374C17.0976 19.9413 16.9295 19.668 16.7178 19.4305L20.5986 13.32C21.1093 12.5142 21.3248 11.5563 21.2085 10.6095L20.916 8.28712C20.8585 7.84542 20.984 7.3997 21.2629 7.05255C21.542 6.70538 21.9506 6.48725 22.3942 6.44849C23.8289 6.31639 25.2453 6.84694 26.2397 7.88937C27.234 8.93179 27.6974 10.3715 27.4979 11.7985L26.9189 15.9288V15.9286C26.8943 16.0933 26.944 16.2602 27.0543 16.3845C27.1633 16.5104 27.3221 16.5825 27.4887 16.5816H34.9575C35.6107 16.5664 36.2454 16.7985 36.7349 17.2315C37.2243 17.6644 37.5323 18.2661 37.597 18.9163C37.6332 19.3972 37.5299 19.8781 37.2992 20.3016C37.0686 20.7249 36.7207 21.0727 36.2972 21.3034C36.9899 21.683 37.464 22.3659 37.5772 23.1477C37.6906 23.9295 37.4297 24.7191 36.8731 25.2796L36.8732 25.2796Z"
        fill="white"
      />
      <path
        d="M11.9737 29.7539C11.63 29.7525 11.2999 29.8882 11.0562 30.1304C10.8126 30.3726 10.6753 30.7021 10.6748 31.0457C10.6743 31.3893 10.8107 31.7192 11.0536 31.9622C11.2966 32.2053 11.6263 32.3417 11.9701 32.3412C12.3137 32.3408 12.6429 32.2037 12.8854 31.96C13.1279 31.7164 13.2633 31.3862 13.2621 31.0426C13.2607 30.7013 13.1246 30.3743 12.8833 30.133C12.642 29.8917 12.3149 29.7553 11.9737 29.7539L11.9737 29.7539Z"
        fill="white"
      />
      <path
        d="M16.6404 20.4607C16.4892 20.0741 16.2242 19.7423 15.8806 19.509C15.537 19.276 15.1307 19.1524 14.7155 19.155H10.1109C9.56068 19.155 9.03299 19.3736 8.64373 19.7624C8.25473 20.1514 8.03564 20.6788 8.03516 21.229V33.2873C8.03657 33.8368 8.25612 34.3633 8.64536 34.7514C9.03436 35.1392 9.56164 35.3571 10.1111 35.3571H14.7157C15.2649 35.3571 15.7917 35.139 16.1802 34.7509C16.5688 34.3628 16.7876 33.8365 16.7886 33.2873V21.229C16.7912 20.9655 16.7408 20.7044 16.6404 20.4607L16.6404 20.4607ZM11.9738 32.9564C11.4674 32.9578 10.981 32.7579 10.6218 32.4006C10.2628 32.0432 10.0603 31.558 10.0591 31.0514C10.0579 30.5449 10.2578 30.0585 10.6152 29.6995C10.9727 29.3406 11.4582 29.1383 11.9646 29.1371C12.4713 29.1361 12.9574 29.3363 13.3164 29.6939C13.6752 30.0514 13.8772 30.5369 13.8782 31.0433C13.8777 31.5491 13.6771 32.034 13.3202 32.3923C12.9636 32.7508 12.4796 32.9536 11.9738 32.9564L11.9738 32.9564Z"
        fill="white"
      />
    </svg>
  );
}

export default ThumbsUpIcon;
