import React from 'react'
// import "./style.css";
import dealerhome1 from '../../../assets/images/dealerhome1.png'

import { Icon11, Icon22, Icon33 } from 'assets/icons/dealerhome'
import { useIntl } from 'react-intl'
const Checkout = () => {
  const intl = useIntl()
  return (
    <div className="h-full p-10 pr-0 sm:p-0 lg:pr-10    ">
      <div className=" h-full rounded-xl w-full sm:p-4 relative ">
        <div className="p-10 lg:p-2 h-full flex flex-col justify-center ">
          <p className="tracking-wider text-[#105e26] z-10">
            {' '}
            {intl.formatMessage({ id: 'for_dealer.offers' })}
          </p>
          <h1 className="text-4xl font-bold my-1 z-10">
            {intl.formatMessage({ id: 'for_dealer.offers.headline' })}
          </h1>
          <p className="text-md text-[#000]  my-2 z-10">
            {intl.formatMessage({
              id: 'for_dealer.offers.sub_headline',
            })}
          </p>

          <div className="pt-16 w-[80%] lg:pt-8 grid grid-cols-3 gap-5   xxl:gap-20 lg:grid-cols-1">
            <div className=" bg-white z-10 m-1 p-16 rounded-3xl shadow-lg shadow-[#263b7e60]">
              <Icon11 />
              <div className="mt-8">
                <p className="text-xl font-semibold my-2">
                  {intl.formatMessage({
                    id: 'for_dealer.offers.feature1',
                  })}
                </p>
                <p className="my-1 ">
                  {intl.formatMessage({
                    id: 'for_dealer.offers.description1',
                  })}
                </p>
              </div>{' '}
            </div>
            <div className=" bg-white z-10 m-1 p-16 rounded-3xl shadow-lg shadow-[#263b7e60]">
              <Icon22 />{' '}
              <div className="mt-8">
                <p className="text-xl font-semibold my-2">
                  {intl.formatMessage({
                    id: 'for_dealer.offers.feature2',
                  })}
                </p>
                <p className="my-1 ">
                  {intl.formatMessage({
                    id: 'for_dealer.offers.description2',
                  })}
                </p>
              </div>{' '}
            </div>
            <div className=" bg-white z-10 m-1 p-16 rounded-3xl shadow-lg shadow-[#263b7e60]">
              <Icon33 />{' '}
              <div className="mt-8">
                <p className="text-xl font-semibold my-2">
                  {intl.formatMessage({
                    id: 'for_dealer.offers.feature3',
                  })}
                </p>
                <p className="my-1 ">
                  {intl.formatMessage({
                    id: 'for_dealer.offers.description3',
                  })}
                </p>
              </div>{' '}
            </div>
          </div>
        </div>

        <div className="absolute right-0 top-0  h-full sm:hidden ">
          <img
            src={dealerhome1}
            className="h-full lg:h-fit lg:rounded-r-[100px]"
            alt="sleekride"
          />
        </div>
      </div>
    </div>
  )
}

export default Checkout
