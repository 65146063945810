import React from "react";

const searchicon = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8093 8.95304C17.8093 10.9129 17.1829 12.7257 16.1205 14.2001L19.6088 17.7074C20.1304 18.2318 20.1304 19.0821 19.6088 19.6066C19.0872 20.1311 18.2415 20.1311 17.7199 19.6066L14.2479 16.1158C12.7595 17.2398 10.9094 17.906 8.90467 17.906C3.98683 17.906 0 13.8975 0 8.953C0 4.00847 3.98683 0 8.90467 0C13.8225 0 17.8093 4.00847 17.8093 8.953V8.95304ZM8.90467 15.2201C12.3472 15.2201 15.1379 12.4142 15.1379 8.95304C15.1379 5.49187 12.3472 2.68594 8.90467 2.68594C5.46218 2.68594 2.6714 5.49187 2.6714 8.95304C2.6714 12.4142 5.46218 15.2201 8.90467 15.2201Z"
        fill={fill || "#707070"}
      />
    </svg>
  );
};

export default searchicon;
