import React from "react";

function ComprehensiveReport({ fill = "#ffffff", ...rest }) {
  return (
    <svg width="178" height="114" viewBox="0 0 178 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="63.6429" height="63.6429" transform="matrix(-1 0 0 1 63.6426 6)" fill="url(#paint0_linear_271_4584)" fillOpacity="0.1"/>
    <rect width="44.3571" height="44.3571" transform="matrix(-1 0 0 1 108 69.6428)" fill="url(#paint1_linear_271_4584)" fillOpacity="0.1"/>
    <path d="M120.491 35.1039L120.543 35.24L120.632 35.4739H129.398C130.55 35.4739 131.484 34.5391 131.484 33.3867V31.3003C131.484 30.1479 130.549 29.2131 129.398 29.2131H123.138C121.985 29.2131 121.05 30.1479 121.05 31.3003V32.3442H119.45C119.829 33.3506 120.134 34.1578 120.314 34.6326L120.491 35.1039Z" fill="#105E26"/>
    <path d="M89.5722 21.1829H84.7041C84.0316 21.1829 83.4873 21.7279 83.4873 22.3997C83.4873 23.0715 84.0316 23.6165 84.7041 23.6165H89.5722C90.2455 23.6165 90.7905 23.0715 90.7905 22.3997C90.7905 21.7279 90.2455 21.1829 89.5722 21.1829Z" fill="#105E26"/>
    <path d="M120.23 36.0637L119.945 35.3118C119.94 35.2976 114.532 20.9953 113.773 18.7267C113.313 17.2774 112.101 16.4738 110.69 16.1287C109.264 15.7587 107.511 15.6695 105.367 15.4902C100.698 15.1011 92.1996 14.7148 87.1403 14.7148C82.0781 14.7148 73.5808 15.1011 68.9123 15.4902C66.7692 15.6702 65.0152 15.7595 63.5877 16.1287C62.1781 16.4738 60.9655 17.2782 60.5049 18.7267C60.2965 19.3532 59.7317 20.8997 59.0244 22.8074C59.3511 23.2043 59.6757 23.6423 59.9939 24.1242C60.2299 24.4849 60.4546 24.8655 60.6729 25.256C61.2979 23.9548 61.7919 22.8784 61.991 22.2815C62.3022 21.4133 62.8011 21.0441 63.986 20.7039C65.1561 20.3928 66.8789 20.2886 69.0426 20.1086C73.6463 19.7245 82.1515 19.3397 87.14 19.3397C92.1264 19.3397 100.633 19.7245 105.237 20.1086C107.401 20.2886 109.125 20.3928 110.293 20.7039C111.477 21.0441 111.979 21.4133 112.289 22.2815C112.948 24.256 116.815 31.4556 118.081 34.8085L63.8217 34.8078C64.4729 38.4647 64.8011 42.7304 64.8075 47.6573C64.8068 50.0782 64.726 52.6734 64.5686 55.3758C64.5261 56.1036 64.3178 56.7832 63.9868 57.3856H75.1397V46.9521H78.2701V57.3856H80.3572V46.9521H83.4869V57.3856H85.574V46.9521H88.7036V57.3856H90.7908V46.9521H93.9204V57.3856H96.0076V46.9521H99.1372V57.3856H127.716V44.8649C127.717 40.3172 122.486 37.1974 120.231 36.0635L120.23 36.0637ZM115.312 52.2852C112.43 52.2852 110.094 49.9493 110.094 47.0684C110.094 44.1868 112.43 41.8516 115.312 41.8516C118.192 41.8516 120.528 44.1868 120.528 47.0684C120.528 49.95 118.192 52.2852 115.312 52.2852Z" fill="#105E26"/>
    <path d="M59.8029 59.8724C59.7051 59.8724 59.608 59.8703 59.5109 59.8632C58.4465 59.8001 57.4508 59.3856 56.6563 58.6868H55.667V73.8353H60.01C61.1617 73.8353 62.0972 72.9005 62.0972 71.7481V67.5738H113.45V71.8849C113.45 72.9614 114.321 73.8345 115.399 73.8345H123.196C124.272 73.8345 125.146 72.9614 125.146 71.8849V64.0969H125.396C128.873 64.0969 128.873 58.686 128.873 58.686L87.1375 58.6867H62.9386C62.0974 59.4245 60.9995 59.8724 59.8019 59.8724L59.8029 59.8724Z" fill="#105E26"/>
    <path d="M115.312 43.0105C113.074 43.0105 111.256 44.8297 111.256 47.0658C111.256 49.3053 113.073 51.1245 115.312 51.1245C117.55 51.1245 119.367 49.3053 119.367 47.0658C119.368 44.8299 117.55 43.0105 115.312 43.0105Z" fill="#105E26"/>
    <path d="M58.4388 18.848C55.4326 22.5162 51.7409 24.4622 48.653 25.4594C47.2377 25.9165 45.9422 26.1851 44.8508 26.3431L44.8515 89.4819C44.8515 92.2004 42.6475 94.4024 39.931 94.4024C37.2132 94.4024 35.0097 92.2004 35.0097 89.4819V53.5519H33.165V89.4819C33.165 92.2004 30.961 94.4024 28.2445 94.4024C25.5267 94.4024 23.3232 92.2004 23.3232 89.4819L23.3225 29.7975C23.0865 30.2816 22.8491 30.8365 22.6138 31.4842C21.492 34.5941 20.5486 39.6953 20.5523 47.6596C20.5516 49.8545 20.6224 52.2648 20.7769 54.9126C20.8797 56.668 19.5388 58.1741 17.7834 58.2768C17.7203 58.2803 17.6572 58.2825 17.5941 58.2825C15.9202 58.2825 14.5184 56.9763 14.4192 55.284C14.259 52.5236 14.1839 49.9892 14.1832 47.6598C14.1995 35.6466 16.1179 28.9982 18.7352 25.0013C20.0427 23.0163 21.5722 21.7441 22.9853 21.0389C24.3141 20.3685 25.4836 20.2062 26.2064 20.1906C26.2702 20.1863 26.3339 20.1814 26.3991 20.1814L41.7759 20.1807C41.89 20.1807 42.002 20.1878 42.1132 20.1998C43.0494 20.1906 46.0295 19.9815 48.9919 18.4514C50.556 17.6485 52.1244 16.5047 53.5156 14.8116C54.904 13.115 56.1364 10.8536 56.9423 7.6935C57.376 5.98907 59.1087 4.95865 60.8132 5.39227C62.5176 5.82599 63.548 7.55815 63.1144 9.26249C62.9755 9.80889 62.8224 10.3369 62.6602 10.8535C62.9663 11.5013 63.2767 12.2617 63.5467 13.1206C63.8671 14.1405 64.13 15.2978 64.2569 16.5714H64.4064C65.5056 16.5714 66.3965 17.4622 66.3965 18.5615V21.4799C66.3965 22.4183 65.7445 23.2 64.8706 23.4098L64.4064 21.0389H63.4504L62.8806 23.4098C62.0067 23.1993 61.3554 22.4176 61.3554 21.4799V18.5615C61.3554 17.4623 62.2463 16.5714 63.3455 16.5714L63.4504 16.5707C63.3654 15.767 63.23 15.0087 63.0542 14.3077C62.8367 13.4396 62.5659 12.6565 62.2825 11.9704C61.2903 14.7103 59.9644 16.9943 58.4391 18.8498L58.4388 18.848Z" fill="#105E26"/>
    <path d="M132.959 18.848C135.965 22.5162 139.657 24.4622 142.745 25.4594C144.16 25.9165 145.455 26.1851 146.547 26.3431L146.546 89.4819C146.546 92.2004 148.75 94.4024 151.466 94.4024C154.184 94.4024 156.388 92.2004 156.388 89.4819V53.5519H158.232V89.4819C158.232 92.2004 160.436 94.4024 163.153 94.4024C165.871 94.4024 168.074 92.2004 168.074 89.4819L168.075 29.7975C168.311 30.2816 168.548 30.8365 168.784 31.4842C169.906 34.5941 170.849 39.6953 170.845 47.6596C170.846 49.8545 170.775 52.2648 170.621 54.9126C170.518 56.668 171.859 58.1741 173.614 58.2768C173.677 58.2803 173.74 58.2825 173.803 58.2825C175.477 58.2825 176.879 56.9763 176.978 55.284C177.138 52.5236 177.214 49.9892 177.214 47.6598C177.198 35.6466 175.28 28.9982 172.662 25.0013C171.355 23.0163 169.825 21.7441 168.412 21.0389C167.083 20.3685 165.914 20.2062 165.191 20.1906C165.127 20.1863 165.064 20.1814 164.998 20.1814L149.622 20.1807C149.507 20.1807 149.395 20.1878 149.284 20.1998C148.348 20.1906 145.368 19.9815 142.406 18.4514C140.841 17.6485 139.273 16.5047 137.882 14.8116C136.493 13.115 135.261 10.8536 134.455 7.6935C134.021 5.98907 132.289 4.95865 130.584 5.39227C128.88 5.82599 127.849 7.55815 128.283 9.26249C128.422 9.80889 128.575 10.3369 128.737 10.8535C128.431 11.5013 128.121 12.2617 127.851 13.1206C127.53 14.1405 127.267 15.2978 127.141 16.5714H126.991C125.892 16.5714 125.001 17.4622 125.001 18.5615V21.4799C125.001 22.4183 125.653 23.2 126.527 23.4098V31.1644H128.517V23.4098C129.391 23.1993 130.042 22.4176 130.042 21.4799V18.5615C130.042 17.4623 129.151 16.5714 128.052 16.5714L127.947 16.5707C128.032 15.767 128.167 15.0087 128.343 14.3077C128.561 13.4396 128.832 12.6565 129.115 11.9704C130.107 14.7103 131.433 16.9943 132.958 18.8498L132.959 18.848Z" fill="#105E26"/>
    <path d="M42.9102 8.9551C42.9102 13.9012 38.901 17.9102 33.9551 17.9102C29.009 17.9102 25 13.901 25 8.9551C25 4.00901 29.0092 0 33.9551 0C38.9012 0 42.9102 4.00919 42.9102 8.9551" fill="#105E26"/>
    <path d="M166.267 8.9551C166.267 13.9012 162.257 17.9102 157.312 17.9102C152.365 17.9102 148.356 13.901 148.356 8.9551C148.356 4.00901 152.366 0 157.312 0C162.258 0 166.267 4.00919 166.267 8.9551" fill="#105E26"/>
    <path d="M15.4785 54.1538H29.209V58.3323H15.4785V54.1538Z" fill="#105E26"/>
    <path d="M129.479 54.1538H143.209V58.3323H129.479V54.1538Z" fill="#105E26"/>
    <path d="M131.965 54.1538H138.001V58.3323H131.965V54.1538Z" fill="#105E26"/>
    <defs>
    <linearGradient id="paint0_linear_271_4584" x1="31.8214" y1="0" x2="31.8214" y2="63.6429" gradientUnits="userSpaceOnUse">
    <stop stopColor="#105E26"/>
    <stop offset="1" stopColor="#157831"/>
    </linearGradient>
    <linearGradient id="paint1_linear_271_4584" x1="22.1786" y1="0" x2="22.1786" y2="44.3571" gradientUnits="userSpaceOnUse">
    <stop stopColor="#105E26"/>
    <stop offset="1" stopColor="#157831"/>
    </linearGradient>
    </defs>
    </svg>
    
  );
}

export default ComprehensiveReport;
