import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIEndpoints } from '../staticStore';
import http from '../http';


// Fetch car makers async thunk
export const fetchCities = createAsyncThunk(
  'options/fetchCities',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(APIEndpoints.GET_CITIES);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch car makers async thunk
export const fetchCarMakers = createAsyncThunk(
  'options/fetchCarMakers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(APIEndpoints.ALL_MAKES);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch car models async thunk
export const fetchCarModels = createAsyncThunk(
  'options/fetchCarModels',
  async (make, { rejectWithValue }) => {
    if (make) {
      try {
        const response = await http.get(`${APIEndpoints.FIND_MODELS_BY_MAKE}make=${make}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    } else {
      return [];
    }
  }
);

// Fetch listing fees async thunk
export const fetchListingFees = createAsyncThunk(
  'options/fetchListingFees',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(APIEndpoints.LISTING_FEES);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    makes: [],
    models: [],
    cities: [],
    listingFees: null,
    language: 'vi', // 'en
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // FETCH CAR MAKERS
      .addCase(fetchCarMakers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCarMakers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.makes = action.payload;
      })
      .addCase(fetchCarMakers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // FETCH CAR MODELS
      .addCase(fetchCarModels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCarModels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.models = action.payload;
      })
      .addCase(fetchCarModels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // FETCH CITIES
      .addCase(fetchCities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cities = action.payload;
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // FETCH LISTING FEES
      .addCase(fetchListingFees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchListingFees.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listingFees = action.payload.listingFee;
      })
      .addCase(fetchListingFees.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});


export default optionsSlice.reducer;
