import React from "react";

function DropDownIcon({ fill = "#ffffff", stroke }) {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0001 1.00008L7 7.00016L0.999919 1.00008"
        stroke={stroke || "#000" } 
        strokeWidth="2"
      />
    </svg>
  );
}

export default DropDownIcon;
