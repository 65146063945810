import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

const Left = () => {
  const navigate = useNavigate()

  const intl = useIntl()
  return (
    <div className="p-20 pb-0 lg:p-10   relative  bg-gradient-to-b from-[#778B7D] to-[#fff]  h-full flex justify-center items-center  border-0  text-black w-full sm:p-6 ">
      <div className="pr-20 lg:pr-0  w-full h-full flex flex-col justify-between">
        <h1 className="xxl:leading-[1.15] text-4xl -lg:xl:text-2xl lg:text-3xl font-bold my-1 sm:text-2xl md:w-[90%]  md:max-w-[400px]  ">
          {intl.formatMessage({
            id: 'for_dealer.intro.headline.left',
          })}
        </h1>
        {/* <p className="xxl:text-lg xxl:w-5/6 text-md text-[#707070]  my-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> */}
        {/* <button
          onClick={() => {
            window.location.href = 'https://dealer.sleekride.vn/login'
          }}
          className="bg-[#105E26] p-3 text-white w-full rounded hidden sm:block    ">
          {intl.formatMessage({
            id: 'for_dealer.dealer_sign_in',
          })}
        </button> */}
        <button
          onClick={() => {
            navigate(`/dealer-contact`)
            window.scrollTo(0, 0)
          }}
          className="bg-[#105E26] p-3 mt-[15%] lg:mt-[5%] text-white w-2/5 rounded md:w-3/5 sm:w-full ">
          {intl.formatMessage({
            id: 'for_dealer.contact_us',
          })}
        </button>
      </div>
    </div>
  )
}

export default Left
