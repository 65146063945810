import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import Card from '@mui/material/Card'
import useMutation from 'redux/hooks/useMutation'
import './style.css'
import clock from '../../../assets/icons/clock.png'
import HeartIcon from '../../../assets/icons/heartIcon'
import { QuestionMark } from 'assets/icons/carCardIcons'
import { APIEndpoints } from 'staticStore'
import { setWishlistItems } from 'redux/wishlist'
import { GoodDealIcon, FairDealIcon, HighPriceIcon, PriceDropIcon, GreatDealIcon } from 'assets/icons/pricingToolsIcon/dealIcon'
import CarouselComp from 'Components/Carousel'
import { listingCarText, listingOriginANDTransmissionText } from 'Utils/Parse/Text'
import { openCheckAvailability, closeCheckAvailability } from 'redux/modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import { VNDshorten, loanEsimationVND_monthlyCalculator } from 'Utils/Parse/currency'
import { FormattedMessage, useIntl } from 'react-intl'

import { ReactComponent as CheckIcon } from './check.svg'
import locationJPG from './location.png'
import odometerJPG from './odometer.png'
import { CheckAvailabilityContext } from 'App'
// import analytics from 'SDK/Analytics'

const DealTypeIcon = {
  'Great Deal': GreatDealIcon,
  'Good Deal': GoodDealIcon,
  'Fair Deal': FairDealIcon,
  'High Price': HighPriceIcon,
  'Price Drop': PriceDropIcon,
}
const DealTypeFormat = {
  'Great Deal': 'greatdeal',
  'Good Deal': 'gooddeal',
  'Fair Deal': 'fairdeal',
  'High Price': 'highprice',
  'Price Drop': 'pricedrop',
}
export default function CarCard({
  id,
  preview = false,
  images,
  post,
  mileage,
  city,
  daysinmarket,
  price,
  est,
  dealType,
  image,
  inHome = false,
  className = '',
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isWishlisted, setIsWishlisted] = useState(false)
  const [addToWishlist] = useMutation({
    endPoint: APIEndpoints.ADD_WISHLIST,
  })
  const [removeFromWishlist] = useMutation({
    endPoint: APIEndpoints.REMOVE_WISHLIST,
    method: 'post',
  })
  const wishlistItems = useSelector((state) => state.wishlist.items)
  const endUserID = useSelector((state) => state.user?.data?._id)
  const isMobile = useMediaQuery('(max-width:425px)')
  const isReverseMobile = useMediaQuery('(min-width:425px)')
  const isBiggerMobile = useMediaQuery('(max-width:768px)')
  const isDesktop = useMediaQuery('(min-width:1280px)')

  const usr = useSelector((state) => state.user?.data)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)

  const { setCheckAvailabilityPost } = useContext(CheckAvailabilityContext)

  const determineCarouselHeight = () => {
    if (isMobile) {
      return '30vh'
    }
    if (isReverseMobile && isBiggerMobile) {
      return '35vh'
    }
    if (isDesktop) {
      return '38vh'
    }
    return '25vh' // default
  }

  const height = determineCarouselHeight()

  const determineCarouselWidth = () => {
    if (isMobile) {
      return '95vw'
    }
    if (isDesktop) {
      return '40vw'
    }
    return '60vw' // default
  }
  const previewDetermineCarouselWidth = () => {
    if (isMobile) {
      return '90vw'
    }
    if (isDesktop) {
      return '40vw'
    }
    return '50vw' // default
  }
  const width = preview ? '95%' : determineCarouselWidth()

  useEffect(() => {
    const isItemInWishlist = wishlistItems.some((item) => item.postID === id)
    setIsWishlisted(isItemInWishlist)
  }, [wishlistItems, id])

  const toggleWishlist = async () => {
    console.log('listingID: ', id, 'endUserID: ', endUserID)
    try {
      let response
      let transformedData
      // Check if the item is currently wishlisted or not
      // response.data will be an individual object containing info about the item
      // whther it is wishlisted or not
      if (isWishlisted) {
        response = await removeFromWishlist({
          listingID: id,
          endUserID: endUserID,
        })
        // If the item is wishlisted, remove it from the wishlist/transformedData
        // and update the Redux store
        transformedData = wishlistItems.filter((item) => item.postID !== id)
      } else {
        response = await addToWishlist({
          listingID: id,
          endUserID: endUserID,
        })
        // If the item is not wishlisted, add it to the wishlist/transformedData
        // and update the Redux store
        transformedData = [...wishlistItems, { ...post, postID: id }]
      }
      // Update the Redux store with the new wishlist items
      dispatch(setWishlistItems(transformedData))
      // Toggle the local state to reflect if the item is wishlisted or not
      setIsWishlisted((prev) => !prev)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const Icon = DealTypeIcon[dealType] || GoodDealIcon

  const user = useSelector((state) => state.user)
  const userData = user.data

  const intl = useIntl()
  return (
    <Card
      sx={{
        maxWidth: preview ? width : '100%',
        width: '100%',
        height: '100%',
        borderRadius: '20px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
      }}
      className={clsx(' relative', {
        [className]: !!className,
      })}>
      {/* {!inHome && ( */}
      <div className="absolute top-3 right-3 z-10  ">
        <HeartIcon
          style={{ cursor: 'pointer', fontSize: '44px' }}
          stroke={dealType === 'Price Drop' ? '#000' : '#fff'}
          onClick={toggleWishlist}
          isWishlisted={isWishlisted}
        />
      </div>
      {/* // )} */}
      {/* limit height */}
      {images && (
        <CarouselComp
          post={post}
          className="w-full h-full"
          imgClassName={` rounded-tl-3xl rounded-tr-3xl w-full h-full object-cover bg-no-repeat bg-center width-[${width}]`}
          images={images}
          silder={false}
          height={height}
          id={id}
        />
      )}
      {/* Car Data */}
      <div className="data-container  p-4 pt-4 pb-4  h-full sm:p-4 flex flex-col text-xs">
        <div className="text-row flex justify-between items-start xl:text-[100%]">
          <div className="flex flex-col">
            <p className="flex data-title w-full h-[48px]">
              <span
                className={clsx('notranslate flex flex-row flex-wrap  ', {
                  // 'w-[200px] h-[45px]': preview,
                })}>
                {preview
                  ? listingCarText(post).split(' ').length > 3
                    ? listingCarText(post).split(' ').slice(0, 3).join(' ') + '\n' + listingCarText(post).split(' ').slice(3).join(' ')
                    : listingCarText(post)
                  : listingCarText(post)}
              </span>
            </p>
            <p className="flex mb-1 data-transmission">
              <FormattedMessage id={post.transmission?.toLowerCase() ?? ''} /> • <FormattedMessage id={post.origin?.toLowerCase() ?? ''} />
            </p>
          </div>
          {!preview && (
            <div className="data-title flex mt-0 items-center justify-end">
              <p className="font-[500] flex flex-row justify-end items-center w-[100px] text-sm p-0 xxl:mr-2 mr-2 ">
                {<FormattedMessage id={DealTypeFormat[dealType]} />}
              </p>
              <span>{dealType == 'Price Drops' ? <img src={Icon} className="sm:w-[35px]" /> : <Icon className="sm:w-[35px]" />}</span>
            </div>
          )}
        </div>

        <div className="city-font mb-5 flex justify-between items-center ">
          <div>
            <span className="flex space-x-1 text-xs font-[400]">
              <div className="flex flex-row">
                <img src={odometerJPG} alt="" className=" bg-transparent w-4 h-4 mr-1  object-contain" />
                {mileage} km
              </div>
              <div className="sm:hidden mx-1"> | </div>
              <div className="flex flex-row">
                <img src={locationJPG} alt="" className=" bg-transparent w-4 h-4  object-contain" />
                {city}
              </div>
            </span>
          </div>
          {!preview && (
            <div className="flex items-center space-x-1 ">
              <>
                <img src={clock} className="h-3 w-3" />
                <p className=" text-center">
                  {daysinmarket} <FormattedMessage id="days_on_market" />
                </p>
              </>
            </div>
          )}
        </div>

        <div className="text-row flex justify-between items-end font mb-3">
          <div className="price-est">
            {dealType === 'Price Drop' && <div className="line-through">3,000,000,000đ</div>}
            <p className="price flex font-bold mb-1">{VNDshorten(post.price)}</p>
            <div
              onClick={() => {
                if (!Object.keys(usr).length && !loggedIn && !isLoading) {
                  navigate('/user-login')
                  return
                }
                navigate('/setting/1')
              }}
              className="flex items-end space-x-1 cursor-pointer">
              <h1 className="est-font underline text-[0.8rem] sm:text-[0.7rem]   text-[#105E26]">{`Est. ${post.monthlyPayment.monthlyPaymentText} ${
                intl.locale === 'vn' ? 'Triệu/Tháng' : 'Trieu/Thang'
              }`}</h1>
              <QuestionMark className="h-4 mb-1" />
            </div>
          </div>

          <div className="flex ">
            <button
              onClick={() => {
                console.log('test', Object.keys(usr).length, !loggedIn, !isLoading)
                if (!Object.keys(usr).length && !loggedIn && !isLoading) {
                  navigate('/user-login')
                  return
                }
                console.log('Clicked on Check Availability', usr)
                // analytics.track("Clicked on Check Availability", {
                //   usr: usr,
                //   post: post,
                // })
                console.log(
                  'Clicked on Check Availability',
                  window.gtag('event', 'message_lead', {
                    message_lead__userId: usr._id,
                    message_lead__postId: post._id,
                    message_lead__dealerId: post.endUser._id,
                  })
                )
                setCheckAvailabilityPost(post)
                // dispatch(openCheckAvailability(post))
              }}
              className=" bg-[#105E26] flex flex-row items-center text-xs font-bold !p-2 rounded-md text-white w-full  shadow-md shadow-[#105E26] lg:text-xs">
              {!preview && (
                <>
                  <CheckIcon className="w-8 h-8 mr-2" />
                </>
              )}
              <div
                className={clsx(' md:p-0', {
                  ' p-2': preview,
                })}>
                <FormattedMessage id="checkavailability" />
              </div>
            </button>
          </div>
        </div>
      </div>
      {/* Car Data */}
    </Card>
  )
}
