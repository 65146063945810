import SellCar from '../../../assets/images/home1.png'
import { ArrowRight, ArrowLeft } from 'assets/icons/HomeIcons'
import dots from '../../../assets/icons/find-card-dots-2.svg'
import { FormattedMessage } from 'react-intl'

import VF8_1 from '../../../assets/images/home_intro/VF8-1.jpg'
import VF8_2 from '../../../assets/images/home_intro/VF8-2.jpg'
import VF8_3 from '../../../assets/images/home_intro/VF8-3.jpg'
import VF8_1_M from '../../../assets/images/home_intro/VF8-1_M.jpg'
import VF8_2_M from '../../../assets/images/home_intro/VF8-2_M.jpg'
import VF8_1_S from '../../../assets/images/home_intro/VF8-1_S.jpg'
import VF8_2_S from '../../../assets/images/home_intro/VF8-2_S.jpg'
import VF8_3_S from '../../../assets/images/home_intro/VF8-3_S.jpg'
import CarouselComp from 'Components/Carousel'
import { useEffect, useLayoutEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { debounce } from '@mui/material'
const ImageMapper = (imgs) => {
  return imgs.map((img) => {
    let IMG = img
    return {
      original: IMG,
      thumbnail: IMG,
    }
  })
}
let BIG_IMAGES = ImageMapper([VF8_2, VF8_1, VF8_3])
let IMAGES = ImageMapper([VF8_2_M, VF8_1_M, VF8_3])
let SMALLER_IMAGES = ImageMapper([VF8_2_S, VF8_1_S, VF8_3_S])

const Right = () => {
  const [isShown, setIsShown] = useState(false)

  const [imgs, setImgs] = useState(IMAGES)

  const isBiggerDesktop = useMediaQuery('(min-width:1800px)')

  const isNotDesktop = useMediaQuery('(max-width:1024px)')

  useEffect(() => {
    //Not Desktop
    if (isNotDesktop) {
      console.log('isNotDesktop')
      setImgs(SMALLER_IMAGES)
      return
    }
    //Biger Desktop
    if (isBiggerDesktop) {
      console.log('isBiggerDesktop')
      setImgs(BIG_IMAGES)
      return
    }
    //Default
    console.log('Default')
    setImgs(IMAGES)
  }, [isBiggerDesktop, isNotDesktop])

  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <div
        className="flex items-center  text-black w-full sm:h-[50vh]  -sm:lg:h-[55vh] h-[90vh] max-h-[60vw]  relative  md:mt-0 z-10  "
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
        {/* <img className="w-full h-full object-cover" src={images[0].thumbnail} alt="" /> */}

        {imgs.length && (
          <CarouselComp
            className=" w-full rounded-bl-[100px]   lg:rounded-none  sm:h-[50vh]  -sm:lg:h-[55vh] h-[90vh] max-h-[60vw]   "
            imgClassName={` w-full     sm:h-[50vh]  -sm:lg:h-[55vh] h-[90vh] max-h-[60vw]  object-cover object-left  bg-no-repeat bg-center `}
            autoPlay={true}
            images={imgs}
            navButtonsAlwaysVisible={isShown}
            silder={false}
            // id={id}
          />
        )}
        <div className=" absolute w-full grid  grid-rows-6 grid-flow-col  gap-4 justify-between h-full sm:w-full   ">
          <div className=" flex row-start-2 col-span-5  items-center justify-between lg:justify-around  sm:w-full  -lg:pl-[2%] -lg:pr-[2.25%] ">
            <div className="z-10 flex flex-col justify-center bg-opacity-75 space-y-2   text-white     px-4 py-2  xxl:py-5 sm:my-2 ">
              <p className=" font-medium  lg:text-sm text-base   tracking-widest">THE ALL NEW,</p>
              <p className=" font-medium  -sm:lg:text-lg sm:text-xs  text-3xl   tracking-widest">All Electric. VF8</p>
            </div>

            <div className="z-10 flex flex-col justify-center bg-opacity-75 space-y-2  text-white    px-4 py-2   xxl:py-5 sm:my-2 ">
              <p className=" font-medium  lg:text-sm text-base text-center tracking-widest ">
                <FormattedMessage id="home.intro.right.starting_at" />
              </p>
              <p className=" font-medium  -sm:lg:text-lg sm:text-xs text-3xl tracking-widest text-center ">1 Tỉ 090 Triệu</p>
            </div>
          </div>
          <div className=" flex justify-between items-center row-start-6 col-span-5 mt-10 lg:mr-10 -lg:pr-20  ">
            <div>
              <img src={dots} alt="" className=" top-10 right-5 relative  w-[5rem] -z-10 lg:hidden " />
            </div>
            <div
              className="z-10  text-white space-y-4 p-3"
              onClick={() => {
                // redirect to vinfast wensite
                window.open('https://shop.vinfastauto.com/vn_vi/dat-coc-xe-vf8.html', '_blank')
              }}>
              {/* <div className="flex justify-end space-x-6 mr-2">
                <ArrowRight className="  stroke-white w-8 "  />{' '}
                <ArrowLeft className="  stroke-white w-8" />
              </div> */}
              <p className="my-2 text-base sm:text-sm   tracking-[0.50em] lg:mb-20">
                <FormattedMessage id="home.intro.right.discover" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  return (
    <div className="flex items-center  text-black w-full   lg:h-[40vh] h-[90vh] max-h-[60vw]  relative  md:mt-0  ">
      <img className="absolute top-0 h-full w-full object-cover" src={SellCar} alt="sleek" />
      <div className="w-full flex flex-col justify-between h-4/5 sm:w-full  ">
        <div className=" flex justify-around mt-10 xxl:mt-10  sm:items-center  sm:mt-0  sm:w-full  ">
          <div className="z-10 flex flex-col justify-center bg-black bg-opacity-80  text-white px-4 py-2  xxl:py-5 sm:my-2 sm:w-fit lLaptop:w-48 xxl:w-56">
            <p className=" text-sm xxl:text-lg   sm:text-sm  tracking-widest">SPORT CAR</p>
            <p className="text-lg xxl:text-2xl   sm:text-sm tracking-widest">The New M3</p>
          </div>

          <div className="z-10 flex flex-col justify-center bg-black bg-opacity-80 text-white px-4 py-2   xxl:py-5 sm:my-2 sm:w-fit lLaptop:w-48 xxl:w-56">
            <p className="text-sm xxl:text-lg text-right tracking-widest ">
              <FormattedMessage id="home.intro.right.starting_at" />
            </p>
            <p className="text-lg xxl:text-2xl tracking-widest text-right sm:text-sm ">2 Tỉ 600 Triệu</p>
          </div>
        </div>
        <div className=" flex justify-end ">
          <div className="z-10  text-white p-3 mx-10">
            <div className="flex justify-end space-x-8 mr-2">
              <ArrowLeft className="w-8" /> <ArrowRight className="w-8" />{' '}
            </div>
            <p className="my-2 text-xs sm:text-sm  tracking-[0.50em]">
              <FormattedMessage id="home.intro.right.discover" />
            </p>
          </div>
        </div>
      </div>
      <img src={dots} className=" absolute bottom-0 w-10" />
    </div>
  )
}

export default Right
