import React, { useEffect, useRef } from 'react'

function GoogleTranslate() {
  const googleTranslateElementInit = () => {
    if (!window.google) return
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element'
    )
  }
  useEffect(() => {
    if (
      !ggref ||
      !Object.keys(window).length ||
      !Object.keys(document).length
    )
      return
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
    return () => {
      if (
        !ggref ||
        !Object.keys(window).length ||
        !Object.keys(document).length
      )
        return
      window.googleTranslateElementInit = undefined
    }
  }, [])

  const ggref = useRef(null)
  return (
    <>
      <div ref={ggref} id="google_translate_element"></div>
    </>
  )
}

export default GoogleTranslate
