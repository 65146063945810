import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import RightDiv from './RightDiv'
import { setListing } from 'redux/listing'
import Btn from 'Components/Buttons/Btn'
import FormSelectBtn from 'Components/FormSelectBtn'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { colors } from '../../Resources/Data/colors'
import { transmissions } from '../../Resources/Data/transmissions'
import { drives } from '../../Resources/Data/drives'
import { origin } from '../../Resources/Data/origin'
import { fuel } from '../../Resources/Data/fuel'
import { useIntl } from 'react-intl'

interface Step1Props {
  pendingListing: any
  handleSubmit: () => void
  loading: boolean
}

const Step1: React.FC<Step1Props> = ({ pendingListing, handleSubmit, loading }) => {
  const dispatch = useDispatch()
  const [odometerDisplay, setOdometerDisplay] = useState('')
  const [charCount, setCharCount] = useState(0)
  const charLimit = 200
  const hasExceededLimit = charCount > charLimit

  const updatePendingListing = (property: string, value: any) => {
    dispatch(setListing({ [property]: value }))
  }

  const handleOverviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePendingListing('overview', e.target.value)
    setCharCount(e.target.value.length) // Update char count on every change
  }

  const canSubmit = () => {
    return (
      pendingListing.overview &&
      pendingListing.exteriorColor &&
      pendingListing.interiorColor &&
      pendingListing.transmission &&
      pendingListing.driveTrain &&
      // pendingListing.origin &&
      pendingListing.fuelType &&
      pendingListing.odometer &&
      pendingListing.sellOrTrade
    )
  }

  const intl = useIntl()

  return (
    <div className="flex space-x-3 sm:flex-col sm:space-x-0">
      <div className="rounded h-fit w-3/5 border-2  p-10 sm:w-full sm:p-8">
        <div className={clsx('w-4/5 my-4 sm:w-full')}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label={intl.formatMessage({ id: 'overview' })}
            multiline
            rows={8}
            placeholder="Tell us about your car (200 Characters)"
            onChange={handleOverviewChange}
            value={pendingListing.overview}
            inputProps={{
              maxLength: charLimit, // 3. Set the max length
            }}
            error={hasExceededLimit} // Display error when limit is exceeded
            helperText={hasExceededLimit ? `Exceeded by ${charCount - charLimit} characters` : `${charLimit - charCount} characters left`}
          />
        </div>

        <div className="w-4/5 my-4 mb-6 sm:w-full flex flex-col gap-4">
          <div>
            <h1 className="font-bold">{intl.formatMessage({ id: 'exterior_color' })}</h1>
            <Autocomplete
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: option.translation,
                }) || ''
              }
              className="w-full mb-5"
              disablePortal
              id="combo-box-demo"
              options={colors}
              value={colors.find((color) => color.name === pendingListing.exteriorColor) || null}
              onChange={(_, value) => updatePendingListing('exteriorColor', value ? value.name : null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label={intl.formatMessage({ id: 'select' })}
                />
              )}
            />
          </div>

          <div>
            <h1 className="font-bold">{intl.formatMessage({ id: 'interior_color' })}</h1>
            <Autocomplete
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: option.translation,
                }) || ''
              }
              className="w-full mb-5"
              disablePortal
              id="combo-box-demo"
              options={colors}
              value={colors.find((color) => color.name === pendingListing.interiorColor) || null}
              onChange={(_, value) => updatePendingListing('interiorColor', value ? value.name : null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label={intl.formatMessage({ id: 'select' })}
                />
              )}
            />
          </div>

          <div>
            <h1 className="font-bold">{intl.formatMessage({ id: 'transmission' })}</h1>
            <Autocomplete
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: option.translation,
                }) || ''
              }
              className="w-full mb-5"
              disablePortal
              id="combo-box-demo"
              options={transmissions}
              value={transmissions.find((transmission) => transmission.name === pendingListing.transmission) || null}
              onChange={(_, value) => updatePendingListing('transmission', value ? value.name : null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label={intl.formatMessage({ id: 'select' })}
                />
              )}
            />
          </div>

          <div>
            <h1 className="font-bold">{intl.formatMessage({ id: 'drivetrain' })}</h1>
            <Autocomplete
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: option.translation,
                }) || ''
              }
              className="w-full mb-5"
              disablePortal
              id="combo-box-demo"
              options={drives}
              value={drives.find((drive) => drive.name === pendingListing.driveTrain) || null}
              onChange={(_, value) => updatePendingListing('driveTrain', value ? value.name : null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label={intl.formatMessage({ id: 'select' })}
                />
              )}
            />
          </div>
          {/* 
Hide origin
          <div>
            <h1 className="font-bold">
              {intl.formatMessage({ id: 'origin' })}
            </h1>
            <Autocomplete
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: option.translation,
                }) || ''
              }
              className="w-full mb-5"
              disablePortal
              id="combo-box-demo"
              options={origin}
              value={
                origin.find(
                  (origin) => origin.name === pendingListing.origin
                ) || null
              }
              onChange={(_, value) =>
                updatePendingListing(
                  'origin',
                  value ? value.name : null
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                // label={intl.formatMessage({ id: 'select' })}
                />
              )}
            />
          </div> */}

          <div>
            <h1 className="font-bold">{intl.formatMessage({ id: 'fuel' })}</h1>
            <Autocomplete
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: option.translation,
                }) || ''
              }
              className="w-full mb-5"
              disablePortal
              id="combo-box-demo"
              options={fuel}
              value={fuel.find((fuel) => fuel.name === pendingListing.fuelType) || null}
              onChange={(_, value) => updatePendingListing('fuelType', value ? value.name : null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label={intl.formatMessage({ id: 'select' })}
                />
              )}
            />
          </div>
          <div>
            <h1 className="font-bold">{intl.formatMessage({ id: 'odometer' })} (km)</h1>
            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => {
                const rawValue = e.target.value.replace(/\D/g, '')
                // Remove any non-digit characters and parse the result as a number

                const odometerValue = parseInt(rawValue, 10)

                // Format the numbers to include commas
                let formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

                if (rawValue === '') {
                  updatePendingListing('odometer', null)
                  setOdometerDisplay('')
                } else if (!isNaN(odometerValue)) {
                  // Update the actual value in the Redux store
                  updatePendingListing('odometer', odometerValue)
                  // Update the odometer display
                  setOdometerDisplay(formattedValue)
                }
              }}
              // Use the formatted odometer value for display
              value={odometerDisplay ? odometerDisplay : pendingListing.odometer}
              inputProps={{
                maxLength: 7,
              }}
            />
          </div>
        </div>
        <div className="w-4/5 my-5 sm:w-full">
          <h1 className="my-2 font-bold">
            {intl.formatMessage({
              id: 'create_listing.steps.1.choice',
            })}
          </h1>
          <p className="my-2 text-xs">
            {intl.formatMessage({
              id: 'create_listing.steps.1.choice.headline',
            })}
          </p>
          <div className="my-4 flex w-full space-x-4  sm:space-x-1 ">
            <FormSelectBtn
              text={intl.formatMessage({
                id: 'create_listing.steps.1.choice.1',
              })}
              isActive={pendingListing.sellOrTrade === 'sell'}
              onClick={() => updatePendingListing('sellOrTrade', 'sell')}
            />
            <FormSelectBtn
              text={intl.formatMessage({
                id: 'create_listing.steps.1.choice.2',
              })}
              isActive={pendingListing.sellOrTrade === 'trade'}
              onClick={() => updatePendingListing('sellOrTrade', 'trade')}
            />
            <FormSelectBtn
              text={intl.formatMessage({
                id: 'create_listing.steps.1.choice.3',
              })}
              isActive={pendingListing.sellOrTrade === 'dealers'}
              onClick={() => updatePendingListing('sellOrTrade', 'dealers')}
            />
          </div>
        </div>

        <div className="w-4/5 my-2 sm:w-full">
          <Btn
            text={intl.formatMessage({ id: 'continue' })}
            className="continue-btn w-2/5 sm:w-3/5"
            handleSubmit={handleSubmit}
            disabled={!canSubmit()}
            loading={loading}
          />
        </div>
      </div>

      <RightDiv loading={loading} />
    </div>
  )
}

export default Step1
