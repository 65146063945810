import { Fuel } from '../Types/common';

export const fuel: Fuel[] = [
  { _id: '1', name: 'gas', label: 'Gas', translation: 'gas' },
  {
    _id: '2',
    name: 'diesel',
    label: 'Diesel',
    translation: 'diesel',
  },
  {
    _id: '3',
    name: 'hybrid',
    label: 'Hybrid',
    translation: 'hybrid',
  },
  {
    _id: '4',
    name: 'electric',
    label: 'Electric',
    translation: 'electric',
  },
  {
    _id: '5',
    name: 'hydrogen',
    label: 'Hydrogen',
    translation: 'hydrogen',
  },
  {
    _id: '6',
    name: 'unknown',
    label: 'Unknown',
    translation: 'unknown',
  },
]