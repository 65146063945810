import React from "react";
import clsx from "clsx";

function CustomInput({
  error,
  handleChange,
  name,
  value,
  autoComplete,
  type,
  placeholder,
  onEnter,
}) {
  return (
    <div className='w-full'>
      <input
        className={clsx(
          "outline-none lg:text-md xl:text-lg text-[21px] border-0 bg-[#F0F0F0] p-4 w-full rounded-md shadow ",
          {
            ["!border-2 border-red-800"]: !!error,
          }
        )}
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={handleChange}
        value={value}
        autoComplete={autoComplete}
        onKeyUp={(e) => {
          if (onEnter) {
            if (e.key === "Enter") {
              onEnter();
            }
          }
        }}
      />
      {error && <div className="text-sm text-red-700">{error}</div>}
    </div>
  );
}

export default CustomInput;
