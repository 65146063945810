import React from 'react'

function ArrowLeft({ stroke = '#ffffff', ...rest }) {
  return (
    <svg
      {...rest}
      width="17"
      height="26"
      viewBox="0 0 17 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.94028 1.9999L12.6618 12.7214L1.94028 23.443"
        stroke={stroke}
        strokeWidth="5"
        className="stroke-white cursor-pointer  w-full h-full  opacity-50 hover:opacity-100"
      />
    </svg>
  )
}

export default ArrowLeft
