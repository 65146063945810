import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowdown.svg'
import TextField from '@mui/material/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import editPNG from '../../assets/icons/edit.svg'
import Autocomplete from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { APIEndpoints, MicroServices } from 'staticStore'
import useMutation from 'redux/hooks/useMutation'
import { setUserData } from 'redux/user'
import MalePicture from '../../assets/images/testinomial2.png'
import FemalePicture from '../../assets/images/testinomial1.png'
import UploadSection from 'Components/UploadSection'
import Close from '@mui/icons-material/Close'
import { v4 as uuidv4 } from 'uuid'
import { Popover } from '@mui/material'
const MyInfo = () => {
  const user = useSelector((state) => state.user)
  const [usrData, setUsrData] = useState({
    endName: user.data?.endName || '',
    endEmail: user.data?.endEmail || '',
    endImage: user.data?.endImage || '',
    endPhone: user.data?.endPhone || '',
    city: user.data?.city || '',
    gender: user.data?.gender || '',
  })

  const [formValidity, setFormValidity] = useState({
    endName: true,
    endEmail: true,
    endPhone: true,
    endImage: true,
    city: true,
    gender: true,
    canSubmit: false,
  })
  const intl = useIntl()

  const validateFields = (usrData) => {
    const isDataChanged =
      usrData.endName !== user.data?.endName ||
      usrData.endEmail !== user.data?.endEmail ||
      usrData.endPhone !== user.data?.endPhone ||
      (usrData.endPhone ?? '') !== (user.data?.endPhone ?? '') ||
      usrData.endImage !== user.data?.endImage ||
      (usrData.endImage ?? '') !== (user.data?.endImage ?? '') ||
      (usrData.city ?? '') !== (user.data?.city ?? '') ||
      (usrData.gender ?? '') !== (user.data?.gender ?? '')
    console.log('isDataChanged', isDataChanged)
    const validation = {
      endName: usrData.endName.trim() !== '',
      endEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(usrData.endEmail),
      endImage: true, // Allow endImage to be optional
      endPhone: true, // Allow endPhone to be optional
      city: true, // Allow city to be optional
      gender: true, // Allow gender to be optional
    }
    console.log('validation', validation)

    const canSubmit = validation.endName && validation.endEmail && isDataChanged

    setFormValidity({ ...validation, canSubmit })
  }

  const handleFieldChange = (field, value) => {
    setUsrData((prevData) => {
      return { ...prevData, [field]: value }
    })
  }
  const [updateInfo] = useMutation({
    endPoint: `${APIEndpoints.UPDATE_INFO}`,
    method: 'put',
  })

  useEffect(() => {
    console.log('usrData', usrData)
    console.log('user', user.data)
    validateFields(usrData)
  }, [usrData, user.data])

  const dispatch = useDispatch()

  const handleSubmit = () => {
    if (formValidity.canSubmit) {
      console.log('Submitted data:', usrData)
      // Add your logic to handle form submission using usrData
      console.log('localStorage', localStorage.getItem('accessToken'))
      updateInfo(usrData, {
        headers: {
          'Auth-Token': `${localStorage.getItem('accessToken')}`,
        },
      }).then((res) => {
        console.log('res', res)
        if (res.data) {
          // compare the data from the backend and the data from the frontend
          // if they are the same, then do nothing
          // if they are different, then update the data from the frontend to the backend

          setUsrData({
            endName: res.data.endName,
            endEmail: res.data.endEmail,
            endImage: res.data.endImage,
            endPhone: res.data.endPhone,
            city: res.data.city,
            gender: res.data.gender,
          })
          //save res.accessToken to localstorage
          localStorage.setItem('accessToken', res.accessToken)
          dispatch(
            setUserData({
              ...user.data,
              endName: res.data.endName,
              endEmail: res.data.endEmail,
              endImage: res.data.endImage,
              endPhone: res.data.endPhone,
              city: res.data.city,
              gender: res.data.gender,
            })
          )
          setDisabled(true)
        }
      })
    } else {
      // toast.error('Please fill in all required fields')
    }
  }

  const [disabled, setDisabled] = useState(true)

  const handleToggleEdit = () => {
    setDisabled((prev) => !prev)
  }

  const inputRef = useRef()

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = URL.createObjectURL(file)

      image.onload = () => {
        const canvas = document.createElement('canvas')
        let { width, height } = image

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }
        }

        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)

        canvas.toBlob(resolve, file.type)
      }

      image.onerror = reject
    })
  }

  const handleUploadFile = async (e) => {
    console.log('handleUploadFile', e)
    const id = uuidv4()
    const uploadImagesEndPoint = `${MicroServices.GATEWAY}${APIEndpoints.UPLOAD_IMAGES}?listing=false`.replace(':id', id.replace(/-/g, ''))

    console.log('uploadImagesEndPoint', uploadImagesEndPoint)

    // Initialize FormData
    const formData = new FormData()

    // Iterate through the files (even if there's only one)
    for (let file of e.target.files) {
      const resizedImageBlob = await resizeImage(file, 500, 500)
      const resizedImageFile = new File([resizedImageBlob], file.name, { type: file.type })
      formData.append('post_listing_image', resizedImageFile) // Append resized images to formData
    }
    fetch(uploadImagesEndPoint, {
      method: 'PUT',
      headers: {
        'Auth-Token': `${localStorage.getItem('accessToken')}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        console.log('UPLOAD IMAGES', res)
        if (res.error) {
          // Handle error
          return
        }
        // Handle success
        console.log('res.data[0]', res.data[0])
        handleFieldChange('endImage', res.data[0].data.url)
      })
      .catch((error) => {
        console.error('Failed to upload images', error)
        // Handle failure
      })
  }

  const handleRemoveImage = () => {
    handleFieldChange('endImage', '')
  }

  const [imgPopupOpen, setImgPopupOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleImgClick = (event) => {
    setAnchorEl(event.currentTarget)
    setImgPopupOpen(true)
  }

  const handleClose = () => {
    setImgPopupOpen(false)
    setAnchorEl(null)
  }

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleClose)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleClose)
    }
  }, [])

  return (
    <>
      <div className="tab-pane fade show active" id="tabs-homeVertical" role="tabpanel" aria-labelledby="tabs-home-tabVertical">
        {
          // if  usrData.endImage is not empty and not the user.data.endImage, then show the close icon
          !disabled && usrData.endImage && usrData.endImage !== user.data?.endImage && (
            <div className="relative">
              <Close className="absolute top-3 right-[30%] cursor-pointer" onClick={() => handleRemoveImage()} />
            </div>
          )
        }
        <div className=" mb-6 w-full h-full flex flex-col   justify-center items-center text-center">
          {/* show rounde user.endImage */}
          <img
            src={
              usrData.endImage?.length
                ? usrData.endImage
                : !user.data?.gender
                ? MalePicture
                : user.data?.gender == 'Male'
                ? MalePicture
                : FemalePicture
            }
            className="rounded-full border w-44 h-44 opacity-100 hover:opacity-80"
            onClick={handleImgClick}
          />

          <Popover
            open={imgPopupOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            PaperProps={{
              style: {
                padding: '0',
                overflow: 'hidden',
                position: 'relative',
                width: '25%',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}>
            <img
              src={
                usrData.endImage?.length
                  ? usrData.endImage
                  : !user.data?.gender
                  ? MalePicture
                  : user.data?.gender === 'Male'
                  ? MalePicture
                  : FemalePicture
              }
              alt="User"
              className="object-contain border"
            />
          </Popover>
          <div className="w-full  my-2 flex flex-col items-center justify-center ">
            <button
              onClick={() => inputRef.current.click()}
              className={clsx('p-3 w-[25%] mt-2 rounded shadow-md', 'bg-[#105E26] text-white', {
                'shadow-[#105E26]': !disabled && formValidity.canSubmit,
                'bg-gray-400 cursor-not-allowed': disabled,
              })}
              disabled={disabled}>
              {intl.formatMessage({
                id: 'create_listing.steps.3.upload_photos',
              })}
            </button>

            <input type="file" ref={inputRef} style={{ display: 'none' }} accept="image/*" onChange={handleUploadFile} />
          </div>
        </div>

        <div
          className="mb-6 w-full h-full flex justify-end items-center text-center
         
        ">
          {' '}
          <img
            src={editPNG}
            onClick={handleToggleEdit}
            className="
           bg-gray-100 rounded-md w-12 h-12 p-2 cursor-pointer  hover:bg-gray-200 transition-all duration-300 ease-in-out
          "
          />
        </div>
        <div className="mb-6">
          <TextField
            className="  text-gray-900 border border-[#105e2675] bg-[#F8FBFE]"
            disabled={disabled}
            fullWidth
            label={intl.formatMessage({ id: 'full_name' })}
            variant="outlined"
            value={usrData.endName}
            onChange={(e) => handleFieldChange('endName', e.target.value)}
          />
        </div>
        <div className="mb-6"></div>
        <div className={'mb-6 ' + user.data?.uid ? ' mb-3' : ''}>
          <TextField
            className="  text-gray-900 border border-[#105e2675] bg-[#F8FBFE]"
            disabled={!!user.data?.uid ?? disabled}
            fullWidth
            label={intl.formatMessage({ id: 'email' })}
            variant="outlined"
            value={usrData.endEmail}
            onChange={(e) => user.data?.uid ?? handleFieldChange('endEmail', e.target.value)}
          />
        </div>
        {/* alert them in red that they can not change if user.data.uid because they already have social sign in */}
        {!disabled ?? !!user.data?.uid ? (
          <div className="mb-6">
            <p className="text-red-500">You can not change your email because you already have social sign in</p>
          </div>
        ) : null}
        <div className="mb-6">
          <TextField
            className="  text-gray-900 border border-[#105e2675] bg-[#F8FBFE]"
            disabled={disabled}
            fullWidth
            label={intl.formatMessage({ id: 'phone_number' })}
            variant="outlined"
            value={usrData.endPhone}
            onChange={(e) => handleFieldChange('endPhone', e.target.value)}
          />
        </div>
        <div className="mb-6">
          <TextField
            className="  text-gray-900 border border-[#105e2675] bg-[#F8FBFE]"
            disabled={disabled}
            fullWidth
            label={intl.formatMessage({ id: 'city' })}
            variant="outlined"
            value={usrData.city}
            onChange={(e) => handleFieldChange('city', e.target.value)}
          />
        </div>
        <div className="mb-6">
          <Autocomplete
            disabled={disabled}
            className="w-full mb-5"
            disablePortal
            id="combo-box-demo"
            options={['Male', 'Female']}
            value={usrData.gender}
            onChange={(_, value) => handleFieldChange('gender', value)}
            renderInput={(params) => (
              <TextField
                className="  text-gray-900 border border-[#105e2675] bg-[#F8FBFE]"
                disabled={disabled}
                {...params}
                fullWidth
                label={intl.formatMessage({ id: 'gender' })}
              />
            )}
          />
        </div>
        <div className=" flex justify-end">
          {/* <p className="text-gray-700">{intl.formatMessage({ id: 'my_info.changed' })} </p> */}
          {/* save green button */}
          <button
            onClick={handleSubmit}
            className={clsx('p-3 w-[25%] mt-2 rounded shadow-md', 'bg-[#105E26] text-white', {
              'shadow-[#105E26]': !disabled && formValidity.canSubmit,
              'bg-gray-400 cursor-not-allowed': disabled || !formValidity.canSubmit,
            })}
            disabled={disabled || !formValidity.canSubmit}>
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default MyInfo
