import React, { Component } from 'react'
import Slider from 'react-slick'
import RightArrow from '../../assets/icons/slider-right-arrow.jsx'
import LeftArrow from '../../assets/icons/slider-active-left-arrow.jsx'
import classes from './style.module.css'
import './style.css'
import useMediaQuery from '@mui/material/useMediaQuery'
import TestinomialCard from 'Components/Cards/TestinomialCard.jsx'

function SampleArrow({ classes, style, onClick, color = '' }) {
  return (
    <LeftArrow
      classes={classes}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      color={color}
    />
  )
}

function ReviewSlider({ reviews, users }) {
  const isMobile = useMediaQuery('(max-width:768px)')
  const isDesktop = useMediaQuery('(max-width:1280px)')
  const isBigDesktop = useMediaQuery('(max-width:1440px)')

  const perRow = !!isMobile ? 3 : !!isDesktop ? 4 : !!isBigDesktop ? 5 : 6

  const settings = (length) => {
    return {
      adaptiveHeight: true,
      infinite: true,
      slidesToShow: length <= perRow ? length : perRow,
      speed: 500,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      swipeToSlide: true,
      swipe: true,
      nextArrow: <SampleArrow classes=" w-16 -rotate-180 " />,
      prevArrow: <SampleArrow classes=" w-16  " />,
    }
  }

  // console.log({ SlickSlider: data.length })

  return (
    <div className=" w-full h-[50vh] flex justify-center items-center ">
      <Slider
        {...settings(reviews.length)}
        className=" w-full h-[50vh] xxl:px-20  xl:px-10   overflow-hidden flex flex-row justify-center items-center"
      >
        <div>
          {reviews.map((rev, index) => (
            <div className="w-full h-full relative  z-[999999]  bg-slate-600">
              <TestinomialCard
                key={index}
                name={users[rev.endUserID]?.endName}
                text={rev?.comment}
              />
            </div>
          ))}
        </div>
      </Slider>
    </div>
  )
}

export default ReviewSlider
