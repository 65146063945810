import React from "react";

const DownloadBannerPrimaryDots = ({ className }) => {
  return (
    <svg
      width="184"
      height="139"
      viewBox="0 0 184 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="91.7049"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 91.7049 135.273)"
        fill="white"
      />
      <circle
        cx="91.7049"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 91.7049 113.217)"
        fill="white"
      />
      <circle
        cx="91.7049"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 91.7049 91.1603)"
        fill="white"
      />
      <circle
        cx="91.7049"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 91.7049 69.1042)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 113.762 135.273)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 113.762 113.217)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 113.762 91.1603)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 113.762 69.1042)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 135.818 135.273)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 135.818 113.217)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 135.818 91.1603)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 135.818 69.1042)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 157.873 135.273)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 157.873 113.217)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 157.873 91.1603)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 157.873 69.1042)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 179.93 135.273)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 179.93 113.217)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 179.93 91.1603)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 179.93 69.1042)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 3.076 135.273)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 3.076 113.217)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 3.076 91.1603)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 3.076 69.1042)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 25.1326 135.273)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 25.1326 113.217)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 25.1326 91.1603)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 25.1326 69.1042)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 47.1893 135.273)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 47.1893 113.217)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 47.1893 91.1603)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 47.1893 69.1042)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 69.244 135.273)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 69.244 113.217)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 69.244 91.1603)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 69.244 69.1042)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="135.273"
        r="3.07601"
        transform="rotate(-90 91.3006 135.273)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="113.217"
        r="3.07601"
        transform="rotate(-90 91.3006 113.217)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="91.1603"
        r="3.07601"
        transform="rotate(-90 91.3006 91.1603)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="69.1042"
        r="3.07601"
        transform="rotate(-90 91.3006 69.1042)"
        fill="white"
      />
      <circle
        cx="91.7049"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 91.7049 69.5939)"
        fill="white"
      />
      <circle
        cx="91.7049"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 91.7049 47.5375)"
        fill="white"
      />
      <circle
        cx="91.7049"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 91.7049 25.4811)"
        fill="white"
      />
      <circle
        cx="91.7049"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 91.7049 3.42522)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 113.762 69.5939)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 113.762 47.5375)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 113.762 25.4811)"
        fill="white"
      />
      <circle
        cx="113.762"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 113.762 3.42522)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 135.818 69.5939)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 135.818 47.5375)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 135.818 25.4811)"
        fill="white"
      />
      <circle
        cx="135.818"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 135.818 3.42522)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 157.873 69.5939)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 157.873 47.5375)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 157.873 25.4811)"
        fill="white"
      />
      <circle
        cx="157.873"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 157.873 3.42522)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 179.93 69.5939)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 179.93 47.5375)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 179.93 25.4811)"
        fill="white"
      />
      <circle
        cx="179.93"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 179.93 3.42522)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 3.076 69.5939)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 3.076 47.5375)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 3.076 25.4811)"
        fill="white"
      />
      <circle
        cx="3.076"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 3.076 3.42522)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 25.1326 69.5939)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 25.1326 47.5375)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 25.1326 25.4811)"
        fill="white"
      />
      <circle
        cx="25.1326"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 25.1326 3.42522)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 47.1893 69.5939)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 47.1893 47.5375)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 47.1893 25.4811)"
        fill="white"
      />
      <circle
        cx="47.1893"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 47.1893 3.42522)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 69.244 69.5939)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 69.244 47.5375)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 69.244 25.4811)"
        fill="white"
      />
      <circle
        cx="69.244"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 69.244 3.42522)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="69.5939"
        r="3.07601"
        transform="rotate(-90 91.3006 69.5939)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="47.5375"
        r="3.07601"
        transform="rotate(-90 91.3006 47.5375)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="25.4811"
        r="3.07601"
        transform="rotate(-90 91.3006 25.4811)"
        fill="white"
      />
      <circle
        cx="91.3006"
        cy="3.42522"
        r="3.07601"
        transform="rotate(-90 91.3006 3.42522)"
        fill="white"
      />
    </svg>
  );
};

export default DownloadBannerPrimaryDots;
