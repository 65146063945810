import DealerStat from 'Components/DealerStat'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

function DealerReview() {
  const id = useParams().id
  useEffect(() => {
    console.log(id)
  }, [id])
  return (
    <div className="w-full h-full p-8">
      Dealer Review {id}
      <div className="w-full h-full flex flex-row">
        <div>
          <DealerStat />
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default DealerReview
