import { Seating } from '../Types/common'

export const seating: Seating[] = [
  {
    _id: '1',
    name: 'leatherSeats',
    label: 'Leather Seats',
    translation: 'leather_seats',
  },
  {
    _id: '2',
    name: 'clothSeats',
    label: 'Cloth Seats',
    translation: 'cloth_seats',
  },
  {
    _id: '3',
    name: 'suedeSeats',
    label: 'Suede Seats',
    translation: 'suede_seats',
  },
  // {
  //   _id: '4',
  //   name: 'vinylSeats',
  //   label: 'Vinyl Seats',
  //   translation: 'vinyl_seats',
  // },
  {
    _id: '5',
    name: 'heatedSeats',
    label: 'Heated Seats',
    translation: 'heated_seats',
  },
  {
    _id: '6',
    name: 'memorySeats',
    label: 'Memory Seats',
    translation: 'memory_seats',
  },
  {
    _id: '7',
    name: 'powerSeats',
    label: 'Power Seats',
    translation: 'power_seats',
  },
]
