import React from "react";

function RightArrowIcon({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="53"
      height="31"
      viewBox="0 0 53 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53 15.4959C52.9782 14.9417 52.7488 14.3011 52.3666 13.8973L39.8958 0.721726C39.0509 -0.0334918 37.4302 -0.34488 36.4859 0.528194C35.5557 1.38829 35.5834 3.05555 36.5098 3.91885L45.2782 13.1708H2.33836C1.04715 13.1708 0 14.212 0 15.4959C0 16.7799 1.04711 17.8211 2.33836 17.8211H45.2782L36.5098 27.073C35.7117 27.8677 35.5644 29.5944 36.4859 30.4637C37.4069 31.3335 39.0852 31.0557 39.8958 30.2696L52.3666 17.0941C52.7934 16.6432 53.0011 16.114 53 15.4955V15.4959Z"
        fill={fill}
      />
    </svg>
  );
}

export default RightArrowIcon;
