import React, { Fragment, useState, useRef, useEffect } from 'react'
import {
  DeleteIcon,
  DropDownIcon,
} from '../../assets/icons/navbarIcons'
import SearchIcon from '../../assets/icons/searchicon.jsx'
import SearchListing from 'Components/SearchListing/index.jsx'
import { cleanSearch } from 'Utils/Search/Filter.js'
import { capitalizeAllFirstLetter } from 'Utils/Parse'
import { APIEndpoints, MicroServices } from 'staticStore'
import {
  cleanQuery,
  GetSearchQuery,
  refactorQuery,
  translateSearchObject,
  translateSearchQuery,
} from 'Utils/Parse/Query'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorder'
import { useIntl } from 'react-intl'

function SearchBar({
  initValue = '',
  translatedInitValue = '',
  inputClassName = '',
  listClassName = '',
  savedSearches = [],
  handleOnClickSearch = () => {},
  handleSaveSearchDelete = (e) => {},
  children = null,
}) {
  const intl = useIntl()
  const backupResult = [
    {
      condition: 'all',
      title: 'All',
      translated: `${intl.formatMessage({
        id: 'all',
      })}`,

      _id: 'all_all',
    },
    {
      condition: 'new',
      title: 'All (New)',
      translated: `${intl.formatMessage({
        id: 'search.all',
      })} (${intl.formatMessage({
        id: 'new',
      })})`,

      _id: 'all_new',
    },
    {
      condition: 'used',
      title: 'All (Used)',
      translated: `${intl.formatMessage({
        id: 'search.all',
      })} (${intl.formatMessage({
        id: 'used',
      })})`,
      _id: 'all_used',
    },
  ]

  const [search, setSearch] = React.useState('')
  const [isFocused, setIsFocused] = useState(false)
  useEffect(() => {
    const getData = async () => {
      const res = await fetch(
        `${MicroServices.GATEWAY}${APIEndpoints.FIND_MAKES_AND_MODELS}`
      )
      const data = await res.json()
      setData(data || [])
    }
    getData()
  }, [])

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  const handleOnSearch = async (e) => {
    if (!data.length) return
    data.map((item) => {
      item.title = capitalizeAllFirstLetter(item.title)
      return item
    })
    const filtered = data.filter((item) => {
      return (
        item.title
          ?.trim()
          .toLowerCase()
          .search(e.target.value?.trim().toLowerCase()) > -1
      )
    })
    const translatedFiltered = filtered.map((item) => {
      item.translated = translateSearchObject(item.title, intl)
      console.log(item)
      return item
    })
    setFilteredData(
      !translatedFiltered.length
        ? backupResult
        : translatedFiltered.slice(0, 5)
    )
  }
  const listRef = useRef()

  const handleSearch = async (e) => {
    const value = e.target.value
    setSearch(value)
    setTranslated(value)
    e.target.value = cleanSearch(value)
    setIsFocused(true)
    handleOnSearch(e)
  }
  const handleOnClick = (e, item) => {
    setSearch(item.title)
    setTranslated(item.translated)
    setIsFocused(false)
    handleOnClickSearch(item)
  }

  const [translated, setTranslated] = useState('')
  useEffect(() => {
    setSearch(initValue)
    setTranslated(translatedInitValue)
  }, [initValue, translatedInitValue])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!listRef.current) return
      if (
        !listRef.current.contains(e.target) &&
        !inputRef.current.contains(e.target)
      ) {
        setIsFocused(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [listRef])

  const inputRef = useRef()

  return (
    <div className=" w-full h-full">
      <div
        className={
          'flex flex-row items-center px-5 w-full   sm:w-full h-12  xxl:h-[55px] ' +
          inputClassName
        }>
        <SearchIcon />
        <input
          ref={inputRef}
          onClick={handleSearch}
          onFocus={() => setIsFocused(true)}
          value={translated}
          onChange={handleSearch}
          className="my-2 w-full flex-auto sm:w-1/2 sm:p-1  px-3 bg-inherit  outline-none h-11 xxl:h-[54px] xxl:text-sm"
          // defaultValue=""
          placeholder={intl.formatMessage({
            id: 'search.placeholder',
          })}
          // style={{ border: "2px solid red" }}
        />
        <div className=" justify-end">
          {search && (
            <button
              onClick={() => {
                setSearch('')
                setTranslated('')
                handleOnSearch({ target: { value: '' } })
                inputRef.current.focus()
              }}
              className="flex items-center justify-center w-8 h-8 rounded-full bg-[#F0F0F0] ">
              <DeleteIcon />
            </button>
          )}
        </div>
      </div>
      {isFocused && (
        <div ref={listRef} className={' ' + listClassName}>
          <ul className="flex flex-col">
            {savedSearches.length > 0 && (
              <div className="pl-4 mt-4 pb-2 text-left flex items-center">
                <i>
                  <FavoriteBorderOutlinedIcon />
                </i>
                <span className="ml-2">
                  {intl.formatMessage({
                    id: 'saved_searches',
                  })}
                </span>
              </div>
            )}
            {savedSearches.map((item) => {
              const title = GetSearchQuery(
                refactorQuery(item?.filter)
              )

              const translatedTitle = translateSearchObject(
                title,
                intl
              )

              const handleSavedSearchClick = (item) => {
                setSearch(title)
                setIsFocused(false)
                const _item = cleanQuery(refactorQuery(item?.filter))
                if (_item) {
                  handleOnClickSearch(_item)
                }
              }

              const handleDelete = () => {
                handleSaveSearchDelete(item)
              }

              return (
                <SearchListing
                  handleClick={() => handleSavedSearchClick(item)}
                  handleDelete={handleDelete}
                  key={item._id}>
                  {translatedTitle}
                </SearchListing>
              )
            })}
            {filteredData.map((item, index) => {
              return (
                <SearchListing
                  handleClick={(e) => handleOnClick(e, item)}
                  key={item._id}>
                  {item.translated}
                </SearchListing>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SearchBar
