import React from "react";
import arrow from "../../priceDrop.png";

function PriceDropIcon({ fill = "#ffffff", ...rest }) {
  return (
    // <div className="bg-[rgb(255,211,55)] w-6 h-6 flex items-center justify-center rounded-full">
    <img src={arrow} />
    // </div>
  );
}

export default PriceDropIcon;
