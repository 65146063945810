import React from 'react'
import { Rating } from 'react-simple-star-rating'
import writeReviewSVG from '../assets/icons/write-review.svg'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

function DealerStat({
  dealerRating = null,
  endUserID = null,
  postID = null,
}) {
  const navigate = useNavigate()

  const intl = useIntl()

  return (
    <div className="pt-10 sm:p-4 pr-0  h-[fit]  sm:w-full sm:my-4  sm:border-t-2 ">
      <p className="text-[25px] font-[700]">
        {intl.formatMessage({
          id: 'listing.tabs.seller.reviews.title',
        })}
      </p>
      <div className="flex items-center  mt-0  space-x-2">
        <Rating
          initialValue={dealerRating ? dealerRating?.avgRating : 5}
          count={5}
          size={20}
          readonly={true}
          //green fill
          fillColor={'#105E26'}
          isHalf={true}
        />
        <span className="text-xs mt-2">
          {dealerRating ? dealerRating?.avgRating : '?'}{' '}
          {intl.formatMessage({
            id: 'listing.tabs.seller.reviews.rate',
          })}
        </span>
      </div>
      <p className="my-1 text-[14px]">
        {dealerRating ? dealerRating?.total : 0}{' '}
        {intl.formatMessage({
          id: 'listing.tabs.seller.reviews.global',
        })}
      </p>

      <div className="my-4 flex flex-col  lg:w-full ">
        <div className="my-1 flex items-center justify-between  space-x-2">
          <p className=" text-[0.85rem]  ">
            {' '}
            5{' '}
            {intl.formatMessage({
              id: 'listing.tabs.seller.reviews.star',
            })}
          </p>
          <div className="   max-w-[50%] w-full   flex bg-gray-200 rounded-md h-4  dark:bg-gray-300">
            <div
              className="bg-[#105E26]  h-4 rounded-md dark:bg-[#105E26]"
              style={{
                width: `${
                  dealerRating ? dealerRating?.listPercentage[0] : 0
                }%`,
              }}></div>
          </div>
          <p className=" w-[50px]  ">
            {dealerRating ? dealerRating?.listPercentage[0] : 0}%{' '}
          </p>
        </div>
        <div className="my-1 flex items-center justify-between space-x-2">
          <p className=" text-[0.85rem]  ">
            {' '}
            4{' '}
            {intl.formatMessage({
              id: 'listing.tabs.seller.reviews.star',
            })}{' '}
          </p>
          <div className="   max-w-[50%] w-full    flex bg-gray-200 rounded-md h-4  dark:bg-gray-300">
            <div
              className="bg-[#105E26] h-4 rounded-md dark:bg-[#105E26]"
              style={{
                width: `${
                  dealerRating ? dealerRating?.listPercentage[1] : 0
                }%`,
              }}></div>
          </div>
          <p className=" w-[50px]  ">
            {dealerRating ? dealerRating?.listPercentage[1] : 0}%
          </p>
        </div>
        <div className="my-1 flex items-center justify-between space-x-2">
          <p className=" text-[0.85rem]  ">
            {' '}
            3{' '}
            {intl.formatMessage({
              id: 'listing.tabs.seller.reviews.star',
            })}{' '}
          </p>
          <div className="   max-w-[50%] w-full   flex bg-gray-200 rounded-md h-4  dark:bg-gray-300">
            <div
              className="bg-[#105E26] h-4 rounded-md dark:bg-[#105E26]"
              style={{
                width: `${
                  dealerRating ? dealerRating?.listPercentage[2] : 0
                }%`,
              }}></div>
          </div>
          <p className=" w-[50px]  ">
            {dealerRating ? dealerRating?.listPercentage[2] : 0}%
          </p>
        </div>
        <div className="my-1 flex items-center justify-between space-x-2">
          <p className=" text-[0.85rem]  ">
            {' '}
            2{' '}
            {intl.formatMessage({
              id: 'listing.tabs.seller.reviews.star',
            })}{' '}
          </p>
          <div className="  max-w-[50%] w-full   flex bg-gray-200 rounded-md h-4  dark:bg-gray-300">
            <div
              className="bg-[#105E26]   h-4  rounded-md dark:bg-[#105E26]"
              style={{
                width: `${
                  dealerRating ? dealerRating?.listPercentage[3] : 0
                }%`,
              }}></div>
          </div>
          <p className=" w-[50px]  ">
            {dealerRating ? dealerRating?.listPercentage[3] : 0}%
          </p>
        </div>
        <div className="my-1 flex items-center justify-between space-x-2">
          <p className=" text-[0.85rem]  ">
            {' '}
            1{' '}
            {intl.formatMessage({
              id: 'listing.tabs.seller.reviews.star',
            })}{' '}
          </p>
          <div className="   max-w-[50%] w-full   flex bg-gray-200 rounded-md h-4  dark:bg-gray-300">
            <div
              className="bg-[#105E26] h-4 rounded-md dark:bg-[#105E26]"
              style={{
                width: `${
                  dealerRating ? dealerRating?.listPercentage[4] : 0
                }%`,
              }}></div>
          </div>
          <p className=" w-[50px]  ">
            {dealerRating ? dealerRating?.listPercentage[4] : 0}%
          </p>
        </div>
        <div
          // onClick={() => {
          //   navigate(`/dealer-review/${endUserID}`)
          //   window.scrollTo(0, 0)
          // }}
          className="my-1 flex justify-end items-center  cursor-pointer ">
          <p className=" text-[0.85rem]  text-[#105e26] ">
            {dealerRating ? dealerRating?.total : 0}{' '}
            {intl.formatMessage({
              id: 'listing.tabs.seller.reviews.review',
            })}
          </p>
        </div>
        <div className=" flex justify-center my-2">
          <button
            onClick={() => {
              navigate(`/leave-review/${postID}`)
              window.scrollTo(0, 0)
            }}
            className="bg-[#105E26] flex flex-row lg:flex-col justify-center items-center p-3 lg:p-2 text-white mt-2 rounded shadow-md shadow-[#105E26]">
            {intl.formatMessage({
              id: 'listing.tabs.seller.reviews.add',
            })}
            {/*  write-review.svg */}
            <img
              src={writeReviewSVG}
              className=" lg:pl-0 pl-2 inline-block"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default DealerStat
