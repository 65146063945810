import React, { useRef, useState } from 'react'
import { Link, useNavigate, useNavigation } from 'react-router-dom'
import { setLoggedIn, setUserData } from 'redux/user'
import { useDispatch } from 'react-redux'
import CustomInput from 'Components/CustomInput'
import { isEmail } from 'utils'
import clsx from 'clsx'
import TinnySpinner from 'Components/TinnySpinner'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import TickIcon from '@mui/icons-material/FileDownloadDone'
import WrongIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import SocialSignup from 'Components/SocialSignup'


const Left = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    email: '',
    password: '',
  })

  const [checkEmail, { loading: checkEmailLoading, error: checkEmailError, data: checkEmailData }] = useMutation({
    method: 'post',
    endPoint: APIEndpoints.CHECK_EMAIL,
  })

  const [login, { loading: loginLoading, error: loginError, data: loginData }] = useMutation({
    method: 'post',
    endPoint: APIEndpoints.LOGIN,
  })

  const handleChange = (ev) => {
    const { name, value } = ev.target
    setState({ ...state, [name]: value })
    if (name === 'password') {
      console.log('Password: ', value)
    }
    if (name === 'endPassword') {
      console.log('End Password: ', value)
    }
    if (name === 'email') {
      console.log('Entered email: ', value)
      if (isEmail(value)) {
        console.log('Email is valid')
        if (value.endsWith('.com')) {
          checkEmail({ endEmail: value })
            .then((data) => {
              console.log('Server response: ', data)
            })
            .catch((err) => {
              console.error('Error checking email: ', err)
            })
        }
      } else {
        console.log('Email is not valid')
      }
    }
  }

  console.log('process.env.REACT_APP_GATEWAY_API_URL', process.env.REACT_APP_GATEWAY_API_URL)

  console.log('Checking Email Error, Data, Loading: >>>> ', {
    checkEmailError,
    checkEmailData,
    checkEmailLoading,
  })

  const canSubmit = () => !checkEmailLoading && !loginLoading && state.password && isEmail(state.email)

  const emailIcon = isEmail(state.email) ? (
    checkEmailError ? (
      <WrongIcon htmlColor="#FF0000" className="absolute  top-[17px] right-3" />
    ) : checkEmailData && isEmail(state.email) ? (
      <TickIcon htmlColor="#105E26" className="absolute  top-[17px] right-3" />
    ) : null
  ) : null

  const handleAfterLogin = (data) => {
    console.log('HANDLE AFTER LOGIN DATA: >>>> ', { data })
    if (data?.accessToken) {
      localStorage.setItem('accessToken', data.accessToken)
    }
    if (data?.data) {
      // alert("loggedIn");
      dispatch(setUserData(data.data))
      dispatch(setLoggedIn(true))
      navigate('/')
    }
  }

  const handleSubmit = () => {
    if (canSubmit()) {
      login({
        endEmail: state.email,
        endPassword: state.password,
      }).then((data) => {
        handleAfterLogin(data)
      })
    }
  }

  const intl = useIntl()

  return (
    <div className="    flex flex-col justify-center p-10   my-3 rounded-2xl border-0 bg-[#FFF]  text-black w-full sm:p-4 shadow-[0px_0px_10px_rgba(0,0,0,0.3)]">
      <div className="my-5  ">
        <div className="">
          <h1 className="text-3xl my-5 font-bold text-center xxl:text-[36px] "> {intl.formatMessage({ id: 'login.welcome_back' })}!</h1>
          <p className="text-md text-[#707070] text-center my-2">
            {intl.formatMessage({ id: 'login.new_to_sleekride' })}?{' '}
            <Link to={`/user-signup`} className="text-[#105e26]">
              {intl.formatMessage({ id: 'login.create_an_account' })}.
            </Link>{' '}
            {intl.formatMessage({ id: 'login.dealer_question' })}
            <a href="https://dealer.sleekride.vn/login" className="text-[#105e26]" target="_blank" rel="noopener noreferrer">
              {' '}
              {intl.formatMessage({ id: 'login.dealer' })}?
            </a>
          </p>
        </div>
      </div>

      <div className="xl:my-3 my-5  relative ">
        <CustomInput
          name="email"
          handleChange={handleChange}
          value={state['email']}
          placeholder={intl.formatMessage({
            id: 'login.enter_your_email',
          })}
          // error={"Email is required"}
        />
        {emailIcon && emailIcon}
        {checkEmailLoading && <TinnySpinner className="absolute  top-[15px] right-0" />}
        {/* <TickIcon  /> */}
      </div>
      <div className="xl:my-3 my-5  ">
        <CustomInput
          name="password"
          handleChange={handleChange}
          value={state['password']}
          placeholder={intl.formatMessage({
            id: 'login.enter_your_password',
          })}
          type="password"
          onEnter={handleSubmit}
          // type="password"
          // error={"Password is required"}
        />
      </div>

      <div className="xl:my-3 my-5 ">
        <button
          onClick={handleSubmit}
          className={clsx('bg-[#105E26] p-3 text-white text-[21px] w-full mt-2 rounded shadow-md   shadow-[#105E26] cursor-pointer', {
            ['bg-gray-400 shadow-none cursor-no-drop']: !canSubmit(),
          })}
          disabled={!canSubmit()}>
          {intl.formatMessage({ id: 'login.login' })}
        </button>
        <div className="mt-8 text-center">
          <Link to="/forgot-password" className="text-[#105E26]">
            {intl.formatMessage({ id: 'login.forgot_password' })}?
          </Link>
        </div>
      </div>

      <div className="xl:my-3 my-5 ">
        <h1 className="text-center text-md font-bold my-2">{intl.formatMessage({ id: 'sign_up.social' })} </h1>
        <SocialSignup />
        <p className="text-md text-[#707070] text-center my-8 sm:text-sm sm:mt-4">
          {intl.formatMessage({ id: 'login.terms_mention' })}{' '}
          <a href="/privacy-policy" className="text-[#105e26]">
            {intl.formatMessage({ id: 'login.statement' })}{' '}
          </a>
          {intl.formatMessage({ id: 'login.and' })}{' '}
          <a href="/terms-of-service" className="text-[#105e26]">
            {intl.formatMessage({ id: 'login.terms_of_service' })}.
          </a>
        </p>
      </div>
    </div>
  )
}

// h1 className="text-center text-md font-bold my-2">
//   Connect with Social
// </h1>
// <div className="flex justify-center space-x-4 my-2">
// <FacebookIcon />{" "}
// <FacebookLogin />
// <GoogleIcon
// className="cursor-pointer"
// onClick={() => {
//   console.log({ googleBtnRef })
//   /* global gapi */
//   console.log(gapi);
//   gapi.auth2
//     .getAuthInstance()
//     .signIn()
//     .then((googleUser) => {
//       console.log({ googleUser });
//       var profile = googleUser.getBasicProfile();

//       const result = {
//         profileId: profile.getId(),
//         fullName: profile.getName(),
//         image: profile.getImageUrl(),
//         email: profile.getEmail(),
//       };
//       if (result?.profileId) {
//         saveUserData(result).then((data) => {
//           console.log(data);
//           if (data) {
//             handleAfterLogin(data);
//           } else {
//             // toast.error("Something went wrong");
//           }
//         });
//       }

//       console.log({ result });
//     })
//     .catch((error) => console.log({ error }));
// }}
// />{' '}
// <AppleIcon />
// </div>
// <GoogleLogin ref={googleBtnRef} />

export default Left
