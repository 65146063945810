import { Roof } from '../Types/common';

export const roof: Roof[] = [
  {
    _id: '1',
    name: 'sunroof',
    label: 'Sunroof',
    translation: 'sunroof',
  },
  {
    _id: '2',
    name: 'moonroof',
    label: 'Moonroof',
    translation: 'moonroof',
  },
  {
    _id: '3',
    name: 'panoramic',
    label: 'Panoramic Roof',
    translation: 'panoramic_roof',
  },
  {
    _id: '4',
    name: 'convertible',
    label: 'Convertible',
    translation: 'convertible',
  },
]