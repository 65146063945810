import useQuery from 'redux/hooks/useQuery'
import React, { useEffect, useState, memo, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { APIEndpoints } from 'staticStore'
import { resetListing, setListing } from './listing'
import { setLoading, setLoggedIn, setUserData } from './user'
import jwt_decode from 'jwt-decode'

function decodeToken(token) {
  return token ? jwt_decode(token) : null
}

function AuthProvider({ children }) {
  const dispatch = useDispatch()

  const accessToken = useMemo(() => localStorage.getItem('accessToken'), [localStorage.getItem('accessToken')])

  const [getUser, { data, loading, error }] = useQuery({
    endPoint: APIEndpoints.LOGGED_IN_USER,
    method: 'get',
  })

  const [getPendingPost] = useQuery({
    endPoint: APIEndpoints.PENDING_POST,
    method: 'get',
  })

  useEffect(() => {
    const token = localStorage.getItem('accessToken')

    const handleExpiredToken = () => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('step')
      dispatch(setUserData({}))
      dispatch(resetListing({}))
      dispatch(setLoggedIn(false))
      dispatch(setLoading(false))
      // Optionally redirect the user to the login page or show an alert
    }

    if (token) {
      getUser()
      const decodedToken = decodeToken(token)
      // Check if the token has expired
      const current_time = Date.now().valueOf() / 1000
      if (decodedToken.exp < current_time) {
        handleExpiredToken()
      } else {
        dispatch(setUserData(decodedToken.user))
        dispatch(setLoggedIn(true))
        dispatch(setLoading(false))
      }
    } else {
      handleExpiredToken()
    }
  }, [accessToken, dispatch])

  useEffect(() => {
    if (!data) {
      dispatch(setUserData({}))
      dispatch(resetListing())
      dispatch(setLoggedIn(false))
      dispatch(setLoading(false))
      return
    }
    console.log('___________________login', { data })
    const user = data.user
    const endUserID = user._id

    console.log({
      params: { endUserID },
    })

    getPendingPost({
      params: { endUserID },
    }).then((postData) => {
      if (postData) {
        dispatch(setListing(postData))
      }
      // console.log({ postData })
    })

    dispatch(setUserData(user))
    dispatch(setLoggedIn(true))
    dispatch(setLoading(false))
  }, [data, dispatch])

  return children
}

export default memo(AuthProvider)
