import React, { createContext, useEffect, useState } from 'react'
import useQuery from 'redux/hooks/useQuery'
import ResponsiveAppBar from 'Components/AppBar'
import CustomizedSteppers from 'Components/Stepper'
import { useSelector } from 'react-redux'

// import "./style.css";
import Left from './Left'
import Right from './Right'
import { BigDots, Bullets, LeftArrow, QuotationMark, RightArrow, SmallDots } from 'assets/icons/Sellmycar'
import bigdots from 'assets/icons/Sellmycar/Bigdots.png'
import smalldots from 'assets/icons/Sellmycar/SmallDots.png'
import { Rating } from 'react-simple-star-rating'
import customersaying from '../../assets/images/customersaying.png'
import ListingProcess from './ListingProcess'
import Footer from 'Components/Footer'
import downloadbanner from '../../assets/images/downloadbanner.png'
import DownloadBannerSecondary from 'Components/DownloadBannerSecondary'
import BG3 from '../../assets/images/BG3.png'
import SecondaryBannerMobile1 from 'assets/images/SecondaryBannerMobile.png'
import { APIEndpoints, MicroServices } from 'staticStore'

import http from '../../http'

const Sellmycar = () => {
  const fetchPendingListing = async (endUserID) => {
    await http
      .get(`${APIEndpoints.PENDING_POST}?endUserID=${endUserID}`)
      .then((res) => {
        console.log('_____________________', res)
        if (res.status === 200) {
          setPendingListing(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const endUserID = useSelector((state) => state.user?.data?._id)
  const [pendingListing, setPendingListing] = useState(null)

  useEffect(() => {
    if (endUserID) {
      fetchPendingListing(endUserID)
    } else {
      setPendingListing(null)
    }
  }, [endUserID])

  return (
    <div>
      {/* container */}

      <div className="p-0 pr-0  sm:p-4  sm:pl-0 sm:pr-0 flex flex-col bg-[#F7FAF8] relative">
        <div className="flex md:flex-col">
          {/* left */}
          <Left pending={pendingListing} />
          {/* right */}
          <Right pendingListing={pendingListing} />
        </div>
        <div className="  absolute bottom-0 flex justify-end w-full bg-[#F7FAF8] ">
          <img src={BG3} className=" h-40 sm:h-20  " />
        </div>
      </div>

      {true ?? (
        <div className="  p-10  -mt-10  sm:p-4 flex md:flex-col bg-[#E7E7E7]  z-50   xxl:m-auto">
          <div className="w-full relative flex justify-center items-center ">
            <div className="absolute left-0 top-0  ">
              {/* <img
              className="md:h-64 sm:h-40 sm:w-56 "
              alt="sleekride"
              src={bigdots}
            /> */}
              {/* <BigDots /> */}
            </div>
            <div className="w-5/6 mt-20 z-10 text-white    xxl:max-w-[1200px] xxl:m-auto ">
              <div className="bg-[#B3D6BD]  flex justify-center  items-center rounded-md p-10 sm:p-0">
                <div className="bg-[#A4C7AE] h-full w-full  rounded-md p-8 sm:p-0">
                  <div className="bg-[#105E26] h-full w-full   rounded-xl p-6 sm:p-6">
                    <p className="text-center text-3xl sm:text-lg">What our Customers Saying</p>
                    <div className="flex justify-center ">
                      <p className="my-4 text-center w-1/2 text-xs sm:w-full">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                    <div className="my-5 flex md:flex-col   ">
                      {/* left */}
                      <div className="w-1/2 md:w-full relative flex justify-center ">
                        <div className="absolute left-0 bottom-0 xxl:left-[5rem] ">
                          <img className="md:h-44 sm:h-28 sm:w-24 xl:h-52 xxl:h-56 " alt="sleekride" src={smalldots} />
                          {/* <SmallDots /> */}
                        </div>
                        <div
                          className="absolute right-0 xxl:right-[5rem] top-0 rounded h-10 w-10 xxl:h-20 xxl:w-20 bg-black text-white 
                          flex items-center justify-center z-20">
                          <span className="-rotate-12">
                            <QuotationMark />
                          </span>
                        </div>
                        <div className=" pl-14 pb-14 pr-5 pt-5 z-10 xxl:w-3/4 xxl:pr-0 xxl:pb-20  ">
                          <img className="xl:w-96 xxl:w-full" alt="sleekride" src={customersaying} />
                        </div>
                        <div className="absolute right-0 bottom-0">
                          <div className="flex mr-5 items-center space-x-1">
                            <LeftArrow /> <Bullets /> <RightArrow />
                          </div>
                        </div>
                      </div>

                      {/* Right */}
                      <div className=" h-full w-1/2 p-4 sm:p-0 xl:p-10  xxl:flex  xxl:flex-col xxl:justify-center  md:w-full">
                        <p className="my-2 font-semibold xxl:text-[21.58px]">Sed do eiusmod tempor incididunt ut labore et dolore</p>
                        <p className="my-2 xxl:text-[19.42px] font-thin">
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                        </p>
                        <p className="mt-4 mb-1 xxl:text-[21.58px] font-semibold">Arya Stark</p>
                        <p className="mb-1 ">Fictional character</p>
                        <Rating initialValue={5} size={20} readonly={true} fillColor={'#FFD800'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="my-8   mx-auto  max-w-[1400px]">
        <ListingProcess />
      </div>

      <div className="my-8   ">
        {/* <img src={downloadbanner} /> */}

        <DownloadBannerSecondary image={SecondaryBannerMobile1} />
      </div>
    </div>
  )
}

export default Sellmycar
