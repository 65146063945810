import { useState } from 'react'
import http from '../../http'
import { toast } from 'react-toastify'
import { findMessage } from 'utils'
import { APIEndpoints } from 'staticStore'

const excludeSuccessMessage = [APIEndpoints.CHECK_EMAIL, APIEndpoints.CREATE_LISTING]
const excludeErrorMessage = [APIEndpoints.CHECK_EMAIL, APIEndpoints.PENDING_POST]

function useMutation({ endPoint, method = 'post' }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [data, setData] = useState(undefined)

  const apiCall = async (variables, config = {}) => {
    //
    // console.log({
    //   endPoint,
    //   method,
    //   config,
    // })
    setLoading(true)
    setError(undefined)
    setData(undefined)

    // console.log({
    //   formData: variables instanceof FormData,
    // })

    // headers set in config
    return http[method](endPoint, variables, config)
      .then((data) => {
        // console.log("USE MUTATION: ", { data })
        setLoading(false)
        if (!excludeSuccessMessage.includes(endPoint)) {
          const status = findMessage(data, 'status')
          const message = findMessage(data)
          const mode = [200, 201].includes(status) ? 'success' : 'warn'
          toast[mode](message)
        }
        if (data.data) {
          setData(data.data)
          return data.data
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error) {
          setError(error)
          if (!excludeErrorMessage.includes(endPoint)) {
            let message = 'Something went wrong'
            const errors = error?.response?.data?.errors
            if (errors && errors.length && errors[0]['msg']) {
              toast.error(errors[0]['msg'])
            } else {
              const errorObj = error.response ? error.response : error
              message = findMessage(errorObj)
              toast.error(message)
            }
          }
        }
      })
  }

  return [apiCall, { data, loading, error }]
}

export default useMutation
