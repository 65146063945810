import React, { useEffect, useState } from 'react'
import CarCard from 'Components/Cards/CarCard'
import { GoodDealIcon } from 'assets/icons/pricingToolsIcon/dealIcon'
// import { ReactComponent as GoodDealIcon } from "assets/icons/goodDeal.svg";
import './style.css'
import DraggableList from 'Components/Carousel/DraggableList/DraggableList'
import { formatCurrency, USDtoVND, VNDshorten } from 'Utils/Parse/currency'
import { MicroServices } from 'staticStore'
import { dayElapesed } from 'Utils/Parse/Time'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Navigation } from 'swiper/modules'
import { ArrowRight, ArrowLeft } from 'assets/icons/HomeIcons'

import 'swiper/swiper-bundle.css' // Make sure you import Swiper styles
import { useIntl } from 'react-intl'
import { getCityLabel } from 'Resources/Data/cities'
import TopDeals from 'Components/Cards/CarCard/TopDeals'

SwiperCore.use([Navigation])

const HomeCardCarousel = () => {
  const fetchListings = async () => {
    const res = await fetch(`${MicroServices.GATEWAY}/vce/view/findTopRating`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        AccessControlAllowOrigin: '*',
        'auth-token': localStorage.getItem('accessToken'),
      },
    })
    const data = await res.json()
    console.log('Find Top Rating', data)
    return data
  }

  const [listings, setListings] = useState([])

  useEffect(() => {
    fetchListings().then((data) => {
      console.log('__________________', data)

      if (data.listings.length > 0) {
        setListings(
          data.listings.map((post, i) => {
            post.images = post.images.map((img) => {
              let IMG = img.data.url
              return {
                original: IMG,
                thumbnail: IMG,
                originalHeight: '100px',
                originalWidth: '100px',
                thumbnailHeight: '100px',
                thumbnailWidth: '100px',
                originalClass: 'w-full h-full object-cover ',
                thumbnailClass: 'w-full h-full object-cover ',
              }
            })
            console.log(post)
            return post
          })
        )
      } else {
        setListings([])
      }
    })
  }, [])

  const intl = useIntl()
  return (
    <div className="-lg:pl-20 lg:pl-8 sm:pl-4 sm:mr-4 [&>div]:w-[98%]  [&>div]:mx-auto ">
      <p className="font-bold text-4xl  xxl:text-[48px] text-center sm:text-2xl pb-16 pt-20">{intl.formatMessage({ id: 'home.top_deals' })}</p>

      <Swiper
        spaceBetween={0} // adjust as needed
        slidesPerView={1.1} // default for mobile
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
          // Sm breakpoint
          640: {
            slidesPerView: 1.5, // adjust this value as per your requirement for sm screens
          },
          // Md breakpoint
          768: {
            slidesPerView: 2, // adjust this value as per your requirement for md screens
          },
          // Lg breakpoint
          1024: {
            slidesPerView: 2.5, // adjust this value as per your requirement for lg screens
          },
          // Xl and 2xl breakpoint
          1336: {
            slidesPerView: 3.5,
          },
          1920: {
            slidesPerView: 4, // 5 slides per view for large desktop
          },
        }}
        className="draggable-list">
        {listings?.map((e, index) => (
          <SwiperSlide key={index}>
            <TopDeals
              id={e._id}
              preview={true}
              images={e.images}
              post={e}
              mileage={e.odometer}
              city={getCityLabel(e.city, intl)}
              daysinmarket={e.daysOnMarket}
              dealType={e?.dealRating?.dealRating ?? 'Unknown'}
              className="car-card w-full flex flex-col lg:flex-row md:flex-col lg:max-w-full box-border"
            />
          </SwiperSlide>
        ))}
        <SwiperSlide key={Math.random * 10000}></SwiperSlide>
        <button className="swiper-button-prev custom-swiper-button">
          <ArrowRight stroke="#0f0f0f" /> {/* you can set stroke prop to change arrow color */}
        </button>
        <button className="swiper-button-next custom-swiper-button">
          <ArrowLeft stroke="#0f0f0f" /> {/* you can set stroke prop to change arrow color */}
        </button>
      </Swiper>
    </div>
  )
}

export default HomeCardCarousel
