import React from 'react'
// import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Logo from '../../assets/images/pngLogo.png'
import People from '../../assets/images/people.png'
import LoginCar from '../../assets/images/logincar.png'
import { useIntl } from 'react-intl'

const Right = () => {
  const intl = useIntl()
  return (
    <div className="       text-black w-full lg:p-12 md:p-4 sm:p-4 relative">
      <div className="rightDiv flex items-center justify-center mt-8">
        <img
          className="h-20 w-32 xxl:h-22 xxl:w-56 object-contain"
          src={Logo}
        />

        <div className="w-[80%] pt-5 space-y-2 flex flex-col justify-start items-center">
          <h1 className=" w-full text-4xl xxl:text-[36px] font-semibold  md:text-2xl xxl:leading-snug lg:text-center   ">
            {intl.formatMessage({ id: 'sign_up.welcome' })}!
          </h1>

          <div className=" w-full  flex justify-start lg:justify-center lg:text-center items-center space-x-2 lg:flex-col   sm:space-y-2 ">
            <div className="flex">
              <img
                className="h-10 w-10 rounded-full border-2 border-black "
                src={People}
              />
              <img
                className="h-10 w-10 rounded-full border-2 border-black -ml-2 "
                src={People}
              />
              <img
                className="h-10 w-10 rounded-full border-2 border-black -ml-2 "
                src={People}
              />
            </div>
            <p className="sm:text-xs xxl:text-lg">
              {' '}
              {intl.formatMessage({ id: 'sign_up.invite' })}
            </p>
          </div>

          <p className=" md:text-sm text-md xxl:text-xl   lg:text-center  xxl:leading-snug ">
            {intl.formatMessage({ id: 'sign_up.description' })}
          </p>
        </div>
      </div>

      <div className=" h-64 -md:h-[550px]  flex items-center   ">
        <img
          className=" absolute right-0 w-4/5 -md:w-full "
          src={LoginCar}
        />
      </div>
    </div>
  )
}

export default Right
