import React from "react";

function ComprehensiveReport({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="48"
      height="53"
      viewBox="0 0 48 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9923 4.72875C13.3504 10.6951 11.7454 25.1533 2.46094 35.2556"
        stroke="#105E26"
        strokeWidth="6.57555"
      />
      <path
        d="M36.592 2.44247C41.0039 11.2905 47.9655 33.4803 40.5166 51.4551"
        stroke="#105E26"
        strokeWidth="6.57555"
      />
    </svg>
  );
}

export default ComprehensiveReport;
