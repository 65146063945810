import React, { useEffect } from 'react'
import { Popover } from '@mui/material'
import { DropDownIcon } from '../../../assets/icons/navbarIcons'
import { capitalizeAllFirstLetter } from 'Utils/Parse'
import { cleanSearch } from 'Utils/Search/Filter'

import { filterBySearch } from 'Utils/Search/Filter'
import List from './List'
import { useIntl } from 'react-intl'
interface LOCATION {
  _id: string
  name: string
}

const SearchResultDropDown = ({
  title,
  api,
  optProps,
  Sort,
  search,
  setSearch,
}: any) => {
  const intl = useIntl()
  const [condition, setCondition] = React.useState(title)
  const [anchorE2, setAnchorE2] = React.useState(null)
  const makeOpen = Boolean(anchorE2)
  const handleMakeClose = () => {
    setAnchorE2(null)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleMakeClose)
    return () => window.removeEventListener('scroll', handleMakeClose)
  }, [])

  useEffect(() => {
    setFetched(optProps)
    setFiltered(optProps.slice(0, 5))
    if (!search[title.toLowerCase()]) return
    const foundLabel = optProps.find(
      (e: any) =>
        e.label.toLowerCase() ===
        search[title.toLowerCase()].toLowerCase()
    )
    if (foundLabel) setCondition(foundLabel.name)
    else setCondition(title)
  }, [optProps])

  const handleMakeClick = async (event: any) => {
    setAnchorE2(event.currentTarget)
    // console.log(fetched.length)
    if (fetched.length) return
    if (!api) {
      setFetched(optProps)
      setFiltered(optProps.slice(0, 5))
      return
    }
    await fetchData()
  }
  const [fetched, setFetched] = React.useState<LOCATION[]>([])
  const [filtered, setFiltered] = React.useState<LOCATION[]>([])

  useEffect(() => {
    if (!search[title.toLowerCase()])
      setCondition(intl.formatMessage({ id: title.toLowerCase() }))
    else {
      const found = optProps.find(
        (c: any) =>
          c.label.toLowerCase() === search[title.toLowerCase()]
      )
      console.log('found', found)

      setCondition(found.name)
    }
  }, [search])

  // fetch  using api and save to state using async
  const fetchData = async () => {
    const response = await fetch(api)
    const data = await response.json()
    const tmp = data.map((e: any) => {
      return {
        _id: e._id,
        name: e.label,
      }
    })
    setFetched(tmp)
    setFiltered(tmp.slice(0, 5))
  }

  const handleOnChange = (e: any) => {
    // based on the input value filter the fetched data
    const search = cleanSearch(e.target.value)
    const filtered = filterBySearch(fetched, search)

    setFiltered(filtered.slice(0, 5))
  }

  return (
    <>
      <button
        onClick={handleMakeClick}
        className="flex  items-center   rounded-2xl border border-[#c5c5c5] sm:mt-0   space-x-1    md:space-x-1 p-4 px-5 sm:px-5 sm:p-1 ">
        {/* ${
          makeOpen ? "text-[#105e26]" : "text-[#c4c4c4]"
        }  */}
        {Sort}

        <span
          className={`tracking-widest text-[#105e26] md:text-[12px]   text-sm  md:tracking-wide `}>
          {condition.toLowerCase() !== title.toLowerCase()
            ? capitalizeAllFirstLetter(condition)
            : condition}
        </span>
        <div className="md:hidden">
          <DropDownIcon stroke="#105e26" />
        </div>
      </button>
      <Popover
        open={makeOpen}
        anchorEl={anchorE2}
        onClose={handleMakeClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginY: '0', marginLeft: '0' }}>
        {/* <DropDownSearch
          placeholder={`Find ${capitalizeFirstLetter(title.toLowerCase())}...`}
          handleOnChange={handleOnChange}
        /> */}
        <List
          options={filtered}
          handleOnClick={(e: any) => {
            const name = e.name || e
            const label = e.label || e
            const found = optProps.find(
              (c: any) =>
                c.label.toLowerCase() === label.toLowerCase()
            )
            console.log('name', name)
            console.log('label', label)
            console.log('found', found)

            setCondition(found.name)
            setSearch((prev: any) => ({
              ...prev,
              [title.toLowerCase()]: label
                ? label.toLowerCase()
                : name.toLowerCase(),
            }))
            handleMakeClose()
          }}
          active={condition}
        />
      </Popover>
    </>
  )
}

export default SearchResultDropDown
