import React from "react";

const overviewIcon = ({ color }) => {
  return (
 
      <svg
        width="27"
        height="19"
        viewBox="0 0 27 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.04 13.0167C25.9218 12.5933 25.7451 12.1843 25.4942 11.7865L24.4209 11.9372C24.2276 11.7014 24.008 11.4938 23.7683 11.3169L23.865 10.2371C23.4721 10.0158 23.0488 9.85282 22.6089 9.75342L21.9563 10.6191C21.6595 10.5895 21.3573 10.596 21.0558 10.6414L20.3609 9.80964C19.9376 9.92784 19.5285 10.1045 19.1307 10.3555L19.2815 11.4288C19.0457 11.622 18.8381 11.8417 18.6612 12.0814L17.5813 11.9847C17.36 12.3773 17.1971 12.8009 17.0977 13.2408L17.9634 13.8934C17.9337 14.1901 17.9402 14.4924 17.9856 14.7939L17.1539 15.4888C17.2721 15.9121 17.4488 16.3211 17.6997 16.7189L18.773 16.5682C18.9663 16.804 19.1859 17.0116 19.4256 17.1885L19.3289 18.2683C19.7216 18.4896 20.1451 18.6526 20.585 18.752L21.2376 17.8863C21.5344 17.916 21.8366 17.9094 22.1381 17.864L22.833 18.6958C23.2563 18.5776 23.6654 18.4009 24.0632 18.1499L23.9124 17.0766C24.1482 16.8834 24.3558 16.6637 24.5327 16.424L25.6126 16.5208C25.8339 16.1281 25.9969 15.7045 26.0962 15.2646L25.2305 14.612C25.2602 14.3153 25.2537 14.013 25.2083 13.7116L26.04 13.0167ZM22.3986 16.0592C21.4008 16.502 20.2331 16.0522 19.7901 15.0544C19.3473 14.0566 19.797 12.8889 20.7948 12.4459C21.7927 12.0029 22.9604 12.4528 23.4034 13.4506C23.8464 14.4484 23.3962 15.6162 22.3986 16.0592Z"
          fill={color}
        />
        <path
          d="M18.3487 10.5696C18.4759 9.70006 18.4847 8.80876 18.3487 7.87783L16.2647 7.28228C16.103 6.6947 15.8698 6.1368 15.5749 5.61837L16.6279 3.72294C16.0896 2.9996 15.4479 2.35782 14.7245 1.81956L12.8291 2.87258C12.3107 2.57767 11.7531 2.34452 11.1652 2.18275L10.5696 0.0987322C9.70007 -0.0284761 8.80877 -0.0372719 7.87783 0.0987322L7.28229 2.18275C6.6947 2.34451 6.1368 2.57766 5.61837 2.87258L3.72294 1.81956C2.9996 2.35782 2.35782 2.9996 1.81956 3.72294L2.87258 5.61837C2.57767 6.13681 2.34453 6.69433 2.18275 7.28228L0.0987322 7.87783C-0.0284761 8.7474 -0.0372719 9.6387 0.0987322 10.5696L2.18275 11.1652C2.34451 11.7528 2.57766 12.3107 2.87258 12.8291L1.81956 14.7245C2.35782 15.4479 2.9996 16.0896 3.72294 16.6279L5.61837 15.5749C6.13681 15.8698 6.69433 16.1029 7.28229 16.2647L7.87783 18.3487C8.74741 18.4759 9.63871 18.4847 10.5696 18.3487L11.1652 16.2647C11.753 16.103 12.3107 15.8698 12.8291 15.5749L14.7245 16.6279C15.4479 16.0896 16.0896 15.4479 16.6279 14.7245L15.5749 12.8291C15.8698 12.3107 16.1029 11.7531 16.2647 11.1652L18.3487 10.5696ZM9.22421 13.1768C7.04103 13.1768 5.27115 11.4071 5.27115 9.22373C5.27115 7.04056 7.04082 5.27068 9.22421 5.27068C11.4076 5.27068 13.1773 7.04035 13.1773 9.22373C13.1771 11.4067 11.4072 13.1768 9.22421 13.1768Z"
          fill={color}
        />
        <path
          d="M11.861 9.22354C11.861 10.6789 10.6812 11.8591 9.22542 11.8591C7.76968 11.8591 6.58984 10.6793 6.58984 9.22354C6.58984 7.76779 7.76968 6.58838 9.22542 6.58838C10.6812 6.58817 11.861 7.768 11.861 9.22354Z"
          fill={color}
        />
      </svg>

  );
};

export default overviewIcon;
