import { useState, useEffect, useRef } from 'react'
import http from '../../http'
import { toast } from 'react-toastify'
import { findMessage } from 'utils'
import { APIEndpoints } from 'staticStore'
import axios from 'axios'

const { CHECK_EMAIL, LOGOUT, PENDING_POST } = APIEndpoints

const excludeSuccessMessage = [CHECK_EMAIL, LOGOUT]
const excludeErrorMessage = [CHECK_EMAIL, PENDING_POST]

function useQuery({ endPoint, type }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [data, setData] = useState(undefined)
  const cancelTokenSource = axios.CancelToken.source()

  const apiCall = async (props = {}) => {
    setLoading(true)
    setError(undefined)
    setData(undefined)

    try {
      const response = await http.get(endPoint, {
        params: { ...(props.params || {}) },
        cancelToken: cancelTokenSource.token,
      })

      setLoading(false)
      if (!excludeSuccessMessage.includes(endPoint)) {
        const status = findMessage(response, 'status')
        const message = findMessage(response)
        const mode = status === 200 ? 'success' : 'warn'
        // toast[mode](message)
      }
      if (response.data) {
        setData(response.data)
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setLoading(false)
        setError(error)

        if (!excludeErrorMessage.includes(endPoint)) {
          const errorObj = error.response ? error.response : error
          let message = findMessage(errorObj)
          // toast.error(message)
        }
      }
    }
  }

  useEffect(() => {
    if (type === 'onLoad') {
      apiCall()
    }

    // Cleanup function
    return () => {
      cancelTokenSource.cancel()
    }
  }, []) // Add any dependencies here if needed

  if (type === 'onLoad') {
    return { data, loading, error }
  }

  return [apiCall, { data, loading, error }]
}

export default useQuery
