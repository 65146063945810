import React, { useEffect, useState } from 'react'
import ResponsiveAppBar from 'Components/AppBar'
import carimage from '../../assets/images/adbanner.png'
import CarCard from 'Components/Cards/CarCard'
import { GoodDealIcon, GreatDealIcon, FairPriceIcon, OverPriceIcon } from '../../assets/icons/pricingToolsIcon/dealIcon'

import priceDropIcon from '../../assets/icons/priceDrop.png'
import { useSelector } from 'react-redux'
import { setWishlistItems } from 'redux/wishlist'
import { useDispatch } from 'react-redux'
import { fetchUserWishlist } from 'redux/wishlist'
import './style.css'
import { useIntl } from 'react-intl'
import { getCityLabel } from 'Resources/Data/cities'

const Wishlist = () => {
  const [WishlistType, setWishlistType] = useState('favorite')
  const wishlistedItems = useSelector((state) => state.wishlist.items)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserWishlist()).catch((error) => {
      console.error('Error fetching wishlist items:', error)
    })
  }, [dispatch])

  const intl = useIntl()

  return (
    <div className="wishlist-body  w-[95%] m-auto sm:w-full">
      {/* container */}
      <div className="p-10 md:p-6 sm:py-4  sm:px-1">
        {/* ad banner */}
        {/* <div
          className="my-4  w-full sm:h-16 md:h-20 lg:h-28 xl:h-36 xxl:h-72  "
          style={{
            backgroundImage: `url(${carimage})`,
            width: '100%',
            backgroundSize: ' contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div> */}

        <div className="mt-10 px-4   w-full sm:px-0  flex items-center justify-between  mb-12">
          <p className="font-bold text-3xl xl:text-[36] xxl:text-[48px]">
            {intl.formatMessage({
              id: 'wishlist',
            })}
          </p>
          {/* <div className="flex rounded-xl w-2/5 sm:w-3/5 xs:w-4/5 p-1 shadow">
            <button
              onClick={() => setWishlistType('favorite')}
              className={`p-2 w-1/2  sm:text-xs sm:p-1 ${
                WishlistType == 'favorite'
                  ? 'bg-[#105E26] text-white  shadow-sm shadow-[#105E26]'
                  : 'bg-[#F5F6F6] text-black'
              }  rounded-lg`}
            >
              Favorite (109)
            </button>
            <button
              onClick={() => setWishlistType('priceDrop')}
              className={`p-2  w-1/2 sm:text-xs sm:p-1  ${
                WishlistType == 'priceDrop'
                  ? 'bg-[#105E26] text-white  shadow-sm shadow-[#105E26]'
                  : 'bg-[#F5F6F6] text-black'
              }   rounded-r-xl shadow-inner`}
            >
              Price Drops (40)
            </button>
          </div> */}
        </div>
        <div className="wishlist-results my-6  w-full flex flex-wrap justify-center  sm:[&>div]:mb-5 sm:[&>div]:!mr-[5px]">
          {wishlistedItems.length === 0 && (
            <div className="text-center" style={{ height: '20vh' }}>
              {intl.formatMessage({
                id: 'no_wishlist',
              })}
            </div>
          )}
          {WishlistType == 'favorite'
            ? wishlistedItems.map((e, index) => {
                // Using 'wishlistedItems' instead of 'cardData'
                console.log(e)
                return (
                  <CarCard
                    key={e._id}
                    post={e}
                    images={e.images}
                    overview={e.overview}
                    odometer={e.odometer}
                    className="sm:!w-full"
                    id={e._id}
                    mileage={e.odometer}
                    city={getCityLabel(e?.city, intl)}
                    daysinmarket={e.daysOnMarket}
                    dealType={e?.dealRating?.dealRating ?? 'Unknown'}
                    Icon={GoodDealIcon}
                  />
                )
              })
            : wishlistedItems.map((e, index) => {
                // Using 'wishlistedItems' instead of 'cardData'
                return (
                  <CarCard
                    key={e._id}
                    post={e}
                    images={e.images}
                    overview={e.overview}
                    odometer={e.odometer}
                    className="sm:!w-full"
                    id={e._id}
                    mileage={e.odometer}
                    city={getCityLabel(e?.city, intl)}
                    daysinmarket={e.daysOnMarket}
                    dealType={e?.dealRating?.dealRating ?? 'Unknown'}
                    Icon={GoodDealIcon}
                  />
                )
              })}
        </div>
        {/* <div className="my-8   flex flex-wrap justify-center  ">
          <button className="bg-[#105E26] p-3 w-[254px] h-[60px] text-white  mt-2 rounded shadow-md shadow-[#105E26]">
            See All
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default Wishlist
