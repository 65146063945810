import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthProvider from 'redux/AuthProvider'
import ErrorBoundary from './ErrorBoundary'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import I18n from './i18n/I18n'
interface Theme {
  palette: {
    primary: {
      light: string
      main: string
      dark: string
      contrastText: string
    }
    secondary: {
      light: string
      main: string
      dark: string
      contrastText: string
    }
  }
}

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#105E26',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#F0F0F0',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  // components: {
  //   MuiOutlinedInput: {
  //     styleOverrides: {
  //       root: {
  //         '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //           // borderColor: 'red',
  //         },
  //       },
  //     },
  //   },
  // },
})

if (process.env.REACT_APP_GATEWAY_API_URL === 'http://api.sleekride.vn/api') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
// create a context to store the check availibility message

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <I18n>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <BrowserRouter>
              <AuthProvider>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
                <ToastContainer />
              </AuthProvider>
            </BrowserRouter>
          </Provider>
        </LocalizationProvider>
      </I18n>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
