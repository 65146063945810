import React from 'react'

const SliderRightArrow = ({ className, style, onClick, color = '' }) => {
  return (
    <svg
      width="35"
      height="54"
      viewBox="0 0 35 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        d="M4 50.4431L27 27.4431L4 4.44312"
        stroke="#105E26"
        className={color}
        strokeWidth="10"
      />
    </svg>
  )
}

export default SliderRightArrow
