// const usd = { style: 'currency', currency: 'USD' }
// export const USDFormatter = new Intl.NumberFormat('en-US', usd)

// So I was able to create the usd formmater and export it to my components
// but what I want to do is create a function that takes in a number and returns
// the formatted number. So I will create a function called formatCurrency
// and it will take in a number and the currency symbol and return the formatted number

export const formatCurrency = (number, currency, style = 'en-US') => {
  const formatter = new Intl.NumberFormat(style, {
    style: 'currency',
    currency: currency,
  })
  return formatter.format(number)
}

export const USDtoVND = (number) => {
  // number is in USD
  // return in VND
  return number * 23625
}
export const VNDtoUSD = (number) => {
  // number is in VND
  // return in USD
  return number / 23625
}

export const loanEsimationVND = (
  loanAmount,
  interestRate,
  loanTerm
) => {
  // LOAN AMOUNT IN VND, INTEREST RATE IN 0-100, LOAN TERM IN MONTH
  // RETURN IN TRIEU => MONETLY PAYMENT

  // Convert the annual interest rate to a monthly rate and express it as a decimal
  const monthlyInterestRate = interestRate / 100 / 12

  // Calculate the number of monthly payments (nt)
  const numberOfPayments = loanTerm

  // Calculate the monthly payment using the formula
  const monthlyPayment =
    (loanAmount * monthlyInterestRate) /
    (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments))

  // Convert the result to millions (trieu) and round to three decimal places
  return (monthlyPayment / 1000000).toFixed(2)
}

export const loanEsimationVND_monthlyCalculator = (
  price,
  monthlyCalculator
) => {
  if (!monthlyCalculator) return loanEsimationVND(price, 7, 12)

  const { downPayment, interestRate, loanDuration, isPercentage } =
    monthlyCalculator

  var priceToPay = isPercentage
    ? price * ((100 - downPayment) / 100)
    : price - downPayment

  return loanEsimationVND(priceToPay, interestRate, loanDuration)
}
export const VNDshorten = (number) => {
  // number is in VND
  // represent in tỉ, triệu, and nghìn đồng
  if (number < 1000) {
    return number + ' Nghìn Đồng'
  }
  let result = ''
  const ty = Math.floor(number / 1000000000)
  const trieu = Math.floor((number - ty * 1000000000) / 1000000)
  const nghin = Math.floor(
    (number - ty * 1000000000 - trieu * 1000000) / 1000
  )

  if (ty > 0) {
    result += ty + ' Tỷ '
  }
  if (trieu > 0) {
    result += trieu + ' Triệu '
  }
  if (nghin > 0 && !result.length) {
    result += nghin + ' Nghìn Đồng '
  }
  return result.trim()
}
