import React from "react";
import arrow from "../../down-right-arrow.png";

function HighPriceIcon({ fill = "#ffffff", ...rest }) {
  return (
    <div className="bg-[#F48930] w-6 h-6 flex items-center justify-center rounded-full">
      <img src={arrow} />
    </div>
  );
}

export default HighPriceIcon;
