import React, { Fragment, useEffect } from 'react'
import { Button, Popover } from '@mui/material'
import { DropDownIcon } from '../../../assets/icons/navbarIcons'
import CrossIcon from '../../../assets/icons/crossIcon.jsx'
import DropdownRightArrow from 'assets/icons/dropdownrightarrow'
import SearchIcon from 'assets/icons/searchicon'
import List from './List'
import { capitalizeFirstLetter } from 'Utils/Parse'
import { useIntl } from 'react-intl'
const SearchResultDropDown = ({
  title,
  initCondition = 'New',
  Sort,
  search,
  setSearch,
}: any) => {
  const [active, setActive] = React.useState(initCondition)
  const [anchorE2, setAnchorE2] = React.useState(null)
  const makeOpen = Boolean(anchorE2)
  const handleMakeClose = () => {
    setAnchorE2(null)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleMakeClose)
    return () => window.removeEventListener('scroll', handleMakeClose)
  }, [])

  const handleMakeClick = (event: any) => {
    setAnchorE2(event.currentTarget)
  }
  const intl = useIntl()

  useEffect(() => {
    if (!search[title.toLowerCase()])
      setActive(initCondition.toLowerCase())
    else setActive(search[title.toLowerCase()])
  }, [search, intl])

  const conditions = ['All', 'New', 'Used']

  return (
    <>
      <button
        onClick={handleMakeClick}
        className="flex  items-center   rounded-2xl border border-[#c5c5c5] sm:mt-0   space-x-1    md:space-x-1 p-4 px-5 sm:px-5 sm:p-1 ">
        {Sort}

        <span
          className={`tracking-widest text-[#105e26] md:text-[12px]   text-sm  md:tracking-wide`}>
          {intl.formatMessage({ id: active })}
        </span>
        <div className="md:hidden">
          <DropDownIcon stroke="#105e26" />
        </div>
      </button>
      <Popover
        open={makeOpen}
        anchorEl={anchorE2}
        onClose={handleMakeClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginY: '0', marginLeft: '0' }}>
        <List
          options={conditions}
          translate={true}
          handleOnClick={(e: string) => {
            setActive(e)
            setSearch((prev: any) => ({
              ...prev,
              [title.toLowerCase()]: e.toLowerCase(),
            }))
            handleMakeClose()
          }}
          active={active}
        />
      </Popover>
    </>
  )
}

export default SearchResultDropDown
