import React from 'react'
import Bg1 from '../../assets/images/sellcarbg1.png'
import Bg2 from '../../assets/images/sellcarbg2.png'
import Bg3 from '../../assets/images/Sellcarbg3.jsx'
import { useNavigate } from 'react-router-dom'

import SellCar from '../../assets/images/sellcarbg.png'
import PendingListingProvider from './context'
import { FormattedMessage } from 'react-intl'

const ContinueRight = ({ pendingListing }) => {
  const navigate = useNavigate()

  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const vin = pendingListing?.vin
    if (!vin) return
    const make = vin?.data?.make
    const model = vin?.data?.model

    if (make) {
      setShow(true)
    }
  }, [pendingListing])
  return (
    <>
      {show && (
        <div
          onClick={() => {
            navigate(`/create-listing/${pendingListing?._id}`)
          }}
          className="continue-right">
          <FormattedMessage id="sell_my_car.continue2" />
        </div>
      )}
    </>
  )
}

const Right = ({ pendingListing }) => {
  return (
    <div className="my-3 w-1/2 lg:w-full  relative flex justify-center items-center">
      <ContinueRight pendingListing={pendingListing} />
      <div className="h-full w-[92%] right-0 absolute   flex justify-center items-center bg-gradient-to-b  from-[#E5ECE7] to-transparent "></div>

      <div className="h-full w-full pt-10 z-10  md:flex md:justify-center md:items-center   ">
        <img
          alt="sleekride"
          className=" w-full h-full  "
          src={SellCar}
        />
      </div>
    </div>
  )
}

export default Right
