import React from "react";

function ComprehensiveReport({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="139"
      height="127"
      viewBox="0 0 139 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="63.6429"
        height="63.6429"
        transform="matrix(-1 0 0 1 63.6426 6)"
        fill="url(#paint0_linear_271_4584)"
        fillOpacity="0.1"
      />
      <rect
        width="44.3571"
        height="44.3571"
        transform="matrix(-1 0 0 1 108 69.6428)"
        fill="url(#paint1_linear_271_4584)"
        fillOpacity="0.1"
      />
      <path
        d="M39.1858 31.4093C39.3672 31.643 39.6186 31.8333 39.9371 31.9762C40.3068 32.1438 40.7988 32.2276 41.3963 32.2276H44.3185V24.8589L41.3953 24.8599C40.8096 24.8599 40.3038 24.9486 39.9351 25.1142C39.6166 25.2552 39.3652 25.4455 39.1858 25.6752C39.0103 25.902 38.8851 26.1653 38.8042 26.4817C38.7007 26.8909 38.6484 27.374 38.6484 27.9172V29.1733C38.6484 29.7136 38.7017 30.1977 38.8062 30.6098C38.887 30.9273 39.0073 31.1817 39.1858 31.4094L39.1858 31.4093Z"
        fill="#105E26"
      />
      <path
        d="M45.1782 49.5794C54.2143 49.5794 61.5654 42.2263 61.5654 33.1912C61.5654 24.1551 54.2143 16.803 45.1782 16.803C36.1411 16.803 28.791 24.1541 28.791 33.1912C28.791 42.2283 36.1421 49.5794 45.1782 49.5794ZM36.9288 27.9166C36.9288 27.2333 37.0008 26.6073 37.1398 26.0571C37.2828 25.506 37.5066 25.0367 37.83 24.6206C38.1849 24.1641 38.6572 23.8033 39.233 23.5459C39.8295 23.2778 40.5571 23.1407 41.3971 23.1407H44.3193V20.1079H46.0388V23.1407H52.5696V24.8592H46.0388V32.228H49.746C52.3182 32.228 53.2314 34.4581 53.2314 36.3698V39.097C53.2314 41.0077 52.3184 43.2398 49.746 43.2398H46.0388V46.2716H44.3193V43.2398H38.255V41.5204H44.3193V33.9474H41.3951C40.5511 33.9474 39.8215 33.8123 39.226 33.5422C38.6492 33.2819 38.1789 32.9191 37.827 32.4646C37.5026 32.0525 37.2778 31.5822 37.1378 31.032C36.9978 30.4799 36.9258 29.8558 36.9258 29.1725L36.9268 27.9165L36.9288 27.9166Z"
        fill="#105E26"
      />
      <path
        d="M51.5101 39.0998V36.3726C51.5101 35.4676 51.2804 33.9492 49.7434 33.9492H46.0361V41.5242H49.7434C51.2804 41.5242 51.5101 40.0049 51.5101 39.0998V39.0998Z"
        fill="#105E26"
      />
      <path
        d="M27.4095 74.1857H35.9191V82.507L27.4095 82.508V97.6607H34.246C34.6916 91.1161 40.1427 85.9232 46.7977 85.9232C53.4527 85.9232 58.906 91.1161 59.3495 97.6607H93.7008C94.1455 91.1161 99.5976 85.9232 106.254 85.9232C112.91 85.9232 118.362 91.1161 118.806 97.6607H123.109V75.9043H117.13V70.664H123.109V69.1565H27.4072L27.4095 74.1857Z"
        fill="#105E26"
      />
      <path
        d="M120.289 48.6152H88.8828V67.4355H122.977L120.289 48.6152Z"
        fill="#105E26"
      />
      <path
        d="M106.253 87.6411C100.544 87.6411 95.858 92.0621 95.4184 97.659C95.3967 97.943 95.376 98.2269 95.376 98.5178C95.376 98.8076 95.3977 99.0935 95.4184 99.3775C95.8601 104.974 100.545 109.395 106.253 109.395C111.961 109.395 116.646 104.974 117.086 99.3775C117.108 99.0935 117.129 98.8076 117.129 98.5178C117.129 98.2279 117.107 97.942 117.086 97.659C116.645 92.0629 111.96 87.6411 106.253 87.6411ZM112.013 99.3786C111.593 102.193 109.183 104.367 106.253 104.367C103.323 104.367 100.911 102.194 100.492 99.3786C100.45 99.0957 100.403 98.8137 100.403 98.5189C100.403 98.2241 100.448 97.9421 100.492 97.6602C100.912 94.8454 103.324 92.6723 106.253 92.6723C109.183 92.6723 111.593 94.8453 112.013 97.6602C112.054 97.9431 112.099 98.2251 112.099 98.5189C112.097 98.8137 112.053 99.0967 112.013 99.3786Z"
        fill="#105E26"
      />
      <path
        d="M46.796 94.3926C44.8143 94.3926 43.1559 95.7975 42.7606 97.6609C42.7015 97.9379 42.668 98.2248 42.668 98.5196C42.668 98.8154 42.7005 99.1023 42.7606 99.3793C43.158 101.245 44.8163 102.648 46.796 102.648C48.7767 102.648 50.4361 101.243 50.8313 99.3793C50.8915 99.1023 50.924 98.8154 50.924 98.5196C50.924 98.2248 50.8915 97.9379 50.8313 97.6609C50.435 95.7965 48.7776 94.3926 46.796 94.3926Z"
        fill="#105E26"
      />
      <path
        d="M46.7957 87.6411C41.0872 87.6411 36.403 92.0621 35.9643 97.659C35.9406 97.943 35.9199 98.2269 35.9199 98.5178C35.9199 98.8076 35.9416 99.0935 35.9643 99.3775C36.405 104.974 41.0882 109.395 46.7957 109.395C52.5042 109.395 57.1884 104.974 57.6291 99.3775C57.6508 99.0935 57.6715 98.8076 57.6715 98.5178C57.6715 98.2279 57.6498 97.942 57.6291 97.659C57.1884 92.0629 52.5042 87.6411 46.7957 87.6411V87.6411ZM52.5564 99.3786C52.1364 102.193 49.7248 104.367 46.7957 104.367C43.8656 104.367 41.4539 102.194 41.035 99.3786C40.9936 99.0957 40.9482 98.8137 40.9482 98.5189C40.9482 98.2241 40.9926 97.9421 41.035 97.6602C41.455 94.8454 43.8666 92.6723 46.7957 92.6723C49.7258 92.6723 52.1374 94.8453 52.5564 97.6602C52.5978 97.9431 52.6432 98.2251 52.6432 98.5189C52.6432 98.8137 52.5978 99.0967 52.5564 99.3786Z"
        fill="#105E26"
      />
      <path
        d="M106.253 94.3926C104.271 94.3926 102.613 95.7975 102.218 97.6609C102.158 97.9379 102.125 98.2248 102.125 98.5196C102.125 98.8154 102.158 99.1023 102.218 99.3793C102.615 101.245 104.273 102.648 106.253 102.648C108.234 102.648 109.891 101.243 110.287 99.3793C110.346 99.1023 110.38 98.8154 110.38 98.5196C110.38 98.2248 110.347 97.9379 110.287 97.6609C109.889 95.7965 108.233 94.3926 106.253 94.3926Z"
        fill="#105E26"
      />
      <path
        d="M53.2822 67.4365H87.1641V48.6162H59.7335L53.2822 67.4365Z"
        fill="#105E26"
      />
      <path
        d="M27.4092 75.9045H34.2012V80.7879H27.4092V75.9045Z"
        fill="#105E26"
      />
      <path
        d="M118.851 72.3835H123.11V74.1858H118.851V72.3835Z"
        fill="#105E26"
      />
      <path
        d="M49.6348 122.717C49.6348 124.641 48.074 126.202 46.1494 126.202C44.2248 126.202 42.665 124.641 42.665 122.717C42.665 120.792 44.2248 119.231 46.1494 119.231C48.074 119.231 49.6348 120.792 49.6348 122.717Z"
        fill="#105E26"
      />
      <path
        d="M13.9562 42.6964L17.12 41.0341L20.2808 42.6964L19.6774 39.1747L22.235 36.6803L18.7014 36.1666L17.12 32.9624L15.5366 36.1666L12 36.6803L14.5596 39.1747L13.9562 42.6964Z"
        fill="#105E26"
      />
      <path
        d="M43.9811 9.0045L46.2408 7.81745L48.4986 9.0045L48.0668 6.4894L49.8957 4.70783L47.3707 4.34008L46.2408 2.05371L45.1109 4.34008L42.5869 4.70783L44.4129 6.4894L43.9811 9.0045Z"
        fill="#105E26"
      />
      <path
        d="M134.635 94.2968L136.462 92.5152L133.937 92.1484L132.808 89.8611L131.677 92.1484L129.152 92.5152L130.981 94.2968L130.549 96.8119L132.808 95.6268L135.067 96.8119L134.635 94.2968Z"
        fill="#105E26"
      />
      <path
        d="M128.979 24.4255L132.807 21.6441L136.635 24.4255L135.173 19.9257L139 17.1453H134.267L132.807 12.6436L131.345 17.1453H126.612L130.44 19.9257L128.979 24.4255Z"
        fill="#105E26"
      />
      <path
        d="M124.58 3.48435C124.58 5.40987 123.019 6.96972 121.095 6.96972C119.17 6.96972 117.609 5.40997 117.609 3.48435C117.609 1.55982 119.17 0 121.095 0C123.019 0 124.58 1.55974 124.58 3.48435Z"
        fill="#105E26"
      />
      <path
        d="M137.881 64.5517C137.881 66.1066 136.622 67.3655 135.068 67.3655C133.514 67.3655 132.254 66.1064 132.254 64.5517C132.254 62.9979 133.514 61.7388 135.068 61.7388C136.621 61.7388 137.881 62.9978 137.881 64.5517Z"
        fill="#105E26"
      />
      <path
        d="M30.2707 17.6866C30.2707 19.7078 28.6321 21.3474 26.61 21.3474C24.5888 21.3474 22.9502 19.7078 22.9502 17.6866C22.9502 15.6655 24.5888 14.0269 26.61 14.0269C28.6321 14.0269 30.2707 15.6655 30.2707 17.6866Z"
        fill="#105E26"
      />
      <path
        d="M38.3703 123.257L38.5882 122.174C38.113 122.079 37.6417 121.972 37.1774 121.856L36.9062 122.928C37.3894 123.05 37.8784 123.158 38.3704 123.257H38.3703Z"
        fill="#105E26"
      />
      <path
        d="M14.1885 93.8021C14.1885 94.2881 14.2003 94.7722 14.224 95.2533L15.3272 95.2001C15.3036 94.7367 15.2937 94.2694 15.2937 93.8011C15.2937 93.3229 15.3065 92.8427 15.3302 92.3596L14.2259 92.3054C14.2003 92.8063 14.1885 93.3042 14.1885 93.8021H14.1885Z"
        fill="#105E26"
      />
      <path
        d="M16.1572 100.804C15.9295 99.8897 15.7422 98.9531 15.6041 98.0215L14.5117 98.1832C14.6557 99.1504 14.8489 100.123 15.0885 101.072L16.1572 100.804Z"
        fill="#105E26"
      />
      <path
        d="M18.4619 109.211C18.9657 110.05 19.5149 110.874 20.0966 111.662L20.9839 111.005C20.4229 110.247 19.8945 109.453 19.4084 108.644L18.4619 109.211Z"
        fill="#105E26"
      />
      <path
        d="M31.8365 119.943L31.3643 120.941C32.2506 121.361 33.1665 121.74 34.0884 122.072L34.4621 121.032C33.5718 120.715 32.6884 120.348 31.8366 119.943H31.8365Z"
        fill="#105E26"
      />
      <path
        d="M18.0681 106.139C17.6628 105.283 17.298 104.402 16.9816 103.517L15.9424 103.889C16.2727 104.806 16.6503 105.722 17.0713 106.61L18.0681 106.139Z"
        fill="#105E26"
      />
      <path
        d="M29.3353 118.6C28.5298 118.116 27.7362 117.585 26.977 117.019L26.3174 117.905C27.1051 118.491 27.9294 119.043 28.7654 119.547L29.3353 118.6Z"
        fill="#105E26"
      />
      <path
        d="M24.0393 116.031L24.7827 115.213C24.0837 114.578 23.4093 113.9 22.7793 113.202L21.959 113.942C22.6146 114.668 23.3137 115.371 24.0393 116.031V116.031Z"
        fill="#105E26"
      />
      <path
        d="M15.8594 88.0929L14.7769 87.876C14.6783 88.3689 14.5925 88.8609 14.5176 89.3539L15.61 89.5156C15.6829 89.0423 15.7648 88.5671 15.8594 88.0929L15.8594 88.0929Z"
        fill="#105E26"
      />
      <path
        d="M136.894 104.345L135.79 104.321C135.78 104.802 135.753 105.279 135.709 105.754L136.809 105.854C136.855 105.356 136.882 104.852 136.894 104.345V104.345Z"
        fill="#105E26"
      />
      <path
        d="M120.393 124.345C121.466 124.117 122.53 123.799 123.559 123.399L123.159 122.37C122.187 122.747 121.179 123.047 120.163 123.263L120.393 124.345Z"
        fill="#105E26"
      />
      <path
        d="M129.244 120.079L128.543 119.226C127.736 119.887 126.874 120.488 125.976 121.011L126.532 121.963C127.482 121.41 128.393 120.777 129.244 120.079H129.244Z"
        fill="#105E26"
      />
      <path
        d="M130.802 117.058L131.628 117.792C132.36 116.967 133.031 116.081 133.622 115.157L132.69 114.562C132.131 115.437 131.495 116.276 130.802 117.058L130.802 117.058Z"
        fill="#105E26"
      />
      <path
        d="M136.245 109.118L135.175 108.843C134.915 109.852 134.574 110.848 134.16 111.802L135.173 112.243C135.611 111.235 135.972 110.183 136.245 109.118V109.118Z"
        fill="#105E26"
      />
      <path
        d="M117.106 124.774L117.049 123.67C116.575 123.695 116.098 123.703 115.616 123.692L115.593 124.796C115.737 124.799 115.879 124.8 116.022 124.8C116.384 124.802 116.747 124.793 117.106 124.774V124.774Z"
        fill="#105E26"
      />
      <path
        d="M134.517 51.8762H135.621V54.8212H134.517V51.8762Z"
        fill="#105E26"
      />
      <path
        d="M134.517 45.9878H135.621V48.9328H134.517V45.9878Z"
        fill="#105E26"
      />
      <path
        d="M134.517 34.2085H135.621V37.1535H134.517V34.2085Z"
        fill="#105E26"
      />
      <path
        d="M134.517 40.0994H135.621V43.0443H134.517V40.0994Z"
        fill="#105E26"
      />
      <defs>
        <linearGradient
          id="paint0_linear_271_4584"
          x1="31.8214"
          y1="0"
          x2="31.8214"
          y2="63.6429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#105E26" />
          <stop offset="1" stopColor="#157831" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_271_4584"
          x1="22.1786"
          y1="0"
          x2="22.1786"
          y2="44.3571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#105E26" />
          <stop offset="1" stopColor="#157831" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ComprehensiveReport;
