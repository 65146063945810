import React, { Fragment, useEffect } from 'react'
import { Button, Popover } from '@mui/material'
import { DropDownIcon } from '../../../assets/icons/navbarIcons'
// import CrossIcon from '../../../assets/icons/crossIcon.jsx'
// import DropdownRightArrow from 'assets/icons/dropdownrightarrow'
// import SearchIcon from 'assets/icons/searchicon'
import MultiRangeSlider from 'Components/slider/MultiRangeSlider'
import { FormattedMessage } from 'react-intl'
const SearchResultDropDown = ({ setSearchParams }: any) => {
  const [anchorE2, setAnchorE2] = React.useState(null)
  const makeOpen = Boolean(anchorE2)
  const handleMakeClose = () => {
    setAnchorE2(null)
  }

  const handleMakeClick = (event: any) => {
    setAnchorE2(event.currentTarget)
  }

  const yearMin = 1998,
    yearMax = 2022
  const mileageMin = 0,
    mileageMax = 1000000

  const [curMinYear, setCurMinYear] = React.useState(yearMin)
  const [curMaxYear, setCurMaxYear] = React.useState(yearMax)
  const [curMinMileage, setCurMinMileage] = React.useState(mileageMin)
  const [curMaxMileage, setCurMaxMileage] = React.useState(mileageMax)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const minYear = urlParams.get('minYear')
    const maxYear = urlParams.get('maxYear')
    if (minYear && maxYear) {
      setCurMinYear(parseInt(minYear))
      setCurMaxYear(parseInt(maxYear))
    }
    const minMileage = urlParams.get('minMileage')
    const maxMileage = urlParams.get('maxMileage')
    if (minMileage && maxMileage) {
      setCurMinMileage(parseInt(minMileage))
      setCurMaxMileage(parseInt(maxMileage))
    }
  }, [])
  return (
    <>
      <button
        onClick={handleMakeClick}
        className="flex items-center space-x-3  md:justify-center"
      >
        <span
          className={`tracking-widest ${
            makeOpen ? 'text-[#105e26]' : 'text-black'
          } md:text-xs md: tracking-wide `}
        >
          <FormattedMessage id="search.year_mileage" />
        </span>
        <DropDownIcon stroke={`${makeOpen ? '#105e26' : '#000'}  `} />
      </button>
      <Popover
        open={makeOpen}
        anchorEl={anchorE2}
        onClose={handleMakeClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginY: '10px' }}
      >
        <ul className="px-3 py-1 text-white flex md:flex-col ">
          <li className={` mx-2 md:mx-0 md:my-2`}>
            <h1>Year</h1>
            <MultiRangeSlider
              sliderAdditionalClass="border border-grey"
              min={yearMin}
              max={yearMax}
              curMin={curMinYear}
              curMax={curMaxYear}
              type={undefined}
              onChange={({
                min,
                max,
              }: {
                min: number
                max: number
              }) => {
                if (!(min === yearMin && max === yearMax)) {
                  setSearchParams((prev: any) => ({
                    ...prev,
                    minYear: min,
                    maxYear: max,
                  }))
                } else {
                  setSearchParams((prev: any) => ({
                    ...prev,
                    minYear: '',
                    maxYear: '',
                  }))
                }
                setCurMinYear(min)
                setCurMaxYear(max)
                console.log(`min = ${min}, max = ${max}`)
              }}
            />
          </li>
          <li className={` mx-2 `}>
            <h1>Mileage</h1>
            <MultiRangeSlider
              min={mileageMin}
              max={mileageMax}
              curMin={curMinMileage}
              curMax={curMaxMileage}
              type="mileage"
              onChange={({
                min,
                max,
              }: {
                min: number
                max: number
              }) => {
                if (!(min === mileageMin && max === mileageMax)) {
                  setSearchParams((prev: any) => ({
                    ...prev,
                    minMileage: min,
                    maxMileage: max,
                  }))
                } else {
                  setSearchParams((prev: any) => ({
                    ...prev,
                    minMileage: '',
                    maxMileage: '',
                  }))
                }
                setCurMinMileage(min)
                setCurMaxMileage(max)
                console.log(`min = ${min}, max = ${max}`)
              }}
            />
          </li>
        </ul>
      </Popover>
    </>
  )
}

export default SearchResultDropDown
