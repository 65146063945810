import React, { Component } from 'react'
import Slider from 'react-slick'
import RightArrow from '../../assets/icons/slider-right-arrow.jsx'
import LeftArrow from '../../assets/icons/slider-active-left-arrow.jsx'
import classes from './style.module.css'
import './style.css'
import useMediaQuery from '@mui/material/useMediaQuery'

function SampleArrow({ classes, style, onClick, color = '' }) {
  return (
    <LeftArrow
      classes={classes}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      color={color}
    />
  )
}

export default function CustomArrows({ data = [], children = null }) {
  const isMobile = useMediaQuery('(max-width:768px)')
  const isDesktop = useMediaQuery('(max-width:1280px)')
  const isBigDesktop = useMediaQuery('(max-width:1440px)')

  const perRow = !!isMobile ? 3 : !!isDesktop ? 4 : !!isBigDesktop ? 5 : 6

  const settings = (length) => {
    return {
      infinite: true,
      slidesToShow: length <= perRow ? length : perRow,
      speed: 500,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      swipeToSlide: true,
      swipe: true,
      nextArrow: <SampleArrow classes=" w-24 -rotate-180 " />,
      prevArrow: <SampleArrow classes=" w-24  " />,
    }
  }

  // console.log({ SlickSlider: data.length })

  return (
      <Slider
        {...settings(data.length)}
        className="flex flex-row justify-center items-center"
      >
        {children
          ? children
          : data.map((el) => (
            <a href={`${window.location.origin}/search-result?make=${el.makeName}`} key={el._id} className={classes.item} rel="noopener noreferrer">
              <img src={el.makeLogo} />
            </a>
              // <p>${el}</p>
            ))}
      </Slider>
  )
}
