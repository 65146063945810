import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import PaymentIconCard from '../Cards/PaymentIconCard'
import { VisaIcon, PayPalIcon, MasterCardIcon, MoMoIcon } from '../../assets/icons/PaymentIcons'
import CircularProgress from '@mui/material/CircularProgress'
import { APIEndpoints } from 'staticStore'
import { useSelector } from 'react-redux'
import useMutation from 'redux/hooks/useMutation'
import Btn from 'Components/Buttons/Btn'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import './style.css'
import { useIntl } from 'react-intl'

const PaymentMethod = ({ id, pendingListing }) => {
  const intl = useIntl()
  const [formData, setFormData] = useState({
    requestType: 'captureWallet',
  })
  const endUser = useSelector((state) => state.user.data)
  const lang = useSelector((state) => state.options.language)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [amount, setAmount] = useState('2500000')
  const [requestType, setRequestType] = useState('captureWallet')

  const [submitPayment, { loading }] = useMutation({
    endPoint: APIEndpoints.PAY,
    method: 'post',
  })

  useEffect(() => {
    checkAllFieldsFilled()
  }, [formData])

  const checkAllFieldsFilled = () => {
    const { requestType } = formData

    const allFilled = requestType !== '' && requestType !== null && requestType !== undefined
    setIsButtonDisabled(!allFilled)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'paymentMethod') {
      setRequestType(value)
      setFormData((prevState) => ({
        ...prevState,
        requestType: value,
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    checkAllFieldsFilled()
  }

  // const handleDateChange = (date) => {
  //     setFormData(prevState => ({
  //         ...prevState,
  //         appointmentDate: date,
  //     }));
  //     checkAllFieldsFilled();
  // };

  const handleSubmitPayment = async (e) => {
    e.preventDefault()
    const payload = {
      lang,
      listingId: id,
      ...formData,
      amount,
    }

    try {
      const responseData = await submitPayment(payload)
      if (responseData && responseData.payUrl) {
        window.location.href = responseData.payUrl
      }
    } catch (err) {
      console.log('Error while submitting payment: ', err)
    }
  }

  return (
    <div className="border-2 rounded-xl p-10 my-3 text-black w-full sm:p-4">
      <div className="mb-10">
        <h1 className="text-3xl font-bold">
          {intl.formatMessage({
            id: 'create_listing.steps.5.title',
          })}
        </h1>
        <p className="text-sm">
          {intl.formatMessage({
            id: 'create_listing.steps.5.description',
          })}
        </p>
      </div>
      {/* 
            <div className="ticket">
                <div className="ticket-header">
                    <div className="info-wrapper">
                        <div className="heading3">Schedule your free vehicle inspection from a Sleekride Servicing Center.</div>
                        <DatePicker
                            // renderInput={(props: any) => <TextField {...props} />}
                            label="Appointment Date (required)"
                            value={formData.appointmentDate}
                            onChange={handleDateChange}
                        />
                    </div>
                </div>
            </div> */}

      <div className="flex gap-x-4 rounded-lg sm:gap-x-1">
        <PaymentIconCard
          Icon={<MoMoIcon className="momo-icon" />}
          name="paymentMethod"
          value="captureWallet"
          onChange={handleChange}
          checked={requestType === 'captureWallet'}
        />
        <PaymentIconCard
          Icon={<MasterCardIcon height={20} />}
          title="Credit Card"
          name="paymentMethod"
          value="mastercard"
          onChange={handleChange}
          checked={requestType === 'mastercard'}
        />
        <PaymentIconCard Icon={<VisaIcon height={40} />} name="paymentMethod" value="visa" onChange={handleChange} checked={requestType === 'visa'} />
      </div>

      <div className="w-full my-8">
        <Btn
          handleSubmit={(e) => handleSubmitPayment(e)}
          loading={loading}
          disabled={isButtonDisabled}
          text={intl.formatMessage({
            id: 'create_listing.steps.5.complete',
          })}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            intl.formatMessage({
              id: 'create_listing.steps.5.complete_purchase',
            })
          )}
        </Btn>
      </div>
    </div>
  )
}

export default PaymentMethod
