import React from "react";

function QuotationMark({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="50"
      height="25"
      viewBox="0 0 91 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.5172 14.0573C91.1491 13.4254 91.1491 12.4008 90.5172 11.7689L80.2193 1.47103C79.5874 0.839107 78.5628 0.839107 77.9309 1.47103C77.299 2.10296 77.299 3.12752 77.9309 3.75944L87.0845 12.9131L77.9309 22.0667C77.299 22.6987 77.299 23.7232 77.9309 24.3551C78.5628 24.9871 79.5874 24.9871 80.2193 24.3551L90.5172 14.0573ZM0.914062 14.5312H89.373V11.2949H0.914062V14.5312Z"
        fill="white"
      />
    </svg>
  );
}

export default QuotationMark;
