import React, { Fragment } from 'react'
import { Button, Popover } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ProfileDropdownIcon from 'assets/icons/ProfileDropdownIcon'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import MalePicture from '../../assets/images/testinomial2.png'
import FemalePicture from '../../assets/images/testinomial1.png'
import clsx from 'clsx'
const ProfileDropDown = ({ className = '', handleLogout, reversedName = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const user = useSelector((state) => state.user?.data)
  const profileOpen = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  //when user scrolls down the page, the dropdown should close
  window.onscroll = function () {
    setAnchorEl(null)
  }

  const intl = useIntl()

  const getName = (name) => {
    let shortendName = ''

    if (name) {
      const nameParts = name.split(' ')
      const firstName = nameParts[0]

      shortendName = firstName.length > 10 ? `${firstName.slice(0, 10)}...` : firstName
    }

    return shortendName
  }
  return (
    <>
      <button onClick={handleClick} className={`flex flex-row justify-center  items-center p-2  space-x-3 ${className}`}>
        {/* create round picture for user.logo */}
        <div
          className={clsx(' -lg:space-x-2 flex flex-row items-center  ', {
          })}>
          <img
            className={clsx('w-10 h-10  rounded-full', {
              'w-7 h-7 mr-3': reversedName,
            })}
            src={user?.endImage ? user?.endImage : !user?.gender ? MalePicture : user?.gender == 'Male' ? MalePicture : FemalePicture}
            alt="user logo"
          />
          <p className="flex-1 text-sm space-x-3">
            {
              // split and slice if possible
              getName(user?.endName)
            }
          </p>
        </div>
        <ProfileDropdownIcon />
      </button>
      <Popover
        open={profileOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ borderRadius: '100%' }}>
        <ul className={'  p-4 text-grey text-sm '}>
          <li className={` border-grey border-b border-opacity-30 `}>
            <button onClick={() => setAnchorEl(null)} className="p-2 ">
              <Link to={'/setting'}>
                <p className=" "> {intl.formatMessage({ id: 'nav_bar.my_account' })}</p>
              </Link>
            </button>
          </li>
          <li className={` border-grey border-b border-opacity-30`} onClick={handleLogout}>
            <button className="p-2 ">
              <p className=" "> {intl.formatMessage({ id: 'nav_bar.log_out' })}</p>
            </button>
          </li>
        </ul>
      </Popover>
    </>
  )
}

export default ProfileDropDown
