import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const VerificationToast = () => {
  const { verificationMessage } = useSelector((state) => state.user)
  useEffect(() => {
    if (verificationMessage) {
      // toast.success(verificationMessage)
    }
  }, [verificationMessage])

  return null
}

export default VerificationToast
