import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react'
import classnames from 'classnames'
import './style.css'
import { VNDshorten } from 'Utils/Parse/currency'

interface MultiRangeSliderProps {
  min: number
  max: number
  curMin?: number
  curMax?: number
  onChange: Function
  type?: 'price' | 'year' | 'mileage' | undefined
  sliderAdditionalClass?: string // additional class for slider, primarily to add a border around the slider values when bg is white
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({
  min,
  max,
  curMin = min,
  curMax = max,
  onChange,
  type,
  sliderAdditionalClass = '',
}) => {
  const [minVal, setMinVal] = useState(curMin)
  const [maxVal, setMaxVal] = useState(curMax)
  const minValRef = useRef<HTMLInputElement>(null)
  const maxValRef = useRef<HTMLInputElement>(null)
  const range = useRef<HTMLDivElement>(null)

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal)
      const maxPercent = getPercent(+maxValRef.current.value) // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxVal)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxVal, getPercent])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal])

  return (
    <div className="slider-container   h-16">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(+event.target.value, maxVal - 1)
          setMinVal(value)
          event.target.value = value.toString()
        }}
        className={classnames('thumb thumb--zindex-3', {
          'thumb--zindex-5': minVal > max - 100,
        })}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(+event.target.value, minVal + 1)
          setMaxVal(value)
          event.target.value = value.toString()
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider flex flex-col-reverse justify-center ">
        <div className={classnames(sliderAdditionalClass, "slider__left-value font-semibold border-0 text-md my-2 p-1 text-[#105e26] rounded bg-white")}>
          {type == 'price'
            ? VNDshorten(minVal)
            : type == 'mileage'
              ? minVal + ' km'
              : minVal}{' '}
        </div>
        <div className={classnames(sliderAdditionalClass, "slider__right-value  font-semibold border-0 text-md my-2 p-1 text-[#105e26] rounded bg-white")}>
          {type == 'price'
            ? VNDshorten(maxVal)
            : type == 'mileage'
              ? maxVal + ' km'
              : maxVal}
        </div>
        <div className="slider__track "></div>
        <div ref={range} className="slider__range "></div>
      </div>
    </div>
  )
}

export default MultiRangeSlider
