import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'

const validation = {
  dealershipName: 'Dealership Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  phone: 'Phone Number',
  zip: 'Zip',
  usedInventorySize: 'Used Inventory Size',
}
const Left = ({ bgColor }) => {
  const intl = useIntl()
  const [formData, setFormData] = useState({
    dealershipName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    zip: '',
    usedInventorySize: '',
  })

  const [sendEmail] = useMutation({
    endPoint: APIEndpoints.SEND_FOR_DEALER_FORM,
    method: 'post',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    let newVal = value
    if (name == 'phone' || name == 'zip') {
      // check if value is a number with regex
      const regex = /^\d+$/
      if (!regex.test(value)) return
    }
    newVal = value
    setFormData({ ...formData, [name]: newVal })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Check if any field is missing
    const missingFields = Object.keys(formData).filter((key) => !formData[key])
    if (missingFields.length === 1) {
      // Display a toast for the missing field
      toast.error(`Please fill in the "${validation[missingFields[0]]}" field`)
    } else if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map((field) => validation[field])
      // Display a toast for missing fields
      toast.error(`Please fill in the following fields: ${missingFieldNames.join(', ')}`)
    } else {
      // Perform further actions like sending data to a server
      console.log(formData)
      sendEmail(formData)
    }
  }

  useEffect(() => {
    console.log(formData)
  }, [formData])

  return (
    <div
      className={`h-full flex flex-col px-28 lg:px-12 p-12 lg:p-6 sm:p-5 rounded-xl border-1 ${
        bgColor && bgColor === '#105e26' ? 'bg-[#105e26] bg-opacity-100 text-white' : 'bg-slate-50 bg-opacity-50 text-black'
      } w-full shadow-[0px_0px_10px_rgba(0,0,0,0.3)] border-black`}>
      <form className=" " onSubmit={handleSubmit}>
        <div className="my-3 text-gray-900 border bg-[#fff] opacity-100 rounded-lg p-2 ">
          <label htmlFor="dealershipName" className="block text-xs text-[#84879E] my-1">
            {intl.formatMessage({
              id: 'for_dealer.contact.dealership_name',
            })}{' '}
            *
          </label>
          <input
            type="text"
            id="dealershipName"
            name="dealershipName"
            value={formData.dealershipName}
            onChange={handleChange}
            className="block w-full outline-none border-0 bg-transparent"
          />
        </div>

        <div className="py-3 grid grid-cols-2 gap-8 sm:grid-cols-1 ">
          <div className="text-gray-900 border bg-[#fff] opacity-100 rounded-lg p-2">
            <label htmlFor="firstName" className="block text-xs text-[#84879E] my-1">
              {intl.formatMessage({
                id: 'for_dealer.contact.first_name',
              })}{' '}
              *
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="block w-full outline-none border-0 bg-transparent"
            />
          </div>
          <div className="text-gray-900 border bg-[#fff] opacity-100 rounded-lg p-2">
            <label htmlFor="lastName" className="block text-xs text-[#84879E] my-1">
              {intl.formatMessage({
                id: 'for_dealer.contact.last_name',
              })}{' '}
              *
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="block w-full outline-none border-0 bg-transparent"
            />
          </div>
        </div>

        <div className="py-3 grid grid-cols-2 gap-8 sm:grid-cols-1 ">
          <div className="text-gray-900 border bg-[#fff] opacity-100 rounded-lg p-2">
            <label htmlFor="email" className="block text-xs text-[#84879E] my-1">
              {intl.formatMessage({
                id: 'for_dealer.contact.email',
              })}{' '}
              *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="block w-full outline-none border-0 bg-transparent"
            />
          </div>
          <div className="text-gray-900 border bg-[#fff] opacity-100 rounded-lg p-2">
            <label htmlFor="phone" className="block text-xs text-[#84879E] my-1">
              {intl.formatMessage({
                id: 'for_dealer.contact.phone',
              })}{' '}
              *
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="block w-full outline-none border-0 bg-transparent"
            />
          </div>
        </div>

        <div className="py-3 grid grid-cols-2 gap-8 sm:grid-cols-1 ">
          <div className="text-gray-900 border bg-[#fff] opacity-100 rounded-lg p-2">
            <label htmlFor="zip" className="block text-xs text-[#84879E] my-1">
              {intl.formatMessage({
                id: 'for_dealer.contact.zip',
              })}{' '}
              *
            </label>
            <input
              type="number"
              id="zip"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              className="block w-full outline-none border-0 bg-transparent"
            />
          </div>
          <div className="text-gray-900 border bg-[#fff] opacity-100 rounded-lg p-2">
            <label htmlFor="usedInventorySize" className="block text-xs text-[#84879E] my-1">
              {intl.formatMessage({
                id: 'for_dealer.contact.used_inventory_size',
              })}{' '}
              *
            </label>
            <input
              type="text"
              id="usedInventorySize"
              name="usedInventorySize"
              value={formData.usedInventorySize}
              onChange={handleChange}
              className="block w-full outline-none border-0 bg-transparent"
            />
          </div>
        </div>

        <div className="my-8 flex justify-center sm:my-6">
          <button
            className={`${
              bgColor && bgColor === '#105e26' ? 'bg-[#fff] text-[#105e26]' : 'bg-[#105E26] shadow-[#105E26] text-white'
            } px-10 p-3 sm:p-2 sm:px-14 rounded-lg shadow-md font-semibold`}>
            {intl.formatMessage({
              id: 'for_dealer.contact.get_started',
            })}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Left
