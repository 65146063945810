import React from 'react'
import ResponsiveAppBar from 'Components/AppBar'
import carimage from '../../assets/images/adbanner.png'
import ResearchCard from 'Components/Cards/ResearchCard'
import { ReactComponent as RightArrow } from '../../assets/icons/researchcardrightArrow.svg'
import Footer from 'Components/Footer'
import downloadbanner from '../../assets/images/downloadbanner.png'
import DownloadBannerSecondary from 'Components/DownloadBannerSecondary'
import SecondaryBannerMobile1 from 'assets/images/SecondaryBannerMobile1.png'
import car5 from '../../assets/images/car5.png'
import car6 from '../../assets/images/car6.png'
import car7 from '../../assets/images/car7.png'
import article1 from '../../assets/images/articleimgs/article1.jpg'
import article2 from '../../assets/images/articleimgs/article2.png'
import article3 from '../../assets/images/articleimgs/article3.jpg'
import article11 from '../../assets/images/articleimgs/article11.jpg'
import article18 from '../../assets/images/articleimgs/article18.png'
import article24 from '../../assets/images/articleimgs/article24.jpg'
import article32 from '../../assets/images/articleimgs/article32.png'
import { FormattedMessage, useIntl } from 'react-intl'
const articleBuyUsedCar = [
  {
    _id: '64da0cda401ccaa8f7fe7d43',
    title: 'THỦ TỤC SANG TÊN ĐỔI CHỦ XE Ô TÔ THEO QUY ĐỊNH MỚI NHẤT',
    short_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ',
    image: article1,
  },
  {
    _id: '64da0cda401ccaa8f7fe7d44',
    title: 'KINH NGHIỆM KHI MUA XE HƠI CŨ TRẢ GÓP 2023',
    short_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ',
    image: article2,
  },
  {
    _id: '64da0cda401ccaa8f7fe7d45',
    title: 'SÔI ĐỘNG THỊ TRƯỜNG MUA BÁN XE HƠI CŨ 2023',
    short_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ',
    image: article3,
  },
]
const articleBuyNewCar = [
  {
    _id: '64da0cda401ccaa8f7fe7d46',
    title: 'TOP 6 MẪU XE KHOẢNG 500 TRIỆU "HOT" NHẤT NĂM 2023',
    short_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ',
    image: article11,
  },
]
const articleReview = [
  {
    _id: '64da0cda401ccaa8f7fe7d48',
    title: 'DRIVEN: ĐÁNH GIÁ MERCEDES-BENZ C-CLASS 2023',
    short_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ',
    image: article24,
  },
  {
    _id: '64da0cda401ccaa8f7fe7d49',
    title: 'DRIVEN: ĐÁNH GIÁ XE HYUNDAI ELANTRA 2023',
    short_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ',
    image: article32,
  },
]
const articleShopandNego = [
  {
    _id: '64da0cda401ccaa8f7fe7d47',
    title: 'CÁCH ĐÀM PHÁN GIÁ XE',
    short_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ',
    image: article18,
  },
]
// const dataTorender1 = [
//   {
//     title: "The best sports car under 30,000 to buy in 2022",
//     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ",
//     image: car5,
//   },
//   {
//     title: "The best sports car under 30,000 to buy in 2022",
//     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ",
//     image: car6,
//   },
//   {
//     title: "The best sports car under 30,000 to buy in 2022",
//     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ",
//     image: car7,
//   },
// ];

const MyComponent = ({ heading, dataTorender }) => {
  return (
    <div>
      <div className="mt-6 md:mt-3 flex justify-between  py-4 ">
        <p className="font-bold xs:text-md  sm:text-lg lg:text-2xl xl:text-3xl xxl:text-[48px]">
          {heading}
        </p>
        <div className="flex items-center">
          <span className="text-xs font-bold mr-2">
            <FormattedMessage id="see_all" />
          </span>
          <RightArrow />
        </div>
      </div>
      <div className="  w-full flex flex-wrap gap-5 xxl:gap-10  xxl:justify-start  py-4 justify-center ">
        {dataTorender.map((el, index) => (
          <ResearchCard
            id={el._id}
            key={el._id}
            title={el.title}
            description={el.desc}
            image={el.image}
          />
        ))}
      </div>
    </div>
  )
}

const Research = () => {
  const intl = useIntl()
  return (
    <div className="w-[90%] m-auto">
      {/* container */}
      <div className="p-10  sm:p-4  ">
        {/* ad banner */}
        {/* <div
          className="my-4  w-full sm:h-16 md:h-20 lg:h-28 xl:h-36 xxl:h-72  "
          style={{
            backgroundImage: `url(${carimage})`,
            width: '100%',
            backgroundSize: ' contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <img className="w-full h-full" src={carimage} />
          <img className="w-full h-full object-cover" src={'https://thumbs.dreamstime.com/z/tires-realistic-horizontal-banner-winter-sale-offering-cashback-up-to-percent-vector-illustration-234390458.jpg'} />
        </div> */}
        <div className="sm:my-5 my-10 xxl:my-20">
          {/* Tips for Buying a Used Car  */}
          <MyComponent
            heading={intl.formatMessage({
              id: 'research.title1',
            })}
            dataTorender={articleBuyUsedCar}
          />

          {/* Tips for Buying a new Car   */}
          <MyComponent
            heading={intl.formatMessage({
              id: 'research.title2',
            })}
            dataTorender={articleBuyNewCar}
          />

          {/* Expert Reviews */}
          <MyComponent
            heading={intl.formatMessage({
              id: 'research.title3',
            })}
            dataTorender={articleReview}
          />

          {/* Previews */}
          <MyComponent
            heading={intl.formatMessage({
              id: 'research.title4',
            })}
            dataTorender={articleReview}
          />

          {/* Shopping and Negotiating */}
          <MyComponent
            heading={intl.formatMessage({
              id: 'research.title5',
            })}
            dataTorender={articleShopandNego}
          />
        </div>
      </div>

      <div className="my-8 sm:my-4  ">
        {/* <img src={downloadbanner} /> */}
        <DownloadBannerSecondary image={SecondaryBannerMobile1} />
      </div>
    </div>
  )
}

export default Research
