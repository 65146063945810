import React, { useState } from "react";
import CarImage from "../../assets/images/carImage.png";


const Right = (pendingListing) => {
  let listing = pendingListing.pendingListing

  return (
    <div className=" p-10  my-3 rounded-xl border-2 bg-[#F0F0F0]  text-black w-full sm:p-4">
      <div className="my-5">
        <h1 className="font-bold">Listing Summary</h1>
        <p className="text-sm">You have 01 item in your cart</p>
      </div>

      <div className="my-5 flex  items-center justify-between space-x-10 sm:space-x-2 ">
        <div className="w-2/5">
          {listing?.images?.length > 0 && (
            <img
              className="h-36 w-56 sm:h-28 sm:w-40 "
              src={listing.images[0].data.url}
            />
          )}
        </div>

        <div className="w-1/5 sm:w-2/6">
          <h1 className="font-bold sm:text-sm">{listing?.vin?.data.make}</h1>
          <p className="text-sm sm:text-xs">{listing?.vin?.data.model}</p>
          <p className="text-sm sm:text-xs">{listing?.vin?.data.year}</p>
        </div>
      </div>

      {/* <div className="my-5 flex  items-center justify-between space-x-10 bg-white p-4 rounded-md ">
        <div className="flex">
          <input type="checkbox" />
          <p className="text-sm ml-2 text-[#707070]">Add Featured Listing</p>
        </div>
        <div>
          <h1 className="font-bold">$49.95</h1>
        </div>
      </div> */}

      <div className="my-5 border border-b-2 ">
      <div className="flex justify-between my-4">
          <h1 className="text-[#707070]">List on sleekride.vn :</h1>
          <h1 className="font-semibold">$49.95</h1>
        </div>
        <div className="subtotal flex justify-between my-4">
          <h1 className="text-[#707070]">Sub Total :</h1>
          <h1 className="font-semibold">$49.95</h1>
        </div>
        <div className="flex justify-between my-4">
          <h1 className="text-[#707070]">Service Tax :</h1>
          <h1 className="font-semibold">$0.00</h1>
        </div>
        <div className="flex justify-between my-4">
          <h1 className="text-[#707070]">Promo Code :</h1>
          <h1 className="font-semibold">$0.00</h1>
        </div>
      </div>

      <div className="my-5  ">
        <div className="flex justify-between my-4">
          <h1 className="text-[#707070]">Total :</h1>
          <h1 className="font-semibold">$49.95</h1>
        </div>
      </div>
    </div>
  );
};

export default Right;
