import React from 'react'

function ArrowRight({ stroke = '#ffffff', ...rest }) {
  return (
    <svg
      {...rest}
      width="17"
      height="26"
      viewBox="0 0 17 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7218 1.9999L4.00029 12.7214L14.7218 23.443"
        stroke={stroke}
        strokeWidth="5"
        className="stroke-white cursor-pointer  p-2  w-full h-full  opacity-50 hover:opacity-100"
      />
    </svg>
  )
}

export default ArrowRight
