import React from "react";

const CrossIcon = ({stroke}) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 0.75L0.75 7.25M0.75 0.75L7.25 7.25"
        stroke= {stroke || "#707070"  } 
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
