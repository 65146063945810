import React, { useState } from 'react'
import CustomInput from 'Components/CustomInput'
import clsx from 'clsx'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
  const [state, setState] = useState({
    email: '',
  })

  const [error, setError] = useState({})

  const [resetPassword, { loading, error: resetError }] = useMutation({
    method: 'post',
    endPoint: APIEndpoints.FORGOT_PASSWORD,
  })

  const handleChange = (ev) => {
    const { name, value } = ev.target
    setState({ ...state, [name]: value })
  }

  const canSubmit = () => state.email && state.email.includes('@')

  const handleSubmit = () => {
    if (canSubmit()) {
      resetPassword({ endEmail: state.email })
        .then((data) => {
          console.log('Password reset link sent:', data)
        })
        .catch((err) => {
          console.error('Password reset error:', err)
        })
    } else {
      setError({ invalidEmail: 'Please enter a valid email address.' })
    }
  }

  return (
    <div className="h-full flex flex-col justify-center p-12 my-5 rounded-2xl border-0 bg-[#FFF] text-black w-full sm:p-6 shadow-[0px_0px_10px_rgba(0,0,0,0.3)]">
      <h1 className="text-3xl my-5 font-bold text-center">Forgot Password?</h1>
      <p className="text-md text-[#707070] text-center my-2">Enter your email address and we'll send you a link to reset your password.</p>

      <div className="xl:my-5 my-8">
        <CustomInput name="email" handleChange={handleChange} value={state.email} placeholder="Email Address" />
        {error.invalidEmail && <span className="text-red-500">{error.invalidEmail}</span>}
      </div>

      <div className="xl:my-5  my-8 mt-16">
        <button
          onClick={handleSubmit}
          className={clsx('bg-[#105E26] p-3 text-white text-[21px] w-full mt-2 rounded shadow-md shadow-[#105E26] cursor-pointer', {
            ['bg-gray-400 shadow-none cursor-no-drop']: !canSubmit(),
          })}
          disabled={!canSubmit()}>
          Send Reset Link
        </button>

        <div className="mt-2 text-center">
          <Link to="/user-login" className="text-[#105E26]">
            Return to login
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
