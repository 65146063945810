import React from 'react'
import ResponsiveAppBar from 'Components/AppBar'
import CustomizedSteppers from 'Components/Stepper'
import Left from './Left'
import Right from './Right'
import Footer from 'Components/Footer'
import { useSelector } from 'react-redux'
import VerifyEmail from './VerifyEmail'
import GoogleTranslate from '../../SDK/GoogleTranslate'
import LangSwitcher from 'i18n/LangSwitcher'
const Checkout = () => {
  const { step } = useSelector((state) => state.signup)

  return (
    <>
      {/* container */}
      {step === 0 ? (
        <div className="h-fit w-full   flex flex-col justify-center items-center -md:h-full  -md:overflow-hidden     bg-[#F7FAF8]">
          <div className="w-full flex justify-end items-center p-5">
            <LangSwitcher />
          </div>
          <div className=" flex justify-center items-center w-full h-full  md:flex-col       ">
            <div className="-md:pl-10  md:p-10 w-full h-full flex justify-center items-center">
              <Left />
            </div>
            <Right />
          </div>
        </div>
      ) : (
        <div className="h-full w-full   flex flex-col justify-center items-center    bg-[#F7FAF8]">
          <VerifyEmail />
        </div>
      )}
    </>
  )
}

export default Checkout
