import React from 'react'
import ResponsiveAppBar from 'Components/AppBar'

import Left from './Left'
import Right from './Right'
import dealerscar from '../../assets/images/dealerscar.png'

import subtract from '../../assets/images/Subtract.png'
import { Icon1, Icon2, Icon3 } from 'assets/icons/dealerhome'
import SleekRideOffers from './SleekRideOffers'
import Reviews from './Reviews'
// import Form from "./Form";
import Form from '../ContactUs/Form'
import Footer from 'Components/Footer'

import './styles.css'
import { useIntl } from 'react-intl'

const Checkout = () => {
  const intl = useIntl()
  return (
    <div>
      {/* container */}
      <div className=" flex justify-center md:flex-col  shadow-inner relative">
        {/* left */}
        {/* right */}
        <Left />
        <Right />
        <div className="absolute   md:hidden   lg:flex lg:items-end    top-16 md:top-[30%] sm:top-[40%] h-full  md:right-[15%] sm:right-[10%] ">
          <img
            className="h-full -lg:h-[550px] -lg:-top-[50px]  md:max-h-[300px] md:-top-[250px] md:left-10   top-10    relative z-10    "
            src={dealerscar}
            alt="sleekride"
          />
        </div>
      </div>
      <div className="xxl:h-[900px] flex my-8 justify-center items-center shadow-inner relative md:bg-[#105e26]">
        <div className="z-10 my-6 p-10 sm:p-4">
          <p className=" mt-28 p-10 text-5xl font-bold text-white text-center sm:mt-0 sm:p-0 sm:text-2xl">
            {intl.formatMessage({ id: 'for_dealer.description' })}
          </p>
          <div className="grid grid-cols-3 gap-[5%] pb-16 sm:pb-0 sm:gap-0 md:grid-cols-1">
            <div className="bg-[#fff] flex flex-col  justify-center items-center  p-8 lg:p-6  sm:mx-0 rounded-3xl">
              <Icon1 />
              <p className="text-black mt-4 mb-2 text-center font-semibold sm:text-sm xxl:text-[24px]">
                {intl.formatMessage({ id: 'for_dealer.feature1' })}
              </p>
              <p className="text-[#A4A7B0]  text-center lg:text-xs text-sm">
                {intl.formatMessage({
                  id: 'for_dealer.description1',
                })}
              </p>
            </div>
            <div className="bg-[#fff] flex flex-col  justify-center items-center  p-8 lg:p-6  sm:mx-0 rounded-3xl">
              <Icon2 />
              <p className="text-black mt-4 mb-2 text-center font-semibold  sm:text-sm xxl:text-[24px]">
                {intl.formatMessage({
                  id: 'for_dealer.feature2',
                })}
              </p>
              <p className="text-[#A4A7B0]  text-center lg:text-xs text-sm">
                {intl.formatMessage({
                  id: 'for_dealer.description2',
                })}
              </p>
            </div>
            <div className="bg-[#fff] flex flex-col  justify-center items-center  p-8 lg:p-6  sm:mx-0 rounded-3xl">
              <Icon3 />
              <p className="text-black mt-4 mb-2 text-center font-semibold  sm:text-sm xxl:text-[24px]">
                {intl.formatMessage({
                  id: 'for_dealer.feature3',
                })}
              </p>
              <p className="text-[#A4A7B0]  text-center lg:text-xs text-sm ">
                {intl.formatMessage({
                  id: 'for_dealer.description3',
                })}
              </p>
            </div>
          </div>
        </div>

        <div className="absolute h-full w-full md:hidden">
          <img className="h-full w-full  " src={subtract} />
        </div>
      </div>
      <div className="my-8 xxl:my-12 xxl:h-[900px]  ">
        <SleekRideOffers />
      </div>

      <div className="my-8 xxl:my-12 ">
        <Reviews />
      </div>

      <div className="my-8 xxl:my-12 sm:px-4 xxl:max-w-6xl xxl:mx-auto">
        <div className="my-4">
          <p className="text-[#105e26] text-center font-bold my-2">
            {intl.formatMessage({
              id: 'for_dealer.contact.intro',
            })}
          </p>
          <p className="text-3xl font-bold text-center my-2 ">
            {intl.formatMessage({
              id: 'for_dealer.contact.headline',
            })}
          </p>
        </div>
        <div className="w-full  my-0 mx-auto">
          <Form bgColor={'#105e26'} />
        </div>
      </div>
    </div>
  )
}

export default Checkout
