import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function CarouselComp({
  post = {},
  className = '',
  imgClassName,
  images,
  silder = true,
  autoPlay = false,
  indicators = false,
  height = '',
  id = '',
  navButtonsAlwaysInvisible = false,
  navButtonsAlwaysVisible = false,
}) {
  const [cur, setCur] = useState(0)
  const [nowed, setNowed] = useState(0)
  const [loading, setLoading] = useState(false)
  const [visibleItems, setVisibleItems] = useState(images) // Initial visible items
  const navigate = useNavigate()

  useEffect(() => {
    setVisibleItems(images)
  }, [images])

  const handleOnCur = (i) => {
    if (loading) return
    setCur(i)
    setNowed(i)
  }

  useEffect(() => {
    setLoading(true)
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 500)
    return () => clearTimeout(timeout)
  }, [cur])

  // autoplay with setInterval if loading is false
  useEffect(() => {
    if (!autoPlay || loading) return
    setLoading(true)
    const interval = setInterval(() => {
      setCur((cur) => (cur + 1) % images.length)
      setLoading(false)
    }, 4000)
    return () => clearInterval(interval)
  }, [cur, autoPlay, images.length, loading])

  const usr = useSelector((state) => state.user?.data)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)

  return (
    <div
      className="w-full h-full"
      onClick={() => {
        if (!id) return
        if (!Object.keys(usr).length && !loggedIn && !isLoading) {
          navigate('/user-login')
          return
        }

        console.log(
          'View Listing Detail :' + id,
          window.gtag('event', 'listing_detail_view', {
            listing_detail_view__userId: usr._id,
            listing_detail_view__postId: post._id,
            listing_detail_view__dealerId: post.endUser._id,
          })
        )

        navigate(`/listing/${id}`)
        window.scrollTo(0, 0)
      }}>
      <Carousel
        interval={4000}
        cycleNavigation={true}
        index={nowed}
        animation="slide"
        height={height}
        autoPlay={autoPlay}
        navButtonsAlwaysVisible={navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
        className={className}
        indicators={indicators}
        changeOnFirstRender={true}
        onChange={(now, prev) => {
          handleOnCur(now)
        }}>
        {visibleItems.map((item, i) => (
          <Item key={i} item={item} className={imgClassName} />
        ))}
      </Carousel>
      {silder && images.length > 1 && (
        <div className="relative overflow-hidden h-[150px]">
          <div
            className="absolute h-full flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${cur * 200}px)`, // Adjust the width of each item (300px in this case)
            }}>
            {images.map((item, i) => (
              <IndividualItem
                key={i}
                onClick={() => {
                  handleOnCur(i)
                }}
                loading={loading}
                item={item}
                cur={cur === i}
                className="w-[300px] h-full"
                imgClassName="p-1 w-full h-full object-cover bg-no-repeat bg-center"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

function IndividualItem({ onClick, loading, item, cur, className = '', imgClassName = '' }) {
  return (
    <div
      onClick={onClick}
      className={`${className} ${cur ? '  bg-[#105e26c8] border rounded-none ' : 'rounded-tl-3xl rounded-tr-3xl '} ${
        loading && ' pointer-events-none  '
      } `}>
      <img className={imgClassName} src={item.original} alt="carousel" />
    </div>
  )
}

function Item({ onClick, loading, item, cur, className = '' }) {
  return (
    <div
      onClick={onClick}
      className={`w-full h-full ${cur ? ' bg-[#105e26c8] border rounded-none ' : 'rounded-tl-3xl rounded-tr-3xl '} ${
        loading && ' pointer-events-none  '
      } `}>
      <img className={className} src={item.original} alt="carousel" />
    </div>
  )
}

export default CarouselComp
