import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { setListing } from 'redux/listing'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import Car from '../../assets/images/carinspectionbanner.png'
import Whyweneed1 from '../../assets/imgs/whyweneed1.svg'
import Whyweneed2 from '../../assets/imgs/whyweneed2.svg'
import Whyweneed3 from '../../assets/imgs/whyweneed3.svg'
import Whyweneed4 from '../../assets/imgs/whyweneed4.svg'
import TestinomialCarousel from 'Components/Carousel/TestinomialCarousel'
import { toast } from 'react-toastify'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints, MicroServices } from 'staticStore'
import Autocomplete from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'
import { useIntl } from 'react-intl'

const tomorrow = dayjs().add(1, 'day') as unknown as Date

type MutationType = [(variables: any) => Promise<any>, { data: any; loading: boolean; error: any }]

const CarImage = () => {
  return (
    <div className="my-3 flex items-center text-black w-full -md:p-4 -md:h-[600px] sm:hidden md:hidden relative">
      <img className="h-full w-full xl:w-auto xxl:w-auto" src={Car} />
    </div>
  )
}

const ScheduleUI = ({ pendingListing, handleChange }: any) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<{
    appointmentDate: Date | null
    serviceCenter?: Record<string, unknown> | null
    listingId?: string | null
  }>({
    appointmentDate: null,
    serviceCenter: null,
    listingId: null,
  })
  const [inspectionID, setInspectionID] = useState<string | null | undefined>(null)

  const [serviceCenters, setServiceCenters] = useState<any>([])

  const [loading, setLoading] = useState<boolean>(false)

  // we detect when inspectionID changes and call the handleChange function
  // this is because the state is not updated immediately
  useEffect(() => {
    if (!inspectionID) return
    if (!pendingListing) return
    if (!pendingListing?.inspection?._id) return
    handleChange()
  }, [inspectionID, pendingListing])

  const url = `${MicroServices.GATEWAY}${APIEndpoints.SERVICE_CENTER}`

  const fetchServiceCenters = async () => {
    await fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setServiceCenters(res)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (serviceCenters.length > 0) return
    fetchServiceCenters()
  }, [pendingListing])

  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({}))

  const [createInspection] = useMutation({
    endPoint: APIEndpoints.CREATE_INSPECTION,
  }) as MutationType

  const createInspectionRequest = async (data: Record<string, unknown>) => {
    try {
      const response = await createInspection(data)
      if (response?.inspection?._id) {
        return response.inspection
      } else {
        console.error('Error creating inspection:', response.statusText)
      }
    } catch (error) {
      console.error('There was an error sending the request:', error)
    }
  }

  const handleDateChange = (date: Date | null) => {
    if (!date) return
    const inspectionData = {
      appointmentDate: date.toISOString(),
    }
    setFormData({
      ...formData,
      listingId: pendingListing._id,
      appointmentDate: date,
    })
    dispatch(setListing({ ...pendingListing, inspection: inspectionData }))
  }

  const handleLocationChange = (e: any) => {
    // const { createdAt, updatedAt, __v, ...rest } = e
    if (!e) return
    const cleanData = {
      ...e,
      createdAt: e?.createdAt?.toString() ?? '',
      updatedAt: e?.updatedAt?.toString() ?? '',
    }

    let serviceData = {
      ...formData,
      serviceCenter: cleanData,
    }
    setFormData(serviceData)

    serviceData = {
      ...formData,
      // @ts-ignore
      appointmentDate: formData?.appointmentDate?.toISOString() ?? formData?.appointmentDate,
      serviceCenter: cleanData,
    }

    dispatch(setListing({ ...pendingListing, inspection: serviceData }))
  }

  // When this component is mounted, it needs to find a parent div 'car-sales-stepper-container', remove all it's existing classes and reset the width of this parent to 100%,
  // and when this component is destroyed, 'car-sales-stepper-container' should be applied these classes p-10 sm:px-0  sm:p-4 xl:min-h-[calc(100vh-510px)] xxl:min-h-[calc(100vh-510px)]
  useEffect(() => {
    const wantedClasses = ['p-10', 'sm:px-0', 'sm:p-4']

    const handleMount = () => {
      const parent = document.getElementById('car-sales-stepper-container')
      if (!parent) return
      parent.classList.remove(...wantedClasses)
      parent.style.width = '100%'

      // get the first h1 element and add the wanted class
      const h1 = parent.querySelector('h1')
      if (!h1) return
      h1.style.padding = '0 2.5rem'
      h1.style.marginTop = '2rem'
      h1.scrollIntoView({ behavior: 'smooth' })

      // get MuiStepper-root class in parent and add the padding
      const stepper = parent.querySelector('.MuiStepper-root') as HTMLElement
      if (stepper) {
        // stepper.style.padding = '20px 2.5rem'
        stepper.style.margin = '30px 2.5rem'
      }
    }

    const handleUnmount = () => {
      const parent = document.getElementById('car-sales-stepper-container')
      if (!parent) return
      parent.style.width = ''
      parent.classList.add(...wantedClasses)
      const h1 = parent.querySelector('h1')
      if (h1) {
        // remove the styles
        h1.style.padding = ''
        h1.style.marginTop = ''
      }
      const stepper = parent.querySelector('.MuiStepper-root') as HTMLElement
      if (stepper) {
        stepper.style.padding = ''
      }
    }

    handleMount()
    return () => {
      handleUnmount()
    }
  }, [])

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!formData.appointmentDate) return // toast.error('Please select a date')
    if (!formData.serviceCenter) return // toast.error('Please select a service center')
    setLoading(true)
    let inspectionData = {
      listingId: pendingListing._id,
      appointmentDate: formData.appointmentDate.toISOString(),
      serviceCenter: formData.serviceCenter,
    }
    const inspection = await createInspectionRequest(inspectionData)

    if (!inspection) return setLoading(false)
    setInspectionID(inspection._id)
    setLoading(false)
    inspectionData = inspection

    // remove listingId from inspectionData
    delete inspectionData.listingId
    dispatch(setListing({ ...pendingListing, inspection: inspectionData }))
  }

  const intl = useIntl()
  return (
    <div
      className="flex md:flex-col justify-center w-full bg-[#F0F0F0] border-[#E1E1E1] border-t-2 border-b-2
            my-4 py-12 -md:py-4 xxl:mx-auto xxhd:max-w-[1440px]">
      <div className="flex flex-col p-10  sm:px-0 sm:p-4 my-auto">
        <div className="flex flex-col">
          <h2 className="text-4xl font-bold my-1 text-left sm:pl-4">
            {intl.formatMessage({
              id: 'create_listing.steps.4.title',
            })}
          </h2>
          <p className="mt-8 text-lg px-0 sm:px-4 text-[#747474]">
            {intl.formatMessage({
              id: 'create_listing.steps.4.description',
            })}
          </p>
          <form onSubmit={handleSubmitForm} className="mt-6 sm:w-full max-w-fit flex flex-col flex-1 sm:px-4 px-0">
            <DatePicker
              // renderInput={(props: any) => <TextField {...props} />}
              label={intl.formatMessage({
                id: 'appointment_date',
              })}
              value={formData.appointmentDate}
              onChange={handleDateChange}
              disablePast
              defaultValue={tomorrow}
              className="bg-white"
            />
            <StyledAutocomplete
              sx={{
                // flexGrow: '2.9 !important',
                marginTop: '1rem',
                backgroundColor: 'white',
              }}
              options={serviceCenters ?? []}
              getOptionLabel={(option: any) => {
                const city = option?.location?.city
                const company = option?.company
                if (city && company) return `${city}, ${company}`
                return city ?? ''
              }}
              // @ts-ignore
              value={formData?.serviceCenter}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: 'select_service_center',
                  })}
                  variant="outlined"
                />
              )}
              onChange={(_, value) => {
                setFormData({
                  ...formData,
                  serviceCenter: value as any,
                })
                handleLocationChange(value)
              }}
            />

            <div className="mt-6 w-full cursor-pointer">
              <Button type="submit" variant="contained" className="w-full" color="primary" size="large" disabled={loading}>
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>

      <CarImage />
    </div>
  )
}

type WhyWeNeedCardType = {
  title: string
  description: string
  icon: string
  color: string
}

const items: WhyWeNeedCardType[] = [
  {
    title: 'create_listing.steps.4.why.1.title',
    description: 'create_listing.steps.4.why.1.description',
    icon: Whyweneed1,
    color: '#105E26',
  },
  {
    title: 'create_listing.steps.4.why.2.title',
    description: 'create_listing.steps.4.why.2.description',

    icon: Whyweneed2,
    color: '#fff',
  },
  {
    title: 'create_listing.steps.4.why.3.title',
    description: 'create_listing.steps.4.why.3.description',

    icon: Whyweneed3,
    color: '#105E26',
  },
  {
    title: 'create_listing.steps.4.why.4.title',
    description: 'create_listing.steps.4.why.4.description',

    icon: Whyweneed4,
    color: '#fff',
  },
]

const WhyWeNeedCard = ({ title, description, icon, color }: WhyWeNeedCardType) => {
  const intl = useIntl()
  return (
    <div
      className={clsx('flex gap-4 justify-center w-full p-4 sm:p-8 rounded-lg -lg:h-auto', {
        'bg-[#105E26] text-[#DADADA]': color === '#105E26',
        'bg-[#F0F0F0] text-[#747474]': color === '#fff',
      })}>
      <div>
        <div className="bg-white rounded-full w-16 h-16 p-2 flex items-center justify-center">
          <img className="h-full w-full" src={icon} alt={title} />
        </div>
      </div>
      <div
        className={clsx({
          'text-white': color === '#105E26',
          'text-black': color === '#fff',
        })}>
        <h4 className=" text-xl my-4 font-bold text-left">{intl.formatMessage({ id: title })}</h4>
        <p className="sm:text-base">{intl.formatMessage({ id: description })}</p>
      </div>
    </div>
  )
}

const WhyWeNeed = () => {
  const intl = useIntl()
  return (
    <div className="flex flex-col -md:py-12 -lg:flex-row -lg:mx-auto -lg:px-10 -sm:px-8 gap-8 justify-center w-full bg-white">
      <div className="-md:my-auto">
        <div>
          <h3 className="text-4xl font-bold my-1 text-left sm:pl-4 -md:px-8">
            {intl.formatMessage({
              id: 'create_listing.steps.4.why.title',
            })}
          </h3>
          <p className="mt-8  px-0 sm:px-4 -md:px-8 sm:text-sm text-lg text-[#747474]">
            {intl.formatMessage({
              id: 'create_listing.steps.4.why.description',
            })}
          </p>
        </div>
      </div>
      <div className="-md:px-8">
        <div className="flex flex-wrap gap-4 -lg:grid -lg:grid-cols-2 -lg:grid-rows-2 -lg:gap-4">
          {items.map((item) => (
            <WhyWeNeedCard key={item.icon} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}

const HowItWorks = () => {
  const intl = useIntl()
  return (
    <>
      <div className="w-full flex flex-col justify-center items-center mt-4 px-8 -md:px-0">
        <h4 className="text-4xl font-bold my-1 text-left sm:pl-4 -md:px-8">
          {intl.formatMessage({
            id: 'create_listing.steps.4.how.title',
          })}
        </h4>
        <ul
          className="mt-8 -lg:mt-16 flex flex-col gap-4 -md:grid -md:grid-cols-2 -md:grid-rows-2 -md:gap-4
                    -md:px-12 -lg:w-full">
          <li className="flex gap-4 items-center text-left bg-[#E1E1E1] text-[#747474] w-full border-1 rounded-md p-4">
            <span className="h-2 w-2 bg-[#105E26] rounded-full"></span>
            <p>
              {intl.formatMessage({
                id: 'create_listing.steps.4.how.1',
              })}
            </p>
          </li>
          <li className="flex gap-4 items-center text-left bg-[#E1E1E1] text-[#747474] w-full border-1 rounded-md p-4">
            <span className="h-2 w-2 bg-[#105E26] rounded-full"></span>
            <p>
              {intl.formatMessage({
                id: 'create_listing.steps.4.how.2',
              })}
            </p>
          </li>
          <li className="flex gap-4 items-center text-left bg-[#E1E1E1] text-[#747474] w-full border-1 rounded-md p-4">
            <span className="h-2 w-2 bg-[#105E26] rounded-full"></span>
            <p>
              {intl.formatMessage({
                id: 'create_listing.steps.4.how.3',
              })}
            </p>
          </li>
          <li className="flex gap-4 items-center text-left bg-[#E1E1E1] text-[#747474] w-full border-1 rounded-md p-4">
            <span className="h-2 w-2 bg-[#105E26] rounded-full"></span>
            <p>
              {intl.formatMessage({
                id: 'create_listing.steps.4.how.4',
              })}
            </p>
          </li>
        </ul>
      </div>
    </>
  )
}

// @ts-ignore
const Step4 = ({ pendingListing, handleSubmit, loading }) => {
  return (
    <>
      <ScheduleUI pendingListing={pendingListing} handleChange={handleSubmit} />
      <WhyWeNeed />
      <HowItWorks />
      <div className="mt-20  xl:my-24 xxl:my-28 w-full ">
        <TestinomialCarousel />
      </div>
    </>
  )
}

export default Step4
