import { ENGLISH } from './en'
import { VIETNAMESE } from './vn'

import VN from '../../assets/imgs/Lang/VN.svg'
import EN from '../../assets/imgs/Lang/EN.svg'

// export const defaultLocale = 'en-US'
export const defaultLocale = 'vn'

export const locales = {
  'en-US': {
    name: 'EN',
    image: EN,
    messages: ENGLISH,
  },
  vn: {
    name: 'VN',
    image: VN,
    messages: VIETNAMESE,
  },
}
