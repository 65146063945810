import ResponsiveAppBar from 'Components/AppBar'
import Footer from 'Components/Footer'
import { FormattedMessage } from 'react-intl'

const TermsAndConditions = () => {
  return (
    <div>
      {/* container */}
      <div className="p-20 pt-5  sm:p-4 ">
        <div>
          <div className="mb-10">
            <p className="text-center font-semibold text-3xl">
              <FormattedMessage id="privacy_policy" />
            </p>
            <p className="text-center font-semibold">
              <FormattedMessage id="site_name" />
            </p>
          </div>
          <div className="mb-4">
            <FormattedMessage id="introduction" />
          </div>
          <div className="font-bold text-lg mb-5">
            <FormattedMessage id="scope" />
          </div>
          <p className="mb-5">
            <FormattedMessage id="scope_description" />
          </p>
          <div>
            <FormattedMessage id="personal_information" />
          </div>
          <div className="mb-5">
            <FormattedMessage id="personal_information_description" />
          </div>
        </div>

        <div>
          <div className="font-bold mb-5">
            <FormattedMessage id="information_provided" />
          </div>
          <ol className="list-decimal">
            <li className="m-3">
              <FormattedMessage id="account_creation" />
            </li>
            <li className="m-3">
              <FormattedMessage id="communications_with_us" />
            </li>
            <li className="m-3">
              <FormattedMessage id="requesting_financing" />
            </li>
            <li className="m-3">
              <FormattedMessage id="surveys" />
            </li>
            <li className="m-3">
              <FormattedMessage id="social_media_content" />
            </li>
            <li className="m-3">
              <FormattedMessage id="registration_sweepstakes_contests" />
            </li>
            <li className="m-3">
              <FormattedMessage id="information_collected_automatically" />
            </li>
            <li className="m-3">
              <FormattedMessage id="automatic_data_collection" />
            </li>
            <li className="m-3">
              <FormattedMessage id="data_collection_continued" />
            </li>

            <li className="m-3">
              <FormattedMessage id="cookies_pixel_tags_web_beacons" />
              <ul className="list-disc">
                <li className="m-3">
                  <FormattedMessage id="cookies" />
                </li>
                <li className="m-3">
                  <FormattedMessage id="pixel_tags_web_beacons" />
                </li>
                <li className="m-3">
                  <FormattedMessage id="analytics_information" />
                </li>
                <li className="m-3">
                  <FormattedMessage id="information_from_other_sources" />
                </li>
              </ul>
            </li>
          </ol>
        </div>

        <p className="font-semibold mb-5 mt-5">
          <FormattedMessage id="how_we_use_information" />
        </p>
        <div className="mb-5">
          <FormattedMessage id="how_we_use_information_description" />
        </div>
        <div>
          <div>
            <FormattedMessage id="how_we_use_information_promise" />
          </div>
          <ul className="list-disc">
            {[
              'managing_your_information_and_accounts',
              'providing_access_and_communicating',
              'activities_to_verify_quality_or_safety',
              'processing_financial_information',
              'providing_advertising_analytics_marketing_services',
              'processing_applications_and_transactions',
              'allowing_you_to_register_for_events',
              'analyzing_and_improving_services_pursuant_to_legitimate_interest',
              'detecting_security_incidents_and_protecting',
              'measuring_interest_and_engagement',
              'undertaking_research_for_technological_development',
              'procedures_to_improve_performance',
              'improving_upgrading_or_enhancing_services_or_device',
              'developing_new_products_and_services',
              'ensuring_internal_quality_control',
              'verifying_your_identity_and_preventing_fraud',
              'debugging_to_identify_and_repair_errors',
              'complying_with_legal_obligations',
              'provide_additional_content_and_services',
              'furnishing_customized_materials_and_auditing',
              'auditing_and_other_purposes',
              'use_of_deidentified_and_aggregated_information',
              'share_content_with_friends_or_colleagues',
              'functionality_related',
              'advertising_or_targeting_related',
              'publicly_accessible_blogs_forums_social_media',
              'use_of_third_party_apis_and_sdks',
            ].map((el) => (
              <li className="m-3">
                <FormattedMessage id={el} />
              </li>
            ))}
          </ul>
          <div>
            <div className="mb-10">
              <div className="font-semibold mb-5">
                DISCLOSING YOUR INFORMATION TO THIRD PARTIES
              </div>
              <ol className="list-decimal">
                {[
                  'Except as provided below, we do not share your personal information. For a list of the categories of personal information we have disclosed or sold about consumers for a business purpose in the past 12 months, see the section CATEGORIES OF INFORMATION WE COLLECT below.',
                  "Fulfilling Your Requests. Autolist may disclose information about you to certain third parties to fulfill your requests. For instance, if you respond to an offer or listing for a third party's product(s) or service(s), or sign up to be notified about related offers or listings from that third party or other third parties, then we will provide such third parties with your information in order to fulfill your request. In addition, if, for example, you post a listing on the Site and agree that we may make it available to third parties, we will provide the listing and all related information to such third parties. Our Privacy Policy does not apply to any third party's handling of your information. If you would like a third party to delete or update your information or stop contacting you, you must contact them directly.",
                  'Service Providers. We may share any personal information we collect about you with our third-party service providers. The categories of service providers (processors) to whom we entrust personal information include: IT and related services; information and services; payment processors; customer service providers; financing partners; and vendors to support the provision of the Services.',
                  'Business Partners. We may provide personal information to business partners with whom we jointly offer products or services. In such cases, our business partner’s name will appear along with ours.',
                  'Affiliates. We may share personal information with our affiliated companies. Advertising Partners. Through our Services, we may allow third-party advertising partners to set Technologies and other tracking tools to collect information regarding your activities and your device (e.g., your IP address, mobile identifiers, page(s) visited, location, time of day). We may also combine and share such information and other information (such as demographic information and past purchase history) with third party advertising partners. These advertising partners may use this information (and similar information collected from other websites) for purposes of delivering targeted advertisements to you when you visit third party websites within their networks. This practice is commonly referred to as “interest-based advertising” or “online behavioral advertising. We may allow access to other data collected by the Services to share information that may be useful, relevant, valuable or otherwise of interest to you. If you prefer not to share your personal information with third party advertising partners, you may follow the instructions below.',
                  'Disclosures to Protect Us or Others. We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; protect your, our or others’ rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity. Disclosure in the Event of Merger, Sale, or Other Asset Transfers. If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract. International Data Transfers.',
                  'You agree that all information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live. We have taken appropriate safeguards to require that your personal information will remain protected and require our third-party service providers and partners to have appropriate safeguards as well. Further details can be provided upon request.',
                ].map((el) => (
                  <li className="m-3">{el}</li>
                ))}
              </ol>
            </div>

            <div className="mb-10">
              <div className="font-semibold mb-5">YOUR CHOICES</div>
              <ol className="list-decimal">
                {[
                  'General',
                  'You have certain choices about your personal information. Where you have consented to the processing of your personal information, you may withdraw that consent at any time and prevent further processing by contacting us as described below. Even if you opt out, we may still collect and use non-personal information regarding your activities on our Services and for other legal purposes as described above.',
                  'Email and Telephone Communications.',
                  'If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails regarding products or Services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding the Services or updates to our Terms or this Privacy Policy).',
                  'We process requests to be placed on do-not-mail, do-not-phone and do-not-contact lists as required by applicable law. Mobile Devices.',
                  'We may send you push notifications through our mobile application. You may at any time opt out from receiving these types of communications by changing the settings on your mobile device. We may also collect location-based information if you use our mobile applications. You may opt out of this collection by changing the settings on your mobile device. “Do Not Track.”',
                  'Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.',
                  'Cookies and Interest-Based Advertising.',
                  'You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits. The online advertising industry also provides websites from which you may opt out of receiving targeted ads from data partners and other advertising partners that participate in self-regulatory programs. You can access these and learn more about targeted advertising and consumer choice and privacy, at www.networkadvertising.org/managing/opt_out.asp, http://www.youronlinechoices.eu/, https://youradchoices.ca/choices/, and www.aboutads.info/choices/. To separately make choices for mobile apps on a mobile device, you can download DAA’s AppChoices application from your device’s app store. Alternatively, for some devices you may use your device’s platform controls in your settings to exercise choice.',
                  'Please note you must separately opt out in each browser and on each device. Advertisements on third party websites that contain the AdChoices link may have been directed to you based on information collected by advertising partners over time and across websites. These advertisements provide a mechanism to opt out of the advertising partners’ use of this information for interest-based advertising purposes. Your Privacy Rights.',
                  'Please note you must separately opt out in each browser and on each device. Advertisements on third party websites that contain the AdChoices link may have been directed to you based on information collected by advertising partners over time and across websites. These advertisements provide a mechanism to opt out of the advertising partners’ use of this information for interest-based advertising purposes. Your Privacy Rights.In accordance with applicable law, you may have the right to: Access to/Portability of Personal Data. about you consistent with legal requirements. In addition, you may have the right in some cases to receive or have your electronic Personal Data transferred to another party.',
                  'Request Correction. of your personal information where it is inaccurate or incomplete. In some cases, we may provide self-service tools that enable you to update your personal information or we may refer you to the controller of your personal information who is able to make the correction.',
                  'Request Deletion. of your personal information, subject to certain exceptions prescribed by law. Request restriction of or object to processing of your personal information, including the right to opt in or opt out of the sale of your Personal Data to third parties, if applicable, where such requests are permitted by law.',
                  'If you would like to exercise any of these rights, please log into your account or contact us as set forth below. We will process such requests in accordance with applicable laws. To protect your privacy, we will take steps to verify your identity before fulfilling your request.',
                ].map((el) => (
                  <li className="m-3">{el}</li>
                ))}
              </ol>
            </div>

            <div className="mb-10">
              <div className="font-semibold mb-5">DATA RETENTION</div>
              <ol className="list-decimal">
                {[
                  'We store the personal information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.',
                ].map((el) => (
                  <li className="m-3">{el}</li>
                ))}
              </ol>
            </div>

            <div className="mb-10">
              <div className="font-semibold mb-5">
                SECURITY OF YOUR INFORMATION
              </div>
              <ol className="list-decimal">
                {[
                  'We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unintentional disclosure.',
                  'By using the Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Services, by mail or by sending an e-mail to you.',
                ].map((el) => (
                  <li className="m-3">{el}</li>
                ))}
              </ol>
            </div>

            <div className="mb-10">
              <div className="font-semibold mb-5">
                OTHER PROVISIONS
              </div>
              <ol className="list-decimal">
                {[
                  'CALIFORNIA SHINE THE LIGHT LAW',
                  `The California “Shine the Light” law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the Third Parties to whom we have disclosed their Personal Information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of Personal Information disclosed to those parties.
         SUPERVISORY AUTHORITY`,
                  `If you are located in the European Economic Area or the UK, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.`,
                  'CHANGES TO OUR PRIVACY POLICY',
                  'We may revise this Privacy Policy from time to time in our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use the Services after the new Privacy Policy takes effect.',
                ].map((el) => (
                  <li className="m-3">{el}</li>
                ))}
              </ol>
            </div>

            <div className="mb-10">
              <div className="font-semibold mb-5">CONTACT US</div>
              <ol className="list-decimal">
                {[
                  'If you have any questions about our privacy practices or this Privacy Policy, or if you wish to submit a request to exercise your rights as detailed in this Privacy Policy, please contact us at:',
                  'Sleekride.LLC. 3265 C Street, Suite 203, San Diego, CA 92102 415.316.4402 feedback@sleekride.vn',
                ].map((el) => (
                  <li className="m-3">{el}</li>
                ))}
              </ol>
            </div>
          </div>

          <div className="mb-10">
            <div className="font-semibold mb-5">
              CATEGORIES OF INFORMATION WE COLLECT
            </div>
            <div>
              <div className="mb-5">Information We Collect:</div>
              <div>
                Our apps collect information that identifies, relates
                to, describes, references, is capable of being
                associated with, or could reasonably be linked,
                directly or indirectly, with a particular consumer or
                device (“personal information”). In particular, our
                website has collected the following categories of
                personal information from its consumers within the
                last twelve (12) months.
              </div>
              <div className="mb-5">
                Categories Actual collected information “Sold” as
                defined by CCPA? Third parties shared with.
              </div>
              <div className="mb-5">
                <span className="font-bold">Identifiers</span>:first
                name, last name, Internet Protocol (IP) address, email
                address, registration_idNoVehicle sellers such as
                Dealers, and Financing providers, such as banks and
                brokers, in order to fulfill requests as directed by
                the user providing this information.
              </div>
              <div className="mb-5">
                <span className="font-bold">
                  Customer records information
                </span>
                :first name, last name, phone, email, address, annual
                income, date of birth, employment status, years with
                employer, monthly rent/mortgage, rent/own/leaseNoIn
                order to fulfill requests as directed by the user
                providing this information, we may share this
                information with vehicle sellers such as Dealers, and
                Financing pre-qualification providers.
              </div>
              <div className="mb-5">
                <span className="font-bold">
                  Characteristics of protected classifications under
                  California or federal law
                </span>
                :age, range, gender. No. Published online as
                user-generated content in the form of dealer reviews
              </div>
              <div className="mb-5">
                <span className="font-bold">
                  Commercial information
                </span>
                : Vehicle purchase history, including trade-in
                information. No. Vehicle sellers such as Dealers, and
                Financing providers, such as banks and brokers, in
                order to fulfill requests as directed by the user
                providing this information.
              </div>
              <div className="mb-5">
                <div>
                  <span className="font-bold">
                    Internet or other electronic network activity
                    information
                  </span>
                  : Sleekride search history, records of users’
                  interactions with Sleekride products and integrated
                  advertisements. Yes. Advertising service providers
                </div>
              </div>
              <div className="mb-5">
                <span className="font-bold">Geolocation data</span>:
                full address, latitude, longitude. No. Vehicle sellers
                such as Dealers, and Financing providers, such as
                banks and brokers, in order to fulfill requests as
                directed by the user providing this information.
              </div>
              <div className="mb-5">
                <span className="font-bold">
                  {' '}
                  Professional or employment-related information
                </span>
                : employment status, years with employer. No. Vehicle
                sellers such as Dealers, and Financing providers, such
                as banks and brokers, in order to fulfill requests as
                directed by the user providing this information.
              </div>
              <div className="mb-5">
                <span className="font-bold">Inferences</span>: Survey
                data reflecting a person's preferences,
                characteristics, psychological trends,
                predispositions, behavior, attitudes, intelligence,
                abilities, and aptitudes. No. We publish articles
                based on this survey data, but do not disclose the
                identities of the individual respondents.
              </div>
              <div>
                * CCPA refers to the California Consumer Privacy Act
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="font-bold mb-5">HUMAN RESOURCES DATA.</div>
          <div>
            The following section applies to our processing in the
            context of human resources, recruitment, and applicant
            consideration. We collect personal information from
            current, prospective, and former employees, their contact
            points in case of a medical emergency, and beneficiaries
            under any insurance policy (“Human Resources Data”). The
            Human Resources Data we collect may include title, name,
            address, phone number, email address, date of birth,
            passport number, driver’s license number, Social Security
            number or other government-issued identification number,
            financial information related to credit checks, bank
            details for payroll, information that may be recorded on a
            CV or application form, language abilities, contact
            information of third parties in case of an emergency and
            beneficiaries under any insurance policy. We may also
            collect Sensitive Human Resources Data such as details of
            health and disability, including mental health, medical
            leave, and maternity leave; information about national
            origin or immigration status; and optional demographic
            information such as race, which helps us achieve our
            diversity goals.
          </div>
          <div>
            We process Human Resources Data for a variety of business
            purposes including:
          </div>
          <ul className="list-disc">
            {[
              'Workflow management, including assigning, managing and administering projects;',
              'Human Resources administration and communication;',
              'Payroll and the provision of benefits;',
              'Compensation, including bonuses and long-term incentive administration, stock plan administration, compensation analysis, including monitoring overtime and compliance with labor laws, and company recognition programs;',
              'Job grading activities;',
              'Performance and employee development management;',
              'Organizational development and succession planning;',
              'Benefits and personnel administration;',
              'Absence management;',
              'Helpdesk and IT support services;',
              'Regulatory compliance;',
              'Internal and/or external or governmental compliance investigations;',

              'Internal or external audits;',
              'Litigation evaluation, prosecution, and defense;',
              'Diversity and inclusion initiatives;',
              'Restructuring and relocation;',
              'Emergency contacts and services;',
              'Employee safety;',
              'Compliance with statutory requirements;',
              'processing of employee expenses and travel charges; and',
              'Acquisitions, divestitures, and integrations.',
            ].map((el) => (
              <li className="m-3">{el}</li>
            ))}
          </ul>
          <div>
            We will use personal information we collect for Human
            Resources activities only for employment-related purposes
            as more fully described above. If we intend to use this
            personal information for any other purpose, we will notify
            the Individual and provide an opportunity to exercise
            their rights under applicable law.
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
