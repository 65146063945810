import React, { createContext, useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './Pages/Home'
import Browse from './Pages/Browse'
import Listing from './Pages/Listing'
import CreateListing from './Pages/CreateListing'
import Checkout from './Pages/Checkout'
import Setting from './Pages/Setting'
import UserLogin from './Pages/UserLogin'
import UserSignup from './Pages/UserSignup'
import VerifyEmail from './Pages/UserSignup/VerifyEmail'
import SearchResult from './Pages/SearchResult'
import Wishlist from './Pages/Wishlist'
import Research from './Pages/Research'
import TermsOfService from './Pages/TermsOfService'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import SellMyCar from './Pages/SellMyCar'
import Inspection from './Pages/Inspection'
import ContactUs from './Pages/ContactUs'
import DealerContact from './Pages/DealerContact'
import DealerHome from './Pages/DealersHome'
import ResponsiveAppBar from 'Components/AppBar'
import Footer from 'Components/Footer'
import CheckAvailability from 'Pages/CheckAvailability'
import VerificationToast from 'Components/Modal/VerificationToast'
import ResearchDetail from 'Pages/Research/Detail'
import DealerReview from 'Pages/DealerReview'
import CheckAvailabilityModal from 'Components/Modal/CheckAvailabilityModal'
import ForgotPassword from 'Pages/ForgotPassword'
import ResetPassword from 'Pages/ResetPassword'
import { useDispatch, useSelector } from 'react-redux'
import { closeCheckAvailability } from 'redux/modal'
import LeaveReview from 'Pages/LeaveReview'
import CompletedInspection from 'Pages/CompletedInspection'
import { get } from 'http'
import GoogleTranslate from './SDK/GoogleTranslate'
import useFetchOptions from 'redux/hooks/useFetchOptions'
import EditListing from 'Pages/EditListing'
import { setStep } from 'redux/listing'
import Chat from 'Pages/Chat'
import LangSwitcher from 'i18n/LangSwitcher'
// import analytics from 'SDK/Analytics'
import { analytics } from 'SDK/Firebase'
function ScrollToTop() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return null
}

export const CheckAvailabilityContext = createContext(null)

const fetchAnalyticsData = async () => {
  try {
    const analyticsData = analytics
    console.log('Analytics data:', analyticsData)
  } catch (error) {
    console.error('Error fetching analytics data:', error)
  }
}

function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [CheckAvailabilityPost, setCheckAvailabilityPost] = useState<any>(null)
  const modalState = useSelector((state: any) => state.modal)
  const { isCheckAvailabilityOpen, selectedPost } = modalState

  const { makes, models, cities, loading, error } = useFetchOptions()

  const usr = useSelector((state: any) => state.user?.data)
  const loggedIn = useSelector((state: any) => state.user?.loggedIn)
  const isLoading = useSelector((state: any) => state.user?.loading)

  useEffect(() => {
    fetchAnalyticsData()
    if (!Object.keys(usr).length || !loggedIn || !isLoading) {
      console.log('Anonymous Webpage View', usr)
      return
    }

    console.log('User logged in', usr)
    // analytics.identify(usr?.id, usr)
  }, [usr, loggedIn, isLoading])

  // set redux listing step to localstorage'
  useEffect(() => {
    console.log(Number(localStorage.getItem('step')), Number(localStorage.getItem('step')) ?? 0)
    dispatch(setStep(Number(localStorage.getItem('step')) ?? 0))
  }, [])

  useEffect(() => {
    if (isCheckAvailabilityOpen) {
      dispatch(closeCheckAvailability())
    }
  }, [location.pathname])

  // const successCallback = (position: any) => {
  //   console.log(position)
  // }
  // const errorCallback = (error: any) => {
  //   console.log(error)
  // }
  // navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
  // function getBrowserLocales(options = {}) {
  //   const defaultOptions = {
  //     languageCodeOnly: false,
  //   }
  //   const opt = {
  //     ...defaultOptions,
  //     ...options,
  //   }
  //   const browserLocales =
  //     navigator.languages === undefined
  //       ? [navigator.language]
  //       : navigator.languages
  //   if (!browserLocales) {
  //     return undefined
  //   }
  //   return browserLocales.map((locale) => {
  //     const trimmedLocale = locale.trim()
  //     return opt.languageCodeOnly
  //       ? trimmedLocale.split(/-|_/)[0]
  //       : trimmedLocale
  //   })
  // }
  // console.log(getBrowserLocales())
  return (
    // @ts-ignore
    <CheckAvailabilityContext.Provider value={{ CheckAvailabilityPost, setCheckAvailabilityPost }}>
      <div className="App">
        <ScrollToTop />
        <VerificationToast />
        {isCheckAvailabilityOpen && (
          <CheckAvailabilityModal post={selectedPost} handleClose={() => dispatch(closeCheckAvailability())} open={isCheckAvailabilityOpen} />
        )}

        {location.pathname !== '/user-login' && location.pathname !== '/user-signup' && <ResponsiveAppBar />}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/create-listing/:id" element={<CreateListing />} />
          <Route path="/edit-listing/:id" element={<EditListing />} />

          <Route path="/browse" element={<Browse />} />
          <Route path="/setting" element={<Setting />}>
            <Route index element={<Setting />} />
            <Route path=":id" element={<Setting />} />
          </Route>
          <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="/user-login" element={<UserLogin />} />
          <Route path="/user-signup" element={<UserSignup />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/research" element={<Research />} />
          <Route path="/research/detail/:id" element={<ResearchDetail />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/sell-my-car" element={<SellMyCar />} />
          <Route path="/car-inspection/:id" element={<Inspection />} />
          <Route path="/completed-inpection/:id" element={<CompletedInspection />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/dealer-contact" element={<DealerContact />} />
          <Route path="/dealer-home" element={<DealerHome />} />
          <Route path="/dealer-review/:id" element={<DealerReview />} />
          <Route path="/leave-review" element={<LeaveReview />} />
          <Route path="/leave-review/:id" element={<LeaveReview />} />
          <Route path="/check-availibility/:id" element={<CheckAvailability />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>

        <Chat />
        {location.pathname !== '/user-login' && !location.pathname.includes('/finish-signup') && location.pathname !== '/user-signup' && <Footer />}
      </div>
    </CheckAvailabilityContext.Provider>
  )
}
export default App
