export function isEmail(emailAdress) {
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  if (emailAdress.match(regex)) return true
  else return false
}

export function findMessage(obj, key = 'message') {
  // console.log({ obj })
  if (typeof obj === 'object') {
    if (obj[key]) return obj[key]
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (obj && obj[key] && typeof obj[key] === 'object') {
          const message = findMessage(obj[key])
          if (message) {
            return message
          }
        }
      }
    }
  }
  return undefined
}

export function findErrors(obj, key = 'message') {
  // console.log({ obj })
  if (typeof obj === 'object') {
    if (obj[key]) return obj[key]
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (obj && obj[key] && typeof obj[key] === 'object') {
          const message = findMessage(obj[key])
          if (message) {
            return message
          }
        }
      }
    }
  }
  return undefined
}

export const convertToFormData = (data) => {
  const dataEntries = Object.entries(data).filter(
    (el) =>
      !!el[0] &&
      (typeof el[1] === 'number' || typeof el[1] === 'boolean' || !!el[1])
  )
  const formData = new FormData()

  if (dataEntries.length) {
    dataEntries.forEach((el) => {
      if (Array.isArray(el[1]) && el[1].length) {
        for (var i = 0; i < el[1].length; i++) {
          // console.log(el[0], el[1][i])
          // console.log('el[1][i].file', el[1][i].file)
          formData.append(el[0], el[1][i].file)
        }
      } else {
        formData.append(el[0], el[1])
      }

      // console.log(el[0], el[1])
    })
  }

  // for (const value of formData.values()) {
  //   if( 1== 0 ) console.log(value);
  // }

  // formData.append("retailer_name", data.retailer_name);

  return formData
}
