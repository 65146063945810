import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'

const validation = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  phone: 'Phone Number',
  message: 'Message',
}

const ContactForm = ({ bgColor }) => {
  const intl = useIntl()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  })

  const [sendEmail] = useMutation({
    endPoint: APIEndpoints.SUBMIT_CONTACT_US,
    method: 'post',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Check for missing fields except for lastName
    const requiredFields = ['firstName', 'email', 'phone', 'message']
    const missingFields = requiredFields.filter((key) => !formData[key])
    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map((field) => validation[field]).join(', ')
      toast.error(`Please fill in the following fields: ${missingFieldNames}`)
      return
    }

    console.log(formData)
    sendEmail(formData)
    toast.success('Your message has been sent!')
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    })
  }

  return (
    <div className={`h-full flex flex-col p-12 rounded-xl ${bgColor ? 'bg-[#105e26] text-white' : 'bg-slate-50 text-black'} shadow-md`}>
      <form onSubmit={handleSubmit}>
        {/* First Name and Last Name Inputs */}
        <div className="mb-4 flex flex-wrap -mx-2">
          <div className="px-2 w-1/2">
            <label htmlFor="firstName" className="block mb-2">First Name *</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="w-full p-2 rounded border"
            />
          </div>
          <div className="px-2 w-1/2">
            <label htmlFor="lastName" className="block mb-2">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 rounded border"
            />
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">
            Email *
          </label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className="w-full p-2 rounded border" />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block mb-2">
            Phone *
          </label>
          <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required className="w-full p-2 rounded border" />
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block mb-2">
            Message *
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="4"
            className="w-full p-2 rounded border"></textarea>
        </div>

        <div className="text-center">
          <button
            className={`${
              bgColor && bgColor === '#105e26' ? 'bg-[#fff] text-[#105e26]' : 'bg-[#105E26] shadow-[#105E26] text-white'
            } px-10 p-3 sm:p-2 sm:px-14 rounded-lg shadow-md font-semibold`}>
            {intl.formatMessage({
              id: 'contact.send',
            })}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
