import React from 'react'
// import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Logo from '../../assets/images/pngLogo.png'
import People from '../../assets/images/people.png'
import LoginCar from '../../assets/images/logincar.png'
const paymentMethod = () => {
  return (
    <div className=" p-20 px-24   my-3    text-black w-full  md:p-10 sm:p-4 relative">
      <div className="flex items-center justify-center my-8">
        <img className="h-20 w-32" src={Logo} />
      </div>
      <div className="my-8">
        <h1 className="text-4xl font-bold text-center  md:text-2xl">
          Contact your Dealer
        </h1>
        <p className="my-4 text-sm text-center">
          We are lorem ipsum team dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua.{' '}
        </p>
      </div>

      <div className="my-8  flex items-center space-x-2 md:space-x-6 sm:flex-col sm:justify-center sm:my-10 sm:space-x-0 sm:space-y-2 xxl:w-[80%] xxl:justify-center ">
        <div className="flex">
          <img
            className="h-10 w-10 rounded-full border-2 border-black "
            src={People}
          />
          <img
            className="h-10 w-10 rounded-full border-2 border-black -ml-2 "
            src={People}
          />
          <img
            className="h-10 w-10 rounded-full border-2 border-black -ml-2 "
            src={People}
          />
        </div>
        <p className="md:text-xs">
          3k+ people joined us, now it’s your turn
        </p>
      </div>

      <div className="my-8 h-64  md:h-32  ">
        <img className=" absolute right-0 w-4/5" src={LoginCar} />
      </div>
    </div>
  )
}

export default paymentMethod
