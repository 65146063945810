import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIEndpoints } from 'staticStore'

export const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    pendingListing: {
      _id: '',
      images: [],
      vin: {
        data: {},
        refId: null,
      },
      endUser: null,
      licensePlate: '',
      city: null,
      overview: '',
      exteriorColor: null,
      transmission: null,
      odometer: '',
      origin: null,
      price: null,
      dealRating: '',
      interiorColor: null,
      driveTrain: null,
      engine: null,
      sellOrTrade: '',
      accidents: '',
      fuelType: '',
      hasModifications: false,
      drivable: true,
      smokedIn: false,
      keys: '',
      condition: '',
      status: 'pending',
      roof: {
        sunroof: false,
        moonroof: false,
        panoramic: false,
        convertible: false,
      },
      safety: {
        antiLockBrakes: false,
        backupCamera: false,
        blindSpotMonitoring: false,
        brakeAssist: false,
        childSafetyLocks: false,
      },
      seating: {
        leatherSeats: false,
        clothSeats: false,
        suedeSeats: false,
        vinylSeats: false,
        heatedSeats: false,
        memorySeats: false,
        powerSeats: false,
      },
      technology: {
        adaptiveCruise: false,
        androidAuto: false,
        appleCarPlay: false,
        aux: false,
        bluetooth: false,
        keylessStart: false,
        navigation: false,
        parkAssist: false,
        radio: false,
        remoteStart: false,
        steeringWheelControls: false,
        usb: false,
        wifi: false,
        wirelessPhoneCharging: false,
      },
      wheels: {
        alloy: false,
        chrome: false,
        steel: false,
      },
      issues: {
        engine: false,
        transmission: false,
        wear_tear: false,
        functional: false,
        fadedPaint: false,
        rust: false,
        body_damage: false,
      },
      inspection: {},
      createdAt: '',
      updatedAt: '',
    },
    step: 0,
  },
  reducers: {
      
    setListing: (state, action) => {
      console.log({ state, action })
      state.pendingListing = {
        ...state.pendingListing,
        ...action.payload,
      }
    },
    resetListing: (state, action) => {
      state.pendingListing = {
        images: [],
        vin: {
          data: {},
          refId: null,
        },
        endUser: null,
        licensePlate: '',
        city: null,
        overview: '',
        exteriorColor: null,
        transmission: null,
        odometer: '',
        origin: null,
        price: null,
        dealRating: '',
        interiorColor: null,
        trim: '',
        driveTrain: null,
        doors: null,
        engine: null,
        sellOrTrade: '',
        seating: '',
        roof: '',
        fuelType: '',
        wheels: '',
        technology: [],
        safety: [],
        accidents: '',
        issues: {
          engine: false,
          transmission: false,
          airConditioning: false,
          electricalSystem: false,
          tirePressure: false,
          noMechanicalAndElectricalIssues: false,
          wear_tear: false,
          functional: false,
          fadedPaint: false,
          rust: false,
          body_damage: false,
          dashWarningLights: false,
          interiorWarningLights: false,
          seatDamage: false,
          acDamage: false,
          buttonsDamage: false,
          noInteriorDamage: false,
          wheelDamage: false,
          dentOrScrapes: false,
          windshieldCracksOrChips: false,
          noExteriorDamage: false,
        },

        inspection: {},
        drivable: '',
        smokedIn: '',
        hasModifcations: '',
        keys: '',
        condition: '',
        status: 'pending',
      }
    },
    setStep: (state, action) => {
      // console.log({ state, action })
      state.step = action.payload
    },
    forwardStep: (state, action) => {
      // console.log({ state, action })
      state.step++
    },
    setVin: (state, action) => {
      // console.log({ state, action })
      state.pendingListing.vin.data = action.payload.data // store vin details in pendingListing.vin.data
      state.pendingListing.vin.refId = action.payload.refId // store vin refId in pendingListing.vin.refId
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListing.fulfilled, (state, action) => {
      state.pendingListing = action.payload
    })
  },
})

// Async thunk for setListing
export const fetchListing = createAsyncThunk(
  'listing/fetchListing',
  async (listingId) => {
    // suppose we are fetching listing by id
    const response = await fetch(`/api/listing/${listingId}`) // replace with API endpoint
    const data = await response.json()
    return data
  }
)

// Action creators are generated for each case reducer function
export const {
  setListing,
  resetListing,
  setRegCities,
  setStep,
  forwardStep,
  setVin,
  setExteriorColor,
  setInteriorColor,
} = listingSlice.actions

export default listingSlice.reducer
