import React from "react";

const overviewIcon = ({ color }) => {
  return (

      <svg
        width="27"
        height="31"
        viewBox="0 0 27 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.0967 0L10.4874 5.22238L16.1932 5.88374L11.967 9.77003L13.1012 15.4012L8.09665 12.5829L3.09209 15.4012L4.22633 9.77003L0.000110452 5.88374L5.70587 5.22238L8.0967 0ZM5.89187 8.36469L7.13773 9.3077L9.69853 5.97398L10.5751 6.64611L7.68228 10.4154L7.3475 10.8484L6.90912 10.519L5.22508 9.24664L5.89187 8.36469ZM17.5902 6.94616H26.7174V8.39641H17.5902V6.94616ZM17.5902 10.1469H22.1538V11.5972H17.5902V10.1469ZM8.09659 15.542L10.4873 20.7644L16.1931 21.4257L11.9669 25.3146L13.1011 30.9436L8.09654 28.1253L3.09198 30.9436L4.22622 25.3146L0 21.4257L5.70576 20.7644L8.09659 15.542ZM5.89176 23.9094L7.13762 24.8499L9.69841 21.5162L10.5749 22.1909L7.68217 25.9576L7.34739 26.3906L6.90901 26.0612L5.22497 24.7888L5.89169 23.9097L5.89176 23.9094ZM17.5901 22.4883H26.7173V23.9386H17.5901V22.4883ZM17.5901 25.6919H22.1537V27.1422H17.5901V25.6919Z"
          fill={color}
        />
      </svg>

  );
};

export default overviewIcon;
