import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import PaymentIconCard from '../../Components/Cards/PaymentIconCard'
import { VisaIcon, PayPalIcon, MasterCardIcon, MoMoIcon } from '../../assets/icons/PaymentIcons'
import CircularProgress from '@mui/material/CircularProgress'
import { APIEndpoints } from 'staticStore'
import { useSelector } from 'react-redux'
import useMutation from 'redux/hooks/useMutation'
import './style.css'

const PaymentMethod = (id, pendingListing) => {
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [phone, setPhone] = useState('')
  const [amount, setAmount] = useState('50000')
  const [requestType, setRequestType] = useState('captureWallet')
  const endUser = useSelector((state) => state.user.data)
  const lang = useSelector((state) => state.options.language)

  const handlePaymentChange = (e) => {
    setRequestType(e.target.value)
  }

  const [submitPayment, { data, loading, error }] = useMutation({
    endPoint: APIEndpoints.PAY,
    method: 'post',
  })

  const handleSubmitPayment = async (e) => {
    console.log('Button was clicked!')
    e.preventDefault()
    const payload = {
      lang,
      listingId: id.id,
      amount,
      requestType,
    }

    try {
      const responseData = await submitPayment(payload)
      console.log(responseData)
      console.log(responseData.payUrl)

      // Check if payUrl exists and redirect the user to that URL
      if (responseData && responseData.payUrl) {
        window.location.href = responseData.payUrl
      }
    } catch (err) {
      console.log('Error while submitting payment: ', err)
    }
  }

  return (
    <div className="p-10 my-3 text-black w-full sm:p-4">
      <div className="mb-10">
        <h1 className="text-3xl font-bold">Checkout</h1>
        <p className="text-sm">Choose your secure payment method</p>
      </div>

      <div className="ticket">
        <div className="ticket-header">
          <div className="heading3">1. Your information</div>
          <div className="info-wrapper">
            <TextField
              id="fullName"
              name="fullName"
              type="name"
              label="Full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              fullWidth
            />
            <TextField
              id="email"
              name="email"
              type="email"
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <TextField id="phone" name="phone" type="tel" label="Phone number" value={phone} onChange={(e) => setPhone(e.target.value)} fullWidth />
          </div>
        </div>
      </div>

      <div className="flex gap-x-4 rounded-lg sm:gap-x-1">
        <PaymentIconCard
          Icon={<MoMoIcon className="momo-icon" />}
          name="paymentMethod"
          value="captureWallet"
          onChange={handlePaymentChange}
          checked={requestType === 'captureWallet'}
        />
        <PaymentIconCard
          Icon={<MasterCardIcon height={20} />}
          title="Credit Card"
          name="paymentMethod"
          value="mastercard"
          onChange={handlePaymentChange}
          checked={requestType === 'mastercard'}
        />
        <PaymentIconCard
          Icon={<VisaIcon height={40} />}
          name="paymentMethod"
          value="visa"
          onChange={handlePaymentChange}
          checked={requestType === 'visa'}
        />
      </div>

      <button
        type="button"
        className="checkout-button w-full my-5 text-white bg-[#105e26] rounded-md"
        onClick={(e) => handleSubmitPayment(e)}
        disabled={loading} // Disable the button when loading
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Complete Purchase'}
      </button>
    </div>
  )
}

export default PaymentMethod
