const inspectionProcessPoints = {
  '150_inspection_report': '150 Points Inspection Report',
  scheduled_inspection: 'SCHEDULED INSPECTION',
  passed: 'PASSED',
  fixed_or_replaced: 'FIXED OR REPLACED',
  from_the_vin: 'FROM THE VIN',
  correct_vin_plate_and_anti_theft_labels:
    'Correct VIN plate and anti-theft labels',
  clean_history: 'Clean History',
  no_reported_accidents_flood_salvage:
    'No reported accidents/flood/salvage',
  under_the_hood: 'UNDER THE HOOD',
  battery_condition_fluid_level_load_test:
    'Battery (condition, fluid level, load test)',
  alternator_belt_condition_tension_charging:
    'Alternator (belt condition/tension, charging)',
  water_pump_noise_leaks: 'Water pump (noise/leaks)',
  ignition_system_operation: 'Ignition system operation',
  fuel_system_operation_connections_leakage:
    'Fuel system operation (connections/leakage)',
  fuel_filter_replace_per_maintenance_schedule:
    'Fuel filter (replace per maintenance schedule)',
  radiator_leaks_road_hazard_damage:
    'Radiator (leaks, road hazard damage)',
  coolant_recovery_tank_condition_fluid_level_sensor_operation:
    'Coolant recovery tank (condition, fluid level, sensor, operation)',
  ac_condenser_corrosion_road_hazard_damage:
    'A/C condenser (corrosion, road hazard damage)',
  cooling_fan_operation: 'Cooling fan operation',
  coolant_replace_if_discolored_insufficient_protection:
    'Coolant (replace if discolored, insufficient protection)',
  coolant_hoses_check_condition: 'Coolant hoses (check condition)',
  power_steering_fluid_level_belt_condition_tension:
    'Power steering (fluid level, belt condition/tension)',
  ac_compressor_proper_cycling_belt_condition_tension:
    'AC compressor (proper cycling, belt condition/tension)',
  heater_valve_adjustment: 'Heater valve adjustment',
  engine_valve_noise: 'Engine valve noise',
  engine_transmission_mount_condition:
    'Engine/transmission mount condition',
  engine_condition_visual_verification_of_oil_change_maintenance:
    'Engine condition (visual verification of oil change maintenance)',
  brake_master_cylinder_and_booster_operation_leaks:
    'Brake master cylinder and booster operation/leaks)',
  tire_tread_depth_record_tire_tread_depth_of_at_least_5_32:
    'Tire tread depth—Record tire tread depth of at least 5/32',
  tires_condition_wear_pattern_correct_pressure:
    'Tires/condition/wear pattern/correct pressure',
  tires_check_for_uneven_wear_or_sidewall_cracking:
    'Tires (check for uneven wear or sidewall cracking)',
  wheels_rims_check_for_damage: 'Wheels/Rims (check for damage)',
  valve_stem_condition: 'Valve stem condition',
  wheel_lug_nuts_all_present_and_properly_torqued:
    'Wheel lug nuts (all present and properly torqued)',
  spare_tire_condition_pressure: 'Spare tire (condition/pressure)',
  ac_system_leaks_lines_hoses_condenser:
    'AC system leaks (lines/hoses/condenser)',
  engine_oil_leaks: 'Engine (oil leaks)',
  transmission_leaks: 'Transmission leaks',
  coolant_leaks: 'Coolant leaks',
  cv_joint_boots_drive_shaft_universal_joint_cracks_leakage:
    'CV Joint/boots/drive shaft/universal joint (cracks, leakage)',
  brake_pad_thickness_at_least_5mm:
    'Brake pad thickness at least 5mm.',
  brake_rotor_thickness: 'Brake rotor thickness',
  brake_caliper_leakage: 'Brake caliper leakage',
  hydraulic_brake_hose_line_leakage_and_free_movement:
    'Hydraulic Brake hose/line leakage and free movement',
  suspension_springs_proper_ride_height:
    'Suspension (springs proper ride height)',
  mcpherson_strut_shock_absorber_leakage:
    'McPherson strut/shock absorber leakage',
  bushing_condition: 'Bushing condition',
  ball_joint_and_tie_rod_end_tightness:
    'Ball joint and tie rod end tightness',
  power_steering_rack_leakage: 'Power steering rack leakage',
  entire_exhaust_system_condition_leaks_damage_noise_catalytic_converter_and_converter_shield:
    'Entire exhaust system (condition, leaks, damage, noise, catalytic converter, and converter shield)',
  emissions_meet_state_local_requirements:
    'Emissions (meet state/local requirements)',
  vehicle_exterior: 'VEHICLE EXTERIOR',
  headlights_high_low_operation_and_headlight_aim_surface_condition:
    'Headlights (high/low operation and headlight aim, surface condition)',
  brake_lights: 'Brake lights',
  fog_lamps_if_applicable: 'Fog lamps (if applicable)',
  daytime_running_lights_if_applicable:
    'Daytime Running lights (if applicable)',
  license_plate_light: 'License plate light',
  tail_lights_operation_lens_condition:
    'Tail lights (operation, lens condition)',
  backup_lamps_operation_lens_condition:
    'Backup lamps (operation, lens condition)',
  emergency_flasher: 'Emergency Flasher',
  vehicle_interior: 'VEHICLE INTERIOR',
  includes_owners_manual: 'Includes Owner’s Manual',
  includes_set_s_of_vehicle_keys: 'Includes set/s of vehicle keys',
  water_leaks_look_for_evidence_of_water_intrusion:
    'Water leaks (look for evidence of water intrusion)',
  fuel_gauge_operation: 'Fuel gauge operation',
  temperature_gauge_operation: 'Temperature gauge operation',
  all_warning_lights_illuminated_with_ignition_on:
    'All warning lights illuminated with ignition “ON”',
  dash_light_operation: 'Dash light operation',
  windshield_wiper_washer_operation:
    'Windshield wiper/washer operation',
  audio_system_radio_cd_telephone_audio_inputs_speaker_operation:
    'Audio System-Radio/CD/Telephone audio inputs, speaker operation',
  bluetooth_operations_if_equipped:
    'Bluetooth operations (if equipped)',
  audio_steering_wheel_controls_if_equipped:
    'Audio steering wheel controls (if equipped)',
  navigation_operation_if_equipped:
    'Navigation operation (if equipped)',
  rear_camera_if_equipped: 'Rear camera (if equipped)',
  telematics_operation_if_equipped_service_not_provided:
    'Telematics operation (if equipped) (service not provided)',
  clock_functions: 'Clock functions',
  steering_wheel_tilt_lock: 'Steering wheel tilt/lock',
  sunroof_operation_if_applicable:
    'Sunroof operation (if applicable)',
  sunshade_operation_condition: 'Sunshade operation/condition',
  headliner_condition: 'Headliner condition',
  cigarette_lighter_power_outlets_if_equipped:
    'Cigarette lighter/power outlets (if equipped)',
  alarm_theft_system_operation_check_remote_if_equipped:
    'Alarm/theft system operation (check remote, if equipped)',
  srs_airbags_exterior_condition_check_warning_lamp_self_check:
    'SRS airbags (exterior condition/check warning lamp self-check)',
  outside_mirror_operation_left_and_right:
    'Outside mirror operation (left and right)',
  rearview_mirror_operation_day_night_operation:
    'Rearview mirror operation (day/night operation)',
  window_operation_noise_speed_and_full_travel:
    'Window operation (noise, speed, and full travel)',
  door_locks_check_manual_and_or_power_operation_and_child_safety_function:
    'Door locks (check manual and or power operation and child safety function)',
  power_sliding_doors_if_equipped:
    'Power sliding doors (if equipped)',
  seat_belts_condition_proper_latching_and_retraction_operation:
    'Seat belts (condition, proper latching and retraction operation)',
  seat_upholstery_leather_condition_front_and_rear:
    'Seat upholstery/leather condition (front & rear)',
  power_seat_operation_if_equipped:
    'Power seat operation (if equipped)',
  seat_heaters_if_equipped_and_check_for_proper_operation:
    'Seat heaters (if equipped and check for proper operation)',
  headrest_movement_if_equipped: 'Headrest movement (if equipped)',
  rear_defrost_operation: 'Rear defrost operation',
  inside_mirror_vanity_light_operation:
    'Inside mirror/vanity light operation',
  interior_lights_switch_door_operation_warning_chimes:
    'Interior lights (switch/door operation, warning chimes)',
  glove_box_glove_box_light_operation:
    'Glove box/glove box light operation',
  luggage_compartment_jack_and_tool_present:
    'Luggage compartment (jack and tool present)',
  trunk_lid_release_trunk_lid_light_operation:
    'Trunk lid release/trunk lid light operation',
  liftgate_operation_manual_power_functions_if_equipped:
    'Liftgate operation (manual/power functions —if equipped)',
  fuel_door_release: 'Fuel door release',
  hood_release_operation: 'Hood release operation',
  how_it_drives: 'HOW IT DRIVES',
  engine_start_up_cold_hot: 'Engine Start-up cold/hot',
  abnormal_noises_engine_running: 'Abnormal noises/engine running',
  engine_idle_smooth_idle_and_acceleration_from_idle:
    'Engine idle–smooth idle and acceleration from idle',
  instrument_pack_gauges_for_normal_operation:
    'Instrument pack gauges for normal operation',
  fluid_leaks_while_engine_is_running:
    'Fluid leaks (while engine is running)',
  trip_computer_if_equipped: 'Trip computer (if equipped)',
  cruise_control_if_equipped: 'Cruise Control (if equipped)',
  automatic_manual_transmission_shift_paddle_shift_feature:
    'Automatic/manual transmission shift, paddle shift feature',
  clutch_operation_engagement: 'Clutch operation/engagement',
  abnormal_wind_noise: 'Abnormal wind noise',
  engine_performance: 'Engine performance',
  vibration_drivetrain_and_wheels:
    'Vibration (drivetrain and wheels)',
  straight_line_stability_pull: 'Straight-line stability/pull',
  steering_wheel_alignment: 'Steering wheel alignment',
  braking_system_including_abs: 'Braking System including ABS',
  parking_brake: 'Parking Brake',
  vehicle_handling: 'Vehicle Handling',
  safety_belts: 'Safety Belts',
  blind_spot_monitoring_if_equipped:
    'Blind Spot Monitoring (if equipped)',
  navigation_system_compass_if_fitted:
    'Navigation system/compass (if fitted)',
  how_it_looks: 'HOW IT LOOKS',
  exterior_lamp_reflector: 'Exterior lamp/reflector',
  sunroof_glass_condition_if_equipped:
    'Sunroof glass condition (if equipped)',
  wipers_condition_of_mechanism: 'Wipers/condition of mechanism',
  wipers_condition_of_blades: 'Wipers/condition of blades',
  side_rear_window_condition: 'Side/rear window condition',
  underhood_clean_engine_compartment:
    'Underhood (clean engine compartment)',
  grille_condition_trim_emblems: 'Grille condition (trim, emblems)',
  hood_condition: 'Hood condition',
  drivers_side_front_rear: 'Driver’s Side (front/rear)',
  passenger_side_front_rear: 'Passenger side (front/rear)',
  front_bumper_condition: 'Front bumper condition',
  rear_bumper_condition: 'Rear bumper condition',
  trunk_lid_liftgate_condition: 'Trunk lid/Liftgate condition',
  roof_condition: 'Roof condition',
  exterior_mirrors_condition: 'Exterior mirrors condition',
  fit_condition_of_glove_box_console_box_and_trim:
    'Fit/condition of glove box, console box, and trim',
  stowage_compartment: 'Stowage compartment',
  sun_visor_and_vanity_mirror_operation:
    'Sun visor and vanity mirror operation',
  glove_box_and_door_lamps: 'Glove box and door lamps',
  load_space_trunk_condition: 'Load space/trunk condition',
  all_mirrors_condition_operation: 'All mirrors: condition/operation',
  trim_carpets_condition: 'Trim/carpets condition',
  dash_and_instrument_panels_condition:
    'Dash and instrument panels condition',
  console_center_armrest_cup_holders_condition:
    'Console/center armrest/cup holders condition',
  front_doors_controls_trim_condition:
    'Front doors (controls, trim condition)',
  rear_doors_quarter_trim_controls_trim_condition:
    'Rear doors/quarter Trim (controls, trim condition)',
  luggage_compartment_mat_trim_carpet_condition:
    'Luggage compartment (mat/trim/carpet condition)',
  includes_standard_set_of_floor_mats:
    'Includes standard set of floor mats',
  detailing: 'DETAILING',
  complete_premium_detail_including_wash_wax_and_high_speed_buff:
    'Complete premium detail including: wash, wax, and high-speed buff',
  complete_premium_interior_detail_including_odor_test:
    'Complete premium interior detail including odor test',
  apply_tire_dressing_to_all_4_tires:
    'Apply tire dressing to all (4) tires',
  clean_engine_compartment: 'Clean engine compartment',
  clean_all_windows: 'Clean all windows',
}

const reviewPage = {
  'review.left.title': 'Leave a review',
  'review.left.stars': 'Click the stars to rate *',
  'review.left.add_review': 'Add a written review *',
  'review.left.add_review.placeholder':
    'What did you like or dislike? How was your experience',
  'review.left.feedback_about': "What's your feedback about? *",
  'review.left.feedback_about.type': 'Select Feedback Type',
  'review.left.feedback_about.type.1': 'Sleekride website feedback',
  'review.left.feedback_about.type.2': 'Dealer review',
  'review.left.feedback_about.type.3': 'A car make and model review',
  'review.left.add_headline': 'Add a headline *',
  'review.left.add_headline.placeholder':
    'What’s most important to know',
  'review.left.submit': 'Submit Review',
  'review.left.required_fields': '* Required fields',

  'review.right.title': 'Thank you for your review',
  'review.right.description':
    'Your review will help us improve our team and dealer partners with the best quality of products and services. We truly appreciate your feedback and looking forward to serve you.',
}

export const ENGLISH = {
  ...inspectionProcessPoints,

  ...reviewPage,
  find: 'FIND',
  select_condition: 'Select Condition',
  all: 'All',
  new: 'New',
  like_new: 'Like New',

  good: 'Good',
  fair: 'Fair',
  used: 'Used',
  make: 'Make',
  model: 'Model',
  location: 'Location',
  distance: 'Distance',

  'nav_bar.home': 'Home',
  'nav_bar.wish_list': 'Wish List',
  'nav_bar.sell_my_car': 'Sell My Car',
  'nav_bar.Research': 'Research',
  'nav_bar.for_dealer': 'For Dealer',

  'nav_bar.sign_in': 'Sign In',
  'nav_bar.my_account': 'My Account',
  'nav_bar.log_out': 'Log Out',

  'footer.description':
    'Our trusted marketplace empowers consumers with unbiased third-party validation on pricing and dealer reputation as well as other information that aids them in finding “Great Deals from Top-Rated Dealers.”',
  'footer.company': 'Company',
  'footer.about': 'About',
  'footer.engineering': 'Engineering',
  'footer.contact-us': 'Contact Us',

  'footer.locations': 'Locations',
  'footer.terms': 'Terms',
  'footer.terms_of_service': 'Terms of Service',
  'footer.privacy_policy': 'Privacy Policy',
  'footer.contact': 'Contact',
  'footer.copyright': '© 2024 Sleekride. All Rights Reserved',
  'home.intro.right.starting_at': 'Starting at',
  'home.intro.right.discover': 'DISCOVER MORE',
  'home.intro.headline': 'Find your perfect car',
  'home.intro.sub_headline':
    'A Trusted Marketplace for New and Used Cars',
  'home.popular_brands': 'Popular Brands',
  'home.about': 'ABOUT',
  'home.about.description':
    'SLEEKRIDE is a global, online automotive marketplace connecting buyers and sellers of new and used cars. Using proprietary technology, search algorithms, and innovative data analytics, we believe we are building the world’s most trusted and transparent automotive marketplace and creating a differentiated automotive search experience for consumers.',
  'home.about.title1': 'TRANSPARENT PRICING',
  'home.about.description1':
    'We aggregate vehicle inventory from sellers and dealers then apply our proprietary analysis to generate a Deal Rating as one of: Great Deal, Good Deal, Fair Deal, or High Priced.',
  'home.about.title2': 'PEACE OF MIND',
  'home.about.description2':
    'Detailed 150 points inspections by our qualfied technicians so you can buy a car with complete peace of mind and enhanced credibility',
  'home.about.title3': 'TRUST',
  'home.about.description3':
    'Through data and advanced analytics and machine learning, we strive to provide you with trustworthy information to help you buy a car you want without being scammed.',
  'home.top_deals': 'Top Deals',
  'home.trending_body': 'Trending Body Styles',
  'home.outro.title': 'Get the Top-Rated App',
  'home.outro.sub_headline': 'Over 1 Million Downloads',

  search_title: 'Search Results for',
  'search.no_result': 'No results found',
  'search.placeholder': 'Find your SLEEKRIDE...',
  saved_search: 'Saved Search',
  saved_searches: 'Saved Searches',
  save_search: 'Save Search',

  'search.price': 'PRICE',
  'search.make': 'MAKE',
  'search.model': 'MODEL',
  'search.body': 'BODY',
  'search.year_mileage': 'YEAR & MILEAGE',
  'search.gear': 'GEAR',
  'search.origin': 'ORIGIN',
  automatic: 'Automatic',
  manual: 'Manual',
  ev: 'EV',
  hybrid: 'Hybrid',
  import: 'Import',
  domestic: 'Domestic',
  filter_location: 'Location',

  greatdeal: 'Great Deal',
  gooddeal: 'Good Deal',
  fairdeal: 'Fair Deal',
  highprice: 'High Price',
  pricedrop: 'Price Drop',
  need_financing: 'Need Financing',
  checkavailability: 'Contact Seller',
  days_on_market: 'Days on market',

  'reviews.title': 'What our client said about us.',
  'reviews.none': 'No Available Review Yet',
  'reviews.write_a_review': 'Write a review',

  'for_dealer.intro.headline.left':
    "Let our sales team help you sell more cars and make more money",
  'for_dealer.contact_us': 'Contact Us',
  'for_dealer.intro.headline.right': 'WANT TO BOOST YOUR DEALERSHIP',
  'for_dealer.dealer_sign_in': 'Dealer Sign In',

  'for_dealer.description':
    'Get Your Car in front of the most ready-to-bye shoopers',
  'for_dealer.feature1': 'Vehicle Pricing Validation',
  'for_dealer.description1':
    'Dealer pricing tool allow you to you view your pricing to see how changes might improve a car’s deal rating and page ranking.',
  'for_dealer.feature2': 'More Qualified Leads',
  'for_dealer.description2':
    'By presenting consumers with data such as our Deal Ratings, Price History, Time on Site, and Vehicle History, we believe our consumer audience is comprised of more informed, ready-to-purchase shoppers.',
  'for_dealer.feature3': 'Boost Your Sale Rates',
  'for_dealer.description3':
    'By connecting dealers with such consumers, we believe we provide dealers with an efficient customer acquisition channel and attractive returns on their marketing spend with us.',

  'for_dealer.offers': 'OFFERS',
  'for_dealer.offers.headline': 'Sleekride offers',
  'for_dealer.offers.sub_headline':
    'Sleekrider offers dealers a variety of premium listing and online advertising options',
  'for_dealer.offers.feature1': 'Inventory Listings',
  'for_dealer.offers.description1':
    'List, manage and control your inventory listing with Sleekride Dealer Dashboard. This is where you can see how your listing rated compare to others in the same marker.',
  'for_dealer.offers.feature2': 'Dealer Backend Solutions',
  'for_dealer.offers.description2':
    'The centerpiece of your Sleekride account giving access to key metrics to your dealership performance, how shoppers engaging your listing and get qualified leads to increase sales.',
  'for_dealer.offers.feature3': 'Marketing Services',
  'for_dealer.offers.description3':
    'Our platform offers auto manufacturers and others the ability to purchase display advertising on our sites to execute targeted marketing strategies for Brand Reinforcement.',
  'for_dealer.what_they_say': 'What They Say',
  'for_dealer.work_at': 'Internet Director at Dan Wolf Automotive:',

  'for_dealer.contact.intro': "Let's Talk",
  'for_dealer.contact.headline': 'Contact Our Team',

  'for_dealer.contact.dealership_name': 'Dealership Name',
  'for_dealer.contact.first_name': 'First Name',
  'for_dealer.contact.last_name': 'Last Name',
  'for_dealer.contact.email': 'Email',
  'for_dealer.contact.phone': 'Phone',
  'for_dealer.contact.zip': 'Zip',
  'for_dealer.contact.used_inventory_size': 'Used Inventory Size',

  'for_dealer.contact.optional':
    'Optional: Fill out more info for faster processing.',
  'for_dealer.contact.get_started': 'Get Started',
  'contact.send': 'Send',

  //Research

  'research.title1': 'Tips for Buying a Used Car',
  see_all: 'See All',
  'research.title2': 'Tips for Buying a new Car',

  'research.title3': 'Expert Reviews',

  'research.title4': 'Previews',

  'research.title5': 'Shopping and Negotiating',

  'reasearch.download_app.headline': 'Download the Highly Rated App',
  'reasearch.download_app.sub_headline':
    'More than 1 Million Downloads',

  'login.welcome_back': 'Welcome Back',
  'login.new_to_sleekride': 'New to Sleekride',
  'login.create_an_account': 'Create an Account',
  'login.dealer_question': 'Are you a ',
  'login.dealer': 'Dealer',
  'login.enter_your_email': 'Enter your email',
  'login.enter_your_password': 'Enter your password',
  'login.login': 'LOGIN',
  'login.forgot_password': 'Forgot Password',
  'login.terms_mention':
    'By signing in to your account, you agree to our',
  'login.statement': 'Privacy Statement',
  'login.and': 'and',
  'login.terms_of_service': 'Terms of Service',
  'login.welcome': 'Say hello to the best car marketplace',
  'login.invite': '3k people joined us, now it’s your turn',

  'sign_up.first_name': 'First name',
  'sign_up.last_name': 'Last name',
  'sign_up.email': 'Email Address',
  'sign_up.phone_number': 'Phone number',
  'sign_up.password': 'Password',
  'sign_up.confirm_password': 'Confirm password',
  'sign_up.sign_up': 'SIGN UP',
  'sign_up.social': 'Or connect with social account',
  'sign_up.welcome': 'Welcome to Sleekride',
  'sign_up.description':
    "At Sleekride, we're passionate about making car transactions easy, efficient and fair. Join the thousands who have trusted us with their car buying and selling needs",
  'sign_up.invite': '3k people joined us, now it’s your turn',

  'account.settings': 'Settings',
  'account.listing': 'My Listing',
  'account.my_info': 'My Info',
  'account.montly_payment': 'Monthly Payment Calculator',
  'account.support': 'Support',
  'account.signout': 'Sign Out',

  full_name: 'Full Name',
  email: 'Email',
  phone_number: 'Phone Number',
  city: 'City',
  gender: 'Gender',
  'my_info.changed':
    'Has your information changed? Contact our support team to update your information.',

  interest_rate: 'Interest Rate',
  loan_duration: 'Loan Duration',
  months: 'Months',
  down_payment: 'Down Payment',
  save: 'Save',

  'support.give_feedback': 'Give Us Feedback',
  'support.sold': 'I sold my car',
  'support.edit': 'Edit my listing',
  terms_of_service: 'Terms of Service',
  privacy_policy: 'Privacy Policy',

  'sell_my_car.continue':
    'Welcome back. Ready to finish selling your',
  'sell_my_car.continue2': 'Pick up where I left off',

  'sell_my_car.headline': 'Sell Your Car 100% Online',
  'sell_my_car.sub_headline':
    'Get the best offer instantly from thousand of dealers',

  'sell_my_car.enter_vin': 'Enter your VIN Number',
  select: 'Select',
  'sell_my_car.what_is_my_vin': 'What is my VIN?',
  'sell_my_car.get_started': 'Get Started',

  listing_process: 'Listing Process',
  'listing_process.description':
    'We’ll help you set a realistic price, sell with ease, and get paid safely. All from the comfort of your home.',

  'listing_process.benefits.1': 'Paperwork Assistance',
  'listing_process.benefits.2': 'Better Exposure',
  'listing_process.benefits.3': 'Price Guidance',
  'listing_process.benefits.4': 'Verified Byers',
  'listing_process.benefits.5': 'Only $4.9 to list',
  'listing_process.benefits.6': 'Secure Payment',
  'listing_process.list': 'List My Car',

  'create_listing.headline': 'Sell your Car in 5 Easy Steps',
  'create_listing.steps.1': 'Car Info',
  'create_listing.steps.2': 'Special Features',
  'create_listing.steps.3': 'Condition & History',
  'create_listing.steps.4': 'Schedule Inspection',
  'create_listing.steps.5': 'Checkout',

  'edit_listing.headline': 'Edit your Listing',

  overview: 'Overview',
  'create_listing.steps.1.overview':
    'Tell us about your car (200 Characters)',
  exterior_color: 'Exterior Color',
  interior_color: 'Interior Color',
  transmission: 'Transmission',
  drivetrain: 'Drive Train',
  origin: 'Origin',
  fuel: 'Fuel',
  odometer: 'Odometer',

  'create_listing.steps.1.choice': 'Sell or Trade In',
  'create_listing.steps.1.choice.headline':
    'Sell us your Car or trade In with your Purchase. We will guide you through it',
  'create_listing.steps.1.choice.1': 'Sell by myself',
  'create_listing.steps.1.choice.2': 'Trade In',
  'create_listing.steps.1.choice.3': 'Sell to dealers',
  continue: 'Continue',

  black: 'Black',
  blue: 'Blue',
  brown: 'Brown',
  gold: 'Gold',
  green: 'Green',
  orange: 'Orange',
  pink: 'Pink',
  purple: 'Purple',
  red: 'Red',
  silver: 'Silver',
  teal: 'Teal',
  white: 'White',
  yellow: 'Yellow',

  'create_listing.automatic': 'Automatic (AT)',
  'create_listing.manual': 'Manual (MT)',
  continuous: 'Continuous (CVT)',
  unknown: 'Unknown',

  fwd: 'FWD',
  rwd: 'RWD',
  awd: 'AWD',
  four_wd: '4WD',

  export: 'Export',

  gas: 'Gasoline',
  diesel: 'Diesel',
  electric: 'Electric',
  hydrogen: 'Hydrogen',

  'create_listing.right.title': 'Vehicle Summary',
  'create_listing.right.city': 'City',
  'create_listing.right.VIN': 'VIN',
  'create_listing.right.car_info': 'Year, Make & Model',
  'create_listing.right.trim': 'Trim',
  'create_listing.right.mileage': 'Mileage',
  'create_listing.right.city_mileage': 'City',
  'create_listing.right.highway_mileage': 'Highway',
  'create_listing.right.made_in': 'Made in',
  'create_listing.right.style': 'Style',
  'create_listing.right.anti_brake_system': 'Anti-Brake System',

  'create_listing.right.support': 'Customer Support',
  'create_listing.right.chat': 'Chat with us',
  'create_listing.right.call': 'Call',

  'create_listing.steps.2.title':
    '   Please review the list below and select your vehicles optional features.',
  seating: 'Seating',
  roof: 'Roof',
  wheels: 'Wheels',
  safety: 'Safety',
  technology: 'Technology',
  option: 'Options',

  leather_seats: 'Leather Seats',
  cloth_seats: 'Cloth Seats',
  suede_seats: 'Suede Seats',
  vinyl_seats: 'Vinyl Seats',
  heated_seats: 'Heated Seats',
  memory_seats: 'Memory Seats',
  power_seats: 'Power Seats',
  sunroof: 'Sunroof',
  moonroof: 'Moonroof',
  panoramic_roof: 'Panoramic Roof',
  convertible: 'Convertible',
  alloy_wheels: 'Alloy Wheels',
  steel_wheels: 'Steel Wheels',
  chrome_wheels: 'Chrome Wheels',
  anti_lock_brakes: 'Anti-lock Brakes',
  backup_camera: 'Backup Camera',
  blind_spot_monitoring: 'Blind Spot Monitoring',
  brake_assist: 'Brake Assist',
  child_safety_locks: 'Child Safety Locks',

  adaptive_cruise: 'Adaptive Cruise',
  android_auto: 'Android Auto',
  apple_carplay: 'Apple CarPlay',
  apple_car_play: 'Apple Car Play',
  auxiliary_audio_input: 'Auxiliary Audio Input',
  aux: 'Auxiliary Audio Input',
  bluetooth: 'Bluetooth',
  keyless_start: 'Keyless Start',
  navigation: 'Navigation',
  park_assist: 'Park Assist',
  radio: 'Radio',
  remote_start: 'Remote Start',
  steering_wheel_controls: 'Steering Wheel Controls',
  usb: 'USB',
  wifi: 'WiFi',
  wireless_phone_charging: 'Wireless Charging',
  
  options_technology: 'Technology Package',
  options_self_driving: 'Self Driving Package',
  options_towing: 'Towing Package',
  options_premium: 'Premium Package',
  options_cold_weather: 'Cold Weather Package',
  options_advanced_safety: 'Advanced Safety Package',

  'create_listing.steps.3.title':
    'Please select the options that most accurately describe the condition of your Vehicle.',
  vehicle_accidents: 'Vehicle Accidents',
  'vehicle_accidents.none': 'None',

  vehicle_drivable: 'Is your vehicle drivable?',
  'vehicle_drivable.yes': 'Yes',
  'vehicle_drivable.no': 'No',

  smoked_in: 'Has your vehicle been smoked in?',
  'smoked_in.yes': 'Smoked in',
  'smoked_in.no': 'Not Smoked in',

  hasModifications: 'Does your vehicle have any modifications?',
  'hasModifications.yes': 'Modification',
  'hasModifications.no': 'No Modification',

  mechanical_electrical_issues: 'Mechanical and Electrical Issues',
  'mechanical_electrical_issues.engine': 'Engine',
  'mechanical_electrical_issues.transmission': 'Transmission',
  'mechanical_electrical_issues.airConditioning': 'Air Conditioning',
  'mechanical_electrical_issues.electricalSystem':
    'Electrical System',
  'mechanical_electrical_issues.tirePressure': 'Tire Pressure',
  'mechanical_electrical_issues.noMechanicalAndElectricalIssues':
    'No Mechanical and Electrical issues',

  interior_damage: 'Interior Damage',
  'interior_damage.wear': 'Wear and Tear',
  'interior_damage.functional': 'Functional',
  'interior_damage.dashWarningLights': 'Dashboard Warnings',
  'interior_damage.interiorWarningLights': 'Interior Lights Damage',
  'interior_damage.acDamage': 'AC Damage',
  'interior_damage.buttonsDamage': 'Buttons Damage',
  'interior_damage.noInteriorDamage': 'No Interior Damage',
  'interior_damage.seatDamage': 'Seat Damage',

  exterior_damage: 'Exterior Damage',
  'exterior_damage.faded': 'Faded Paint',
  'exterior_damage.rust': 'Rust',
  'exterior_damage.damage': 'Body Damage',
  'exterior_damage.dentOrScrapes': 'Dent or Scrapes',
  'exterior_damage.windshieldCracksOrChips':
    'Windshield Cracks or Chips',
  'exterior_damage.noExteriorDamage': 'No Exterior Damage',

  'create_listing.steps.3.condition_question':
    'What is the best way to describe about the condition of your Vehicle',
  'create_listing.steps.3.photos_note':
    'Your vehicle photos are extremely important to your posting and it appeals to users so we recommend photos to be aspect ratio of 4:3 or 3:2, such as 1200 x 900 pixels, 1500 x 1000 pixels, or 2000 x 1333 pixels.',
  vehicle_keys: 'Vehicle Keys',

  'create_listing.steps.3.price': 'Price (VND)',

  'create_listing.steps.3.add_photos':
    'Add Photos (Front, Rear, Right, Left, Interior)',

  'create_listing.steps.3.upload_photos': 'Upload Photos',
  'create_listing.steps.3.upload_more': 'Upload More',

  'create_listing.steps.4.title': 'Schedule Your Car Inspection',

  'create_listing.steps.4.description':
    'Get the most money for your used car with Sleekride 150-Point inspection. WIth the inspection, you will attract more serious buyers and get the best price for your used car.',

  appointment_date: 'Appointment Date',
  select_service_center: 'Select Service Center',

  'create_listing.steps.4.why.title': 'Why We Need Inspection',
  'create_listing.steps.4.why.description':
    'Sleekride strive to provide a trust worthy and pleasant car shopping experience. We aim to provide used car buyers with data so they can make a good purchasing decision. For sellers, we aim to provide tools to help you sell your car fast and get the best price for your used car. Its a Win-Win proposition.',

  'create_listing.steps.4.why.1.title': 'Sell Your Car Fast',
  'create_listing.steps.4.why.1.description':
    'Detailed digital vehicle inspection report includes evaluation of your car’s interior, exterior,road test, and its engine will attract buyers.',
  'create_listing.steps.4.why.2.title': 'Get Best Price for Your Car',
  'create_listing.steps.4.why.2.description':
    'Your goal is to get the highest price for your used car. With the inspection, you can command pricing power of your used car. You can get more money with inspection than without.',
  'create_listing.steps.4.why.3.title': 'Establish Trust',
  'create_listing.steps.4.why.3.description':
    'With the inspection, buyers are confidence about what they see online therefore you have a better chance of getting the highest value for your used car.',
  'create_listing.steps.4.why.4.title': 'Enhanced Credibility',
  'create_listing.steps.4.why.4.description':
    'Vehicle inspection helps car sellers to get the desired price for their car by providing authentic details.',

  'create_listing.steps.4.how.title':
    'How Sleekride Car Inspection Works?',
  'create_listing.steps.4.how.1':
    'Make a booking for the car inspection service',
  'create_listing.steps.4.how.2':
    'Our representative will contact you to schedule a time for vehicle inspection',
  'create_listing.steps.4.how.3':
    'Bring your car to Sleekride certified service center & conduct the inspection',
  'create_listing.steps.4.how.4':
    'Your detailed vehicle inspection report will be posted with on your car listing',

  'create_listing.steps.5.title': 'Checkout',
  'create_listing.steps.5.description':
    'Choose your secure payment method',

  'create_listing.steps.5.complete': 'Complete',
  'create_listing.steps.5.complete_purchase': 'Complete Purchase',

  'create_listing.steps.5.summary.title': 'Listing Summary',
  'create_listing.steps.5.summary.description':
    'You have 01 item in your cart',
  'create_listing.steps.5.summary.list': 'List on sleekride.vn',
  'create_listing.steps.5.summary.sub_total': 'Sub Total',
  'create_listing.steps.5.summary.tax': 'Service Tax',
  'create_listing.steps.5.summary.promo': 'Promo Code',
  'create_listing.steps.5.summary.total': 'Total',

  'listing.condition.new': 'New Car',
  'listing.condition.used': 'Used Car',

  'listing.tabs.overview': 'OVERVIEW',
  'listing.tabs.features': 'FEATURES & OPTIONS',
  'listing.tabs.features.1': 'Interior',
  'listing.tabs.features.2': 'Technology',
  'listing.tabs.features.3': 'Roof',
  'listing.tabs.features.4': 'Wheels',
  'listing.tabs.features.5': 'Safety',
  'listing.tabs.features.6': 'Others',
  'listing.tabs.seller': 'SELLER INFO',
  // Start of Cur

  'listing.tabs.seller.private': 'Private Seller',
  'listing.tabs.seller.dealer': 'Premier Dealer',
  'listing.tabs.seller.share': 'Share',
  'listing.tabs.seller.message': 'Message',

  'listing.tabs.seller.reviews.title': 'Dealer Reviews',
  'listing.tabs.seller.reviews.rate': 'out of 5',
  'listing.tabs.seller.reviews.global': 'global ratings',
  'listing.tabs.seller.reviews.star': 'star',
  'listing.tabs.seller.reviews.review': 'Review',
  'listing.tabs.seller.reviews.add': 'Add your review',

  // End of Cur
  wishlist: 'Wish List',
  no_wishlist: 'No items in wish list',
  'listing_detail.vehicle.title': 'VEHICLE INFORMATION',
  'listing_detail.vehicle.trim': 'Trim',
  'listing_detail.vehicle.made_in': 'Made In',
  'listing_detail.vehicle.VIN': 'VIN',
  'listing_detail.vehicle.exterior': 'Exterior Color',
  'listing_detail.vehicle.condition': 'Condition',
  'listing_detail.vehicle.gas': 'Gas Mileage',
  'listing_detail.vehicle.doors': 'Doors',
  'listing_detail.vehicle.transmission': 'Transmission',
  'listing_detail.vehicle.drivetrain': 'Drivetrain',
  'listing_detail.vehicle.fuel': 'Fuel',
  'listing_detail.vehicle.interior': 'Interior Color',
  'listing_detail.vehicle.engine': 'Engine',
  'listing_detail.vehicle.body': 'Body Style',

  'listing_detail.intelligence.title': 'Buyer intelligence',
  'listing_detail.intelligence.days': 'Days on Market',
  'listing_detail.intelligence.lower': 'Lower then similar listings',
  'listing_detail.intelligence.higher':
    'Higher then similar listings',
  'listing_detail.intelligence.change': 'Historic Price Change',

  'listing_detail.inspection.title': '150-Point Inspection',
  'listing_detail.inspection.description':
    'This vehicle went through a 150 point inspection so that you can feel secure about the quality of your car.',
  'listing_detail.inspection.missing': 'This vehicle has not been inspected yet. Please check back later.',
  'listing_detail.inspection.unknown': 'Unknown',
  'listing_detail.inspection.model': 'Model',

  'listing_detail.inspection.1': 'Clean History',
  'listing_detail.inspection.2': 'A/C condenser',
  'listing_detail.inspection.3': 'Transmission leaks',
  'listing_detail.inspection.4': 'Tires',
  'listing_detail.inspection.5': 'Braking System including ABS',
  'listing_detail.inspection.6': 'Water leaks',
  'listing_detail.inspection.7': 'Suspension',
  'listing_detail.inspection.8': 'Headlights',
  'listing_detail.inspection.9':
    'No reported accidents/flood/salvage',
  'listing_detail.inspection.10': 'SEE ENTIRE INSPECTION LIST',
  'listing_detail.inspection.11': 'INSPECTION REPORT UNAVAILABLE',

  'listing_detail.inspection.failed': 'Failed',
  'listing_detail.inspection.passed': 'Passed',

  'listing_detail.similar_vehicles': 'Vehicles you might like',

  site_name: 'sleekride.vn',
  introduction:
    'Sleekride LLC. ("Autolist") provides websites, mobile applications, and other technologies to allow our customers to list and/or locate cars, trucks, or other automobiles for sale. Our Privacy Policy ("Privacy Policy") is designed to help you understand how we collect, use and share your personal information and to assist you in exercising the privacy rights available to you.',
  scope: 'Scope',
  scope_description:
    'This Privacy Policy applies to personal information processed by us in our business, including on our websites, mobile applications, and other online or offline offerings (collectively, the "Services").',
  personal_information: '1. PERSONAL INFORMATION WE COLLECT',
  personal_information_description:
    'The categories of personal information we collect depend on whether you are a customer, user, applicant, or visitor, and the requirements of applicable law.',
  information_provided: 'Information You Provide to Us',
  account_creation:
    'Account Creation: When you create a user account, we may collect personal information such as your first and last name, email address, phone number, zip code, username, and password.',
  communications_with_us:
    'Your Communications with Us. We collect personal information from you such as your first and last name, email address, phone number, and mailing address when you request information about our Services, register for our newsletter, request customer or technical support, apply for a job or otherwise communicate with us.',
  requesting_financing:
    'Requesting Financing. When you request vehicle financing through our website, we may collect personal information to determine your eligibility, including your first and last name; email address; phone number; date of birth; Social Security number; information about your current and previous places of residence, including your rent or mortgage costs; information about your current and previous employment, including your income; information about your financial history; and information relevant to your financing request, such as the value of a vehicle you are trading in, your down payment amount, and the term of financing you are requesting.',
  surveys:
    'Surveys. We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information which may include personal information.',
  social_media_content:
    'Social Media Content. We may offer forums, blogs, or social media pages. Any content you provide on these channels will be considered “public” and is not subject to privacy protections.',
  registration_sweepstakes_contests:
    'Registration for Sweepstakes or Contests. We may run sweepstakes and contests. Contact information you provide may be used to reach you about the sweepstakes or contest and for other promotional, marketing, and business purposes, if permitted by law. In some jurisdictions, we are required to publicly share information of winners.',
  information_collected_automatically:
    'Information Collected Automatically or From Others',
  automatic_data_collection:
    'Automatic Data Collection. We may collect certain information automatically when you use the Services. This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, details about your browser, operating system or device, location information, Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, and other information about how you use the Services. Information we collect may be associated with accounts and other devices.',
  data_collection_continued:
    'In addition, we may automatically collect data regarding your use of our Services, such as the types of content you interact with and the frequency and duration of your activities. We may combine your information with information that other people provide when they use our Services, including information about you when they tag you.',
  cookies_pixel_tags_web_beacons:
    'Cookies, Pixel Tags/Web Beacons, Analytics Information, and Interest-Based Advertising technologies. We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through the Services. Technologies are essentially small data files placed on your computer, tablet, mobile phone, or other devices that allow us and our partners to record certain pieces of information whenever you visit or interact with our Services.',
  technologies_description:
    'We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through the Services. Technologies are essentially small data files placed on your computer, tablet, mobile phone, or other devices that allow us and our partners to record certain pieces of information whenever you visit or interact with our Services.',
  cookies:
    'Cookies. Cookies are small text files placed in visitors’ computer browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.',
  pixel_tags_web_beacons:
    'Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects information about users’ engagement on that web page. The use of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement.',
  analytics_information:
    'Analytics. We may also use Google Analytics and other service providers to collect information regarding visitor behavior and visitor demographics on our Services. For more information about Google Analytics, please visit www.google.com/policies/privacy/partners/. You can opt out of Google’s collection and processing of data generated by your use of the Services by going to http://tools.google.com/dlpage/gaoptout.',

  information_from_other_sources:
    'Information from Other Sources. We may obtain information about you from other sources, including through third-party services and organizations to supplement information provided by you. For example, if you access our Services through a third-party application, such as an app store, a third-party login service, or a social networking site, we may collect information about you from that third-party application that you have made public via your privacy settings. Information we collect through these services may include your name, your user identification number, your user name, location, gender, birth date, email, profile picture, and your contacts stored in that service. This supplemental information allows us to verify information that you have provided to us and to enhance our ability to provide you with information about our business, products, and Services.',

  how_we_use_information: 'HOW WE USE YOUR INFORMATION.',
  how_we_use_information_description:
    ' We use your information for a variety of business purposes, including to:',
  how_we_use_information_promise:
    '  Fulfill our contract with you and provide you with our Services, such as:',

  managing_your_information_and_accounts:
    'Managing your information and accounts;',
  providing_access_and_communicating:
    'Providing access to certain areas, functionalities, and features of our Services; Communicating with you about your account, activities on our Services and policy changes;',
  activities_to_verify_quality_or_safety:
    'Undertaking activities to verify or maintain the quality or safety of a service or device;',
  processing_financial_information:
    'Processing your financial information and other payment methods for products or Services purchased;',
  providing_advertising_analytics_marketing_services:
    'Providing advertising, analytics and marketing services;',
  processing_applications_and_transactions:
    'Processing applications and transactions; and',
  allowing_you_to_register_for_events:
    'Allowing you to register for events.',
  analyzing_and_improving_services_pursuant_to_legitimate_interest:
    'Analyze and improve our Services pursuant to our legitimate interest, such as:',
  detecting_security_incidents_and_protecting:
    'Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those responsible for that activity;',
  measuring_interest_and_engagement:
    'Measuring interest and engagement in our Services and short-term, transient use, such as contextual customization of ads;',
  undertaking_research_for_technological_development:
    'Undertaking research for technological development and demonstration;',
  procedures_to_improve_performance:
    'Rrocedures to improve their performance, resilience, reliability or efficiency;',
  improving_upgrading_or_enhancing_services_or_device:
    'Improving, upgrading or enhancing our esearching and developing products, services, marketing or security pServices or device;',
  developing_new_products_and_services:
    'Developing new products and Services;',
  ensuring_internal_quality_control:
    'Ensuring internal quality control;',
  verifying_your_identity_and_preventing_fraud:
    'Verifying your identity and preventing fraud;',
  debugging_to_identify_and_repair_errors:
    'Debugging to identify and repair errors that impair existing intended functionality; Enforcing our terms and policies; and',
  complying_with_legal_obligations:
    'Complying with our legal obligations, protecting your vital interest, or as may be required for the public good.',
  provide_additional_content_and_services:
    'Provide you with additional content and Services, such as:',
  furnishing_customized_materials_and_auditing:
    'Furnishing you with customized materials about offers, products, and Services that may be of interest, including new content or Services; Auditing relating to interactions, transactions and other compliance activities; and Other purposes you consent to, are notified of, or are disclosed when you provide personal information.',
  auditing_and_other_purposes:
    'Auditing relating to interactions, transactions and other compliance activities; and Other purposes you consent to, are notified of, or are disclosed when you provide personal information.',
  use_of_deidentified_and_aggregated_information:
    'Use De-identified and Aggregated Information. We may use personal information and other data about you to create de-identified and aggregated information, such as de-identified demographic information, de-identified location information, information about the computer or device from which you access our Services, or other analyses we create.',

  share_content_with_friends_or_colleagues:
    'Share Content with Friends or Colleagues. Our Services may offer various tools and functionalities. For example, we may allow you to provide information about your friends through our referral services. Our referral services may allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use our Services. How We Use Automatic Collection Technologies. We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies to automatically collect information through the Services. Our uses of these Technologies fall into the following general categories: Operationally Necessary. This includes Technologies that allow you access to our Services, applications, and tools that are required to identify irregular site behavior, prevent fraudulent activity and improve security or that allow you to make use of our functionality; Performance Related. We may use Technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how our visitors use the Services;',

  functionality_related:
    'Functionality Related. We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed;',
  advertising_or_targeting_related:
    'Advertising or Targeting Related. We may use first party or third-party Technologies to deliver content, including ads relevant to your interests, on our Services or on third party sites. Cross-Device Tracking. Your browsing activity may be tracked across different websites and different devices or apps. For example, we may attempt to match your browsing activity on your mobile device with your browsing activity on your laptop. To do this our technology partners may share data, such as your browsing patterns, geo-location and device identifiers, and will match the information of the browser and devices that appear to be used by the same person. Notice Regarding Third Party Websites, Social Media Platforms and Software Development Kits. The Services may contain links to other websites, and other websites may reference or link to our website or other Services. These other websites are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve and are not responsible for the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.',
  publicly_accessible_blogs_forums_social_media:
    'Our Services may include publicly accessible blogs, forums, social media pages, and private messaging features. By using such Services, you assume the risk that the personal information provided by you may be viewed and used by third parties for any number of purposes. In addition, social media buttons such as Facebook, Twitter, and LinkedIn (that might include widgets such as the “share this” button or other interactive mini-programs) may be on our site. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. These social media features are either hosted by a third party or hosted directly on our site. Your interactions with these features apart from your visit to our site are governed by the privacy policy of the company providing it.',

  use_of_third_party_apis_and_sdks:
    'We may use third-party APIs and software development kits (“SDKs”) as part of the functionality of our Services. APIs and SDKs may allow third parties including analytics and advertising partners to collect your personal information for various purposes including to provide analytics services and content that is more relevant to you. For more information about our use of APIs and SDKs, please contact us as set forth below.',

  // start
}

// # Example: To access the children of "Explore," you can use ENGLISH["explore"].
// # Example: To access "Account settings" under "Account Settings," you can use ENGLISH["account_settings"]["account_settings"].
