export const capitalizeFirstLetter = (string) => {
  if(!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeAllFirstLetter = (string) => {
  return string
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ')
}
