import { Safety } from '../Types/common';

export const safety: Safety[] = [
  {
    _id: '1',
    name: 'antiLockBrakes',
    label: 'Anti-lock Brakes',
    translation: 'anti_lock_brakes',
  },
  {
    _id: '2',
    name: 'backupCamera',
    label: 'Backup Camera',
    translation: 'backup_camera',
  },
  {
    _id: '3',
    name: 'blindSpotMonitoring',
    label: 'Blind Spot Monitoring',
    translation: 'blind_spot_monitoring',
  },
  {
    _id: '4',
    name: 'brakeAssist',
    label: 'Brake Assist',
    translation: 'brake_assist',
  },
  {
    _id: '5',
    name: 'childSafetyLocks',
    label: 'Child Safety Locks',
    translation: 'child_safety_locks',
  },
]
