import React from 'react'
import CustomizedSteppers from 'Components/Stepper'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const CreateListing = () => {
  const { id } = useParams()
  console.log('ID: ', id)

  return (
    <div>
      <div
        id="car-sales-stepper-container"
        className={`p-10 sm:px-0  sm:p-4 xl:min-h-[calc(100vh-510px)] xxl:min-h-[calc(100vh-510px)]`}
      >
        <h1 className="text-xl font-bold sm:ml-5">
          <FormattedMessage id="create_listing.headline" />
        </h1>
        <CustomizedSteppers id={id} edit={false} />
      </div>
    </div>
  )
}

export default CreateListing
