import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { setListing } from 'redux/listing'
import { useParams } from 'react-router-dom'
import './style.css'
import Step5Left from './Step5Left.jsx'
import Step5Right from './Step5Right.jsx'

const Step5 = (props) => {
  const dispatch = useDispatch()
  const { loading } = props // loading props from parent
  const pendingListing = useSelector((state) => state.listing.pendingListing)
  const { id } = useParams()

  const [getListing, { loading: listingLoading }] = useMutation({
    endPoint: APIEndpoints.GET_LISTING.replace(':id', id),
    method: 'get',
  })

  useEffect(() => {
    if (!pendingListing) {
      // Fetch listing only if it's not already loaded
      getListing()
        .then((response) => {
          const pendingListing = response
          if (pendingListing) {
            dispatch(setListing(pendingListing))
          }
        })
        .catch((error) => {
          console.error('Error fetching the listing:', error)
        })
    }
  }, [])

  if (loading || listingLoading) {
    return <div>Loading...</div> // You can replace this with any loading spinner or component
  }

  return (
    <div className="p-10 sm:p-5 flex md:flex-col xl:min-h-[calc(100vh-510px)] xxl:min-h-[calc(100vh-510px)]">
      <Step5Left id={id} pendingListing={pendingListing} />
      <Step5Right id={id} pendingListing={pendingListing} />
    </div>
  )
}

export default Step5
