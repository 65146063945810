import React from "react";
import arrow from "../../top-right-arrow.png";

function FairDealIcon({ fill = "#ffffff", ...rest }) {
  return (
    <div className="bg-[#FFD337] w-6 h-6 flex items-center justify-center rounded-full">
      <img src={arrow} />
    </div>
  );
}

export default FairDealIcon;
