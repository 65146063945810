import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import clock from '../../../assets/icons/clock.png'
import './style.css'
import ImageGallery from 'react-image-gallery'
import { ReactComponent as RightNav } from '../../../assets/icons/arrowRightProductImageSlider.svg'
import { ReactComponent as LeftNav } from '../../../assets/icons/arrowLeftProductImageSlider.svg'
import { Link } from 'react-router-dom'
import Heart from '../../../assets/icons/heartIcon'

const renderRightNav = (onClick, disabled) => (
  <RightNav
    className="arrows arrowRight h-6 sm:right-0 cursor-pointer"
    onClick={onClick}
    disabled={disabled}
  />
)
const renderleftNav = (onClick, disabled) => (
  <LeftNav
    className="arrows arrowLeft h-6 sm:left-0 cursor-pointer"
    onClick={onClick}
    disabled={disabled}
  />
)
export default function CarTypeCard({ image, Icon, name }) {
  return (
    <Card
      sx={{
        minWidth: { xs: 200, sm: 220, md: 230, lg: 250, xl: 280 },
        width: { xs: 200, sm: 220, md: 230, lg: 250, xl: 280 },
        height: 'auto',
        // borderRadius: "20px",
        position: 'relative',
        margin: '20px',
        padding: 0,
        boxShadow: 'none',
      }}
    >
      {/* <img  alt='ss' src={car1} /> */}

      <div className="h-[400px] flex justify-center items-center">
        <div className="h-[280px] w-1/3 bg-gradient-to-b from-[#ECECEC] to-[#C1C1C1] "></div>
        <div className="absolute flex justify-center items-center w-full h-full   z-10">
          <img
            className=""
            alt="ss"
            src={image}
          />
        </div>
        <p className="absolute bottom-0 text-[28px] font-[700] leading-[34.13px] z-10">
          {name}
        </p>
      </div>

      {/* </CardActionArea> */}
    </Card>
  )
}

{
  /* <CardActionArea
    
      > */
}
{
  /* <CardMedia
        component="img"
        height="140"
        image={image}
        alt="green iguana"
      /> */
}

// import * as React from "react";
// import "./style.css";

// import { ReactComponent as RightNav } from "../../../assets/icons/arrowRightProductImageSlider.svg";
// import { ReactComponent as LeftNav } from "../../../assets/icons/arrowLeftProductImageSlider.svg";

// const renderRightNav = (onClick, disabled) => (
//   <RightNav
//     className="arrows arrowRight h-6 sm:right-0"
//     onClick={onClick}
//     disabled={disabled}
//   />
// );
// const renderleftNav = (onClick, disabled) => (
//   <LeftNav
//     className="arrows arrowLeft h-6 sm:left-0"
//     onClick={onClick}
//     disabled={disabled}
//   />
// );
// export default function CarStyleCard({ dealType, image }) {
//   if( 1== 0 ) console.log(dealType);
//   return (
//     <div
//     className="relative h-72 w-96 flex flex-col items-center justify-center md:h-52  md:min-w-[200px] md:w-64 sm:min-w-[150px] "
//     // sx={{ minWidth:{xs: 280, sm:300 , md:310 , lg:340} , width: {xs: 280, sm:300 , md:310 , lg:340}, borderRadius:'20px' ,position:'relative', margin:'20px' }}
//     >
//       <div className="h-full w-1/3 bg-gradient-to-b from-[#ECECEC] to-[#C1C1C1] "></div>
//       <div className="absolute  top-[35%] z-10">
//       <img className=" z-10 w-full"                      alt="ss" src={image} />

//       </div>
//     </div>
//   );
// }
