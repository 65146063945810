import { refactorQuery } from 'Utils/Parse/Query'

export const getParams = (obj, curState = null) => {

  const { make, model, condition, body, gear, origin } = obj
  const paramObj = {
    condition: condition ?? '',
    make: make ?? '',
    model: model ?? '',
    body: body ?? '',
    gear: gear ?? '',
    origin: origin ?? '',
  }
  if (curState) {
    paramObj.distance = curState.distance ?? ''
    paramObj.location = curState.location ?? ''
  }

  const params = refactorQuery(paramObj)

  return params.toString()
}
