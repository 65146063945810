


export const dayElapesed = (createdAt) => {
          const date = new Date(createdAt)
      const milisecs = date.getTime()
      const miliElapsed = Date.now() - milisecs
      //get date difference in days
    const daysElapsed = Math.floor(miliElapsed / (1000 * 60 * 60 * 24))
    return daysElapsed
}