import React, { useEffect } from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import UserSettings from '../../Components/UserSettings'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingSpinner from 'Components/LoadingSpinner'

const Setting = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const idAsNumber = parseInt(id)

  useEffect(() => {
    navigate('/setting', { replace: true })
  }, [idAsNumber, navigate])

  const user = useSelector((state) => state.user?.data)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)

  useEffect(() => {
    if (!user && !loggedIn && !isLoading) {
      navigate('/user-login', { replace: true })
    }
  }, [user, loggedIn, isLoading, navigate])

  if (isLoading) {
    // You can render a loading spinner or any other UI while waiting for the data
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="flex-1">
      <UserSettings user={user} id={idAsNumber || 0} />
    </div>
  )
}

export default Setting
