import React from 'react'
import {
  HomeIcon1,
  HomeIcon2,
  HomeIcon3,
} from 'assets/icons/HomeIcons'
import about from '../../../assets/images/about.png'
import aboutsmall from '../../../assets/images/aboutsmall.png'
import { useIntl } from 'react-intl'

const Checkout = () => {
  const intl = useIntl()
  return (
    <div className=" -md:lg:px-10  pr-10  md:p-0 sm:p-0 lg:h-fit ">
      <div className=" rounded-xl w-full  relative sm:p-4  h-full ">
        <div className="  sm:p-0 sm:pt-[20rem] -md:lg:pt-0 h-full flex flex-col justify-end items-center pt-10  ">
          <div className=" self-end lg:w-full w-[62%] flex flex-col justify-center items-end">
            <div className="w-full z-1 flex my-1">
              <p className="w-1/2 font-semibold tracking-wider text-[#105e26] z-10  px-10 sm:w-full !mr-[100px] sm:px-1 sm:text-center sm:tracking-widest ">
                {intl.formatMessage({ id: 'home.about' })}
              </p>
              <div className=" top-0  right-0 -md:lg:-right-8  -md:lg:z-50 absolute   -lg:hidden ">
                <img
                  src={aboutsmall}
                  className="h-full w-full"
                  alt="sleekride"
                />
              </div>
            </div>
            <div className="w-full z-1 flex my-1">
              <p className="text-4xl font-bold my-1 z-10 px-10 sm:px-1 sm:w-full sm:text-2xl !mr-[100px]  sm:text-center">
                SLEEKRIDE
              </p>
            </div>
            <div className="-md:lg:p-0 -sm:md:px-5 sm:p-0 ">
              
            <div className="w-full z-1 flex -md:lg:justify-start sm:justify-center items-center ">
              <p className="  leading-[1.75rem]  text-[#000] lg:bg-white lg:bg-opacity-95  lg:rounded-md lg:shadow-md shadow-xl drop-shadow-xl my-2 z-10 m-1 p-8  -md:lg:pt-5  -md:max-w-[55%] w-full  sm:text-sm sm:px-6 sm:text-left ">
                {intl.formatMessage({ id: 'home.about.description' })}
              </p>
            </div>
                </div>

            <div className="pt-12 self-end md:pt-4 -sm:md:px-5   sm:p-0 grid grid-cols-3 gap-5 md:grid-cols-1">
              <div className=" bg-white z-10 m-1 p-8 rounded-md shadow-xl drop-shadow-xl sm:p-4 -md:lg:p-2">
                <HomeIcon1 />{' '}
                <div className="mt-8">
                  <p className=" text-lg font-semibold my-2 h-[50px] md:h-full flex  items-center lg:text-base">
                    {intl.formatMessage({ id: 'home.about.title1' })}
                  </p>
                  <p className="my-1  sm:max-w-full ">
                    {intl.formatMessage({
                      id: 'home.about.description1',
                    })}{' '}
                  </p>
                </div>{' '}
              </div>
              <div className=" bg-white z-10 m-1 p-8 rounded-md shadow-xl drop-shadow-xl sm:p-4 -md:lg:p-2">
                <HomeIcon2 />{' '}
                <div className="mt-8">
                  <p className=" text-lg font-semibold my-2 h-[50px] md:h-full flex  items-center lg:text-base">
                    {intl.formatMessage({ id: 'home.about.title2' })}
                  </p>
                  <p className="my-1  sm:max-w-full ">
                    {intl.formatMessage({
                      id: 'home.about.description2',
                    })}
                  </p>
                </div>{' '}
              </div>
              <div className=" bg-white z-10 m-1 p-8 rounded-md shadow-xl drop-shadow-xl sm:p-4 -md:lg:p-2">
                <HomeIcon3 />{' '}
                <div className="mt-8">
                  <p className=" text-lg font-semibold my-2 h-[50px] md:h-full flex  items-center lg:text-base">
                    {intl.formatMessage({ id: 'home.about.title3' })}
                  </p>
                  <p className="my-1  sm:max-w-full ">
                    {intl.formatMessage({
                      id: 'home.about.description3',
                    })}
                  </p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>

        <div className=" lg:hidden absolute left-0 top-0 w-[36%] md:w-1/2 md:h-1/2 h-full sm:hidden   ">
          <img
            src={about}
            className="h-full w-full object-contain object-left md:object-right"
            alt="sleekride"
          />
        </div>
      </div>
    </div>
  )
}

export default Checkout
