import React from "react";

function FilterItem({ text }) {
  return (
    <div className="py-1 px-5 rounded-2xl border-[1px] border-gray-400 mr-1 shadow-md  cursor-pointer bg-[#CACACA] text-[#69A379] text-sm">
      {text}
    </div>
  );
}

export default FilterItem;
