import React, { useEffect, useState } from 'react'
import './style.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import { ReactComponent as TrendUp } from '../../assets/icons/trendup.svg'
import { ReactComponent as TrendDown } from '../../assets/icons/trenddown.svg'
import { ReactComponent as Tickmark } from '../../assets/icons/tickmark.svg'
import { ReactComponent as Crossmark } from '../../assets/icons/crossmark.svg'
import { ReactComponent as QuestionMark } from '../../assets/icons/questionmarkicon.svg'
import SeeInspectionListIcon from '../../assets/icons/seeinspectionlisticon'
import http from '../../http'
import LoadingSpinner from 'Components/LoadingSpinner'
// import { GoodDealIcon } from "assets/icons/pricingToolsIcon/dealIcon";

import Listingtabs from '../../Components/Listingtabs'
import { Container, Grid } from '@mui/material'
import Footer from 'Components/Footer'
import CarCard from 'Components/Cards/CarCard'
import { GoodDealIcon, FairDealIcon, HighPriceIcon } from 'assets/icons/pricingToolsIcon/dealIcon'

import { Link, useNavigate, useParams } from 'react-router-dom'
import CarInfo from 'Components/CarInfo'
import { MicroServices } from 'staticStore'
import { capitalizeFirstLetter } from 'Utils/Parse'
import ReviewSlider from 'Components/SlickSlider/ReviewSlider'
import CarouselComp from 'Components/Carousel'
import { dayElapesed } from 'Utils/Parse/Time'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getCityLabel } from 'Resources/Data/cities'
import { USDtoVND, VNDshorten } from 'Utils/Parse/currency'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Navigation } from 'swiper/modules'
import { ArrowRight, ArrowLeft } from 'assets/icons/HomeIcons'

import 'swiper/swiper-bundle.css' // Make sure you import Swiper styles
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import clsx from 'clsx'

SwiperCore.use([Navigation])

// Translated messages in French with matching IDs to what you declared

const inspectionList1 = [2, 12, 32, 25, 111]
const inspectionList1_key = [1, 2, 3, 4, 5]
const inspectionList2 = [58, 39, 46, 3]
const inspectionList2_key = [6, 7, 8, 9]

export const getListingDetail = async (id) => {
  const response = await http.get(`${MicroServices.GATEWAY}/vce/view/findPost/${id}`)
  console.log(response)
  if (response.status === 200) {
    console.log(':_________________________________')
    let listing = response.data
    if (listing && listing.images)
      listing.images = listing.images.map((img) => {
        let IMG = img.data.url
        return {
          original: IMG,
          thumbnail: IMG,
          originalHeight: '200px',
          originalWidth: '200px',
          thumbnailHeight: '200px',
          thumbnailWidth: '200px',
        }
      })
    // console.log('LISTING: ', listing)
    return listing
  } else {
    return null
  }
}

const Listing = () => {
  const navigate = useNavigate()
  const id = useParams().id //carId

  const user = useSelector((state) => state.user?.data)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)

  useEffect(() => {
    console.log('test', user, !loggedIn, !isLoading)
    if (isLoading) return
    if (!user && !loggedIn && !isLoading) {
      navigate('/user-login')
    }
  }, [user, loggedIn, isLoading, navigate])

  const [carData, setCarData] = useState(null)

  const getNumMil = (mileage, intl) => {
    if (!mileage) return
    let res = mileage.slice(0, mileage.indexOf(' '))

    // if vn convert miles to km else miles
    if (intl.locale === 'vn') {
      res = (parseInt(res) * 1.609).toFixed(0)
    }
    return res
  }

  const [loading, setLoading] = useState('start')

  useEffect(() => {
    setLoading('start')
    getListingDetail(id).then((data) => {
      // console.log(data)
      setCarData(data)
      if (data) setLoading('end')
      else setLoading('error')
    })
  }, [])

  useEffect(() => {
    // console.log(loading)
  }, [loading])

  // this is handling the responsiveness of the carousel by the window width
  // now add handling of the window height to also resize the width and height of the carousel
  // from smallest to biggest, from height to width, combine them to get the height
  // use the and operator to get the smallest height and width
  const isMobile = useMediaQuery('(max-width:768px)')
  const isIpad = useMediaQuery('(max-width:1024px)')
  const isDesktop = useMediaQuery('(max-width:1400px)')
  const isSmallDesktop = useMediaQuery('(max-width:1600px)')
  const isBigDesktop = useMediaQuery('(max-width:1920px)')
  const isBigHeight = useMediaQuery('(max-height:1600px)')
  const isSmallHeight = useMediaQuery('(max-height:800px)')
  const isSmallerHeight = useMediaQuery('(max-height:600px)')

  const height =
    !!isMobile && !!isSmallerHeight
      ? 300
      : !!isMobile && !!isSmallHeight
      ? 300
      : !!isMobile && !!isBigHeight
      ? 300
      : !!isIpad && !!isSmallerHeight
      ? 300
      : !!isIpad && !!isSmallHeight
      ? 500
      : !!isIpad && !!isBigHeight
      ? 500
      : !!isDesktop && !!isSmallerHeight
      ? 400
      : !!isDesktop && !!isSmallHeight
      ? 400
      : !!isDesktop && !!isBigHeight
      ? 400
      : !!isSmallDesktop && !!isSmallerHeight
      ? 400
      : !!isSmallDesktop && !!isSmallHeight
      ? 400
      : !!isSmallDesktop && !!isBigHeight
      ? 400
      : !!isBigDesktop && !!isSmallerHeight
      ? 400
      : !!isBigDesktop && !!isSmallHeight
      ? 400
      : !!isBigDesktop && !!isBigHeight
      ? 600
      : 600

  const [similarCar, setSimilarCar] = useState([])

  useEffect(() => {
    const getSimilarCar = async () => {
      const response = await fetch(`${MicroServices.GATEWAY}/vce/view/filterPosts?offset=0&limit=3`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          AccessControlAllowOrigin: '*',
          'auth-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify({
          condition: carData.condition,
          make: carData.vin.data.make,
        }),
      })
      const data = await response.json()
      // for (let i = 0; i < 4; i++) {
      //   data.listings.push({ ...data.listings[0] })
      // }
      console.log('_____________Similar Car______________')
      console.log(data)
      if (response.status === 200) {
        setSimilarCar(
          data.listings.map((post, i) => {
            post.images = post.images.map((img) => {
              let IMG = img.data.url
              return {
                original: IMG,
                thumbnail: IMG,
                originalHeight: '100px',
                originalWidth: '100px',
                thumbnailHeight: '100px',
                thumbnailWidth: '100px',
                originalClass: 'w-full h-full object-cover ',
                thumbnailClass: 'w-full h-full object-cover ',
              }
            })
            console.log(post)
            return post
          })
        )
      }
    }
    if (carData) getSimilarCar()
  }, [carData])

  const intl = useIntl()

  return (
    <div className=" w-[90%] xxl:w-[85%] mx-auto mt-10 lg:mt-5 sm:mt-2  sm:w-[92%] ">
      {!carData ? (
        // if no car data found
        <>
          {loading !== 'error' ? (
            <div className="flex justify-center items-center h-[80vh]">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="flex justify-center items-center h-[80vh]">
              <h1 className="text-2xl">No car found</h1>
            </div>
          )}
        </>
      ) : (
        <>
          {/* container  */}
          <div className=" p-0 flex xl:gap-x-5 xxl:gap-x-10 flex-row lg:flex-col sm:py-4 sm:px-0  w-[100%] ">
            {/* left */}
            <div className="w-full ">
              {/* image slider */}
              <div className=" sm:flex w-full lg:space-y-10 inline-block  sm:flex-col-reverse">
                {/* <div className="relative w-full   inline-block  ">
                  <ImageGallery
                    items={carData.images}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    renderLeftNav={renderleftNav}
                    renderRightNav={renderRightNav}
                    additionalClass="w-full h-full "
                  />
                </div> */}
                <div className={`w-full -lg:hidden h-[${height}px]    box-content`}>
                  <CarInfo
                    post={carData}
                    height={height}
                    condition={carData.condition}
                    transmission={carData.transmission}
                    className=" border-[#C0C0C0] border-[1px]"
                    title={carData.overview}
                    mileage={carData.odometer}
                    city={getCityLabel(carData.city, intl)}
                    price={carData.price}
                    status={carData.status}
                  />
                </div>
                <div className=" w-full h-full ">
                  <CarouselComp
                    post={carData}
                    className="w-full h-full flex justify-center items-center  box-border bg-center  pb-3 "
                    imgClassName={` w-full h-full flex justify-center items-center  object-cover bg-no-repeat bg-center `}
                    images={carData.images}
                    height={height}
                  />
                </div>
              </div>

              <div className=" lg:hidden">
                <Listingtabs
                  post={carData}
                  postID={id}
                  endUser={carData.endUser}
                  dealer={carData.endUser.dealer}
                  overview={carData.overview}
                  roof={carData.roof}
                  seating={carData.seating}
                  safety={carData.safety}
                  wheels={carData.wheels}
                  option={carData.option}
                  technologies={carData.technology}
                />
              </div>
            </div>
            {/* right */}
            <div className="w-[48%] lg:w-full md:my-5 h-full ">
              {/* vehicle details */}
              <div className={`w-full lg:hidden h-[${height}px] lg:pt-[1rem]   box-content`}>
                <CarInfo
                  post={carData}
                  height={height}
                  condition={carData.condition}
                  transmission={carData.transmission}
                  className=" border-[#C0C0C0] border-[1px]"
                  title={carData.overview}
                  mileage={carData.odometer}
                  city={getCityLabel(carData.city, intl)}
                  price={carData.price}
                  status={carData.status}
                />
              </div>
              {/* vehicle information */}
              <div className="w-[100%] bg-[#F0F0F0]   mT-14 p-8   sm:my-0 sm:px-2 mt-[10px] ">
                <h1 className="my-2 lg:text-2xl xl:text-3xl xxl:text-[30px] font-bold text-[#105E26] sm:text-3xl">
                  {intl.formatMessage({
                    id: 'listing_detail.vehicle.title',
                  })}
                </h1>
                <div className="  bg-[#105e26] border rounded border-[#105e26] my-2 h-1 w-20"></div>
                <ul className="w-full">
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.trim',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">{carData.vin.data.trim_level}</span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.made_in',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">{carData.vin.data.made_in}</span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.VIN',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">{carData.vin.data.vin}</span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.exterior',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">
                      {intl.formatMessage({
                        id: carData.exteriorColor.toLowerCase(),
                      })}
                    </span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.condition',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">
                      {intl.formatMessage({
                        id: carData.condition.toLowerCase(),
                      })}
                    </span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.gas',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">
                      {`${getNumMil(carData.vin.data.city_mileage, intl) ?? '?'} ${intl.formatMessage({
                        id: 'create_listing.right.city_mileage',
                      })} / ${getNumMil(carData.vin.data.highway_mileage, intl) ?? '?'} ${intl.formatMessage({
                        id: 'create_listing.right.highway_mileage',
                      })}`}
                    </span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.doors',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">4</span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.transmission',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">
                      {intl.formatMessage({
                        id: carData.transmission.toLowerCase(),
                      })}
                    </span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.drivetrain',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">
                      {intl.formatMessage({
                        id: carData.driveTrain.toLowerCase(),
                      })}
                    </span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.fuel',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">
                      {intl.formatMessage({
                        id: carData.fuelType.toLowerCase(),
                      })}
                    </span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.interior',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">
                      {intl.formatMessage({
                        id: carData.interiorColor.toLowerCase(),
                      })}
                    </span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.engine',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">{carData.vin.data.engine}</span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="text-[#747474] ">
                      {intl.formatMessage({
                        id: 'listing_detail.vehicle.body',
                      })}
                    </span>
                    <span className="font-semibold text-right ml-auto">{carData.vin.data.style}</span>
                  </li>
                  {/* <li className="flex justify-between py-4 ">
                    <span className="text-[#747474] ">Stock #</span>
                    <span className="font-semibold text-right ml-auto">2248623</span>
                  </li> */}
                </ul>
              </div>
              <div className=" -lg:hidden">
                <Listingtabs
                  post={carData}
                  postID={id}
                  endUser={carData.endUser}
                  dealer={carData.endUser.dealer}
                  overview={carData.overview}
                  roof={carData.roof}
                  seating={carData.seating}
                  safety={carData.safety}
                  wheels={carData.wheels}
                  option={carData.option}
                  technologies={carData.technology}
                />
              </div>
            </div>
          </div>

          {/* Buyer intelligence */}
          <div className="p-10 mt-8 pb-6 lg:px-0">
            <h1 className="text-center text-2xl pb-6 font-semibold">
              {intl.formatMessage({
                id: 'listing_detail.intelligence.title',
              })}
            </h1>
            <div className="bg-[#F0F0F0] flex justify-between p-8 my-8 rounded-md sm:flex-col ">
              <div className="border-r border-black flex flex-col justify-center items-center w-1/3 sm:w-full sm:border-r-0   sm:py-3 ">
                <div className="flex items-center space-x-2">
                  <h1 className="text-xl font-bold">
                    {carData?.dealRating?.abs?.PriceDifPercent
                      ? carData?.dealRating?.abs?.PriceDifPercent.split(' %')[0].split('.')[0] + '%'
                      : 'Unknown'}
                  </h1>
                  {parseInt(carData?.dealRating?.real?.PriceDifPercent.split(' %')[0]) <= 0 ? <TrendDown /> : <TrendUp />}
                </div>
                <span className=" lg:text-sm text-center">
                  {intl.formatMessage({
                    id:
                      parseInt(carData?.dealRating?.real?.PriceDifPercent.split(' %')[0]) <= 0
                        ? 'listing_detail.intelligence.lower'
                        : 'listing_detail.intelligence.higher',
                  })}
                </span>
              </div>
              <hr id="hr1" className="h-[1px] w-28 bg-black m-auto hidden sm:block" />
              <div className="border-r border-black flex flex-col justify-center items-center w-1/3 sm:w-full sm:border-r-0  sm:py-3  ">
                <div>
                  <h1 className="text-xl font-bold">{dayElapesed(carData.createdAt)}</h1>
                </div>
                <span className=" lg:text-sm text-center">
                  {intl.formatMessage({
                    id: 'listing_detail.intelligence.days',
                  })}
                </span>
              </div>
              <div id="hr2" className="h-[1px] w-28 bg-black m-auto hidden sm:block" />
              <div className="flex flex-col justify-center items-center w-1/3 sm:w-full  sm:py-3">
                <div className="flex items-center space-x-2 ">
                  <h1
                    className={clsx('text-xl font-bold ', {
                      'text-[#105E26]': carData.price - carData.originalPrice < 0,
                      'text-[#FF0000]': carData.price - carData.originalPrice > 0,
                    })}>
                    {carData.price - carData.originalPrice > 0 ? ' (+) ' : carData.price - carData.originalPrice == 0 ? '' : '(-)'}
                    {Math.abs(carData.price - carData.originalPrice) == 0 ? '--' : VNDshorten(Math.abs(carData.price - carData.originalPrice))}
                  </h1>
                  {/* <TrendDown /> */}
                </div>
                <span className=" lg:text-sm text-center">
                  {intl.formatMessage({
                    id: 'listing_detail.intelligence.change',
                  })}
                </span>
              </div>
            </div>
          </div>
          {/* 150-Point Inspection */}
          <div className="pb-6  sm:p-4 ">
            <h1 className="text-center text-2xl font-semibold">
              {intl.formatMessage({
                id: 'listing_detail.inspection.title',
              })}
            </h1>
            <p className="text-center text-[#747474] my-4">
              {carData?.inspection?.report
                ? intl.formatMessage({ id: 'listing_detail.inspection.description' })
                : intl.formatMessage({ id: 'listing_detail.inspection.missing' })}
            </p>
          </div>
          <div className="flex  justify-center items-center sm:flex-col my-2 md:px-20 sm:px-0  ">
            <div className="w-4/5 flex flex-col justify-center items-center space-y-5 ">
              <div className=" w-3/4 flex md:flex-col sm:justify-center sm:items-center ">
                <h1 className="font-semibold mr-2">
                  {intl.formatMessage({
                    id: 'listing_detail.inspection.model',
                  })}{' '}
                </h1>
                <span className="notranslate">{capitalizeFirstLetter(carData.vin.data.model)}</span>
              </div>
            </div>
            <div className="w-4/5 flex flex-col justify-center items-center space-y-5">
              <div className=" w-3/4 flex md:flex-col sm:justify-center sm:items-center  ">
                <h1 className="font-semibold mr-2">VIN </h1>
                <span className="notranslate">{carData.vin.data.vin}</span>
              </div>
            </div>
          </div>
          {carData?.inspection?.report?.answers ? (
            <div className="flex  justify-center my-8 md:flex-col ">
              <div className="w-[100%] md:w-full flex flex-col items-center space-y-5 md:my-4   sm:m-auto sm:mb-0">
                {inspectionList1.map((e, index) => {
                  return (
                    <div key={index} className="bg-[#F0F0F0] w-3/4 lg:w-[90%] lg:h-[75px] inline-flex rounded  justify-between items-center p-4 ">
                      <div className="font-bold sm:text-sm  w-full h-full">
                        {intl.formatMessage({
                          id: `listing_detail.inspection.${inspectionList1_key[index]}`,
                        })}
                      </div>
                      <div className="flex items-center ">
                        {carData?.inspection?.report?.answers[e]?.answer.toLowerCase() === 'failed' ? <Crossmark /> : <Tickmark />}{' '}
                        <span className="sm:text-sm ml-3 w-[75px]  text-center">
                          {carData?.inspection?.report?.answers[e]?.answer.toLowerCase() !== 'failed'
                            ? intl.formatMessage({
                                id: `listing_detail.inspection.passed`,
                              })
                            : intl.formatMessage({
                                id: `listing_detail.inspection.failed`,
                              })}{' '}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="w-[100%] md:w-full flex flex-col items-center space-y-5 md:my-4  sm:m-auto">
                {inspectionList2.map((e, index) => {
                  return (
                    <div key={index} className="bg-[#F0F0F0] w-3/4 lg:w-[90%] lg:h-[75px] inline-flex rounded  justify-between items-center p-4 ">
                      <div className="font-bold sm:text-sm  w-full h-full">
                        {intl.formatMessage({
                          id: `listing_detail.inspection.${inspectionList2_key[index]}`,
                        })}
                      </div>
                      <div className="flex items-center  ">
                        {carData?.inspection?.report?.answers[e]?.answer.toLowerCase() === 'failed' ? <Crossmark /> : <Tickmark />}{' '}
                        <span className="sm:text-sm ml-3 w-[75px]   text-center">
                          {carData?.inspection?.report?.answers[e]?.answer.toLowerCase() !== 'failed'
                            ? intl.formatMessage({
                                id: `listing_detail.inspection.passed`,
                              })
                            : intl.formatMessage({
                                id: `listing_detail.inspection.failed`,
                              })}{' '}
                        </span>
                      </div>
                    </div>
                  )
                })}
                <div className="bg-[#F0F0F0] w-3/4 lg:w-[90%] lg:h-[75px] inline-flex rounded  justify-between items-center p-4">
                  <div
                    onClick={() => {
                      navigate(`/completed-inpection/${id}`)
                      window.scrollTo(0, 0)
                    }}
                    className="text-[#105E26] font-bold sm:text-sm cursor-pointer">
                    {intl.formatMessage({
                      id: `listing_detail.inspection.10`,
                    })}
                  </div>
                  <div className="flex items-center ">
                    <SeeInspectionListIcon />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // NO INSPECTION CHECKLIST
            <div className="flex  justify-center my-8 md:flex-col ">
              <div className="w-[100%] md:w-full flex flex-col items-center space-y-5 md:my-4   sm:m-auto sm:mb-0">
                {inspectionList1.map((e, index) => {
                  return (
                    <div key={index} className="bg-[#F0F0F0] w-3/4 lg:w-[90%] lg:h-[75px] inline-flex rounded  justify-between items-center p-4 ">
                      <div className="font-bold sm:text-sm  w-full h-full">
                        {intl.formatMessage({
                          id: `listing_detail.inspection.${inspectionList1_key[index]}`,
                        })}
                      </div>
                      <div className="flex items-center ">
                        <QuestionMark />{' '}
                        <span className="sm:text-sm ml-3 w-[75px]   text-center">
                          {/* {intl.formatMessage({ id: 'listing_detail.inspection.unknown' })} */}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="w-[100%] md:w-full flex flex-col items-center space-y-5 md:my-4  sm:m-auto">
                {inspectionList2.map((e, index) => {
                  return (
                    <div key={index} className="bg-[#F0F0F0] w-3/4 lg:w-[90%] lg:h-[75px] inline-flex rounded  justify-between items-center p-4 ">
                      <div className="font-bold sm:text-sm  w-full h-full">
                        {intl.formatMessage({
                          id: `listing_detail.inspection.${inspectionList2_key[index]}`,
                        })}
                      </div>
                      <div className="flex items-center">
                        <QuestionMark />{' '}
                        <span className="sm:text-sm ml-3 w-[75px]   text-center">
                          {/* {intl.formatMessage({ id: 'listing_detail.inspection.unknown' })} */}
                        </span>
                      </div>
                    </div>
                  )
                })}
                <div className="bg-[#F0F0F0] w-3/4 lg:w-[90%] lg:h-[75px] inline-flex rounded  justify-between items-center p-4">
                  <div className="text-[#7a7a7a] font-bold sm:text-sm cursor-pointer">
                    {intl.formatMessage({
                      id: `listing_detail.inspection.11`,
                    })}
                  </div>
                  <div className="flex items-center ">
                    <SeeInspectionListIcon color="grey" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* END OF NO INSPECTION CHECKLIST */}

          <div className="p-6 my-5 px-0 ">
            <p className=" mb-14 text-center text-3xl font-semibold">
              {intl.formatMessage({
                id: 'listing_detail.similar_vehicles',
              })}
            </p>

            {/* display similar car not including the one being shown */}
            <Swiper
              spaceBetween={1} // adjust as needed
              slidesPerView={1} // default for mobile
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              breakpoints={{
                // Sm breakpoint
                640: {
                  slidesPerView: 1.5, // adjust this value as per your requirement for sm screens
                },
                // Md breakpoint
                768: {
                  slidesPerView: 2, // adjust this value as per your requirement for md screens
                },
                // Lg breakpoint
                1024: {
                  slidesPerView: 2.5, // adjust this value as per your requirement for lg screens
                },
                // Xl and 2xl breakpoint
                1336: {
                  slidesPerView: 3.5,
                },
                1920: {
                  slidesPerView: 4, // 5 slides per view for large desktop
                },
              }}
              className="draggable-list relative w-[105%]">
              {similarCar?.map((e, index) => (
                <SwiperSlide key={index}>
                  <CarCard
                    id={e._id}
                    images={e.images}
                    post={e}
                    mileage={e.odometer}
                    city={getCityLabel(e.city, intl)}
                    daysinmarket={dayElapesed(e.createdAt)}
                    dealType={e?.dealRating?.dealRating ?? 'Unknown'}
                    Icon={GoodDealIcon}
                    className="car-card w-full flex flex-col lg:flex-row md:flex-col lg:max-w-full box-border"
                  />
                </SwiperSlide>
              ))}
              <SwiperSlide key={Math.random * 10000}></SwiperSlide>
              <button className="swiper-button-prev custom-swiper-button">
                <ArrowRight fill="#000" /> {/* you can set fill prop to change arrow color */}
              </button>
              <button className="swiper-button-next custom-swiper-button">
                <ArrowLeft fill="#000" /> {/* you can set fill prop to change arrow color */}
              </button>
            </Swiper>
          </div>
        </>
      )}
    </div>
  )
}

export default Listing
