import React from "react";

function ComprehensiveReport({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00195 12.002C2.00252 14.3833 2.85242 16.6863 4.39892 18.4971C5.94541 20.3079 8.08707 21.5078 10.439 21.881V14.892H7.90195V12.002H10.442V9.80196C10.3859 9.28705 10.4438 8.7661 10.6118 8.27612C10.7797 7.78615 11.0535 7.33915 11.4136 6.96689C11.7737 6.59463 12.2114 6.30623 12.6956 6.12218C13.1797 5.93813 13.6985 5.86294 14.215 5.90196C14.965 5.91396 15.715 5.98096 16.455 6.10196V8.56096H15.191C14.9761 8.53282 14.7577 8.55332 14.5519 8.62094C14.346 8.68856 14.158 8.80157 14.0017 8.95163C13.8454 9.10168 13.7249 9.28494 13.6489 9.48785C13.573 9.69077 13.5436 9.90816 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C15.4506 21.5826 17.2132 20.7497 18.6421 19.4807C20.071 18.2118 21.1064 16.5599 21.6257 14.7208C22.145 12.8816 22.1264 10.9322 21.5721 9.10329C21.0179 7.27438 19.9512 5.64259 18.4983 4.4011C17.0455 3.15961 15.2673 2.36043 13.3743 2.09816C11.4814 1.8359 9.55287 2.12154 7.81721 2.92126C6.08154 3.72099 4.61137 5.00131 3.58076 6.61064C2.55015 8.21997 2.00226 10.0909 2.00195 12.002Z"
        fill={fill}
      />
    </svg>
  );
}

export default ComprehensiveReport;
