import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import './style.css'

export default function CheckAvailabilityModal({ post, open, handleClose }) {
  const endUser = useSelector((state) => state.user.data)
  console.log('MODAL POST: >>> ', post)
  const [formData, setFormData] = useState({ endUser: endUser, post: post, message: '' })
  const [sendCheckAvailabilityMessage] = useMutation({
    endPoint: APIEndpoints.SEND_CHECK_AVAILABILITY_MESSAGE.replace(':id', post._id),
    method: 'post',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    sendCheckAvailabilityMessage(formData, { headers: { 'Auth-Token': `${localStorage.getItem('accessToken')}` } })
      .then((res) => {
        // Clear the form
        setFormData({ message: '', sender: endUser })
        // toast.success('Email sent successfully.')
        handleClose()
      })
      .catch((error) => {
        // toast.error('Error occurred while sending the email.')
      })
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="modal absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white max-h-[90vh] overflow-auto w-3/6 p-12">
        <div className="my-5 xxl:my-8">
          <h1 className="text-3xl font-bold text-center xxl:text-[36px]">Check Availability</h1>
        </div>
        Inquiry {post.vin.data.year} {post.vin.data.make} {post.vin.data.model}
        <div className="my-8 xxl:my-12 sm:my-4">
          <textarea
            name="message"
            className="outline-none h-40 p-4 border-0 bg-[#F0F0F0] w-full rounded-md shadow"
            placeholder="+ Add Message"
            value={formData.message}
            onChange={handleChange}
            type="text"></textarea>
        </div>
        <div className="modalBtn my-8 xxl:my-12 mt-16">
          <button onClick={handleSubmit} className="bg-[#105E26] p-3 text-white w-1/2 sm:w-full rounded shadow-md shadow-[#105E26]">
            Send Message
          </button>
        </div>
      </div>
    </Modal>
  )
}
