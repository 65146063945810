import React from "react";

function PayPalIcon({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#105E26" />
      <path
        d="M29.6841 8.03571H14.9005C12.8927 8.03571 11.2502 9.67823 11.2502 11.6859L11.25 29.1769C11.25 31.1846 12.8925 32.8272 14.9002 32.8272H15.5391C15.4783 32.5229 15.4783 32.1883 15.4783 31.8537C15.4783 31.519 15.5086 31.2147 15.5391 30.8801H14.9002C13.9573 30.8801 13.1662 30.1197 13.1662 29.1461L13.1665 11.6861C13.1665 10.7432 13.9269 9.98269 14.9005 9.98269H29.6841C30.6271 9.98269 31.3876 10.7432 31.3876 11.6861V29.1771C31.3876 30.12 30.6271 30.9111 29.6841 30.9111H29.0452C29.1061 31.2154 29.1061 31.55 29.1061 31.8846C29.1061 32.219 29.1061 32.5233 29.0756 32.8273H29.7145C31.7222 32.8273 33.3647 31.1848 33.3647 29.1771V11.6861C33.3344 9.67839 31.6916 8.03587 29.6839 8.03587L29.6841 8.03571Z"
        fill="white"
      />
      <path
        d="M27.3728 13.0245H17.2128C16.6653 13.0245 16.2393 13.4503 16.2393 13.998C16.2393 14.5454 16.6651 14.9715 17.2128 14.9715H27.3423C27.8898 14.9715 28.3159 14.5457 28.3159 13.998C28.3462 13.4503 27.8898 13.0245 27.3727 13.0245H27.3728Z"
        fill="white"
      />
      <path
        d="M27.3728 17.8002H17.2128C16.6653 17.8002 16.2393 18.2261 16.2393 18.7738C16.2393 19.3212 16.6651 19.7473 17.2128 19.7473H27.3423C27.8898 19.7473 28.3159 19.3214 28.3159 18.7738C28.3462 18.2261 27.8898 17.8002 27.3727 17.8002H27.3728Z"
        fill="white"
      />
      <path
        d="M27.3728 22.576H17.2128C16.6653 22.576 16.2393 23.0019 16.2393 23.5496C16.2393 24.097 16.6651 24.5231 17.2128 24.5231H27.3423C27.8898 24.5231 28.3159 24.0972 28.3159 23.5496C28.3462 23.0019 27.8898 22.576 27.3727 22.576H27.3728Z"
        fill="white"
      />
      <path
        d="M22.2929 26.8043C19.4943 26.8043 17.2129 29.0857 17.2129 31.8843C17.2129 34.6828 19.4943 36.9642 22.2929 36.9642C25.0914 36.9642 27.3729 34.6524 27.3729 31.8539C27.3729 29.0554 25.0914 26.8043 22.2929 26.8043V26.8043ZM24.6656 31.428L22.2017 33.8919C22.0192 34.0744 21.8062 34.1656 21.5628 34.1656C21.3194 34.1656 21.1066 34.0744 20.9239 33.8919L19.7072 32.6753C19.3726 32.3406 19.3726 31.7626 19.7072 31.428C20.0418 31.0934 20.6198 31.0934 20.9544 31.428L21.5628 32.0363L23.388 30.2111C23.7226 29.8765 24.3007 29.8765 24.6353 30.2111C25.0306 30.5154 25.0306 31.0934 24.6656 31.428L24.6656 31.428Z"
        fill="white"
      />
    </svg>
  );
}

export default PayPalIcon;
