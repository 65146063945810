import React, { useState } from 'react'
import SearchIcon from 'assets/icons/searchicon'
const DropDownSearch = ({ placeholder = '...', handleOnChange }) => {
  const [query, setQuery] = useState('')

  const handleSetQuery = (e) => {
    setQuery(e.target.value)
    handleOnChange(e)
  }
  return (
    <div className="flex  items-center p-2  space-x-3   ">
      <SearchIcon fill="" />
      <input
        placeholder={placeholder}
        value={query}
        onChange={handleSetQuery}
        type="text"
        className="bg-transparent  border-b-[1px] border-gray-500 outline-none text-black overflow-x-clip   px-1 pt-2"
      />
    </div>
  )
}

export default DropDownSearch
