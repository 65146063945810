import React from 'react'
import { DeleteIcon } from '../../assets/icons/navbarIcons'

function SearchListing({ handleClick, handleDelete, children }) {
  return (
    <div className="w-full h-full" onClick={handleClick}>
      <li className="flex justify-between items-center w-full px-4 py-2  cursor-pointer transition-all duration-150 ease-in-out  bg-inherit hover:bg-gray-300   ">
        {children}

        {
          handleDelete && (
            <span onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              handleDelete()
            }}>
              <DeleteIcon />
            </span>
          )
        }
      </li>
    </div>
  )
}

export default SearchListing
