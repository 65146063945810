import { useEffect, useRef, useState } from 'react'
import RightDiv from './RightDiv'
import clsx from 'clsx'
import { setListing } from 'redux/listing'
import { fetchListingFees } from 'redux/options'
import { useDispatch, useSelector } from 'react-redux'
import { APIEndpoints, MicroServices } from 'staticStore'
import ToggleButton from './ToggleButton'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Close from '@mui/icons-material/Close'
import Btn from 'Components/Buttons/Btn'
import TextField from '@mui/material/TextField'
import UploadSection from 'Components/UploadSection'
import { useIntl } from 'react-intl'

const Step3 = ({ pendingListing, handleSubmit, loading }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const inputRef = useRef()
  const [priceDisplay, setPriceDisplay] = useState('')
  const [counter, setCounter] = useState(pendingListing.keys || 1)
  const [canSubmit, setCanSubmit] = useState(false)
  const images = pendingListing.images
  const listingFees = useSelector((state) => state.options.listingFees);

  useEffect(() => {
    // Check if price is set and at least one image is uploaded
    if (pendingListing.price > 0 && images.length > 0) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
    // setCanSubmit(true);
  }, [pendingListing.price, images])

  useEffect(() => {
    dispatch(fetchListingFees());
  }, [dispatch]);

  
  const modifiedHandleSubmit = async () => {
    if (listingFees && listingFees.amount < 1) {
      // If amount is less than 1, mark the car as paid
      try {
        const postId = pendingListing._id; // Assuming you have the pendingListing ID available
        const markAsPaidEndpoint = `${MicroServices.GATEWAY}${APIEndpoints.MARK_AS_PAID.replace(':id', postId)}`;
  
        const response = await fetch(markAsPaidEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Auth-Token': localStorage.getItem('accessToken'), // Include auth token if required
          },
        });
  
        const result = await response.json();
        if (response.ok) {
          console.log('Car marked as paid', result);
          // Implement the logic to skip Step 4 and 5
          // Perhaps navigate to the relevant step or update UI
        } else {
          console.error('Failed to mark car as paid', result);
          // Handle failure
        }
      } catch (error) {
        console.error('Error in marking car as paid', error);
        // Handle error
      }
    } else {
      // Proceed normally if amount is 1 or more
      handleSubmit();
    }
  };
  

  const handleChange = (obj) => {
    dispatch(setListing({ ...pendingListing, ...obj }))
  }

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = URL.createObjectURL(file)

      image.onload = () => {
        const canvas = document.createElement('canvas')
        let { width, height } = image

        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)

        canvas.toBlob(resolve, file.type)
      }

      image.onerror = reject
    })
  }

  const handleUploadFile = async (e) => {
    console.log('handleUploadFile', e)
    const id = pendingListing._id
    const uploadImagesEndPoint = `${MicroServices.GATEWAY}${APIEndpoints.UPLOAD_IMAGES}`.replace(':id', id)

    // Initialize FormData
    const formData = new FormData()

    // Iterate through the files (even if there's only one)
    for (let file of e.target.files) {
      const resizedImageBlob = await resizeImage(file)
      const resizedImageFile = new File([resizedImageBlob], file.name, { type: file.type })
      formData.append('post_listing_image', resizedImageFile) // Append resized images to formData
    }
    fetch(uploadImagesEndPoint, {
      method: 'PUT',
      headers: {
        'Auth-Token': `${localStorage.getItem('accessToken')}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('UPLOAD IMAGES', data)
        // Handle success
        dispatch(
          setListing({
            ...pendingListing,
            images: [...images, ...data.postListing.images],
          })
        )
      })
      .catch((error) => {
        console.error('Failed to upload images', error)
        // Handle failure
      })
  }

  const handleRemoveImage = (url) => {
    const updatedImages = pendingListing.images.filter((el) => el !== url)
    dispatch(setListing({ ...pendingListing, images: updatedImages }))
  }

  const handleCheckboxChange = (e, issueType) => {
    const checked = e.target.checked

    dispatch(
      setListing({
        ...pendingListing,
        issues: {
          ...pendingListing.issues,
          [issueType]: checked, // update the specific issue inside 'issues'
        },
      })
    )
  }

  const updatePendingListing = (property, value) => {
    dispatch(setListing({ ...pendingListing, [property]: value }))
  }

  // Increment the counter function
  const incrementCounter = () => {
    if (counter < 5) {
      setCounter(counter + 1)
      updatePendingListing('keys', counter + 1)
    }
  }

  // Decrement the counter function
  const decrementCounter = () => {
    if (counter > 1) {
      setCounter(counter - 1)
      updatePendingListing('keys', counter - 1)
    }
  }

  const renderCheckbox = (label, checked, onChange) => (
    <div>
      <FormControlLabel control={<Checkbox checked={checked} onChange={onChange} />} label={label} />
    </div>
  )

  return (
    // container
    <div className="flex space-x-3 sm:flex-col sm:space-x-0">
      <div className="rounded h-fit w-3/5 border-2  p-10 sm:w-full sm:p-8">
        {/* 3 buttons */}
        <div className="w-4/5 my-5 sm:w-full">
          <p className="my-2 sm:text-xs">
            {intl.formatMessage({
              id: 'create_listing.steps.3.title',
            })}
          </p>
          <h1 className="font-semibold my-4">
            {intl.formatMessage({
              id: 'vehicle_accidents',
            })}
          </h1>
          <div className="my-2 flex w-full space-x-4 sm:space-x-1">
            <div className="w-1/3" onClick={() => handleChange({ accidents: 0 })}>
              <button
                className={
                  pendingListing.accidents === 0
                    ? 'bg-[#105E26] text-white p-3 w-full font-semibold rounded sm:text-xs sm:p-1 sm:py-3'
                    : 'bg-[#f0f0f0] p-3 w-full text-[#105E26] font-semibold rounded sm:text-xs sm:p-1 sm:py-3'
                }>
                {intl.formatMessage({
                  id: 'vehicle_accidents.none',
                })}
              </button>
            </div>
            <div className="w-1/3" onClick={() => handleChange({ accidents: 1 })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.accidents === 1,
                })}>
                1
              </button>
            </div>
            <div className="w-1/3" onClick={() => handleChange({ accidents: 2 })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.accidents === 2,
                })}>
                2+
              </button>
            </div>
          </div>
        </div>
        {/* 2 buttons */}
        <div className="w-4/5 my-5 sm:w-full">
          <h1 className="font-semibold my-4">
            {intl.formatMessage({
              id: 'vehicle_drivable',
            })}
          </h1>
          <div className="my-2 flex w-full space-x-4 sm:space-x-1">
            <div className="w-1/3 sm:w-1/2" onClick={() => handleChange({ drivable: true })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.drivable === true,
                })}>
                {intl.formatMessage({
                  id: 'vehicle_drivable.yes',
                })}
              </button>
            </div>
            <div className="w-1/3 sm:w-1/2" onClick={() => handleChange({ drivable: false })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.drivable === false,
                })}>
                {intl.formatMessage({
                  id: 'vehicle_drivable.no',
                })}
              </button>
            </div>
          </div>
        </div>

        <div className="w-4/5 my-5 sm:w-full">
          <h1 className="font-semibold my-4">
            {intl.formatMessage({
              id: 'mechanical_electrical_issues',
            })}
          </h1>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'mechanical_electrical_issues.engine',
              }),
              pendingListing.issues.engine,
              (event) => handleCheckboxChange(event, 'engine')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'mechanical_electrical_issues.transmission',
              }),
              pendingListing.issues.transmission,
              (event) => handleCheckboxChange(event, 'transmission')
            )}
          </div>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'mechanical_electrical_issues.airConditioning',
              }),
              pendingListing.issues.airConditioning,
              (event) => handleCheckboxChange(event, 'airConditioning')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'mechanical_electrical_issues.electricalSystem',
              }),
              pendingListing.issues.electricalSystem,
              (event) => handleCheckboxChange(event, 'electricalSystem')
            )}
          </div>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'mechanical_electrical_issues.tirePressure',
              }),
              pendingListing.issues.tirePressure,
              (event) => handleCheckboxChange(event, 'tirePressure')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'mechanical_electrical_issues.noMechanicalAndElectricalIssues',
              }),
              pendingListing.issues.noMechanicalAndElectricalIssues,
              (event) => handleCheckboxChange(event, 'noMechanicalAndElectricalIssues')
            )}
          </div>
        </div>

        <div className="w-4/5 my-5 sm:w-full">
          <h1 className="font-semibold my-4">
            {intl.formatMessage({
              id: 'interior_damage',
            })}
          </h1>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'interior_damage.dashWarningLights',
              }),
              pendingListing.issues.dashWarningLights,
              (event) => handleCheckboxChange(event, 'dashWarningLights')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'interior_damage.interiorWarningLights',
              }),
              pendingListing.issues.interiorWarningLights,
              (event) => handleCheckboxChange(event, 'interiorWarningLights')
            )}
          </div>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'interior_damage.seatDamage',
              }),
              pendingListing.issues.seatDamage,
              (event) => handleCheckboxChange(event, 'seatDamage')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'interior_damage.acDamage',
              }),
              pendingListing.issues.acDamage,
              (event) => handleCheckboxChange(event, 'acDamage')
            )}
          </div>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'interior_damage.buttonsDamage',
              }),
              pendingListing.issues.buttonsDamage,
              (event) => handleCheckboxChange(event, 'buttonsDamage')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'interior_damage.noInteriorDamage',
              }),
              pendingListing.issues.noInteriorDamage,
              (event) => handleCheckboxChange(event, 'noInteriorDamage')
            )}
          </div>
        </div>

        <div className="w-4/5 my-5 sm:w-full">
          <h1 className="font-semibold my-4">
            {intl.formatMessage({
              id: 'exterior_damage',
            })}
          </h1>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'exterior_damage.faded',
              }),

              pendingListing.issues.fadedPaint,
              (event) => handleCheckboxChange(event, 'fadedPaint')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'exterior_damage.rust',
              }),

              pendingListing.issues.rust,
              (event) => handleCheckboxChange(event, 'rust')
            )}
          </div>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'exterior_damage.damage',
              }),

              pendingListing.issues.bodyDamage,
              (event) => handleCheckboxChange(event, 'bodyDamage')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'exterior_damage.dentOrScrapes',
              }),

              pendingListing.issues.dentOrScrapes,
              (event) => handleCheckboxChange(event, 'dentOrScrapes')
            )}
          </div>
          <div className="flex gap-6 justify-between w-full [&>*]:w-1/2">
            {renderCheckbox(
              intl.formatMessage({
                id: 'exterior_damage.windshieldCracksOrChips',
              }),

              pendingListing.issues.windshieldCracksOrChips,
              (event) => handleCheckboxChange(event, 'windshieldCracksOrChips')
            )}
            {renderCheckbox(
              intl.formatMessage({
                id: 'exterior_damage.noExteriorDamage',
              }),

              pendingListing.issues.noExteriorDamage,
              (event) => handleCheckboxChange(event, 'noExteriorDamage')
            )}
          </div>
        </div>

        <div className="w-4/5 my-5 sm:w-full">
          <h1 className="font-semibold my-4">
            {intl.formatMessage({
              id: 'smoked_in',
            })}
          </h1>
          <div className="my-2 flex w-full space-x-4 sm:space-x-1">
            <div className="w-1/3 sm:w-1/2" onClick={() => handleChange({ smokedIn: true })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.smokedIn === true,
                })}>
                {intl.formatMessage({
                  id: 'smoked_in.yes',
                })}
              </button>
            </div>
            <div className="w-1/3 sm:w-1/2" onClick={() => handleChange({ smokedIn: false })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.smokedIn === false,
                })}>
                {intl.formatMessage({
                  id: 'smoked_in.no',
                })}
              </button>
            </div>
          </div>
        </div>

        <div className="w-4/5 my-5 sm:w-full">
          <h1 className="font-semibold my-4">
            {intl.formatMessage({
              id: 'hasModifications',
            })}
          </h1>
          <div className="my-2 flex w-full space-x-4 sm:space-x-1">
            <div className="w-1/3 sm:w-1/2" onClick={() => handleChange({ hasModifications: true })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.hasModifications === true,
                })}>
                {intl.formatMessage({
                  id: 'hasModifications.yes',
                })}
              </button>
            </div>
            <div className="w-1/3 sm:w-1/2" onClick={() => handleChange({ hasModifications: false })}>
              <button
                className={clsx('bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3', {
                  '!text-white !bg-[#105E26] ': pendingListing.hasModifications === false,
                })}>
                {intl.formatMessage({
                  id: 'hasModifications.no',
                })}
              </button>
            </div>
          </div>
        </div>

        {/* Select Condition */}
        <div className="w-4/5 my-5 sm:w-full">
          <p className="my-2 sm:text-xs mb-4">
            {intl.formatMessage({
              id: 'create_listing.steps.3.condition_question',
            })}
          </p>
          <div className="my-2 flex w-full space-x-4 sm:space-x-1">
            <div className="w-1/3" onClick={() => handleChange({ conditionRating: 'like_new' })}>
              <ToggleButton
                text={intl.formatMessage({
                  id: 'like_new',
                })}
                isActive={pendingListing.conditionRating === 'like_new'}
              />
            </div>
            <div className="w-1/3" onClick={() => handleChange({ conditionRating: 'good' })}>
              <ToggleButton
                text={intl.formatMessage({
                  id: 'good',
                })}
                isActive={pendingListing.conditionRating === 'good'}
              />
            </div>
            <div className="w-1/3" onClick={() => handleChange({ conditionRating: 'fair' })}>
              <ToggleButton
                text={intl.formatMessage({
                  id: 'fair',
                })}
                isActive={pendingListing.conditionRating === 'fair'}
              />
            </div>
          </div>
        </div>

        {/* Counter UI */}
        <h1 className="my-2 font-bold">
          {intl.formatMessage({
            id: 'vehicle_keys',
          })}
        </h1>
        <div className="flex items-center">
          <Button onClick={decrementCounter} disabled={counter <= 1}>
            <RemoveIcon />
          </Button>
          <span>{counter}</span>
          <Button onClick={incrementCounter} disabled={counter >= 5}>
            <AddIcon />
          </Button>
        </div>

        <h1 className="font-bold">
          {intl.formatMessage({
            id: 'create_listing.steps.3.price',
          })}
        </h1>
        <TextField
          fullWidth
          variant="outlined"
          onChange={(e) => {
            // Remove any non-digit characters
            const numericString = e.target.value.replace(/\D/g, '')

            if (numericString === '') {
              updatePendingListing('price', 0)
              setPriceDisplay('')
              return
            }

            // Parse the result as a number
            const priceValue = parseInt(numericString, 10)

            // Format the numbers to include commas
            let formattedPriceValue = numericString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

            // Update the actual value in the Redux store
            updatePendingListing('price', priceValue)

            // Update the price display
            setPriceDisplay(formattedPriceValue)
          }}
          // Use the formatted odometer value for display
          value={priceDisplay}
        />

        <h1 className="pt-4 font-semibold">
          {intl.formatMessage({
            id: 'create_listing.steps.3.add_photos',
          })}
        </h1>
        <p className="my-2 sm:text-xs mb-4">
          {intl.formatMessage({
            id: 'create_listing.steps.3.photos_note',
          })}
        </p>
        {images.length ? (
          <div className="uploaded-images flex items-start">
            {images.map((url, index) => (
              <div className="relative" key={index}>
                <Close className="absolute top-3 right-3 cursor-pointer" onClick={() => handleRemoveImage(url)} />
                <img src={url.data.url} className="w-40 h-40 mr-3" />
              </div>
            ))}
            <div className="add-more" onClick={() => inputRef.current.click()}>
              <div className="add-more-icon"> + </div>
              <input type="file" ref={inputRef} style={{ display: 'none' }} multiple accept="image/*" onChange={handleUploadFile} />
              <div className="">
                {intl.formatMessage({
                  id: 'create_listing.steps.3.upload_more',
                })}
              </div>
            </div>
          </div>
        ) : (
          <UploadSection inputRef={inputRef} handleUploadFile={handleUploadFile} />
        )}

        <div className="w-full my-8">
          <Btn text={intl.formatMessage({ id: 'continue' })} loading={loading} disabled={!canSubmit} handleSubmit={modifiedHandleSubmit} />
        </div>
      </div>
      <RightDiv />
    </div>
  )
}

export default Step3
