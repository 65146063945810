import React, { useEffect } from 'react'
import { MicroServices, APIEndpoints } from 'staticStore'
import DynamicDropDown from '../DynamicDropDown'

export interface CustomDropDownPropTypes {
  searchParams: any
  setSearchParams: Function
}

const SearchResultDropDown: React.FC<CustomDropDownPropTypes> = ({
  searchParams,
  setSearchParams,
}) => {
  const [smallScreen, setSmallScreen] = React.useState(false)

  useEffect(() => {
    // detect when the screen size is below 1025px
    if (window?.innerWidth < 1025) {
      setSmallScreen(true)
    } else {
      setSmallScreen(false)
    }
  }, [])

  return (
    <>
      <DynamicDropDown
        title="MAKE"
        overrideTitle={false}
        enabledSearch={false}
        state={searchParams}
        setState={setSearchParams}
        deps={'model'}
        api={`${MicroServices.GATEWAY}${APIEndpoints.ALL_MAKES}`}
        property="makeName"
        className=" tracking-widest text-black md:text-xs md:tracking-wide "
      />

      {searchParams?.make && !smallScreen && (
        <DynamicDropDown
          title="MODEL"
          overrideTitle={false}
          enabledSearch={false}
          state={searchParams}
          setState={setSearchParams}
          api={`${MicroServices.GATEWAY}${
            APIEndpoints.FIND_MODELS_BY_MAKE
          }${searchParams.make && `make=${searchParams.make}`}`}
          cache={false}
          disabled={!searchParams.make}
          property="modelName"
          className=" tracking-widest text-black md:text-xs md:tracking-wide "
        />
      )}
    </>
  )
}

export default SearchResultDropDown
