import React from "react";

function RightArrowIcon({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="53"
      height="31"
      viewBox="0 0 53 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45547e-06 15.5041C0.0217524 16.0583 0.251189 16.6989 0.633392 17.1027L13.1042 30.2783C13.9491 31.0335 15.5698 31.3449 16.5141 30.4718C17.4443 29.6117 17.4166 27.9445 16.4902 27.0812L7.72184 17.8292L50.6616 17.8292C51.9529 17.8292 53 16.788 53 15.5041C53 14.2201 51.9529 13.1789 50.6616 13.1789L7.72184 13.1789L16.4902 3.92696C17.2883 3.13228 17.4356 1.40556 16.5141 0.536306C15.5931 -0.33353 13.9148 -0.0556503 13.1042 0.730374L0.633391 13.9059C0.20661 14.3568 -0.0010802 14.886 5.45551e-06 15.5045L5.45547e-06 15.5041Z"
        fill={fill}
      />
    </svg>
  );
}

export default RightArrowIcon;
