import React from "react";

const profileDropdownIcon = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9L0.803847 2.51244e-08L11.1962 -8.834e-07L6 9Z"
        fill="#464646"
      />
    </svg>
  );
};

export default profileDropdownIcon;
