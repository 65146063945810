import React, { useState } from 'react'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const Left = () => {
  const { id } = useParams()
  const [formData, setFormData] = useState({
    message: '',
  })

  const [sendCheckAvailabilityMessage] = useMutation({
    endPoint: APIEndpoints.SEND_CHECK_AVAILABILITY_MESSAGE.replace(':id', id),
    method: 'post',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    sendCheckAvailabilityMessage(formData, { headers: { 'Auth-Token': `${localStorage.getItem('accessToken')}` } })
      .then((res) => {
        console.log('Response:', res)
        // Assuming that the 'res' object has an 'ok' property for success
        if (res.status === 200) {
          console.log('SUCCESS')
          // toast.success("Email sent successfully!");

          // Clear the form
          setFormData({
            message: '',
          })
        }
      })
      .catch((error) => {
        console.error('Error submitting the form:', error)
        // toast.error("Error occurred while sending the email.");
      })
  }

  return (
    <div className=" p-10  my-3 rounded-xl border-0 bg-[#FFF]  text-black w-full sm:p-4 shadow-md">
      {/* Heading */}
      <div className="my-5 xxl:my-8  ">
        <h1 className="text-3xl font-bold text-center xxl:text-[36px]">Check Availability</h1>
      </div>

      {/* inputs */}

      <div className="my-8 xxl:my-12 sm:my-4   ">
        <textarea
          name="message"
          className="outline-none h-40  p-4 border-0 bg-[#F0F0F0]   w-full rounded-md shadow"
          placeholder="+ Add Message"
          value={formData.message}
          onChange={handleChange}
          type="text"></textarea>
      </div>

      {/* button */}
      <div className=" my-8 xxl:my-12  mt-16">
        <button onClick={handleSubmit} className="bg-[#105E26] p-3 text-white w-full mt-2 rounded shadow-md shadow-[#105E26]">
          Send Message
        </button>
      </div>
    </div>
  )
}

export default Left
