import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FindArrow } from 'assets/icons/HomeIcons'
import CrossIcon from '../../assets/icons/crossIcon.jsx'
import CarCard from 'Components/Cards/CarCard'
import { GoodDealIcon } from '../../assets/icons/pricingToolsIcon/dealIcon'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import TestinomialCarousel from 'Components/Carousel/TestinomialCarousel'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PriceDropDown from 'Components/dropdown/searchResultDropdown/PriceDropDown'
import MakeModelDropDown from 'Components/dropdown/searchResultDropdown/MakeModelDropDown'
import YearMileageDropDown from 'Components/dropdown/searchResultDropdown/YearMileageDropDown'
import { bodyType } from 'Components/dropdown/searchResultDropdown/BodyTypeDropDown'
import LocationDropDown from 'Components/dropdown/searchResultDropdown/LocationDropDown'
import SortDropDown from 'Components/dropdown/searchResultDropdown/SortDropDown'
import FilterItem from './FilterItem'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { ReactComponent as Location } from '../../assets/icons/location.svg'
import { ReactComponent as Sort } from '../../assets/icons/sort.svg'
import { ReactComponent as WriteReviewIcon } from '../../assets/icons/write-review.svg'
import { APIEndpoints, MicroServices } from 'staticStore'
import { SearchBar } from 'Components/Search'
import { getParams } from 'Utils/Search/Handler'
import { cleanQuery, GetSearchQuery, refactorQuery, translateSearchQuery } from 'Utils/Parse/Query'
import { DISTANCE_OPTIONS } from 'Resources/Enums/Search'
import DynamicDropDown from 'Components/dropdown/DynamicDropDown'
import { cityOptions, getCityLabel } from 'Resources/Data/cities'
import { dayElapesed } from 'Utils/Parse/Time'
import clsx from 'clsx'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'

import './style.css'
import { FormattedMessage, useIntl } from 'react-intl'
import { fetchUserWishlist } from 'redux/wishlist.js'
import { listingCarText } from 'Utils/Parse/Text.js'

const SearchResult = () => {
  const user = useSelector((state) => state.user?.data)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)
  const userIsAuthenticated = useRef(false)

  const [savedSearches, setSavedSearches] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserWishlist()).catch((error) => {
      console.error('Error fetching wishlist items:', error)
    })
  }, [dispatch])

  /**
   * @typedef {Object} SavedSearches
   * @property {string} _id
   * @property {string} user_id
   * @property {string} filter
   */
  /**
   *
   * @param {SavedSearches} data
   * @returns void
   */
  const updateSavedSearches = (data) => {
    setSavedSearches((prev) => {
      return [...prev, data]
    })
  }

  const handleSaveSearch = async () => {
    // if user is not logged in, redirect to login page
    if (!userIsAuthenticated.current) {
      navigate('/user-login')
    }
    // if user is logged in, save search
    const _params = refactorQuery(localSearchParams)?.toString()
    // we don't want to save the default params
    if (_params === 'condition=new' || _params === 'condition=used') {
      // toast.info('These are the default search parameters. Please change them to save a search.')
      return
    }

    const payload = {
      user_id: user._id,
      filter: _params,
    }

    await fetch(`${MicroServices.GATEWAY}${APIEndpoints.USER_SAVED_SEARCH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('accessToken'),
      },
      body: JSON.stringify(payload),
    })
      .then(async (res) => {
        const data = await res.json()
        if (res.ok) {
          updateSavedSearches(data)
        }
      })
      .catch((err) => {})
  }

  const getSavedSearches = async () => {
    if (!user?.id) return
    await fetch(`${MicroServices.GATEWAY}${APIEndpoints.USER_SAVED_SEARCH}/${user?.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('accessToken'),
      },
    }).then(async (res) => {
      const data = await res.json()
      if (res.ok) {
        setSavedSearches(data)
      }
    })
  }

  const deleteSavedSearch = async (item) => {
    await fetch(`${MicroServices.GATEWAY}${APIEndpoints.USER_SAVED_SEARCH}/${item?._id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('accessToken'),
      },
    }).then(async (res) => {
      const data = await res.json()
      if (res.ok) {
        setSavedSearches((prev) => {
          return prev.filter((e) => e._id !== item._id)
        })
      }
    })
  }

  // We are using this to hide the make and model dropdowns on small screens
  const [smallScreen, setSmallScreen] = React.useState(false)
  useEffect(() => {
    // detect when the screen size is below 1025px
    if (window?.innerWidth < 1025) {
      setSmallScreen(true)
    } else {
      setSmallScreen(false)
    }
  }, [])

  useEffect(() => {
    if (loggedIn) {
      userIsAuthenticated.current = true
    } else {
      userIsAuthenticated.current = false // Clear the local state
    }
  }, [loggedIn])

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  const { search } = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()

  const [localSearchParams, setLocalSearchParams] = useState({})
  const [searchBarText, setSearchBarText] = useState('')
  const [translatedSearchBarText, setTranslatedSearchBarText] = useState('')
  const [savedSearchQueryActive, setSavedSearchQueryActive] = useState(false)

  useEffect(() => {
    // When search params change, update the local SearchParams state
    getSavedSearches()

    const params = refactorQuery(search)
    if (params.get('condition') !== 'all' && params.get('condition') !== 'new' && params.get('condition') !== 'used') {
      params.set('condition', 'all')
      navigate(`?${params.toString()}`, { replace: true })
    }
    const cleanedQuery = refactorQuery(localSearchParams) // url params

    if (params.toString() === cleanedQuery.toString()) return
    setLocalSearchParams(cleanQuery(params))
  }, [search])

  useEffect(() => {
    // When local search Params change, update the search bar text
    const _params = refactorQuery(localSearchParams)
    console.log(GetSearchQuery(_params))
    setSearchBarText(GetSearchQuery(_params))
    setTranslatedSearchBarText(translateSearchQuery(_params, intl))
  }, [localSearchParams, intl])

  useEffect(() => {
    // if either of these change, we need to refresh the page
    if (Object.keys(localSearchParams).length === 0) return
    const params = refactorQuery(search)
    const cleanedQuery = refactorQuery(localSearchParams)
    // update url, without refreshing page, with new query params
    if (params.toString() !== cleanedQuery.toString()) {
      navigate(`?${cleanedQuery.toString()}`, { replace: true })
    }
  }, [localSearchParams.location, localSearchParams.distance, localSearchParams.condition])

  useEffect(() => {
    // We check if the initValue is part of a saved search query and update the savedSearchQueryActive state.
    if (!savedSearches?.length) return
    if (!searchBarText.length) return
    const _initValue = searchBarText
    const _savedSearches = savedSearches?.map((e) => {
      const _ = e?.filter?.toLowerCase()
      return GetSearchQuery(refactorQuery(_ ?? ''))
    })
    const _savedSearchQueryActive = _savedSearches?.includes(_initValue)
    setSavedSearchQueryActive(_savedSearchQueryActive)
  }, [localSearchParams, savedSearches, search])

  const handleOnClickSearch = (item) => {
    // navigate to search page with query params
    const params = getParams(item, localSearchParams)

    navigate(`/search-result?` + params, { replace: true })
  }

  const [page, setPage] = useState(1)
  const [skip, setSkip] = useState(0)
  const [lastPage, setLastPage] = useState(1)
  const onPageChange = (event, value) => {
    setPage(value)
    setSkip((value - 1) * 15)
  }

  const fetchListings = async () => {
    const res = await fetch(`${MicroServices.GATEWAY}/vce/view/filterPosts?limit=15&offset=${skip}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AccessControlAllowOrigin: '*',
        'auth-token': localStorage.getItem('accessToken'),
      },
      body: JSON.stringify({
        make: localSearchParams?.make,
        model: localSearchParams?.model,
        condition: localSearchParams?.condition === 'all' ? '' : localSearchParams?.condition,
        location: localSearchParams?.location,
        distance: localSearchParams?.distance,
        body: localSearchParams?.body,
        gear: localSearchParams?.gear,
        origin: localSearchParams?.origin,
        min: localSearchParams?.min,
        max: localSearchParams?.max,
        minYear: localSearchParams?.minYear,
        maxYear: localSearchParams?.maxYear,
        minMileage: localSearchParams?.minMileage,
        maxMileage: localSearchParams?.maxMileage,
      }),
    })
    const data = await res.json()

    return data
  }

  const [listings, setListings] = useState([])

  useEffect(() => {
    if (Object.keys(localSearchParams).length === 0) return

    fetchListings().then((data) => {
      setLastPage(data.lastPage)
      if (data.listings.length === 0) setListings([])

      if (data.listings.length > 0) {
        setListings(
          data.listings.map((post, i) => {
            console.log(
              'Search Result Listing View Detected',
              window.gtag('event', 'search_result_listing_view', {
                search_result_listing_view__dealerId: post.endUser._id,
                search_result_listing_view__search: GetSearchQuery(refactorQuery(localSearchParams)),
                search_result_listing_view__listingTitle: listingCarText(post),
              }),
              {
                search_result_listing_view__dealerId: post.endUser._id,
                search_result_listing_view__search: GetSearchQuery(refactorQuery(localSearchParams)),
                search_result_listing_view__listingTitle: listingCarText(post),
              }
            )

            post.images = post.images.map((img) => {
              let IMG = img.data.url
              return {
                original: IMG,
                thumbnail: IMG,
                originalHeight: '100px',
                originalWidth: '100px',
                thumbnailHeight: '100px',
                thumbnailWidth: '100px',
                originalClass: 'w-full h-full object-cover ',
                thumbnailClass: 'w-full h-full object-cover ',
              }
            })

            return post
          })
        )
      } else {
        setListings([])
      }
    })
  }, [localSearchParams, skip])

  const columnsOnLg = 2
  const columnsOnXl = 3
  const rowsOnLg = Math.ceil(listings.length / columnsOnLg)
  const rowsOnXl = Math.ceil(listings.length / columnsOnXl)

  let gridClass = ''
  if (listings.length <= columnsOnLg) {
    gridClass = 'lg:grid-cols-1'
  } else if (listings.length <= columnsOnXl) {
    gridClass = 'lg:grid-cols-2'
  } else {
    gridClass = 'lg:grid-cols-2 xl:grid-cols-3'
  }

  const [LocationDropdown, setLocationDropdown] = useState([])

  useEffect(() => {
    // console.log('LocationDropdown', LocationDropdown)
    setLocationDropdown(
      cityOptions.map((c) => {
        // console.log(c)

        if (intl.locale === 'vn') return { ...c, name: c.vnLabel }
        return { ...c, name: c.label }
      })
    )
    // console.log(
    //   'After LocationDropdown',
    //   cityOptions.map((c) => {
    //     console.log(c)

    //     if (intl.locale === 'vn') return { ...c, name: c.vnLabel }
    //     return { ...c, name: c.label }
    //   })
    // )
  }, [intl.locale])

  return (
    <div className=" m-auto flex flex-col w-[95%] p-5  sm:p-4 lg:px-2">
      {/* container */}
      <h1 className="font-bold   text-[#707070] mt-6 lg:order-1">{`${intl.formatMessage({ id: 'search_title' })} "${translatedSearchBarText}"`}</h1>
      <div className="w-full flex flex-col justify-center lg:order-3">
        <div className="my-4 md:my-0 w-inherit  bg-[#F0F0F0] rounded-xl  flex justify-between  sm:flex-col ">
          <div className=" w-full relative flex flex-row items-center text-center sm:w-full ">
            <SearchBar
              initValue={searchBarText}
              translatedInitValue={translatedSearchBarText}
              inputClassName="  w-full   sm:w-full "
              handleOnClickSearch={handleOnClickSearch}
              listClassName=" absolute w-full   block z-20 bg-white  rounded-xl shadow-lg  sm:mt-10"
              savedSearches={savedSearches}
              handleSaveSearchDelete={deleteSavedSearch}
            />
          </div>
          <div className=" w-[30%] justify-end pr-2   sm:w-full sm:border-none sm:justify-between flex items-center border-l-2 border-l-[#CDCDCD] space-x-2 table:w-[60%] pl-3 ">
            <div className="flex space-x-1 sm:hidden">
              {localSearchParams?.make && (
                <div
                  // on click remove make and model
                  onClick={() => {
                    setLocalSearchParams((prev) => {
                      return {
                        ...prev,
                        make: '',
                        model: '',
                      }
                    })
                  }}
                  className="cursor-pointer border border-[#CACACA] p-1  rounded-xl flex items-center space-x-1">
                  <span className="text-xs text-[#69A379]">{localSearchParams.make} </span>
                  <CrossIcon />
                </div>
              )}
              {localSearchParams?.model && (
                <div
                  // on click remove model
                  onClick={() => {
                    setLocalSearchParams((prev) => {
                      return {
                        ...prev,
                        model: '',
                      }
                    })
                  }}
                  className="cursor-pointer border border-[#CACACA] p-1  rounded-xl flex items-center space-x-1">
                  <span className="text-xs text-[#69A379]">{localSearchParams.model} </span>
                  <CrossIcon />
                </div>
              )}
              {localSearchParams?.gear && (
                <div
                  // on click remove model
                  onClick={() => {
                    setLocalSearchParams((prev) => {
                      return {
                        ...prev,
                        gear: '',
                      }
                    })
                  }}
                  className="cursor-pointer border border-[#CACACA] p-1  rounded-xl flex items-center space-x-1">
                  <span className="text-xs text-[#69A379]">{localSearchParams.gear} </span>
                  <CrossIcon />
                </div>
              )}
              {localSearchParams?.origin && (
                <div
                  // on click remove model
                  onClick={() => {
                    setLocalSearchParams((prev) => {
                      return {
                        ...prev,
                        origin: '',
                      }
                    })
                  }}
                  className="cursor-pointer border border-[#CACACA] p-1  rounded-xl flex items-center space-x-1">
                  <span className="text-xs text-[#69A379]">{localSearchParams.origin} </span>
                  <CrossIcon />
                </div>
              )}
            </div>
            <div onClick={handleSaveSearch} className="p-1   flex items-center space-x-1 sm:hidden cursor-pointer">
              {savedSearchQueryActive ? <FavoriteIcon className="text-[#105E26]" /> : <FavoriteBorderOutlinedIcon className="text-[#707070]" />}

              <span className={clsx(savedSearchQueryActive ? 'text-[#105E26]' : 'text-[#707070]', 'text-xs')}>
                {intl.formatMessage({
                  id: savedSearchQueryActive ? 'saved_search' : 'save_search',
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-2xl xl:text-[80%] menu  w-full  my-3  xxl:my-0  py-3 mt-0 rounded-md flex lg:order-2 justify-between items-center mx-0 px-1 sm:my-4 sm:px-3">
        <Box className=" flex-1   hidden lg:flex">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit">
            <MenuIcon />
          </IconButton>
          <Menu
            className="  "
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', xl: 'none' },
            }}>
            <MenuItem>
              <PriceDropDown setSearchParams={setLocalSearchParams} />
            </MenuItem>
            <MenuItem>
              <MakeModelDropDown searchParams={localSearchParams} setSearchParams={setLocalSearchParams} />
            </MenuItem>
            {localSearchParams?.make && smallScreen && (
              <MenuItem>
                <DynamicDropDown
                  title="MODEL"
                  overrideTitle={false}
                  enabledSearch={false}
                  state={localSearchParams}
                  setState={setLocalSearchParams}
                  api={`${MicroServices.GATEWAY}${APIEndpoints.FIND_MODELS_BY_MAKE}${localSearchParams.make && `make=${localSearchParams.make}`}`}
                  cache={false}
                  disabled={!localSearchParams.make}
                  property="modelName"
                  className=" tracking-widest text-black md:text-xs md:tracking-wide "
                />
              </MenuItem>
            )}
            <MenuItem>
              <DynamicDropDown
                overrideTitle={false}
                enabledSearch={false}
                key={'Body Dropdown Menu'}
                title={'BODY'}
                shown={10}
                state={localSearchParams}
                setState={setLocalSearchParams}
                optionProps={bodyType}
                className=" tracking-widest text-black md:text-xs md:tracking-wide "
                listClasses=" flex flex-row  "
              />
            </MenuItem>
            <MenuItem>
              <YearMileageDropDown setSearchParams={setLocalSearchParams} />
            </MenuItem>
            <MenuItem>
              <DynamicDropDown
                key={'Gear Dropdown Menu'}
                title={'GEAR'}
                state={localSearchParams}
                setState={setLocalSearchParams}
                overrideTitle={false}
                enabledSearch={false}
                optionProps={['Automatic', 'Manual', 'EV', 'Hybrid']}
                className=" tracking-widest text-black md:text-xs md:tracking-wide "
              />
            </MenuItem>
            <MenuItem>
              <DynamicDropDown
                key={'Origin Dropdown Menu'}
                title={'ORIGIN'}
                state={localSearchParams}
                setState={setLocalSearchParams}
                overrideTitle={false}
                enabledSearch={false}
                optionProps={['Import', 'Domestic']}
                className="tracking-widest text-black md:text-xs md:tracking-wide "
              />
            </MenuItem>
          </Menu>
        </Box>
        <div className=" w-full flex justify-around px-8  lg:hidden lg:px-2 lg:justify-evenly ">
          <PriceDropDown setSearchParams={setLocalSearchParams} />
          <MakeModelDropDown searchParams={localSearchParams} setSearchParams={setLocalSearchParams} />
          <DynamicDropDown
            key={'Body Dropdown'}
            title={'BODY'}
            shown={10}
            state={localSearchParams}
            setState={setLocalSearchParams}
            overrideTitle={false}
            enabledSearch={false}
            optionProps={bodyType}
            className=" tracking-widest text-black md:text-xs md:tracking-wide "
            listClasses=" flex flex-row  "
          />
          <YearMileageDropDown setSearchParams={setLocalSearchParams} />
          <DynamicDropDown
            key={'Gear Dropdown'}
            title={'GEAR'}
            state={localSearchParams}
            setState={setLocalSearchParams}
            overrideTitle={false}
            enabledSearch={false}
            optionProps={['Automatic', 'Manual', 'EV', 'Hybrid']}
            className="tracking-widest text-black md:text-xs md:tracking-wide "
            translate={true}
          />
          <DynamicDropDown
            key={'Origin Dropdown'}
            title={'ORIGIN'}
            state={localSearchParams}
            setState={setLocalSearchParams}
            overrideTitle={false}
            enabledSearch={false}
            optionProps={['Import', 'Domestic']}
            className="tracking-widest text-black md:text-xs md:tracking-wide "
            translate={true}
          />
        </div>
        <div>
          <div
            className="w-[200px] h-[60px] flex px-5  items-center text-sm xxl:text-md text-white bg-[#105E26] rounded-md md:w-[100px] duration-200 hover:bg-[#106F2B]    cursor-pointer"
            onClick={() => {
              // parse query params
              // navigate to search page with query params
              const params = refactorQuery(localSearchParams)
              navigate(`/search-result?${params.toString()}`)
            }}>
            <p className="flex-1 w-full">{intl.formatMessage({ id: 'find' })}</p>
            <div className="flex justify-end">
              <FindArrow className="sm:hidden" />
            </div>
          </div>
        </div>
      </div>
      <div className=" hidden  items-center mt-2 ">
        {/* <div> */}
        <div className="flex items-center flex-wrap">
          <FilterItem text="Price" />
          <FilterItem text="Make" />
          <FilterItem text="Body Type" />
        </div>
        {/* <FilterItem text="Gear" /> */}
        {/* </div> */}
        <div className="py-1 px-1 border-[1px] border-gray-400 mr-1 shadow-md text-gray-600 cursor-pointer flex items-center ml-auto sm:border-none sm:shadow-none">
          <BsThreeDotsVertical />{' '}
        </div>
        {/* <FilterItem text="Year & Millage" /> */}
      </div>
      <div className="mt-4 xxl:my-0 xxl:mt-8  flex lg:order-3 justify-end  w-full space-x-5 md:space-x-3  sm:space-x-0   sm:justify-between   -lg:mb-14 mb-8">
        {/* <div>
          <LocationDropDown
            title="DISTANCE"
            Sort={<p className="text-sm  text-[#747474]">~</p>}
            optProps={DISTANCE_OPTIONS}
            search={localSearchParams}
            setSearch={setLocalSearchParams}
          />
        </div> */}
        <div>
          <LocationDropDown
            title="LOCATION"
            Sort={
              <p className="text-sm  text-[#747474]">
                <Location className="mr-1" />
              </p>
            }
            optProps={LocationDropdown}
            search={localSearchParams}
            setSearch={setLocalSearchParams}
          />
        </div>
        <div>
          <SortDropDown
            title="CONDITION"
            Sort={<Sort className="mr-1 w-4" />}
            initCondition={localSearchParams.condition}
            search={localSearchParams}
            setSearch={setLocalSearchParams}
          />
        </div>
      </div>
      <div
        className={`search-results lg:order-3 grid gap-x-4 gap-y-4 ${gridClass} 
                    -lg:my-8 justify-between w-full sm:[&>div]:mb-4 sm:mb-5 sm:[&>div]:!mr-[0px]`}>
        {!listings?.length && (
          <div className="w-full col-span-full flex justify-center items-center">
            <p className="text-[#707070] text-2xl">{intl.formatMessage({ id: 'search.no_result' })}</p>
          </div>
        )}
        {listings?.map((e, index) => {
          console.log(e)
          return (
            <CarCard
              id={e._id}
              key={e._id}
              images={e.images}
              post={e}
              mileage={e.odometer}
              city={getCityLabel(e?.city, intl)}
              daysinmarket={e.daysOnMarket}
              dealType={e?.dealRating?.dealRating ?? 'Unknown'}
              Icon={GoodDealIcon}
              // autoscale width on multiple devices
              className={clsx('m-0')}
            />
          )
        })}
      </div>
      {!!listings?.length && (
        <div className="flex mt-9  py-4 justify-center m-auto lg:order-3 ">
          <Stack
            sx={{
              backgroundColor: '#F0F0F0',
              padding: { xs: '2px', md: '5px' },
              // paddingY: {sm:'5px',},
              borderRadius: '100px',
              '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': { backgroundColor: '#105e26', color: '#fff' },
              '& .MuiPaginationItem-text': { color: '#707070' },
              '& .MuiPaginationItem-root': { margin: 0, padding: 0 },
              '& .MuiButtonBase-root': {
                margin: { xs: 0, md: '4px' },
                padding: 0,
              },
              '& .MuiPaginationItem-sizeMedium': {
                margin: 0,
                padding: 0,
              },
              '& .MuiPaginationItem-circular': {
                minWidth: { xs: '28px', sm: '32px' },
                minHeight: { xs: '28px', sm: '32px' },
                margin: 0,
                padding: 0,
              },
              '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
                margin: 0,
                padding: 0,
              },
            }}
            spacing={0}>
            <Pagination count={lastPage} page={page} onChange={onPageChange} />
          </Stack>
        </div>
      )}
      <div className="mb-12 mt-12 w-full lg:order-3 ">
        <TestinomialCarousel />
        <button
          onClick={() => {
            // if user is not logged in, redirect to login page
            // else to leave-review
            if (!Object.keys(user).length && !loggedIn && !isLoading) {
              navigate('/user-login')
              return
            }

            navigate('/leave-review')
          }}
          className="bg-[#105E26] ml-auto mr-44 mt-4 font-semibold p-3 text-white w-[160px]  rounded shadow-md shadow-[#105E26] flex text-sm">
          <FormattedMessage id="reviews.write_a_review" />
          <WriteReviewIcon className="ml-auto" />
        </button>
      </div>
    </div>
  )
}

export default SearchResult
