import React, { useEffect, useState } from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import Btn from 'Components/Buttons/Btn'
import { setListing } from 'redux/listing'
import { RootState } from 'redux/store'
import Autocomplete from '@mui/material/Autocomplete'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { City } from 'Resources/Types/city'
import { FormattedMessage, useIntl } from 'react-intl'
import PendingListingProvider from './context'

import './style.css'
import { setUserData } from 'redux/user'
import { cityOptions } from 'Resources/Data/cities'
interface Ileft {
  pendingListing: any
}

interface CityOption {
  _id: string
  name: string
  label: string
  vnLabel: string
}

const ContinueLeft: React.FC<Ileft> = ({ pendingListing }) => {
  const navigate = useNavigate()

  const [show, setShow] = React.useState(false)
  const [makeModelText, setMakeModelText] = React.useState('')

  React.useEffect(() => {
    const vin = pendingListing?.vin
    if (!vin) return
    const make = vin?.data?.make
    const model = vin?.data?.model

    if (make) {
      setShow(true)
      setMakeModelText(`${make} ${model}`)
    }
  }, [pendingListing])
  return (
    <>
      {show && (
        <div
          onClick={() => {
            navigate(`/create-listing/${pendingListing?._id}`)
          }}
          className="continue-left">
          <FormattedMessage id="sell_my_car.continue" /> {makeModelText}?
        </div>
      )}
    </>
  )
}
interface I {
  pending: any
}
const Left: React.FC<I> = ({ pending }) => {
  const theme = useTheme()

  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({}))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [inputError, setInputError] = useState<string | null>(null)
  const [licensePlate, setLicensePlate] = useState('')
  const endUser = useSelector((state: RootState) => state.user?.data)
  const city = useSelector((state: RootState) => state.listing.pendingListing.city)
  const pendingListing = useSelector((state: RootState) => state.listing.pendingListing)
  const lang = useSelector((state: RootState) => state.options.language)
  const cities: City[] = useSelector((state: RootState) => state.options.cities)

  let vin = licensePlate
  let endUserData = {
    _id: endUser?._id,
    endName: endUser?.endEmail,
    endEmail: endUser?.endEmail,
  }
  let variables = { vin, endUser: endUserData, city, lang }

  const [createListing]: any = useMutation({
    endPoint: APIEndpoints.CREATE_LISTING,
    method: 'post',
  })

  const updatePendingListing = (property: string, value: any) => {
    console.log('Updating pending listing: ', property, value)
    dispatch(setListing({ [property]: value }))
  }

  useEffect(() => {
    // Clear the inputError whenever licensePlate changes
    if (inputError && licensePlate.length === 17) {
      setInputError(null)
    }
  }, [licensePlate, inputError])

  // if the vin doesn't exist, create a listing with the vin
  const handleSubmit = async () => {
    // Check VIN length here
    if (licensePlate.length !== 17) {
      setInputError('VIN Number should be 17 digits')
      return
    }

    if (inputError !== null) {
      console.log('Input Error Detected: ', inputError) // Log the error
      return
    }
    try {
      const res = await createListing(variables, {
        headers: {
          'Auth-Token': `${localStorage.getItem('accessToken')}`,
        },
      })
      if (res.error || !res.data) {
        console.error('Failed to create listing. Error Details: ', res.error)
        return
      }
      if (res.accessToken) {
        localStorage.setItem('accessToken', res.accessToken)
      }
      if (res.user) {
        console.log('dispatch setUserData: ', res.user)
        dispatch(
          setUserData({
            ...res.user,
            endName: res.user.endName,
            endEmail: res.user.endEmail,
            endImage: res.user.endImage,
            endPhone: res.user.endPhone,
            city: res.user.city,
            gender: res.user.gender,
          })
        )
      }
      const newPending = res.data
      // Add new pendingListing to local storage
      dispatch(setListing(newPending))
      navigate(`/create-listing/${newPending._id}`)
    } catch (error) {
      setInputError('VIN Verification failed.')
      return
    }
  }
  const license = pendingListing.licensePlate || licensePlate

  const intl = useIntl()

  return (
    <div className="lg:p-10 xl:p-20 my-3 border-0  z-20  text-black w-1/2 lg:w-full sm:p-4 flex flex-col justify-center xxl:p-20 xxl:py-5 relative">
      <ContinueLeft pendingListing={pending} />
      <div className="my-5  ">
        <div className="">
          <h1 className="text-4xl xl:text-[50px] xxl:text-[60px] leading-none font-bold  ">
            <FormattedMessage id="sell_my_car.headline" />
          </h1>
          <p className="text-[16px] text-[#707070]  my-2">
            <FormattedMessage id="sell_my_car.headline" />
          </p>
        </div>
      </div>
      <div className=" p-5  rounded-xl  shadow-[0px_0px_10px_rgba(0,0,0,0.3)]  max-w-lg  sm:px-1  ">
        <div className=" mt-2 xxl:mt-6  flex rounded-xl w-full  p-2 sm:flex-col sm:pb-10">
          <div className="w-3/5 sm:w-full sm:pb-4">
            <h1 className="font-bold sm:text-sm text-[28px] -lg:xl:text-[20px] lg:text-[14px]">VIN</h1>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              placeholder="ex. 4T4BF1FKXER340134"
              label={intl.formatMessage({
                id: 'sell_my_car.enter_vin',
              })}
              rows={1}
              multiline
              onChange={(ev) => setLicensePlate(ev.target.value)}
              value={license}
            />
            {inputError && <p className="text-red-500">{inputError}</p>}
          </div>

          <div className="w-2/5 ml-5 sm:ml-0 sm:w-full xl:h-[54px] xxl:h-[80px]">
            <h1 className="font-bold  sm:text-sm text-[28px] -lg:xl:text-[20px] lg:text-[14px] w-full">
              <FormattedMessage id="city" />
            </h1>
            <StyledAutocomplete
              className="w-full mb-5"
              disablePortal
              id="combo-box-demo"
              options={cityOptions}
              getOptionLabel={(option: unknown) => (intl.locale === 'vn' ? (option as CityOption).vnLabel : (option as CityOption).label)}
              value={cityOptions.find((c) => c.label === pendingListing.city || c.vnLabel === pendingListing.city) ?? null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: 'select',
                  })}
                />
              )}
              onChange={(_, value: unknown) => updatePendingListing('city', value ? (value as { label: String }).label : null)}
            />
          </div>
        </div>
        <div className="text-[#105E26] font-bold text-sm cursor-pointer margin-left">
          <FormattedMessage id="sell_my_car.what_is_my_vin" />
        </div>
        <div className="mt-2 xxl:mt-6  cursor-pointer">
          <Btn
            text={intl.formatMessage({
              id: 'sell_my_car.get_started',
            })}
            loading=""
            handleSubmit={handleSubmit}
            className=""
          />
        </div>
      </div>
    </div>
  )
}

export default Left
