import React from 'react'

const SliderLeftArrow = ({ classes, style, onClick, color = '' }) => {
  return (
    <svg
      width="35"
      height="54"
      viewBox="0 0 35 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      style={style}
      onClick={onClick}
    >
      <path
        d="M31 50L8 27L31 4"
        stroke="#105E26"
        className={color}
        strokeWidth="10"
      />
    </svg>
  )
}

export default SliderLeftArrow
