import React from 'react'
// import image from '../../../assets/images'
import {
  ListingIcon1,
  ListingIcon2,
  ListingIcon3,
  ListingIcon4,
  ListingIcon5,
  ListingIcon6,
} from 'assets/icons/Sellmycar'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const Right = () => {
  const navigate = useNavigate()

  return (
    <div className=" p-10   my-3 flex flex-col justify-center    text-black w-1/2 md:w-full relative">
      <div className="grid grid-cols-2 gap-4">
        <div className=" rounded-lg bg-[#E7E7E7] p-6 space-x-2 flex items-center">
          <ListingIcon1 />{' '}
          <p className="font-semibold">
            <FormattedMessage id="listing_process.benefits.1" />
          </p>
        </div>
        <div className=" rounded-lg bg-[#E7E7E7] p-6 space-x-2 flex items-center">
          <ListingIcon2 />{' '}
          <p className="font-semibold">
            <FormattedMessage id="listing_process.benefits.2" />
          </p>{' '}
        </div>
        <div className=" rounded-lg bg-[#E7E7E7] p-6 space-x-2 flex items-center">
          <ListingIcon3 />{' '}
          <p className="font-semibold">
            <FormattedMessage id="listing_process.benefits.3" />
          </p>{' '}
        </div>
        <div className=" rounded-lg bg-[#E7E7E7] p-6 space-x-2 flex items-center">
          <ListingIcon4 />{' '}
          <p className="font-semibold">
            <FormattedMessage id="listing_process.benefits.4" />
          </p>{' '}
        </div>
        <div className=" rounded-lg bg-[#E7E7E7] p-6 space-x-2 flex items-center">
          <ListingIcon5 />{' '}
          <p className="font-semibold">
            <FormattedMessage id="listing_process.benefits.5" />
          </p>{' '}
        </div>
        <div className=" rounded-lg bg-[#E7E7E7] p-6 space-x-2 flex items-center">
          <ListingIcon6 />{' '}
          <p className="font-semibold">
            <FormattedMessage id="listing_process.benefits.6" />
          </p>{' '}
        </div>
      </div>
      <br></br>
      <button
        onClick={() => {
          navigate(`/sell-my-car`)
          window.scrollTo(0, 0)
        }}
        className="bg-[#105E26] p-3 text-white w-1/2 mt-2 rounded shadow-md shadow-[#105E26]"
      >
        <FormattedMessage id="listing_process.list" />
      </button>
    </div>
  )
}

export default Right
