import React from 'react'
// import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Logo from '../../assets/images/pngLogo.png'
import People from '../../assets/images/people.png'
import LoginCar from '../../assets/images/logincar.png'
import './index.css'
import { useIntl } from 'react-intl'

const Right = () => {
  const intl = useIntl()
  return (
    <div className="       text-black w-full lg:p-12 md:p-4 sm:p-4 relative">
      <div className="rightDiv flex items-center justify-center mt-8">
        <img
          className="h-20 w-32 xxl:h-44 xxl:w-56 object-contain"
          src={Logo}
        />
        <h1 className="text-4xl xxl:text-[36px] font-semibold  lg:text-center md:text-2xl xxl:leading-snug  text-center p-10 py-5 lg:p-5 ">
          {' '}
          {intl.formatMessage({ id: 'login.welcome' })}
        </h1>
      </div>

      <div className=" md:h-64 -md:lg:h-[384px] -lg:h-[456px]   flex items-center   ">
        <img
          className=" absolute right-0 w-4/5 -md:w-full  "
          src={LoginCar}
        />
      </div>

      <div className=" md:my-8  flex items-center space-x-2 lg:flex-col justify-center  mdm:my-0  sm:space-x-0 sm:space-y-2 ">
        <div className="flex">
          <img
            className="h-10 w-10 rounded-full border-2 border-black "
            src={People}
          />
          <img
            className="h-10 w-10 rounded-full border-2 border-black -ml-2 "
            src={People}
          />
          <img
            className="h-10 w-10 rounded-full border-2 border-black -ml-2 "
            src={People}
          />
        </div>
        <p className="sm:text-xs xxl:text-lg lg:text-center">
          {' '}
          {intl.formatMessage({ id: 'login.invite' })}
        </p>
      </div>
    </div>
  )
}

export default Right
