import React from "react";

import { Review1,Review2 } from "assets/icons/dealerhome";
import reviewvideo from 'assets/images/reviewvideo.png'
const Left = () => {
  return (
    <div className="flex items-center rounded-xl border-0 w-1/2 md:w-full sm:p-6 ">
      
      <div className="p-10 sm:p-0 relative w-full h-full lg:flex lg:items-center ">


        <div className="p-10 lg:p-0   ">
          <img src={reviewvideo} className='xxl:w-full' alt="sleekride" />
        </div>
      
      <div className="absolute left-10  lg:left-5 top-0 lg:top-10         md:-top-3 sm:hidden"><Review1 /></div>
      <div className="absolute right-20 lg:right-10  bottom-0 lg:bottom-10 md:-bottom-3 sm:hidden"><Review2 /></div>
      
      </div>
    </div>
  );
};

export default Left;
