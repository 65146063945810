import { Transmission } from '../Types/common'

export const transmissions: Transmission[] = [
  {
    _id: '1',
    name: 'automatic',
    label: 'Automatic (AT)',
    translation: 'create_listing.automatic',
  },
  {
    _id: '2',
    name: 'manual',
    label: 'Manual (MT)',
    translation: 'create_listing.manual',
  },
  {
    _id: '3',
    name: 'continuous',
    label: 'Continuous (CVT)',
    translation: 'continuous',
  },
  {
    _id: '4',
    name: 'unknown',
    label: 'Unknown',
    translation: 'unknown',
  },
]
