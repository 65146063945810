import article1 from "../../assets/images/articleimgs/article1.jpg";
import article2 from "../../assets/images/articleimgs/article2.png";
import article3 from "../../assets/images/articleimgs/article3.jpg";
import article4 from "../../assets/images/articleimgs/article4.jpg";
import article5 from "../../assets/images/articleimgs/article5.jpg";
import article6 from "../../assets/images/articleimgs/article6.jpg";
import article7 from "../../assets/images/articleimgs/article7.jpg";
import article8 from "../../assets/images/articleimgs/article8.jpg";
import article9 from "../../assets/images/articleimgs/article9.jpg";
import article10 from "../../assets/images/articleimgs/article10.jpg";
import article11 from "../../assets/images/articleimgs/article11.jpg";
import article12 from "../../assets/images/articleimgs/article12.jpg";
import article13 from "../../assets/images/articleimgs/article13.jpg";
import article14 from "../../assets/images/articleimgs/article14.jpg";
import article15 from "../../assets/images/articleimgs/article15.jpg";
import article16 from "../../assets/images/articleimgs/article16.jpg";
import article17 from "../../assets/images/articleimgs/article17.jpg";
import article18 from "../../assets/images/articleimgs/article18.png";
import article19 from "../../assets/images/articleimgs/article19.jpg";
import article20 from "../../assets/images/articleimgs/article20.jpg";
import article21 from "../../assets/images/articleimgs/article21.jpg";
import article22 from "../../assets/images/articleimgs/article22.png";
import article23 from "../../assets/images/articleimgs/article23.jpg";
import article24 from "../../assets/images/articleimgs/article24.jpg";
import article25 from "../../assets/images/articleimgs/article25.png";
import article26 from "../../assets/images/articleimgs/article26.png";
import article27 from "../../assets/images/articleimgs/article27.png";
import article28 from "../../assets/images/articleimgs/article28.png";
import article29 from "../../assets/images/articleimgs/article29.png";
import article30 from "../../assets/images/articleimgs/article30.png";
import article31 from "../../assets/images/articleimgs/article31.png";
import article32 from "../../assets/images/articleimgs/article32.png";
import article33 from "../../assets/images/articleimgs/article33.png";
import article34 from "../../assets/images/articleimgs/article34.png";
import article35 from "../../assets/images/articleimgs/article35.png";
import article36 from "../../assets/images/articleimgs/article36.png";
import article37 from "../../assets/images/articleimgs/article37.png";
import article38 from "../../assets/images/articleimgs/article38.png";
import article39 from "../../assets/images/articleimgs/article39.png";

import quotation1 from "../../assets/images/articleimgs/quotation1.png";
import quotation2 from "../../assets/images/articleimgs/quotation2.png";
import quotation3 from "../../assets/images/articleimgs/quotation3.png";
import quotation4 from "../../assets/images/articleimgs/quotation4.png";
import quotation5 from "../../assets/images/articleimgs/quotation5.png";
import quotation6 from "../../assets/images/articleimgs/quotation6.png";
import quotation7 from "../../assets/images/articleimgs/quotation7.png";

import { Yt, Fb, Tiktok, Twitter } from "assets/icons/FooterIcons";
import advertise1 from "../../assets/images/advertising/advertise1.jpg";
import advertise2 from "../../assets/images/advertising/advertise2.jpg";
import { useParams } from "react-router-dom";
const listArticleDum = [
  {
    id: "64da0cda401ccaa8f7fe7d43",
    img: [article1],
    adBanner: [advertise1, advertise1, advertise2],
    htmlTemplate: (imgArray) => (
      <>
        <h2 className="text-3xl leading-10 my-8">
          THỦ TỤC SANG TÊN ĐỔI CHỦ XE Ô TÔ THEO QUY ĐỊNH MỚI NHẤT
        </h2>
        <p className="my-4">
          Cùng với sự phát triển sôi động của thị trường mua bán xe hơi cũ trong
          những năm trở lại đây, theo đó quy trình sang tên đổi chủ cũng trở
          thành chủ đề mà nhiều độc giả của Sleekride đặc biệt quan tâm. Trong
          nội dung bài viết này, chúng tôi sẽ cập nhật tới quý độc giả những
          thông tin thiết thực nhất theo pháp luạt hiện hành năm 2023
        </p>
        <p className="my-4">
          Theo quy định của Pháp Luật hiện hành, thủ tục sang tên đổi chủ xe ô
          tô cũ được chia thành hai trường hợp: “Sang tên xe ô tô cùng tỉnh và
          khác tỉnh. Để tránh rủi ro liên quan thì bạn cần thực hiện các thủ tục
          sang tên theo quy định của pháp luật. Theo Thông tư 58/2020/TT-BCA, để
          đảm bảo các thủ tục được thực hiện thuận lợi và nhanh chóng, chủ
          phương tiện mới cần chuẩn bị hồ sơ sang tên xe ô tô với đày đủ các
          giầy tờ sau:
        </p>
        <p className="my-4">- Tờ khai đăng ký sang tên đổi chủ xe theo mẫu;</p>
        <p className="my-4">- Giấy tờ tùy thân của chủ xe mới;</p>
        <p className="my-4">- Giấy chứng nhận đăng ký xe ô tô;</p>
        <p className="my-4">
          - Hợp đồng mua bán xe xe ô tô cũ đã được công chứng hoặc chứng thực
          theo quy định của pháp luật;
        </p>
        <p className="my-4">
          - Chứng từ nộp lệ phí trước bạ: Biên lai nộp tiền vào ngân sách nhà
          nước bằng tiền mặt, séc hoặc ủy nhiệm chỉ của ngân hàng hợp lệ;
        </p>
        <p className="my-4">
          - Hồ sơ góc của xen(Trong trường hợp sang tên khác tỉnh thành).
        </p>
        <p className="my-4 underline font-semibold	">Thủ tục sang tên xe ô tô</p>
        <p className="my-4 font-semibold	">Thủ tục sang tên xe khi cùng tỉnh</p>
        <p className="my-4">
          Nếu hộ khẩu thường trú của người bán và người mua (người chuyển nhượng
          và người được chuyển nhượng) cùng tỉnh/thành, quy trình thủ tục sang
          tên xe ô tô cùng tỉnh thủ tục sang tên đổi chủ xe ô tô khá đơn giản,
          chỉ gồm 5 bước:
        </p>
        <p className="my-4">
          Bước 1: Làm hợp đồng và công chứng hợp đồng mua bán xe ô tô.
        </p>
        <p className="my-4">
          Bước 2: Nộp lệ phí trước bạ xe ô tô cũ theo quy định.
        </p>
        <p className="my-4">
          Bước 3: Nộp hồ sơ đăng ký sang tên xe tại Phòng Cảnh sát giao thông
          Công an tỉnh, thành phố trực thuộc Trung ương.
        </p>
        <p className="my-4">Bước 4: Nộp lệ phí đăng ký xe theo quy định.</p>
        <p className="my-4">
          Bước 5: Nhận giấy hẹn lầy Giầy chứng nhận đăng ký xe mới.
        </p>
        <p className="my-4">
          Thông thường, khi chuyển nhượng, mua bán xe cũ cùng tỉnh, biển kiểm
          soát xe vẫn được giữ như cũ, chỉ có thông tin về chủ sở hữu xe được
          thay đổi trên Giầy đăng ký xe.
        </p>
        <p className="my-4 font-semibold">Thủ tục sang tên xe khi khác tỉnh</p>
        <p className="my-4">
          Đồi với xe đã qua sử dụng được chuyển nhượng, mua bán lại thì quy
          trình thủ tục sang tên xe ô tô khác tỉnh có phần phức tạp hơn. Cả hai
          bên phải thực hiện theo quy trình như sau:
        </p>
        <p className="my-4">
          Bước 1: Ký kết và công chứng hợp đồng mua bán xe ô tô.
        </p>
        <p className="my-4">
          Bước 2: Nộp lệ phí trước bạ áp dụng cho ô tô cũ theo quy định.
        </p>
        <p className="my-4">
          Bước 3: Rút hồ sơ gốc của xe ô tô tại Phòng Cảnh sát giao thông Công
          an tỉnh/thành nơi người bán đăng ký.
        </p>
        <p className="my-4">
          Bước 4: Nộp hồ sơ đăng ký sang tên xe tại Phòng Cảnh sát giao thông
          Công an tỉnh/thành nơi người mua đăng ký thường trú.
        </p>
        <p className="my-4">
          Bước 6: Bốc và nhận biển số mới tại chỗ, nhận giấy hẹn lầy Giấy chứng
          nhận đăng ký xe.
        </p>
        <p className="my-4">
          Bước 7: Sau khi nhận Giấy chứng nhận đăng ký xe, chủ xe tiền hành thủ
          tục đăng kiểm xe (làm sẻ đăng kiểm) theo biển số mới.
        </p>
        <p className="my-4">
          Trong trường hợp không thể sắp xếp thời gian hoặc đang cơ trú tại địa
          phương khác với tỉnh, thành đăng ký hộ khẩu thường trú, chủ sở hữu xe
          có thể ủy quyền cho người thân hoặc đơn vị cung cấp dịch vụ sang tên
          xe ô tô thay mặt xử lý hồ sơ sang tên theo quy định.
        </p>
        <p className="my-4 font-semibold">
          Thủ tục sang tên xe ô tô từ công ty sang cá nhân hoặc công ty khác
        </p>
        <p className="my-4">
          Thủ tục sang tên xe từ công ty cho cá nhân hoặc công ty khác cũng
          tương tự thủ tục sang tên từ cá nhân cho cá nhân. Tuy nhiên, người mua
          hoặc người nhận chuyển nhượng cần bỏ sung một số giầy tờ sau:
        </p>
        <p className="my-4">
          - Hóa đơn đỏ và hóa đơn theo mẫu của công ty tự in;
        </p>
        <p className="my-4">
          - Quyết định thanh lý xe có đầy đủ chữ ký của Giám đốc hoặc người có
          thẳm quyền;
        </p>
        <p className="my-4">
          - Hợp đồng mua bán xe đã được công chứng và chữ ký của Giám đốc hoặc
          người được ủy quyền:
        </p>
        <p className="my-4">- Biên bản đăng ký mẫu con dầu của công ty;</p>
        <p className="my-4">- Giấy phép đăng ký kinh doanh;</p>
        <p className="my-4">
          - Giấy giới thiệu của người trực tiếp làm thủ tục sang tên, có đóng
          dầu và chữ ký hợp lệ.
        </p>
        <p className="my-4">- Lệ phí đăng ký sang tên xe</p>
        <p className="my-4">
          Khi nộp hồ sơ đăng ký sang tên xe ô tô, người đăng ký xe phải nộp một
          khoản lệ phí theo quy định của pháp luật. Cụ thể theo quy định tại
          Thông tư số 229/2016/TT-BTC, chỉ phí sang tên xe ô tô cũ được quy định
          như sau:
        </p>
        <p className="my-4">
          - Trường hợp cấp lại giấy đăng ký không kèm biển số (cùng tỉnh):
          30.000 đồng/lần/xe.
        </p>
        <p className="my-4">
          - Trường hợp cấp lại giấy đăng ký kèm biển số mới: 150.000
          đồng/làn/xe.
        </p>
        <p className="my-4 font-semibold">
          Xe ô tô không sang tên đổi chủ có bị phạt không?
        </p>
        <p className="my-4">
          Căn cứ vào Nghị định 100/2019/NĐ-CP về quy định xử phạt hành chính
          trong lĩnh vực giao thông đường bộ và đường sắt, chủ phương tiện chưa
          làm thủ tục đăng ký xe hoặc đăng ký sang tên xe theo quy định khi mua,
          được cho, được tặng, được phân bổ, được điều chuyển, được thừa kế tài
          sản sẽ bị áp dụng xử phạt như sau:
        </p>
        <p className="my-4">
          Khoản 4 và Khoản 7 Điều 30 quy định mức xử phạt chủ phương tiện vi
          phạm quy định liên quan đến giao thông đường bộ, cụ thể:
        </p>
        <p className="my-4">
          Phạt tiền từ 2.000.000 đồng đền 4.000.000 đồng đối với cá nhân, từ
          4.000.000 đồng đến 8.000.000 đồng đối với tổ chức là chủ xe ô tô, máy
          kéo, xe máy chuyên dùng và các loại xe tương tự xe ô tô không làm thủ
          tục đăng ký sang tên xe (để chuyển tên chủ xe trong Giấy đăng ký xe
          sang tên của mình) theo quy định khi mua, được cho, được tặng, được
          phân bồ, được điều chuyển, được thừa kế tài sản là xe ô tô, máy kéo,
          xe máy chuyên dùng, các loại xe tương tự xe ô tô;
        </p>
        <p className="my-4">
          Phạt tiền từ 400.000 đồng đến 600.000 đồng đối với cá nhân, từ 800.000
          đồng đến 1.200.000 đồng đối với tổ chức là chủ xe mô tô, xe gắn máy và
          các loại xe tương tự xe mô tô không làm thủ tục đăng ký sang tên
          chuyển nhượng xe (để chuyển tên chủ xe trong Giấy đăng ký xe sang tên
          của mình) theo quy định khi mua, được cho, được tặng, được phân bồ,
          được điều chuyển, được thừa kế tài sản là xe mô tô, xe gắn máy, các
          loại xe tương tự xe mô tô;
        </p>
        <p className="my-4 font-semibold">Phạt bổ sung:</p>
        <p className="my-4">
          Thực hiện hành vi vi phạm quy định tại điểm b khoản 4; điểm e, điểm g
          khoản 5; điểm c, điểm d, điểm l điểm m khoản 7. Điều này buộc phải làm
          thủ tục đăng ký sang tên, đổi lại, thu hồi giấy đăng ký xe, biển số
          xe, giấy chứng nhận kiểm định an toàn kỹ thuật và bảo vệ môi trường
          theo quy định (trừ trường hợp bị tịch thu phương tiện).
        </p>
      </>
    ),
  },
  {
    id: "64da0cda401ccaa8f7fe7d44",
    img: [article2, article4, article5, article6],
    adBanner: [advertise1, advertise1, advertise2],
    htmlTemplate: (imgArray) => (
      <>
        <h2 className="text-3xl leading-10 my-8">
          KINH NGHIỆM KHI MUA XE HƠI CŨ TRẢ GÓP 2023
        </h2>
        <p className="my-4">
          Sự nở rộ của thị trường mua bán xe ô tô cũ trong những năm trở lại đây
          khiến cho việc sở hữu một chiếc xe hơi “đầu tay” không còn là khó khăn
          quá lớn. Cùng với sự phát triển này, các ngân hàng hiện nay hầu hết
          đều có những chính sách hỗ trợ trả góp cho khách hàng mua xe ô tô cũ
          với. nhiều ưu điểm vượt trội. Không cần phải xuống ngay một số tiền
          quá lớn vẫn có thể sở hữu ngay một chiếc xe “mơ ước”. Tuy nhiên, đừng
          bỏ qua bài viết này nều bạn không muốn bỏ lỡ những kinh nghiệm bỏ túi
          hữu ích!
        </p>
        <img src={imgArray[1]} className="w-full"></img>
        <p className="my-4">
          Có thể nói, trước nhu cầu mua xe ô tô cũ trả góp ngày càng lớn của thị
          trường, nhiều ngân hàng đã cho ra đời những sản phẩm cho vay vốn mua
          xe ô tô đã qua sử dụng với những ưu đãi không thua kém gì so với mua
          xe ô tô mới. Các ngân hàng hiện nay đang có chính sách cho vay tối đa
          lên đến 70-75% giá trị chiếc xe, thời hạn cho vay có thể lên tới tôi
          đa là 8 năm với thủ tục khá nhanh gọn. So với việc mua xe mới, thì mua
          xe cũ trả góp sẽ giúp người tiêu dùng bỏ ra số vốn ban đầu ít hơn, và
          thủ tục để sở hữu xe cũng nhanh gọn hơn chỉ cần làm thủ tục sang tên
          đổi chủ thay vì phải làm đăng ký, đăng kiểm, bảo hiểm vv... Lãi suất
          ưu đãi tuy thuộc vào chính sách của mỗi ngân hàng từng chương trình,
          từng thời điểm. Vậy nên mầu chốt để có thể mua xe ô tô cũ trả góp là
          phải lựa chọn được xe phù hợp, đảm bảo điều kiện vay vốn ngân hàng, và
          lựa chọn được ngân hàng có chương trình trả góp với lãi suất hợp lý
          nhất.
        </p>
        <img src={imgArray[2]} className="w-full"></img>
        <p className="my-4 font-semibold	">
          Kinh nghiệm chọn xe đảm bảo điều kiện vay vốn ngân hàng
        </p>
        <p className="my-4">
          Lưu ý đầu tiên khi mua xe ô tô cũ trả góp chính là phải lựa chọn được
          chiếc xe đảm bảo điều kiện vay vốn của ngân hàng. Vì khi mua ô tô trả
          góp các ngân hàng thường chỉ hỗ trợ trả góp với những đời xe không quá
          cũ và thời gian từ khi xuất xưởng đến khi vay trả góp không quá 6 năm.
          Ngoài ra với mỗi loại xe khác nhau thì cũng có từng chính sách mua trả
          góp khác nhau, xe càng cũ thì thời gian vay vồn càng ngắn.
        </p>
        <p className="my-4">
          Ngân hàng cũng quy định rõ về giá trị của xe ô tô cũ, chỉ hỗ trợ cho
          vay trả góp với những xe đủ điều kiện về năm sản xuất cũng như có giá
          trị tồi thiểu 250 triệu.
        </p>
        <p className="my-4">
          Việc nắm rõ các quy trình, thủ tục mua xe ô tô cũ trả góp cũng rất
          quan trọng. Thông thường, quy trình mua xe ô tô cũ trả góp cũng giống
          với thủ tục mua xe ô tô mới. Các tổ chức tín dụng hoặc ngân hàng sẽ
          trở thành bên thứ 3 giúp bạn thanh toán tiền mua xe theo nhu cầu và
          hợp đồng của khách hàng. Khi bên mua và bên bán xe ô tô cũ đã thỏa
          thuận xong và ký kết các hợp đồng pháp lý, cũng như bên mua đáp ứng đủ
          điều kiện vay trả góp của ngân hàng. Thì ngân hàng sẽ tiền hành giải
          ngân trực tiếp vào tài khoản ngân hàng của bên bán xe ô tô cũ.
        </p>
        <p className="my-4">
          Lãi suất khi mua xe ô tô cũ trả góp sẽ được ngân hàng cho vay quyết
          định. Việc tìm hiểu kỹ về lãi suất mua xe ô tô cũ trả góp sẽ giúp bạn
          có thể lựa chọn được gói vay trả góp với lãi suất thấp nhất, từ đó
          tiết kiệm được chỉ phí mua xe. Hiện nay, lãi suất khi mua xe trả góp
          sẽ dao động từ 7,5% - 8,5%/năm với gói vay 12 tháng và với gói vay 6
          tháng sẽ là 6,5% — 7,5%/năm.
        </p>
        <p className="my-4">
          Một lưu ý quan trọng khác mà nhiều người hay bỏ qua, đó là hạn mức vay
          khi mua xe ô tô trả góp. Thông thường các ngân hàng sẽ hỗ trợ hạn mức
          vay tư 65-70% giá trị của xe khi mua xe ô tô cũ trả góp. Nhưng ngân
          hàng còn có thể hỗ trợ hạn mức vay cao hơn lên đến 90% nếu như khách
          hàng có các tài sản đảm bảo khác như nhà đất, vv...
        </p>
        <p className="my-4">
          Vì thể khi quyết định mua xe ô tô cũ trả góp, bạn nên cân nhắc nhiều
          yếu tố như thu nhập của bản thân, giá trị xe ô tô cũ và số tiền bạn có
          để quyết định nên vay trả góp với hạn mức bao nhiêu. Hạn mức vay vốn
          được quyết định dựa trên giá trị định giá của chiếc xe chứ không phải
          giá niêm yết của bên bán.
        </p>
        <img src={imgArray[3]} className="w-full"></img>
        <p className="my-4 font-semibold">
          Quy trình và thủ tục mua xe ô tô cũ trả góp
        </p>
        <p className="my-4">
          Thủ tục mua xe ô tô cũ trả góp cần được thực hiện từ việc ký kết hợp
          đồng mua bán xe, chuẩn bị các giấy tờ cân thiết cho đến khi chính thức
          làm thủ tục sang tên cho chủ mới. Khi ký kết hợp đồng mua bán xe ô tô
          cần ghi rõ những nội dung chủ yêu như sau trong hợp đồng:
        </p>
        <p className="my-4">
          - Đồi tượng của hợp đồng mua bán <br />
          - Chất lượng của tài sản mua bán <br />
          - Giá và phương thức thanh toán <br />
          - Thời hạn thực hiện hợp đồng mua bán <br />
          - Địa điểm giao tài sản <br />
          - Phương thức giao tài sản <br />
          - Quyền và nghĩa vụ của các bên <br />- Giấy tờ mua xe ô tô cũ trả góp
          cần có trong hồ sơ
        </p>
        <p className="my-4">
          Bên cạnh đó, khách hàng cần chuẩn bị những loại giấy tờ mua xe cũ trả
          góp như sau:
        </p>
        <p className="my-4">
          - Giấy tờ thân nhân - CMND/CCCD
          <br />
          - Sổ hộ khẩu
          <br />
          - Giấy kết hôn hoặc chứng nhận độc thân
          <br />
          - Giầy tờ chứng minh nguồn thu nhập và tài sản
          <br />
          - Giấy tờ xe cũ sẽ mua trả góp (nhằm cung cấp thêm thông tin chỉ tiết
          về xe) như:
          <br />
          - Photo giấy đăng ký xe.
          <br />- Giấy đăng kiểm xe.
        </p>
        <p className="my-4">
          Khách hàng cần ký hợp đồng mua xe với bên bán, sau đó thực hiện thủ
          tục sang tên trên giấy đăng ký. Tiếp đó, khách hàng ký hợp đồng thế
          chấp xe và nộp cho phía ngân hàng. Khách hàng nhận xe sau khi ngân
          hàng giải ngân thành công.
        </p>
      </>
    ),
  },
  {
    id: "64da0cda401ccaa8f7fe7d45",
    img: [article3, article7, article8, article9, article10],
    adBanner: [advertise1, advertise1, advertise2],
    htmlTemplate: (imgArray) => (
      <>
        <h2 className="text-3xl leading-10 my-8">
          SÔI ĐỘNG THỊ TRƯỜNG MUA BÁN XE HƠI CŨ 2023
        </h2>
        <p className="my-4">
          Sau hai năm đại dịch Covid-19 với nhiều khó khăn và thách thức khiến
          cho tư duy về chỉ tiêu tài chính của mỗi chúng ta đều phải thích nghi
          và thay đổi. Là một phương tiện đi lại không thể thiều trong đời sống
          hiện đại, nhiều gia đình và các bạn trẻ đang hướng đền tìm mua xe hơi
          cũ trên các nền tảng thương mại để tiết kiệm chi phí. Trào lưu săn xe
          hơi “lướt giá tót khiến cho thị trường mua bán xe hơi cũ đã sớm trở
          nên sôi động từ dầu năm 2023.
        </p>
        <img src={imgArray[1]} className="w-full"></img>
        <p className="my-4">
          Thị trường ô tô cũ mặc dù rất ít thống kê, không ồn ào như xe mới
          nhưng thực sự rất sôi động bởi cho tới thời điểm hiện tại, nó vẫn là
          lựa chọn phù hợp nhất với khả năng tài chính và lồi tư duy của người
          Việt Nam. Không ít người mua sẵn sàng bỏ cả tháng trời đề lặn lội trên
          các diễn đàn xe ô tô hay những trang tin bán xe, rao vặt để tìm kiềm
          thông tin nhằm săn xe giá “hời”. Bảo Minh (30 tuổi, Marketing Manager
          một phòng khám nha khoa tại Sài Gòn) chia sẻ: “Sau dịch, mình biết sẽ
          có nhiều người gặp vần đề về tài chính nền sẽ muốn pass lại xe của họ
          với giá hời hơn bình thường. Nên mình cùng mấy người bạn đều rủ nhau
          cùng săn xe đợt này hi vọng sẽ tìm được nhiều option tốt. Bản thân
          mình cũng đủ tài chính để mua xe hơi đập hộp, nhưng mình thầy ở tuổi
          của mình cần phải tích luỹ và dành tiền cho nhiều mục tiêu khác trong
          cuộc sống nên cũng không nhất thiết phải phí phạm tiền vào một khoản
          xe mới, trong khi nếu mua xe hàng lướt thì giá có thể rẻ hơn tới 30%
          thì tội gì?.
        </p>
        <p className="my-4">
          Năm 2022, thị trường xe ô tô mới ở Việt Nam đã lần đầu tiên cán mốc
          500.000 xe và điều này là dầu hiệu cho thấy, nhu cầu mua ô tô của
          người dân đã trở thành một xu hướng tất yếu. Theo thống kê từ các thị
          trường khác, khi xe mới phát triển thì đồng nghĩa với thị trường xe ô
          tô cũ cũng sẽ gia tăng mạnh mẽ. Trong khi tỷ lệ xe mới tính trên dân
          số đang cải thiện mạnh mẽ thì tỷ lệ giao dịch xe cũ ở Việt Nam còn khá
          thấp so với khu vực, hiện chỉ vào khoảng 0,5 lần so với lượng ô tô mới
          bán ra. Trong khi thị trường Thái Lan là 2,4 xe cũ/1 xe mới bán ra,
          Indonesia 1,5 làn và Malaysia 2 lằn. Những thị trường xe không lồ như
          Mỹ, tỷ lệ xe cũ giao dịch gấp hơn 3 lần so với xe mới. Mặc dù chưa có
          con số thống kê chính xác các giao dịch xe ô tô cũ ở năm 2022 nhưng dự
          đoán số lượng trao tay có thể lên tới 250.000 xe và tiềm năng năm 2023
          sẽ còn tiếp tục tăng trưởng mạnh mẽ vì nhu cầu đồi xe, lên đời và nhu
          cầu chuyển đổi xe từ xe máy lên ô tô không chỉ còn giới hạn trong khu
          vực thành thị.
        </p>
        <img src={imgArray[2]} className="w-full"></img>
        <p className="my-4">
          Theo các con số và khảo sát từ các thị trường lân cận, Việt Nam dự báo
          số lượng xe cũ giao dịch năm 2023 sẽ đạt con số 400.000 xe, tương đư
          ng với khoảng 4 tỷ đô la. Lý giải cho tốc độ tăng trưởng nóng này còn
          do mộ phần là ở sự thiều hụt nguồn cung chip bán dẫn toàn cầu, kéo
          theo giá xe mới tăng cao khiến thị trường xe thứ cấp trở nên nhộn
          nhịp. Bên cạnh đó, tình trạng thiều hụt chất bán dẫn được cải thiện
          giúp nguồn cung xe mới và cũ tăng dần khi người dùng bán cũ và đổi
          mới.
        </p>
        <img src={imgArray[3]} className="w-full"></img>
        <p className="my-4">
          Thị trường sôi động, dòng xe tiết kiệm xăng là ưu tiên hàng đầu của
          khách hàng khi tìm mua xe ô tô cũ. Top các xe cũ được lựa chọn nhiều
          nhất là các xe thuộc phân khúc A và B, trong đó đặc biệt có mẫu xe
          Vinfast Fadil đời 2020 hiện đang liên tục chiếm lĩnh nhiều bảng xếp
          hạng các xe bán chạy trên thị thường. Động cơ lêu tôn ít nhiên liệu,
          đồng thời phù hợp với tình trạng đường xá đô thị hiện nay. Top 5 các
          xe bán chạy nhất lân lượt Hyundai Accent, Hyundai i10, Kia Morning,
          Toyota Vios và Honda City, đây luôn là các tên sáng giá cho sự lựa
          chọn giữ nhu cầu di chuyển gia đình và kinh doanh dịch vụ. 3/5 dòng xe
          đến từ các thương hiệu Hàn Quốc, cho thấy người tiêu dùng đã có sự
          chuyển dịch và tin tưởng hơn phân khúc A, xe đô thị cỡ nhỏ
        </p>
        <img src={imgArray[4]} className="w-full"></img>
        <p className="my-4">
          Kể từ giai đoạn cuối năm 2022 tới nay, thị trường ô tô cũ ghi nhận sự
          biến động giá ở 2 dòng xe bán tải à MPV đa dụng, sự biến động này diễn
          ra ở tất cả các dòng đời từ 2016 - 2021, cho thấy bên cạnh nhu cầu đối
          với các mẫu sedan phân khúc A/B, xe bán tải nổi bật lên là xu hướng
          mới của tương lai khi tính đa chức năng đang được đặt lên hàng đầu
        </p>
      </>
    ),
  },
  {
    id: "64da0cda401ccaa8f7fe7d46",
    img: [
      article11,
      article12,
      article13,
      article14,
      article15,
      article16,
      article17,
    ],
    adBanner: [advertise1, advertise1, advertise2],
    htmlTemplate: (imgArray) => (
      <>
        <h2 className="text-3xl leading-10 my-8">
          TOP 6 MẪU XE KHOẢNG 500 TRIỆU "HOT" NHẤT NĂM 2023
        </h2>
        <p className="my-4">
          Tài chính ở mức 500 triệu đã có vô vàn những lựa chọn xe phong phú.
          Dưới đây là 5 mẫu xe được Sleekride đặc biệt đánh giá cao cho phân
          khúc giá này!
        </p>
        <p className="my-4 font-semibold">No 1: Hyundai Accent</p>
        <img src={imgArray[1]} className="w-full"></img>
        <p className="my-4">
          Hyundai Accent là mẫu xe luôn dẫn đầu bảng tiêu thụ trên thị trường
          cho phân khúc xe hạng B, thường xuyên nằm trong top 10 xe bán chạy
          nhất hàng tháng tại Việt Nam. Với hai phiên bản Accent MT có giá dưới
          500 triệu đồng và phiên bản Accent AT giá khoảng 500 triệu. Bên cạnh
          về lợi thế giá bán cạnh tranh, mẫu xe Accent của hãng Hyundai còn được
          đánh giá cao về mặt kiểu dáng và trang bị đi kèm. Xe có thiết kế thể
          thao, hiện đại đáp ứng tốt các nhu cầu của người tiêu dùng phân khúc
          này. Thêm vào đó, xe có hệ thống an toàn đầy đủ các tính năng oần
          thiết ở tất cả các phiên bản. Với những ai đang muồn mua xe mới ở mức
          giá tầm 500 triệu thì Accent là một gợi ý nên tham khảo đầu tiên.
        </p>
        <p className="my-4">
          <span className="font-semibold">Giá xe:</span> <br />
          Hyundai Accent 4MT: 472 triệu đồng <br />
          Giá xe Hyundai Accent 4AT: 501 triệu đồng <br />
          Giá xe Hyundai Accent 4AT đặc biệt: 542 triệu đồng
        </p>

        <p className="my-4">
          <span className="font-semibold"> Ưu điểm Hyundai Accent:</span> <br />
          Thiết kế hiện đại, thể thao <br />
          Trang bị hấp dẫn <br />
          Hệ thống an toàn đầy đủ các tính năng cần thiết <br />
          Khả năng cách âm khá tốt
        </p>
        <p className="my-4">
          <span className="font-semibold"> Nhược điểm Hyundai Accent:</span>
          <br />
          Hàng ghế sau hơi kém thoáng do thiết kế thể thao
        </p>

        <p className="my-4 font-semibold">No 2: Kia Sonet</p>
        <img src={imgArray[2]} className="w-full"></img>
        <p className="my-4">
          Kia Sonet là mẫu xe 5 chỗ gầm cao hiếm hoi hiện nay có giá chỉ từ 500
          triệu đồng. Về Việt Nam từ cuối năm 2021, Kia Sonet thu hút được rất
          nhiều sự quan tâm của người tiêu dùng và tạo hiệu ứng rất tốt trên thị
          trường nhờ thiết kế bề ngoài thể thao, trẻ trung bắt mắt. Mẫu xe này
          có thể dễ dàng di chuyển nhanh nhẹn, linh hoạt trong thành phó, hội tụ
          đầy đủ các yếu tố của một chiếc SUV đô thị nhỏ gọn cần có. Thậm chí,
          tuy giá rẻ nhưng Sonet vẫn sở hữu dàn trang bị phong phú đến bắt ngờ.
          Tất cả các phiên bản của Sonet gần như nằm gọn trong khoảng giá từ 500
          - 600 triệu.
        </p>
        <p className="my-4">
          <span className="font-semibold">Giá xe:</span> <br />
          Giá xe Kia Sonet MT: 499 triệu đồng <br />
          Giá xe Kia Sonet Deluxe: 554 triệu đồng <br />
          Giá xe Kia Sonet Luxury: 594 triệu đồng <br />
          Giá xe Kia Sonet Premium: 624 triệu đồng
        </p>

        <p className="my-4">
          <span className="font-semibold">Ưu điểm Kia Sonet:</span> <br />
          Thiết kế thể thao, trẻ trung <br />
          Kích thước nhỏ gọn, đi phố dễ dàng <br />
          Trang bị hiện đại <br />
          Vận hành linh hoạt, nhanh nhẹn
        </p>

        <p className="my-4 font-semibold">No 3: Mazda 2</p>
        <img src={imgArray[3]} className="w-full"></img>
        <p className="my-4">
          Bạn hỏi bất cứ một tay buôn xe nào tư vấn cho bạn mẫu xe dưới 500
          triệu đồng mà đảm bảo đẹp, tiện nghĩ, sang trọng thì sẽ đều thầy nhắc
          tới Mazda 2. Đây là mẫu xe thừa kế không ít những thế mạnh từ các đàn
          anh nổi tiếng cùng nhà Mazda như Mazda 3 và Mazda CX-5. Tuy giá xe chỉ
          tầm 500 triệu đồng nhưng Mazda 2 không hề khiến công chúng thất vọng.
          Ngôn ngữ thiết ké KODO cùng triết lý tối giản “Less is more” mang đến
          cho Mazda 2 một diện mạo thời trang từ ngoài vào trong với hệ thống
          trang bị xe hiện đại bậc nhất phân khúc xe hạng B với đèn pha dạng LED
          có tính năng thích ứng, tự động bật/tắt và cân bằng góc chiều, xe có
          lẫy chuyền số, màn hình HUD vv. Động cơ 1.5L vận hành êm ái, tiết
          kiệm. Khả năng cách âm của xe cũng khá tốt.
        </p>
        <p className="my-4">
          <span className="font-semibold">Giá xe:</span> <br />
          Giá xe Mazda 2 1.5L AT (sedan): 479 triệu đồng <br />
          Giá xe Mazda 2 1.5L Deluxe (sedan): 509 triệu đồng <br />
          Giá xe Mazda 2 1.5L Luxury (sedan): 559 triệu đồng <br />
          Giá xe Mazda 2 1.5L Premium (sedan): 599 triệu đồng <br />
          Giá xe Mazda 2 Sport 1.5L Deluxe (hatchback): 519 triệu đồng <br />
          Giá xe Mazda 2 Sport 1.5L Luxury (hatchback): 574 triệu đồng <br />
          Giá xe Mazda 2 Sport 1.5L Premium (hatchback): 619 triệu đồng <br />
        </p>
        <p className="my-4">
          <span className="font-semibold"> Ưu điểm Mazda 2:</span> <br />
          Thiết kế thời trang, sang trọng <br />
          Trang bị cao cấp hàng đầu phân khúc <br />
          Động cơ 1.5L êm ái, ồn định, tiết kiệm <br />
          Hệ thống an toàn vượt trội <br />
        </p>
        <p className="my-4">
          <span className="font-semibold"> Nhược điểm Mazda 2:</span>
          <br />
          Hàng ghê sau hơi chật so với các xe cùng phân khúc
        </p>
        <p className="my-4 font-semibold">No 4: Suyuki Swift</p>
        <img src={imgArray[4]} className="w-full"></img>
        <p className="my-4">
          Một chiếc xe 5 chỗ có thiết kế mang phong cách hơi hướng cổ điển Châu
          Âu, được mệnh danh là “tiểu mini Cooper” của Nhật mà có giá trong phân
          khúc chỉ 500 triệu! Suzuki Swift là một mẫu hatchback hạng B nhỏ gọn,
          trẻ trung, cá tính. Khoang cabin khá thoáng so với kích thước chung.
          Khung gầm xe được đánh giá chắc chắn, cho độ ổn định tốt. Tuy nhiên
          hơi tiếc khi Swift chỉ dùng động cơ 1.2L. Dù không yếu nhưng khả năng
          tăng tốc cũng có phần hạn chế. Ngoài ra, hệ thống an toàn xe khá cơ
          bản. Nhìn chung, Swift chủ yếu thu hút nhiều bởi thiết kế rất phù hợp
          với chị em phụ nữ.
        </p>
        <p className="my-4">
          <span className="font-semibold">Giá xe:</span> <br />
          Giá xe Suzuki Swift GLX: 559 triệu đông
        </p>
        <p className="my-4">
          <span className="font-semibold"> Ưu điểm Suyuki Swift:</span> <br />
          Thiết kế phong cách châu Âu độc đáo <br />
          Kích thước nhỏ gọn, luồn lách trong phó dễ dàng <br />
          Nội thất rộng thoáng so với kích thước <br />
          Khung gầm chắc chắn, ổn định tốt
        </p>
        <p className="my-4">
          <span className="font-semibold"> Nhược điểm Suyuki Swift:</span>
          <br />
          Hàng ghế sau hơi nhỏ <br />
          Động cơ 1.2L tăng tốc chậm ở dải tốc từ 60 km/h trở lên <br />
          Hệ thống an toàn khá cơ bản <br />
        </p>
        <p className="my-4 font-semibold">No 5: Honda City</p>
        <img src={imgArray[5]} className="w-full"></img>
        <p className="my-4">
          Honda City được đánh giá là một trong các lựa chọn tốt nhất khi mua xe
          ô tô khoảng 500 triệu đồng. Tuy giá xe City nhỉnh hơn nhiều đối thủ,
          nhưng mẫu sedan hạng B của hãng Honda này lại sở hữu nhiều giá trị
          khác biệt. Tương tự như các “đàn anh” Honda Civic hay Honda Accord,
          ngoại hình Honda City mang đậm chất thể thao, nam tính.
        </p>
        <p className="my-4">
          Nội thất xe được thiết kế gãy gọn, không gian rộng rãi bậc nhất phân
          khúc này. Hệ thống trang bị đầy đủ cùng động cơ 1.5L cho công suất
          mạnh mẽ hơn các xe khác cùng dung tích. Khung gầm đằm chắc chắn. vận
          hành ổn định ở dải tốc cao. Cảm giác lái xe thú vị. Đặc biệt Honda vốn
          được mệnh danh là hãng xe có chất lượng xe vận hành bền bỉ vượt thời
          gian. Vậy nên Honda City đích thị là một cái tên không thể bỏ qua
          trong nhóm các xe ở khoảng giá tiền 500 triệu.
        </p>
        <p className="my-4">
          <span className="font-semibold">Giá xe:</span> <br />
          Giá xe Honda Gity G: 529 triệu đồng <br />
          Giá xe Honda City L: 569 triệu đồng <br />
          Giá xe Honda City RS: 599 triệu đồng <br />
        </p>
        <p className="my-4">
          <span className="font-semibold"> Ưu điểm Honda City:</span> <br />
          Thiết kế thể thao, cá tính <br />
          Nội thất rộng rãi bậc nhất phân khúc <br />
          Trang bị đầy đủ <br />
          Động cơ 1.5L mạnh mẽ, cảm giác lái thú vị <br />
          Khung gầm cứng cáp, vận hành ổn định
        </p>
        <p className="my-4">
          <span className="font-semibold"> Nhược điểm Honda City:</span>
          <br />
          Hàng ghế sau không gập được <br />
          Phanh sau dùng loại tang trồng
        </p>
        <p className="my-4 font-semibold">No 6: KIA K3</p>
        <img src={imgArray[6]} className="w-full"></img>
        <p className="my-4">
          Kia K3 là mẫu xe hạng C giá rẻ nhất phân khúc. Đây là một cái tên hấp
          dẫn với những ai đang đắn đo mua xe ở mức tàm giá 500 triệu. Với mức
          khởi điểm khoảng 500 triệu, bạn có thể mua được bản K3 số sàn. Bên
          cạnh lợi thế giá bán cạnh tranh, Kia K3 còn có nhiều điểm mạnh khác
          như thiết kế trẻ trung thể thao, nội thắt rộng rãi, trang bị hiện đại,
          động cơ 1.6L mượt mà khi lái.
        </p>
        <p className="my-4">
          <span className="font-semibold">Giá xe:</span> <br />
          Giá xe Kia K3 1.6 Deluxe MT: 584 triệu đồng <br />
          Giá xe Kia K3 1.6 Luxury: 639 triệu đồng <br />
          Giá xe Kia K3 1.6 Premium: 674 triệu đồng <br />
          Giá xe Kia K3 2.0 Premium: 704 triệu đồng <br />
          Giá xe Kia K3 1.6 Turbo GT: 779 triệu đồng
        </p>
        <p className="my-4">
          <span className="font-semibold"> Ưu điểm KIA K3:</span> <br />
          Kiểu dáng trẻ trung, thể thao, năng động <br />
          Nội thất rộng rãi <br />
          Động cơ 1.6L vận hành mượt mà <br />
          Trang bị hấp dẫn so với giá
        </p>
        <p className="my-4">
          <span className="font-semibold"> Nhược điểm KIA K3:</span> <br />
          Trần xe hàng ghế sau hơi kém thoáng <br />
          Động cơ 1.6L tăng tốc hơi “gượng” khi ở dải tốc trên 80 km/h
        </p>
      </>
    ),
  },
  {
    id: "64da0cda401ccaa8f7fe7d47",
    img: [article18, article19, article20, article21, article22, article23],
    adBanner: [advertise1, advertise1, advertise2],
    htmlTemplate: (imgArray) => (
      <>
        <h2 className="text-3xl leading-10 my-8">CÁCH ĐÀM PHÁN GIÁ XE</h2>
        <p className="my-4">
          Bạn đã đặt ngân sách của mình. Bạn đã thực hiện nghiên cứu của mình và
          tìm thấy một chiếc ô tô đã qua sử dụng mà bạn muốn mua. Giờ thì sao?
          Đã đến lúc đàm phán. Làm cho một thỏa thuận. Nhưng việc tìm kiếm mức
          giá tốt nhất không nhất thiết phải tạo ra trải nghiệm mua xe khó chịu,
          gây tranh cãi. Tạo ra một trường hợp mạnh mẽ cho con số bạn có trong
          đầu là cơ hội tốt nhất để bạn có được nó
        </p>
        <h3 className="my-4  text-2xl">Mang nghiên cứu của bạn</h3>
        <img src={imgArray[1]} className="w-full"></img>
        <p className="my-4">
          Nếu bạn đang đàm phán về giá nhà hoặc tiền lương của mình, bạn sẽ
          không mong đợi người đối diện chỉ mỉm cười và đồng ý với con số của
          bạn mà không cần nghiên cứu để chứng minh điều đó. Khi nói đến việc
          mua một chiếc xe đã qua sử dụng, các quy tắc tương tự cũng được áp
          dụng.
        </p>
        <p className="my-4">
          Trước khi đến đại lý ô tô, hãy tìm hướng dẫn định giá trực tuyến từ
          một bên thứ ba có uy tín cho loại xe cụ thể mà bạn muốn mua—Sleekride
          cung cấp cho bạn giá trị thị trường tức thì, xếp hạng giao dịch, thời
          gian xe có mặt trên thị trường và người bán có giảm giá hay không. giá
          theo thời gian. Mang nghiên cứu của bạn đến đại lý và hỏi tại sao giá
          bán lại cao hơn nhiều so với giá trị thị trường tức thời của
          Sleekride. Đừng ngạc nhiên hay tranh cãi nều người quản lý bán hàng
          của đại lý nói với bạn rằng người mua ô tô luôn chi nhiều hơn giá trị
          tìm thấy trên mạng. Chỉ cần chỉ ra một vài chiếc xe tương tự trong khu
          vực của bạn có giá gần với con số bạn có trong đâu.
        </p>
        <p className="my-4">
          Nếu đại lý không thể giải thích giá trị gia tăng mà bạn nhận được với
          chi phí bổ sung, bạn có thể muốn tiếp tục có lẽ là hướng tới một trong
          những chiếc xe khác mà bạn đã xác định.
        </p>
        <h3 className="my-4  text-2xl">
          Đảm bảo xe có báo cáo kiểm tra 150 điểm
        </h3>
        <img src={imgArray[2]} className="w-full"></img>
        <p className="my-4">
          Tương tự với mua nhà? Áp dụng ở đây khi mua xe. Khi cân nhắc để mua
          một ngôi nhà, nó thường phụ thuộc vào việc kiểm tra do một chuyên gia
          bên thứ ba khách quan tiến hành. Tại sao nên mua xe hơi khác nhau?
        </p>
        <p className="my-4 ">
          Giống như việc đi lướt qua và nhìn bề ngoài thì không đủ để đánh giá
          một ngôi nhà, lái thử cũng sẽ chỉ giúp bạn đánh giá được một khía cạnh
          nhất định của chiếc xe mới tiềm năng của mình. Để đảm bảo rằng chiếc
          xe đã qua sử dụng mà bạn đang cân nhắc là đúng như những gì nó được
          liệt kê và xứng đáng với mức giá hợp lý cho tình trạng đó, hãy xem xét
          một bảng báo cáo kiểm tra chi tiết - 150 điểm của Sleekride do một bên
          kiểm định thứ ba thực hiện.
        </p>
        <p className="my-4">
          Bảng báo cáo kiểm tra 150 điểm của Sleekride sẽ cho bạn biết không chỉ
          bất cứ điều gì cần sửa chữa, và chúng đã được sửa chửa chưa và đã thay
          thế bằng những gì. Điều này sẽ đảm bảo chiếc xe bạn mua đang ở tình
          trạng tốt hoặc có cần phải sửa chữa không. Tùy thuộc vào những gì cần
          sửa chữa, người bán có thể sẵn sàng giảm giá hoặc giúp bạn sửa chửa
          những điểm đang có vấn đề. Chắc chắn đây là một điều cần phải được hổ
          trợ.
        </p>
        <p className="my-4">
          Nếu đại lý hoàn toàn không thay đổi, hoặc nếu báo cáo kiểm tra cho
          thầy tình trạng của chiếc xe thấp hơn nhiều so với tình trạng ghi
          trong bài đăng, hãy chuẩn bị một lý do khác để tìm nơi khác và cũng
          đương nhiên với trường hợp nếu người bán không có báo cáo kiểm tra
          hoặc cố gắng thuyết phục bạn không kiểm tra ngay từ đầu.
        </p>
        <h3 className="my-4  text-2xl">Nhìn xa hơn giá bán</h3>
        <img src={imgArray[3]} className="w-full"></img>
        <p className="my-4">
          Nếu tin vui rằng đại lý đồng ý với giá xe bạn đề xuất! Nhưng đó không
          nhất thiết là nơi quá trình đàm phán đã kết thúc. Các cơ hội khác để
          tiết kiệm sẽ còn bao gồm: giá trao đổi, các hổ trợ tài chính và các
          tiện ích bổ sung nữa.
        </p>
        <p className="my-4">
          Cần hổ trợ tài chính? Sleekride hợp tác với các ngân hàng lớn và đáng
          tin cậy và có thể cung cấp cho bạn các khoản vay lãi suất thấp. Các
          dịch vụ cho vay mua ô tô có thể thay đổi đáng kể tùy thuộc vào điểm
          tín dụng và khoảng thanh toán trước của bạn. Hãy chắc chắn hiểu các
          lựa chọn tài chính của bạn trước khi bạn đến đại lý. Nếu đại lý không
          thể đạt được mức giá mục tiêu của bạn, nhưng bạn thực sự muốn chiếc
          xe, hãy yêu cầu các tiện ích bổ sung mà không mất thêm chi phí nào như
          bảo hành mở rộng, sơn lót, thay dầu và điều chỉnh miễn phí hoặc loại
          cao su có thể phù hợp mọi thời tiết để làm thảm trải sàn có thể là một
          chiến lược đàm phàn hiệu quả đấy!.
        </p>
        <h3 className="my-4  text-2xl">Giữ bình tĩnh</h3>
        <img src={imgArray[4]} className="w-full"></img>
        <p className="my-4">
          Trung bình, mất khoảng bốn giờ để mua một chiếc xe. Điều cuối cùng bạn
          muốn làm là dành thời gian dài như vậy tại một đại lý chỉ để bước ra
          khỏi showroom tay không (dù họ phục vụ một số dịch vụ đi kèm miễn phí
          như cà phê). Tuy nhiên, điều quan trọng là bạn phải chuẩn bị sẵn sàng
          để tiếp tục nếu cuộc đàm phán về xe hơi gặp trở ngại. Trên thực tế,
          bình tĩnh bỏ đi thường là điều cần thiết để đưa cuộc trò chuyện trở
          lại đúng hướng. Mặt khác, nếu đại lý trúng số của bạn hoặc đến gần,
          bạn không muốn bị cuốn vào chế độ đàm phán đến mức không nhận ra đã
          đến lúc phải bắt tay và lái xe đi với sự hài lòng rằng mình đã có được
          một chiếc xe tuyện với, thỏa thuận
        </p>
        <h3 className="my-4  text-2xl">Điểm mấu chốt</h3>
        <img src={imgArray[5]} className="w-full"></img>
        <p className="my-4">
          Bạn có thể mặc cả trong quá trình mua xe hơi , thậm chí là như bạn
          mong muôn như vậy không sao cả. Người bán xe mà bạn đang làm việc cũng
          biết rằng bạn muốn có được giá tột, giống như bạn biết đại lý cần tính
          một số khoản chênh lệch để kinh doanh có lãi. Chia sẻ giá mua, bạn
          đang hy vọng việc trả trước sẽ mang lại cho đại lý cơ hội cơ hội trả
          giá ngược hoặc họ có thể đồng ý với mức giá thấp mà bạn đã đề xuất. Dù
          bằng cách nào, đó đều là một phần của quy trình mua sắm ô tô tiêu
          chuẩn và việc quay đi quay lại một chút cũng đáng để bạn có thể cảm
          thấy tự tin rằng mình đã mua được một chiếc xe mới với mức giá thấp
          nhất có thể.
        </p>
      </>
    ),
  },
  {
    id: "64da0cda401ccaa8f7fe7d48",
    img: [
      article24,
      article25,
      article26,
      article27,
      article28,
      article29,
      article30,
      article31,
      quotation1,
      quotation2,
      quotation3,
    ],
    adBanner: [advertise1, advertise1, advertise2],
    htmlTemplate: (imgArray) => (
      <>
        <h2 className="text-3xl leading-10 my-8">
          DRIVEN: ĐÁNH GIÁ MERCEDES-BENZ C-CLASS 2023
        </h2>
        <p className="my-4">
          <span className="font-semibold">Loại xe: </span> Mercedes-Benz C-Class
          2023 là một chiếc xe hạng sang nhỏ gọn. Người mua có thể chọn C-Class
          theo một trong ba kiêu thân xe gồm sedan, coupe hoặc cabriolet.
        </p>
        <p className="my-4">
          <span className="font-semibold">Hệ truyền động:</span> Mỗi chiếc
          Mercedes-Benz C300 Sedan mới đều được trang bị tiêu chuẩn động cơ bốn
          xi-lanh, tăng áp, dung tích 2.0 lít. Nó tạo ra công suất 255 mã lực và
          mô-men xoắn 295 lb-ft trong C 300 Sedan nhờ hệ thống hybrid nhẹ 48
          volt. Nó có thể tăng 20 mã lực trong thời gian ngắn khi tăng tốc mạnh.
          Hệ dẫn động cầu sau là tiêu chuẩn trên tất cả các mẫu xe, nhưng hệ
          thống dẫn động bồn bánh 4MATIC của Mercedes-Benz có sẵn trên mỗi mẫu
          với một khoản phụ phí. Hộp số tự động 9 cấp là trang bị tiêu chuẩn
          trên dòng sản phẩm C300.
        </p>
        <p className="my-4 font-semibold">Có gì mới cho năm 2023?</p>
        <p className="my-4">
          Mẫu sedan Mercedes-Benz C-Class sử dụng lại thiết kế hoàn toàn như mẫu
          xe năm 2022, vì vậy không có bắt kỳ thay đổi đáng chú ý nào đói với
          mẫu xe 2023. Tuy nhiên, Mercedes-Benz sẽ giới thiệu chiếc Mercedes-AMG
          C43 Sedan hoàn toàn mới cùng với những người anh em AMG C43 Coupe và
          Cabriolet cho năm 2023. Mặc dù bài đánh giá Mercedes-Benz C-Class của
          chúng tôi hiện không bao gồm mẫu xe đó, nhưng nó dự kiến sẽ được bán
          vào năm 2023, mang lại công suất hơn 400 mã lực và thời gian 0-60 giây
          dưới 5 giây.
        </p>

        <p className="my-4 font-semibold">Ưu điểm:</p>
        <p className="my-4 ">
          - Nội thất đúng chất xe sang Đức <br />
          - Nhiều không gian nội thất hơn cho hành khách và hàng hóa so với hầu
          hết các mẫu xe khác cùng hạng <br />- Với mức giá thấp hơn một nửa so
          với S-Class lớn hơn, bạn sẽ có được cảm giác tương tự, kiểu dáng tương
          tự và công nghệ tương tự như mẫu xe đó
        </p>
        <p className="my-4 font-semibold">Nhược điểm:</p>
        <p className="my-4">
          - Cảm giác phanh yếu không phù hợp với động lực lái sắc nét mà phần
          còn lại của chiếc xe mang lại <br />
          - Mặc dù hệ thống thông tin giải trí MBUX tốt, nhưng các nút điều
          khiển cảm ứng có thể hơi khó chịu và có thể mắt một chút thời gian để
          tìm hiểu <br />- Động cơ bồn xi-lanh tăng áp đôi khi có thể gây buồn
          ngủ
        </p>
        <p className="my-4 font-semibold">Tổng quan:</p>
        <img className="w-full" src={imgArray[1]} />
        <p className="my-4">
          Mercedes-Benz C 300 2023 là một chiếc xe toàn diện trong phân khúc xe
          hạng sang cỡ nhỏ. Các đối thủ như Audi A4, BMW 3-Series, Volvo S60,
          Lexus IS và Genesis G70 mang đến sự cạnh tranh gay gắt trên bảng,
          nhưng C 300 vẫn gây ấn tượng với chúng tôi.
        </p>
        <p className="my-4">
          Kiểu dáng nội thất đặc biệt, danh sách các tính năng công nghệ, hiệu
          suất ổn định tổng thể và tính linh hoạt giúp nó đạt vị trí gần đầu
          phân khúc. Nó nằm trong dòng sản phẩm của Mercedes-Benz bên dưới
          E-Class lớn hơn và trên A-Class nhỏ hơn. Nó cũng trông giống như
          S-Class lớn hơn và đẳng cấp hơn nhiều so với các mẫu trước đây của nó,
          một điều tốt cho những ai muốn đạt đến hạng cao nhất mà không phải chỉ
          tiền để làm như vậy.
        </p>
        <p className="my-4">
          Tuy nhiên, C 300 tiêu chuẩn phải là chiếc xe quá đủ để đáp ứng hầu hết
          người mua. Mẫu sedan thực dụng, coupe thể thao và cabriolet sang trọng
          đều mang đến điều gì đó cho mọi người.
        </p>
        <p className="my-4 font-semibold">Điểm tổng thể: 4.25/5 sao</p>
        <p className="my-4 font-semibold">Kinh nghiệm lái xe: 3.5/5 sao</p>
        <img className="w-full" src={imgArray[2]} />
        <p className="my-4">
          Thật không may cho C-Class, trải nghiệm lái xe của nó không đáp ứng
          được những gì nó cung cấp ở hầu hết các khu vực khác. Nó chắc chắn có
          chất lượng xe tốt như người ta mong đợi từ bắt kỳ nhà sản xuất ô tô
          nào trong số ba nhà sản xuất ô tô lớn của Đức, nhưng hệ thống phanh
          của nó thực sự khiên chiếc xe thắt vọng. Chúng buồn tẻ một cách khác
          thường và không mang lại nhiều cảm giác hay truyền cảm hứng cho sự tự
          tin.
        </p>
        <p className="my-4">
          Hệ thống truyền động tuyệt vời và nhạy bén của nó kiềm được một vài
          điểm. Hộp số 9 cấp đôi khi có thể phức tạp, nhưng nó cho cảm giác mạnh
          mẽ hơn nhiều so với công suất 255 mã lực được quảng cáo.
        </p>
        <p className="my-4">
          Lái xe BMW 3-Series sắc nét hơn, mặc dù nó cũng thoải mái. Ngay cả
          Genesis G70 cũng dễ ném hơn một chút. C- Class vẫn có đủ khả năng cân
          bằng khi lái để được coi là nằm trong nửa trên của phân khúc. Trọng
          tâm hiệu suất của họ cũng mang lại khả năng xử lý sắc nét hơn, mặc dù
          BMW M3 và Cadillac CT4-V Blackwing được cho là vẫn tự hào về trải
          nghiệm hiệu suất tổng thể tốt hơn.
        </p>
        <p className="my-4 font-semibold">Tính năng an toàn: 4.5/5 sao</p>
        <img className="w-full" src={imgArray[3]} />
        <p className="my-4">
          Mercedes-Benz C 300 2023 đi kèm với nhiều tính năng an toàn tiêu
          chuẩn. Cũng như nhiều xe sang khác, một số tính năng an toàn tiên tiền
          nhất của C 300 được dành riêng cho các cấp độ trang bị cao hơn hoặc
          phải trả thêm tiền. Volvo S60 và Genesis G70 đều vượt trội so với C
          300 khi nói đến công nghệ an toàn ở cấp độ đầu vào, nhưng C 300 không
          quá kém so với chúng.
        </p>
        <p className="my-4">
          Các tính năng an toàn tiêu chuẩn trên mỗi chiếc sedan C 300 bao gồm
          phanh khẳn cấp tự động, cảnh báo va chạm phía trước, đèn pha tự động
          chiều xa, giám sát sự chú ý của người lái, giám sát điểm mù và hỗ trợ
          thoát hiểm an toàn. Ngoài ra còn có tiêu chuẩn là hệ thống PRE-SAFE
          của Mercedes-Benz. Nó thắt chặt dây an toàn và đóng cửa số nếu xe phát
          hiện có tai nạn.
        </p>
        <p className="my-4">
          Các tính năng an toàn bổ sung được bao gồm như một phần của gói hỗ trợ
          người lái có sẵn trên hai cấp độ trang trí cao nhất của chiếc sedan.
          Nó đi kèm với cảnh báo phương tiện cắt ngang phía sau, phanh khẩn cấp
          tự động phía sau, kiểm soát hành trình thích ứng dừng và đi, hỗ trợ
          chuyển làn đường và hỗ trợ theo dõi làn đường. Gói tương tự cũng có
          sẵn trên coupe và cabriolet nhưng với một số tính năng xáo trộn.
        </p>
        <p className="my-4">
          NHTSA tại Hoa Kỳ đã không thử nghiệm va chạm với Mercedes-Benz C-Class
          2023, nhưng IIHS đã đánh giá tổng thể tốt cho cả hai mẫu sedan C-Class
          2022 và 2023. Mẫu xe năm 2022 thậm chí đã nhận được giải thưởng Top
          Safety Pick+ cho khả năng va chạm, xếp hạng này sẽ được chuyền sang
          mẫu xe năm 2023 vì xe đã có những thay đồi tối thiều.
        </p>
        <p className="my-4 font-semibold">Giá trị: 3.5/5 sao</p>
        <img className="w-full" src={imgArray[4]} />
        <p className="my-4">
          Mercedes-Benz C-Class đại diện cho một giá trị tốt hơn mức trung bình
          cho phân khúc, nhưng nó không đạt được mức tốt nhất trong lớp, Những
          lý do lớn nhất cho điều này bao gồm sự mắt giá khét tiếng của những
          chiếc sedan sang trọng của Đức, giá khởi điểm tương đối cao và mức
          tiết kiệm nhiên liệu chỉ ở mức trung bình trong phân khúc. Mặc dù có
          một số thiều sót, bao gồm cả mức giá của nó, nhưng bạn sẽ nhận được
          khá nhiều xe với mức giá cao hơn mà bạn phải trả. C300 có công nghệ
          tuyệt vời, kiểu dáng hoàn toàn hiện đại, nội thất đầy đủ tiện nghỉ và
          hiệu suất tốt so với những chiếc khác trong lớp. Genesis G70 và Audi
          A4 được cho là đại diện cho hai giá trị tốt nhất trong phân khúc.
        </p>
        <p className="my-4">
          Mặc dù Audi thiều sức mạnh của C-Class, "nhưng nó được trang bị tiêu
          chuẩn với hệ dẫn động tắt cả các bánh, mức giá cơ bản thấp hơn và được
          cho là nội thất tốt nhất trong phân khúc. G70 không cao cấp bằng,
          không tiết kiệm nhiên liệu và không có công. nghệ tiên tiền như
          O-Class, nhưng nó đi kèm với mức giá thắp hơn đáng kể, chế độ bảo hành
          tuyệt vời và hệ thống thông tin giải trí dễ sử dụng hơn.
        </p>
        <p className="my-4 font-semibold">Tính năng công nghệ: 4.5/5 sao</p>
        <img className="w-full" src={imgArray[5]} />
        <p className="my-4">
          Khi Mercedes-Benz thiết kế lại C-Class vào năm ngoái, nó đã đạt được
          lợi thế cạnh tranh về công nghệ so với các đối thủ hàng đầu. Kết hợp
          các tính năng an toàn chủ động tiêu chuẩn và sẵn có cùng các dịch vụ
          công nghệ, C- Class đứng gần đầu phân khúc, điều được kỳ vọng ở một
          thương hiệu uy tín như Mercedes-Benz.
        </p>
        <p className="my-4">
          Các tính năng công nghệ tiêu chuẩn trên mỗi chiếc sedan C 300 bao gồm
          màn hình thông tin giải trí màn hình cảm ứng 11,9 inch, cụm đồng hồ đo
          kỹ thuật số 12,3 inch, Apple CarPlay không dây, Android Auto không
          dây, điểm truy cập Wi-Fi, điều hòa khí hậu hai vùng, cửa sỏ trời chỉnh
          điện, sưởi ấm ghề trước, ghề trước chỉnh điện, đèn viền 64 màu, nắp
          cốp chỉnh điện, đèn pha LED và đèn hậu LED.
        </p>
        <p className="my-4">
          Các tính năng tùy chọn và những tính năng được tìm thầy trên các phiên
          bản C 300 cao hơn bao gồm sạc thiết bị không dây, hệ thống âm thanh
          vòm Burmester 3D 15 loa, hệ thống camera quan sát xung quanh, hỗ trợ
          đỗ xe chủ động, màn hình hiển thị trên kính chắn gió, hệ thống định
          vị, vô lăng có sưởi bánh xe, và đèn pha kỹ thuật số.
        </p>
        <p className="my-4 font-semibold">Tính thực dụng: 4.5/5 sao</p>
        <img className="w-full" src={imgArray[6]} />
        <p className="my-4">
          Mặc dù là một chiếc xe cỡ nhỏ, C 300 sedan khá thực dụng so với những
          chiếc khác trong phân khúc. BMW 3- Series có nhiều không gian hơn và
          Audi A4 xếp sau C-Class.
        </p>
        <p className="my-4">
          C-Class sedan giành giải nhất trong phân khúc về khoảng đề chân phía
          sau. Hành khách ngồi phía sau được xử lý đến 36 inch. Kết hợp với khả
          năng vận hành êm ái, yên tĩnh của C 300, bạn sẽ có một chuyền đi thoải
          mái. Hành khách ngồi phía trước cũng có chỗ để chân rộng rãi với chỗ
          ngồi hỗ trợ cho những chuyền đi dài hơn.
        </p>
        <p className="my-4">
          Hệ dẫn động tắt cả các bánh có sẵn để tăng thêm độ an toàn trong điều
          kiện lái xe kém, nhưng hệ thống AWD tiêu chuẩn của Audi A4, C-Class
          lại nằm dưới mức hoàn hảo. Rất ít xe sedan hạng sang nhỏ gọn khác có
          nhiều tính năng hơn trong phân khúc.
        </p>
        <p className="my-4 font-semibold">Kiểu dáng & Thiết kế: 5/5 sao</p>
        <img className="w-full" src={imgArray[7]} />
        <p className="my-4">
          Rất ít đồi thủ trong phân khúc có thể cạnh tranh với mỗi C-Class trong
          bộ phận kiểu dáng. C 300 sedan theo phong cách S-Class lớn hơn, uy tín
          hơn một cách đẹp mắt. C 300 Cabriolet sang trọng và trông sang trọng.
          Các phiên bản AMG của mỗi mẫu xe đều bổ sung thêm các chỉ tiết thể
          thao để trông thể thao hơn mà không cần đi quá xa.
        </p>
        <p className="my-4">
          Bên trong là nhiều câu chuyện tương tự. C-Class có một trong những nội
          thắt tốt nhất trong lớp, cạnh tranh với các đối thủ hàng đầu như Audi
          A4. Mọi thứ trông và cảm giác được xây dựng tốt, và khu vực thiết bị
          trung tâm gọn gàng và sạch sẽ. Đương nhiên, bạn có thể chọn da Nappa
          để làm nổi bật mọi thứ hơn một chút.
        </p>
        <p className="my-4">
          Volvo S60 cạnh tranh với ngoại thất đẹp trai của C-Class, nhưng nội
          thất của nó không có những đường nét bóng bẩy mà C-Class sở hữu. Audi
          A4 được cho là ít nhất có nội thất tốt như C-Class, nhưng lại thiều
          kiểu dáng bên ngoài cần thiết để vượt qua C-Class. Nhìn chung, C-Class
          giành chiến thắng trong ngày với cú đắm phong cách có 1-2 của nó.
        </p>
        <p className="my-4 font-semibold">Chỉ phí bao nhiêu?</p>
        <p className="my-4">
          Mỗi mẫu C-Class mới đều được trang bị tiêu chuẩn động cơ 4 xi-lanh 2.0
          lít tăng áp, công suất 255 mã lực, dẫn động cầu sau và hộp số tự động
          chín cấp. Chiếc sedan C-Class được hưởng lợi từ hệ thống hybrid nhẹ 48
          volt bổ sung mà Coupe và Cabriolet thiêu.
        </p>
        <p className="my-4">
          Các tính năng an toàn tiêu chuẩn trên mỗi chiếc Mercedes-Benz C300
          Sedan bao gồm phanh khẳn cấp tự động, cảnh báo va chạm phía trước,
          giám sát sự chú ý của người lái, giám sát điểm mù, hỗ trợ thoát hiểm
          an toàn, đèn pha chiều xa tự động và hệ thống PRE-SAFE của
          Mercedes-Benz.
        </p>
        <p className="my-4">
          Các tính năng tiêu chuẩn trên phiên bản Exclusive bao gồm mọi thứ từ
          phiên bản Premium cộng với sạc thiết bị không dây, hệ thống Âm thanh
          vòm Burmester 3D 15 loa, hệ thống đỗ xe có tằm nhìn bao quanh, hệ
          thống chiều sáng xung quanh nâng cao, bệ cửa trước được chiều sáng và
          cảm biến đỗ xe.
        </p>
        <p className="my-4">
          Hai gói AMG có sẵn trên C-Class Coupe không phải AMG. Gói AMG Line bao
          gồm phanh thể thao, bàn đạp ga và phanh bằng thép không gỉ, vô lăng
          thể thao, hệ thống treo thể thao, hệ thống lái thể thao, bánh xe AMG
          18 inch và kiểu dáng thân xe AMG.
        </p>
        <img className="w-full" src={imgArray[8]} />
        <img className="w-full my-8" src={imgArray[9]} />
        <img className="w-full my-8" src={imgArray[10]} />
      </>
    ),
  },
  {
    id: "64da0cda401ccaa8f7fe7d49",
    img: [
      article32,
      article33,
      article34,
      article35,
      article36,
      article37,
      article38,
      article39,
      quotation4,
      quotation5,
      quotation6,
      quotation7,
    ],
    adBanner: [advertise1, advertise1, advertise2],
    htmlTemplate: (imgArray) => (
      <>
        <h2 className="text-3xl leading-10 my-8">
          DRIVEN: ĐÁNH GIÁ XE HYUNDAI ELANTRA 2023
        </h2>
        <p className="my-4">
          <span className="font-semibold">Loại xe: </span> Xe nhỏ gọn 4 cửa, 5
          chỗ ngồi.
        </p>
        <p className="my-4">
          <span className="font-semibold">Khoảng giá: </span> Từ 599 triệu đồng
          đến 799 triệu đồng.
        </p>
        <p className="my-4">
          <span className="font-semibold">Hệ truyền động:</span> Hệ truyền động
          cơ bản của Hyundai Elantra 2023 là động cơ 4 xi-lanh, dung tích 2.0
          lít, công suất 147 mã lực kết hợp với hộp số CVT dẫn động bánh trước.
        </p>
        <p className="my-4">
          <span className="font-semibold">Các mẫu: </span> N-Line spec có động
          cơ 4 xi-lanh 1,6 lít tăng áp 201 mã lực kết hợp với hộp số ly hợp kép
          bảy cấp.
        </p>
        <p className="my-4 font-semibold">Có gì mới cho năm 2023?</p>
        <p className="my-4">
          Đồi với năm 2023, các mẫu xe Hyundai Elantra N-line sẽ không còn hộp
          số sàn nữa và gói SEL Premium có sẵn trước đây cũng bị ngừng cung cấp.
          Các mẫu xe được trang bị Gói tiện lợi SEL có bánh xe 17 inch lớn hơn,
          cụm đồng hồ đo 10,25 inch và màn hình cảm ứng có kích thước giống hệt
          nhau.
        </p>

        <p className="my-4 font-semibold">Ưu điểm:</p>
        <p className="my-4 ">
          - Nội thất rộng rãi <br />
          - Nhiều tính năng an toàn tiêu chuẩn <br />
          - Công nghệ dễ sử dụng <br />- Mang lại giá trị cho đồng đô la
        </p>
        <p className="my-4 font-semibold">Nhược điểm:</p>
        <p className="my-4">
          - Vài miếng nhựa nội thất rẻ tiền <br />
          - Công cụ cấp nhập cảnh vừa đủ <br />- Hiệu suất N-Line đắt hơn, nhưng
          thiếu một số tính năng chính
        </p>
        <p className="my-4 font-semibold">Tổng quan:</p>
        <img className="w-full" src={imgArray[1]} />
        <p className="my-4">
          Hyundai Elantra là một chiếc xe nhỏ gọn dành cho năm hành khách và là
          một sản phẩm hấp dẫn trong một phân khúc hạn chế chỗ ngồi. Elantra
          theo mô hình cổ điển của Hyundai, mang lại giá trị tuyệt vời về giá cả
          và chế độ bảo hành hấp dẫn. Nhiều tùy chọn hệ thống truyền động cũng
          có nghĩa là nhiều kiểu người mua sẽ tìm thầy sự phần khích, tính thực
          tế hoặc khả năng tiết kiệm nhiên liệu mà họ yêu cầu khi mua một chiếc
          ô tô mới.
        </p>
        <p className="my-4">
          Những người mua đang tìm kiếm phương tiện di chuyển đáng tin cậy, tiết
          kiệm và thiết thực sẽ thích Elantra cơ bản hơn, nhưng những người tìm
          kiếm cảm giác lái thể thao năng động có thể muốn xem xét các đối thủ
          cạnh tranh hoặc Elantra N chú trọng hiệu suất, mà chúng tôi không đưa
          vào bài đánh giá này vì nó hoàn toàn khác với những chiếc xe hơi như
          Honda City và đang nhận được hầu hết các đánh giá tích cực, nếu bạn
          tình cờ tìm kiếm thứ gì đó sôi nổi hơn trên thị trường.
        </p>
        <p className="my-4">
          Cho dù chọn một chiếc xế cơ bản, hybrid tiết kiệm nhiên liệu hay
          N-Line thể thao hơn với động cơ tăng áp, thì Elantra chắc chắn là một
          ứng cử viên trong phân khúc này. Nó cung cấp giá trị và chất lượng tốt
          trên trong cùng giá tiền khi so sánh với phần còn lại của thị trường.
          Những người mua sắm trong phân khúc xe nhỏ gọn chắc chắn nên xem xét
          Elantra
        </p>
        <p className="my-4 font-semibold">Điểm tổng thể: 3.7/5 sao</p>
        <p className="my-4 font-semibold">Kinh nghiệm lái xe: 3/5 sao</p>
        <img className="w-full" src={imgArray[2]} />
        <p className="my-4">
          Động cơ 2.0 lít cơ sở cung cấp đủ sức mạnh cho việc lái xe trong thế
          giới thực, nhưng còn lâu mới thú vị. Để có khả năng tăng tốc thú vị
          hơn, phiên bản N-Line có công suất 201 mã lực và giúp tăng chỉ số hồi
          hộp.
        </p>
        <p className="my-4">
          So với nhóm đầu trong phân khúc, khả năng xử lý của Elantra ở mức
          trung bình. Một sự thỏa hiệp tuyệt vời về độ chính xác với việc vẫn
          duy trì mức độ thoải mái cao. Những người mua đang tìm kiềm khả năng
          xử lý chặt chẽ nhất nên cân nhắc Mazda3 hoặc Honda City.
        </p>
        <p className="my-4 font-semibold">Tính năng an toàn: 4/5 sao</p>
        <img className="w-full" src={imgArray[3]} />
        <p className="my-4">
          Hyundai Elantra 2023 đã nhận được xếp hạng 5 sao từ Cục Quản lý An
          toàn Giao thông Đường cao tốc Quốc gia (NHTSA) cũng như danh hiệu Lựa
          chọn An toàn Hàng đầu từ Viện Bảo hiểm An toàn Đường cao tốc (IIHS)
          tại Hoa Kỳ.
        </p>
        <p className="my-4">
          Các tính năng an toàn tiêu chuẩn bao gồm phanh khẳn cấp tự động, cảnh
          báo chệch làn đường, giám sát điểm mù, hỗ trợ tránh va chạm phương
          tiện cắt ngang phía sau, cảnh báo sự chú ý của người lái và hỗ trợ đi
          theo làn đường. Các tính năng an toàn có sẵn bao gồm kiểm soát hành
          trình thích ứng với tính năng định tâm làn đường chủ động và hỗ trợ
          đèn pha.
        </p>
        <p className="my-4 font-semibold">Giá trị: 3.5/5 sao</p>
        <img className="w-full" src={imgArray[4]} />
        <p className="my-4">
          Elantra cạnh tranh tốt với các đối thủ của nó trong phân khúc xe nhỏ
          gọn. Vấn đề là sự cạnh tranh cũng rất lớn, nếu mua sắm trong phân khúc
          xe nhỏ gọn, Elantra nên được xem xét cùng với một số đồi thủ cạnh
          tranh trước khi mua.
        </p>
        <p className="my-4">
          Một nhược điểm của Elantra khi so sánh với những chiếc tốt nhất trong
          phân khúc là sự nghèo nàn của các bề mặt nhựa rẻ tiền trong nội thất.
          Mặc dù không phải là một công cụ giảm giá, nhưng một số đối thủ cạnh
          tranh có trang trí nội thất cao cấp hơn.
        </p>
        <p className="my-4 font-semibold">Tính năng công nghệ: 4.5/5 sao</p>
        <img className="w-full" src={imgArray[5]} />
        <p className="my-4">
          Các mẫu cơ bản được trang bị hệ thống thông tin giải trí màn hình cảm
          ứng 8 inch bao gồm Android Auto không dây và Apple CarPlay không dây,
          một điều hiếm thầy trong phân khúc. Đồng hồ đo tương tự được tăng
          cường bởi màn hình màu 4,2 inch nhỏ trong cụm. Các tính năng khác như
          kết nói Bluetooth, đài HD và hai cổng USB là tiêu chuẩn. Các cấp độ
          trang trí cao hơn và gói tiện lợi SEL có màn hình cảm ứng 10,25 inch
          và cụm đồng hồ đo kỹ thuật số hoàn toàn 10,25 inch.
        </p>
        <p className="my-4">
          Hệ thống điều khiển bằng giọng nói có sẵn cho phép điều chỉnh rảnh tay
          các cài đặt kiểm soát khí hậu hoặc kích hoạt các tính năng khác chỉ
          bằng một từ hoặc cụm từ.
        </p>

        <p className="my-4 font-semibold">Tính thực dụng: 4.5/5 sao</p>
        <img className="w-full" src={imgArray[6]} />
        <p className="my-4">
          Một cabin rộng rãi cho phép vận chuyển 5 người lớn tương đối thoải
          mái, bao gồm cả sự rộng rãi tuyệt vời cho hàng ghê sau.
        </p>
        <p className="my-4">
          Một cốp rộng 14,2 foot khối nằm ở giữa gói để chở hàng trong hạng
          nhưng phải chứa hành lý xách tay cho tất cả hành khách có một số chỗ
          trồng. Hàng ghế sau gập xuống để chứa các vật dụng lớn hơn hoặc dài
          hơn.
        </p>
        <p className="my-4">
          Một số người đánh giá nhận thầy việc mở cốp nhỏ khiền việc chất đồ vào
          cóp của Elantra khó khăn hơn so với những mẫu xe khác trong phân khúc.
        </p>
        <p className="my-4">
          Hiện tại không có phiên bản hatchback, không giống như một số đối thủ
          cạnh tranh.
        </p>
        <p className="my-4 font-semibold">Kiểu dáng & Thiết kế: 3.5/5 sao</p>
        <img className="w-full" src={imgArray[7]} />
        <p className="my-4">
          Thiết kế bên ngoài nổi bật với những đường nét sắc sảo và kiểu dáng
          mạnh mẽ nổi bật trong phân khúc xe cỡ nhỏ. Vẻ đẹp nằm trong mắt của
          người ngắm nhìn, nhưng Elantra chắc chắn mang đến điều gì đó khác
          biệt.
        </p>
        <p className="my-4">
          Một cabin hành khách lớn có nghĩa là Elantra cảm thầy rộng rãi mà
          không phải là một trong những chiếc xe tổng thể lớn nhất trong lớp.
        </p>
        <p className="my-4">
          Hàng ghế trước thoải mái và rộng rãi. Chỗ để chân của hàng ghế sau phù
          hợp một cách đáng ngạc nhiên, với tắt cả, trừ những chiếc chân dài
          nhất, đều dễ dàng lắp vào.
        </p>
        <p className="my-4">
          Nội thất gọn gàng và đơn giản khi so sánh với kiểu dáng bên ngoài. Mặc
          dù các khu vực chính bao gồm các vật liệu mềm, nhưng rất nhiều nhựa
          cứng khiến Elantra không có cảm giác cao cấp.
        </p>
        <p className="my-4 font-semibold">Chỉ phí bao nhiêu?</p>

        <img className="w-full" src={imgArray[8]} />
        <img className="w-full my-8" src={imgArray[9]} />
        <img className="w-full my-8" src={imgArray[10]} />
        <img className="w-full my-8" src={imgArray[11]} />
      </>
    ),
  },
];

const ResearchDetail = () => {
  const params = useParams().id;
  const articleData = listArticleDum.find((art) => art.id === params);

  return (
    <div className="w-[90%] m-auto">
      <div className="w-[80%] flex flex-col items-end m-10 gap-4">
        {/* Head banner */}
        <img className="w-full object-cover " src={articleData.img[0]}></img>
        <div className="flex gap-2">
          <button className="border-none bg-transparent">Share</button>
          <Fb fill="#105E26" />
          <Twitter fill="#105E26" />
          <Tiktok fill="#105E26" />
          <Yt fill="#105E26" />
        </div>
      </div>
      <div className="flex m-10 justify-between">
        <div className="w-[65%]">
          {/*Main content*/}
          {articleData.htmlTemplate(articleData.img)}
        </div>

        <div className="w-[30%] flex flex-col gap-12 my-8">
          {/*Advertisements*/}
          {articleData.adBanner.map((adpic) => {
            return <img className="w-full" src={adpic}></img>;
          })}
        </div>
      </div>
    </div>
  );
};
export default ResearchDetail;
