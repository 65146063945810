import clsx from "clsx";

function ToggleButton({ text, isActive }) {
  return (
    <button
      className={clsx(
        "bg-[#f0f0f0]  p-3 w-full text-[#105E26] font-semibold rounded  sm:text-xs sm:p-1 sm:py-3",
        {
          ["!text-white !bg-[#105E26]"]: isActive,
        }
      )}
    >
      {text}
    </button>
  );
}

export default ToggleButton;
