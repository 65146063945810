import { configureStore } from "@reduxjs/toolkit";
import userReducer from"./user";
import signupReducer from"./signup";
import listingReducer from "./listing"
import wishlistReducer from "./wishlist"
import optionsReducer from "./options"
import modalReducer from "./modal"

const store  = configureStore({
  reducer: {
    user: userReducer,
    signup: signupReducer,
    listing: listingReducer,
    wishlist: wishlistReducer,
    options: optionsReducer,
    modal: modalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>

export default store