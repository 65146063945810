import React from 'react'
import './style.css'

const PaymentIconCard = ({ Icon, title, name, value, onChange, checked, selectedPaymentMethod }) => {
  return (
    <div
      className={`payment-card ${
        selectedPaymentMethod === value ? 'active' : ''
      }`}
    >
      <label className="block cursor-pointer">
        <input
          type="radio"
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          className="sr-only"  // This class hides the input visually
        />
        <div className="payment flex h-16 w-36 border-lightgrey bg-lightgrey border-2 rounded-xl sm:w-24 xs:w-20 xs:h-14">
          <div
            className={`flex w-full h-full rounded-lg items-center justify-evenly`}
          >
            <div className={`flex items-center  ${title && 'gap-x-0'}  `}>
              {title && (
                <span className="text-[#105E26] px-2 sm:px-0 sm:pr-1 sm:text-xs">
                  {title}
                </span>
              )}
              {Icon}
            </div>
          </div>
        </div>
      </label>
    </div>
  )
}

export default PaymentIconCard
