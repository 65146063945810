import ResponsiveAppBar from "Components/AppBar";
import Footer from "Components/Footer";
import React from "react";
import carimage from "../../assets/images/adbanner.png";

const TermsAndConditions = () => {
  return (
    <div>
      {/* container */}
      <div className="p-10  sm:p-4  ">
        <p className="text-center font-semibold text-3xl">Terms of Service</p>
        <p className="text-center font-semibold">sleekride.vn</p>
        <p className="my-4">
          Welcome to this website (Site), as operated by Sleekride, LLC.
          (Company). Company operates the Site and other associated services and
          offerings, including, without limitation, mobile and tablet
          applications, that allow users to list and/or locate cars, trucks or
          other automobiles (collectively, Vehicles) for sale (collectively, the
          "Service"), and makes it available to you subject to the following
          terms and conditions of service (the "Terms"). The Terms are a legal
          contract between you, an individual user or single entity
          (individually User or collectively, "Users"), and Company regarding
          your use of the Service. You will also be subject to any additional
          posted guidelines or rules applicable to specific premium services,
          products, and features offered through the Service, which are hereby
          incorporated into these Terms.
        </p>
        <p className="my-4">
          You are granted permission to use the Service, provided that you
          comply with the Terms. BY ACCESSING AND USING THE SERVICE, YOU
          ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY
          THE TERMS. IF YOU DO NOT AGREE TO THESE TERMS AT ANY TIME, PLEASE DO
          NOT USE THE SERVICE.
        </p>
        <div className="my-4 ">
          <p className="font-bold text-lg">1. Applicable Terms and Policies.</p>
          <div className="p-6">
            <p className="my-4">
              a. Guidelines. When using the Service, you will be subject to any
              additional posted guidelines or rules applicable to specific
              services and features, including, without limitation, end user
              license agreements for downloadable applications, which may be
              posted from time to time (collectively, the "Guidelines"). All
              such Guidelines are hereby incorporated by reference into the
              Terms.
            </p>
            <p className="my-4">
              b. Privacy Policy. Please read the Privacy Policy ("Privacy
              Policy") carefully for details relating to what information and
              data Company collects from you and other users, and how we use
              that information internally and disclose it to third parties.
              Company's Privacy Policy is hereby incorporated into these Terms
              by reference.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">2. Our Proprietary Rights.</p>
          <div className="p-6">
            <p className="my-4">
              a. General. The content, interactive and visual features,
              software, information, listings, and all other elements of the
              Service provided by Company (Company Materials) are owned and/or
              licensed by Company and protected by all relevant intellectual
              property, proprietary, and other applicable laws and regulations.
              Company Materials do not include User Submissions (as defined
              below). Except as expressly authorized by Company, you agree not
              to sell, license, distribute, copy, modify, publicly perform or
              display, or otherwise make unauthorized use of the Service and
              Company Materials. Company reserves all rights not expressly
              granted herein.
            </p>
            <p className="my-4">
              b. License. Company grants you a limited non-exclusive license to
              access and use the Service for your personal, non-commercial
              purposes
            </p>
            <p className="my-4">
              c. Further Restrictions. You further agree not to (i)
              intentionally interfere with, damage, impair, or disable the
              Service's operation, by any means (whether through automated means
              or otherwise), including uploading or otherwise disseminating
              viruses, worms, spyware, adware or other malicious code; (ii) make
              unsolicited offers, advertisements, proposals, or send junk mail
              or spam to other Users; (iii) use the Service or Company Materials
              for any unlawful purpose or as prohibited by these Terms; (iv)
              defame, harass, abuse, threaten, stalk, impersonate, or defraud
              other Users, or collect personal information about them or third
              parties without their consent; (v) use any robot, spider, scraper,
              site search/retrieval application, or other automated means to
              access the Service for any purpose (including, without limitation,
              to retrieve information from or data mine the Service) without
              Company's express consent or bypass Company's robot exclusion
              headers or similar measures; (vi) remove, circumvent, disable,
              damage or otherwise interfere with the Service's security-related
              features, features that prevent or restrict the use or copying of
              any part of the Service, or features that enforce Service
              limitations; (vii) attempt to gain unauthorized access to the
              Service, other User accounts, computer systems or networks
              connected to the Service through hacking, password mining, or any
              other means; (viii) deep-link to the Service, and you agree you
              will promptly remove any links that Company finds objectionable in
              its sole discretion; (ix) reverse engineer, decompile, disassemble
              or otherwise attempt to discover the source code of the Service,
              except and only to the extent that such activity is expressly
              permitted by applicable law notwithstanding this limitation; (x)
              harm or exploit minors; or (xi) modify, adapt, reproduce,
              translate, or create derivative works based upon the Service,
              except to the extent expressly permitted by applicable law
              notwithstanding this limitation.
            </p>
            <p className="my-4">
              d. Service Access. The Service is controlled and offered by
              Company from its facilities in the United States of America and
              Vietnam, including all or a portion of the Service that may be
              operated from the "cloud", or other similar distributed hosting
              environment. Company makes no representations that the Service is
              appropriate or available for use in other locations. If you are
              accessing or using the Service from other jurisdictions, you do so
              at your own risk and you are responsible for compliance with local
              law.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">3. User Submissions.</p>
          <div className="p-6">
            <p className="my-4">
              a. General. The Service may now or in the future permit the
              submission and posting of or linking to data, files, text, photos,
              commentary or any other content submitted by you and other Users,
              including without limitation, listings for Vehicles (collectively,
              "User Submissions"), and the hosting and/or publishing of such
              User Submissions. Company may or may not use or publish your User
              Submissions in its sole discretion. You understand that whether or
              not such User Submissions are published, Company does not
              guarantee confidentiality with respect thereto. Furthermore, the
              Service may now or in the future restrict the amount and/or number
              of User Submissions you may host on the Service, and you agree
              that Company may impose such limits in its sole discretion.
            </p>
            <p className="my-4">
              b. Grant of Rights. By submitting User Submissions to Company or
              the Service, you hereby grant Company and its affiliates a
              worldwide, non-exclusive, fully paid-up, royalty-free,
              irrevocable, transferable license, with the right to grant and
              authorize sublicenses, to use, reproduce, distribute, modify,
              adapt, prepare derivative works of, display, perform, and
              otherwise exploit (collectively, Use) your User Submissions in
              connection with the Service (including, without limitation, to
              provide you with any related services) and/or Companys (and its
              successors) business (including without limitation for promoting
              and redistributing part or all of the Service (and derivative
              works thereof) in any media formats and through any media channels
              now known or hereafter discovered). You grant Company, its
              affiliates, and sublicensees the right to use any personal
              information that you submit in connection with such User
              Submission, as provided for in the Privacy Policy. You also agree
              to irrevocably waive (and cause to be waived) any claims and
              assertions of moral rights or attribution with respect to your
              User Submissions. You also hereby grant to each User of the
              Service a non-exclusive license to access your User Submissions
              through the Service, and to use, reproduce, distribute, prepare
              derivative works of, display, and perform such User Submissions as
              permitted by the functionality of the Service and these Terms.
              Except for the limited rights set forth in these Terms, each User
              retains all right, title, and interest in its User Submissions. If
              you make suggestions or provide feedback to Company on improving
              or adding new features to the Service, Company may Use such
              suggestions or feedback in any manner without any compensation to
              you.
            </p>
            <p className="my-4">
              c. User Submissions Representations and Warranties. You and each
              User shall be solely responsible for your User Submissions and the
              consequences of uploading, posting, or publishing them through the
              Service. In connection with User Submissions, you represent and
              warrant that: (i) you own, or have the necessary rights to use and
              authorize Company to use, all intellectual property or other
              proprietary rights to User Submissions to enable inclusion and use
              of User Submissions in the manner contemplated by these Terms, and
              to grant the rights and license set forth above (without any
              additional compensation); and (ii) your User Submissions, Companys
              and other Users use of such User Submissions in connection with
              the Service, and Companys and other Users exercise of the license
              rights set forth above, do not and will not: (a) infringe,
              violate, or misappropriate any third-party right, including any
              intellectual property and proprietary rights; (b) slander, defame,
              libel, or invade the right of privacy, publicity or other property
              rights of any other person; or (c) violate any applicable law or
              regulation (including, without limitation, any laws applicable to
              the advertising or sale of Vehicles).
            </p>
            <p className="my-4">
              d. User Submissions Prohibited Uses. In connection with your User
              Submissions, you further agree that you will not: (i) publish
              falsehoods or misrepresentations that could damage Company or any
              third party; (ii) submit material that is inaccurate, misleading,
              defamatory, threatening, pornographic, obscene, vulgar, harassing,
              harmful, hateful, abusive, racially or ethnically offensive or
              encourages (or provides instruction regarding) conduct that would
              be considered a criminal offense, give rise to civil liability, or
              is otherwise inappropriate; (iii) impersonate another person or
              entity or falsely state or otherwise misrepresent your affiliation
              with a person or entity; or (iv) upload User Submissions that
              would be harmful to minors in any manner.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">
            4. Non-Company Content Disclaimer.
          </p>
          <div className="p-6">
            <p className="my-4">
              You understand that when using the Service, you will be exposed to
              User Submissions and other content from a variety of sources,
              including listings information that Company aggregates from third
              parties (collectively, Non-Company Content). COMPANY IS NEITHER
              RESPONSIBLE NOR LIABLE IN ANY WAY FOR THE ACCURACY, INTEGRITY,
              QUALITY, LEGALITY, USEFULNESS, SAFETY, OR INTELLECTUAL PROPERTY
              RIGHTS OR INFRINGEMENT RELATING TO SUCH NON-COMPANY CONTENT, NOR
              FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
              OF ANY NON-COMPANY CONTENT DISPLAYED OR TRANSMITTED VIA THE
              SERVICE, OR ANY CONTACT YOU HAVE WITH ANY THIRD PARTY
              PROVIDER/CONTRIBUTOR OF NON-COMPANY CONTENT, OR ANY TRANSACTION
              YOU CONSUMMATE IN CONNECTION WITH YOUR USE OF OR ACCESS TO ANY
              NON-COMPANY CONTENT (INCLUDING, WITHOUT LIMITATION, ANY SALE OR
              PURCHASE OF A VEHICLE). WITHOUT LIMITING THE GENERALITY OF THE
              FOREGOING, COMPANY IS NOT RESPONSIBLE OR LIABLE (UNDER ANY THEORY
              OF LIABILITY) FOR ANY USERS OR THIRD PARTYS VIOLATION OF LAWS OR
              REGULATIONS RELATING TO THE PURCHASE AND/OR SALE OF VEHICLES. YOU
              FURTHER UNDERSTAND AND ACKNOWLEDGE THAT YOU MAY BE EXPOSED TO
              NON-COMPANY CONTENT THAT IS OFFENSIVE, INDECENT, OR OBJECTIONABLE,
              AND YOU AGREE TO WAIVE, AND HEREBY DO WAIVE, ANY LEGAL OR
              EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST COMPANY
              WITH RESPECT THERETO. COMPANY DOES NOT ENDORSE ANY USER SUBMISSION
              OR OTHER NON-COMPANY CONTENT OR ANY OPINION EXPRESSED THEREIN.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">
            5. Non-Monitoring of Non-Company Content and Users.
          </p>
          <div className="p-6">
            <p className="my-4">
              Company does not control the Non-Company Content posted by Users
              and does not have any obligation to pre-screen or monitor such
              Non-Company Content. If at any time, Company chooses, in its sole
              discretion, to pre-screen or monitor the Non-Company Content,
              Company nonetheless assumes no responsibility for the Non-Company
              Content, no obligation to modify or remove any inappropriate
              Non-Company Content, and no responsibility for the conduct of the
              User submitting any such Non-Company Content. Without limiting the
              foregoing, Company and its designees may, at any time and without
              prior notice, remove any Non-Company Content, in whole or in part,
              for any reason. Furthermore, you alone are responsible for your
              involvement with other Users. Company reserves the right, but has
              no obligation, to monitor disagreements between you and other
              Users. Company disclaims all liability related to any User
              disagreement.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">6. Account Information.</p>
          <div className="p-6">
            <p className="my-4">
              In order to use some features of the Service, you may have to
              create an account through our online form(s) or depending on the
              service, through the use of your login(s) for Facebook or other
              social networking sites. When creating an account, you must
              provide true, accurate, current, and complete information. You
              also must ensure that this information is kept accurate and
              up-to-date at all times. Our use and disclosure of such
              information is specified in our Privacy Policy.
            </p>
            <p className="my-4">
              If you are opening an account or using the Service on behalf of
              yourself, you represent that you are the age of majority in your
              jurisdiction and fully able and competent to enter into these
              Terms. In any case, you affirm that you are over the age of 13, as
              the Service is not intended for children under 13.
            </p>
            <p className="my-4">
              If you are opening an account or using the Service on behalf of a
              company, entity, or organization (collectively Registering
              Organization), then you represent and warrant that you are an
              authorized representative of that Registering Organization with
              the authority to bind such organization to the Terms, and agree to
              be bound by the Terms on behalf of such Registering Organization.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">7. Password.</p>
          <div className="p-6">
            <p className="my-4">
              When you register you will be asked to provide a password. As you
              will be responsible for all activities that occur under your
              password, you should keep your password confidential. You are
              solely responsible for maintaining the confidentiality of your
              account and password and for restricting access to your computer,
              and you agree to accept responsibility for all activities that
              occur under your account or password. If you have reason to
              believe that your account is no longer secure (for example, in the
              event of a loss, theft or unauthorized disclosure or use of your
              account ID or password), you should immediately notify Company.
              You may be liable for the losses incurred by Company or others due
              to any unauthorized use of your account.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">
            8. Third Party Services and Third Party Materials.
          </p>
          <div className="p-6">
            <p className="my-4">
              Company may provide tools through the Service that enable you to
              export information, including exporting User Submissions to third
              party services (such as Facebook) or to your devices. By using
              these tools, you agree that we may transfer such information
              accordingly and that you will not use such exported information
              other than for your personal, non-commercial use. Such third party
              services are not under our control, and we are not responsible for
              their use of your exported information. The Service may also
              contain links, information, and references to third party
              products, services, and websites, which Company does not control
              or maintain (Reference Sites). Access to and use of any Reference
              Sites is at the User's own risk and Company is not responsible for
              the legality, accuracy or reliability of information or statements
              on Reference Sites. Company provides these links merely as a
              convenience, and the inclusion of such links does not imply an
              endorsement. You should review any applicable terms and policies
              of such Reference Sites, as Companys Terms do not apply to them.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">9. Dealings with Advertisers.</p>
          <div className="p-6">
            <p className="my-4">
              Your correspondence or business dealings with, or participation in
              promotions of, advertisers or other third parties found on or
              through the Service are solely between you and such advertiser or
              third party. You agree that Company will not be responsible or
              liable for any loss or damage incurred as a result of any such
              dealings or as the result of the presence of such
              advertisers/third parties on the Service.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">10. Disputes with Third Parties</p>
          <div className="p-6">
            <p className="my-4">
              If you have a dispute with: (i) another User of the Service; (ii)
              the provider of any Reference Site or Non-Company Content; (iii)
              any provider of any listing posted on or made available in
              connection with the Service; (iv) any third party that contacts
              you as a result of your use of the Service; or (v) any other third
              party arising in connection with your use of the Service, you
              release us (and our officers, directors, agents, subsidiaries,
              joint ventures and employees) from claims, demands and damages
              (actual and consequential) of every kind and nature, known and
              unknown, arising out of or in any way connected with such
              disputes. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA
              CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE DOES NOT EXTEND
              TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN
              HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY
              HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">11. Availability of the Service.</p>
          <div className="p-6">
            <p className="my-4">
              Company may make changes to or discontinue any part of the Service
              at any time without notice. The Service may be out of date, and
              Company makes no commitment to update materials on the Service.
            </p>
          </div>
        </div>
        <div className="my-4 ">
          <p className="font-bold text-lg">12. Termination.</p>
          <div className="p-6">
            <p className="my-4">
              These Terms become effective on the date you first use the Service
              and continue in effect until terminated in accordance with the
              provisions herein. You agree that Company may suspend, disable,
              delete or terminate your account or use of the Service at any time
              in its sole discretion, and you agree that Company shall not be
              liable to you or any third-party for any such termination. You may
              terminate these Terms at any time by closing your account, if any,
              and discontinuing use of the Service. In the event that these
              Terms are terminated, you will not register a new account under a
              different name. Upon termination, all licenses granted by Company
              herein will terminate. In the event that your account is removed
              or deleted for any reason, User Submissions that you submitted or
              saved may no longer be available. Company shall not be responsible
              for the loss of such content.
            </p>
          </div>
        </div>
        <div className="my-4 ">
          <p className="font-bold text-lg">
            13. Indemnification; Hold Harmless.
          </p>
          <div className="p-6">
            <p className="my-4">
              You agree to indemnify, defend, and hold harmless Company, and its
              parent, subsidiaries, affiliates or any related companies, its
              suppliers, licensors and partners, and the officers, directors,
              employees, agents and representatives of any of them from any and
              all claims, losses, obligations, damages, liabilities, costs or
              debt and expenses (including attorneys fees) arising out of: (i)
              your use or misuse of the Service; (ii) your User Submissions,
              including Companys or other Users use, display or other exercise
              of their license rights granted herein with respect to your User
              Submissions; (iii) your violation of these Terms; (iv) your
              violation of the rights of any other person or entity, including
              claims that any User Submission infringes or violates any third
              party intellectual property rights; and (v) your breach of the
              foregoing representations, warranties, and covenants. Company
              reserves the right to assume the exclusive defense and control of
              any matter for which you are required to indemnify us and you
              agree to cooperate with our defense of these claims. You agree not
              to settle any matter without the prior written consent of Company.
              Company will use reasonable efforts to notify you of any such
              claim or proceeding upon becoming aware of it.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">14. DISCLAIMERS.</p>
          <div className="p-6">
            <p className="my-4">
              DISCLAIMER AS TO LISTINGS. COMPANY IS ONLY A LISTING SERVICE FOR
              VEHICLES THAT BRINGS TOGETHER BUYERS AND SELLERS AND IS NOT A
              PARTY TO ANY TRANSACTION BETWEEN BUYERS AND SELLERS THAT
              ORIGINATES ON THE SERVICE. YOU UNDERSTAND AND AGREE THAT THE
              LISTINGS ON THIS WEBSITE DO NOT CONSTITUTE OFFERS FOR THE SALE OF
              VEHICLES, AND ARE THEREFORE NOT SUBJECT TO APPLICABLE LAWS
              RELATING THERETO. COMPANY, ITS AFFILIATES, PARTNERS AND SUPPLIERS
              DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF OR
              THE RESULTS OF USE OF THE SERVICE OR ANY REFERENCE SITES IN TERMS
              OF CORRECTNESS, ACCURACY OF THE LISTINGS (INCLUDING, WITHOUT
              LIMITATION, APPEARANCE, ACCESSORIES, PRICE, OR AVAILABILITY OF ANY
              GIVEN VEHICLE) OR OTHERWISE. WITHOUT LIMITING THE GENERALITY OF
              THE FOREGOING, COMPANY DOES NOT WARRANT THAT ANY OF THE LISTINGS
              ON THIS SITE ARE AFFORDABLE OR A GOOD DEAL FOR YOU. INFORMATION
              ABOUT A PARTICULAR VEHICLE IS SUPPLIED BY THE SELLER, AND THE
              PRICE AND OTHER TERMS OF ANY SALE REMAIN SUBJECT TO NEGOTIATIONS
              BETWEEN THE BUYER AND THE SELLER. THE PRICES LISTED BY SELLERS ON
              THE SITE OFTEN EXCLUDE TAXES, FINANCE CHARGES, TITLE, LICENSE, AND
              OTHER FEES ANY OR ALL OF WHICH MAY BE ADDED TO THE LISTED PRICE TO
              ARRIVE AT THE FINAL PRICE YOU PAY FOR A GIVEN VEHICLE. COMPANY,
              ITS AFFILIATES, PARTNERS AND SUPPLIERS ARE NOT RESPONSIBLE FOR,
              AND DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING, ANY
              VEHICLE OR OTHER PRODUCTS OR SERVICES LISTED ON THE SITE OR ANY
              REFERENCE SITE OR THAT YOU MAY TRANSACT WITH THIRD PARTIES FOR AS
              A RESULT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE. GENERAL
              DISCLAIMER. THE SERVICE, COMPANY MATERIALS, AND ANY NON-COMPANY
              CONTENT MADE AVAILABLE ON THE SERVICE ARE PROVIDED ON AN AS IS
              BASIS, AND TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
              APPLICABLE LAW, COMPANY, ITS AFFILIATES, PARTNERS, AND SUPPLIERS
              DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS OR IMPLIED, INCLUDING,
              BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. YOU
              UNDERSTAND AND AGREE THAT YOU USE, ACCESS, DOWNLOAD, OR OTHERWISE
              OBTAIN INFORMATION, MATERIALS, OR DATA THROUGH THE SERVICE
              (INCLUDING RSS FEEDS) OR ANY REFERENCE SITES AT YOUR OWN
              DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR
              ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM) OR
              LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH
              MATERIALS OR DATA, OR ANY LOSSES OR HARMS OF ANY KIND YOU MAY
              SUFFER AS A RESULT OF FOLLOWING UP ON, TRANSACTING AS A RESULT OF,
              OR OTHERWISE RELYING ON ANY SUCH INFORMATION. COMPANY, ITS
              AFFILIATES, PARTNERS AND SUPPLIERS DO NOT WARRANT THAT THE COMPANY
              MATERIALS, NON-COMPANY CONTENT, THE SERVICE, OR ANY OTHER
              INFORMATION OFFERED THROUGH THE SERVICE OR ANY REFERENCE SITES
              WILL BE UNINTERRUPTED, OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL
              COMPONENTS AND DO NOT WARRANT THAT ANY OF THE FOREGOING WILL BE
              CORRECTED.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">15. LIMITATION OF LIABILITY.</p>
          <div className="p-6">
            <p className="my-4">
              UNDER NO CIRCUMSTANCES, AND UNDER NO LEGAL THEORY, INCLUDING
              WITHOUT LIMITATION NEGLIGENCE, SHALL COMPANY, ITS AFFILIATES,
              PARTNERS OR SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT,
              INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES (INCLUDING WITHOUT
              LIMITATION LOSS OF PROFITS, DATA OR USE OR COST OF COVER) ARISING
              UNDER THESE TERMS OR THAT RESULT FROM YOUR USE OR INABILITY TO USE
              THE COMPANY MATERIALS, NON-COMPANY CONTENT, USER SUBMISSIONS OR
              THE SERVICE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGES. IN NO EVENT SHALL COMPANY, ITS AFFILIATES,
              PARTNERS OR SUPPLIERS TOTAL AGGREGATE LIABILITY TO YOU UNDER THESE
              TERMS OR IN CONNECTION WITH THE SERVICE EXCEED THE GREATER OF ONE
              HUNDRED DOLLARS OR FEES PAID IN THE PRECEDING TWELVE MONTHS.
              APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
              LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
              LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES,
              COMPANYS LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">
            16. Digital Millennium Copyright Act.
          </p>
          <div className="p-6">
            <p className="my-4">
              Notification of Infringement . It is Companys policy to respond to
              clear notices of alleged copyright infringement that comply with
              the Digital Millennium Copyright Act (DMCA). In addition, we will
              promptly terminate without notice accounts of those determined to
              be "repeat infringers". If you are a copyright owner or an agent
              thereof, and believe that any User Submission or other Non-Company
              Content infringes upon your copyrights, you may submit a
              notification pursuant to the DMCA by providing our Copyright Agent
              designated below with the following information in writing (see 17
              U.S.C § 512(c)(3) or consult your own legal counsel to confirm
              these requirements): (i) A physical or electronic signature of a
              person authorized to act on behalf of the owner of an exclusive
              right that is allegedly infringed; (ii) Identification of the
              copyrighted work claimed to have been infringed, or, if multiple
              copyrighted works on the Service are covered by a single
              notification, a representative list of such works from the
              Service; (iii) Identification of the material that is claimed to
              be infringing or to be the subject of infringing activity and that
              is to be removed or access to which is to be disabled, and
              information reasonably sufficient to permit Company to locate the
              material; (iv) Information reasonably sufficient to permit Company
              to contact the complaining party, such as an address, telephone
              number, and, if available, an electronic mail address at which the
              complaining party may be contacted; (v) A statement that the
              complaining party has a good faith belief that use of the material
              in the manner complained of is not authorized by the copyright
              owner, its agent, or the law; and (vi) A statement that the
              information in the notification is accurate, and under penalty of
              perjury, that the complaining party is authorized to act on behalf
              of the owner of an exclusive right that is allegedly infringed.
              Upon removing any allegedly infringing material, Company will
              notify the alleged infringer of such takedown. Please note that
              under Section 512(f) of the DMCA, any person who knowingly
              materially misrepresents that material or activity is infringing
              may be subject to liability. Counter Notification . If you elect
              to send our Copyright Agent a counter notice, to be effective it
              must be a written communication that includes the following
              (please consult your legal counsel or See 17 U.S.C. Section
              512(g)(3) to confirm these requirements): (i) A physical or
              electronic signature of the subscriber; (ii) Identification of the
              material that has been removed or to which access has been
              disabled and the location at which the material appeared before it
              was removed or access to it was disabled; (iii) A statement under
              penalty of perjury that the subscriber has a good faith belief
              that the material was removed or disabled as a result of mistake
              or misidentification of the material to be removed or disabled;
              and (iv) The subscriber's name, address, and telephone number, and
              a statement that the subscriber consents to the jurisdiction of
              Federal District Court for the judicial district in which the
              address is located, or if the subscriber's address is outside of
              the United States, for any judicial district in which Company may
              be found, and that the subscriber will accept service of process
              from the person who provided notification under subsection
              (c)(1)(C) or an agent of such person. Upon receiving a
              DMCA-compliant counter notice, Company will provide the notifying
              party a copy of the counter notice and inform that party that
              Company will restore the material in 10 business days. Company
              will then reinstate the removed material not less than 10, but no
              more than 14, business days unless it first receives notice from
              the party that filed the initial notice that such party has filed
              a court action against the alleged infringer. Please note that
              under Section 512(f) of the DMCA, any person who knowingly
              materially misrepresents that material or activity was removed or
              disabled by mistake or misidentification may be subject to
              liability. Designated Copyright Agent . Companys Designated
              Copyright Agent to receive notifications and counter-notifications
              of claimed infringement can be reached as follows: 3265 C Street,
              Suite 203, San Diego, CA 92102 415.316.4402 feedback@sleekride.vn
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">17. Trademarks.</p>
          <div className="p-6">
            <p className="my-4">
              Trademarks of Company may be included within the Service. All
              trademarks, logos and service marks found on the Service (Company
              Marks) are the property of Company or other third parties. Users
              are not permitted to use any Company Marks without the prior
              written consent of Company or the third party that owns the
              respective Company Mark. Company bears no responsibility or
              liability for, and disclaims sponsorship of or affiliation with,
              any third party marks uploaded to or displayed through the
              Service. Company is generally unable to evaluate the merits of
              disputes regarding third party marks and encourages Users to
              resolve any such disputes directly.
            </p>
          </div>
        </div>
        <div className="my-4 ">
          <p className="font-bold text-lg">18. Contact Information.</p>
          <div className="p-6">
            <p className="my-4">
              The Service is offered by Company, located at 3265 C Street, Suite
              203, San Diego, CA 92102 415.316.4402 feedback@sleekride.vn. If
              you are a resident of certain jurisdictions, you may have this
              same information emailed to you by sending a letter to the
              foregoing address with your email address and a request for this
              information.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">19. Survival.</p>
          <div className="p-6">
            <p className="my-4">
              Sections 2 (other than 2b), 3, 4, 6, 7, 8, 9, 10, and 13-21 will
              survive any termination of these Terms or your account.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">
            20. Dispute Resolution and Arbitration.
          </p>
          <div className="p-6">
            <p className="my-4">
              Generally. In the interest of resolving disputes between you and
              Company in the most expedient and cost effective manner, you and
              Company agree that any and all disputes arising in connection with
              these Terms shall be resolved by binding arbitration. Arbitration
              is more informal than a lawsuit in court. Arbitration uses a
              neutral arbitrator instead of a judge or jury, may allow for more
              limited discovery than in court, and can be subject to very
              limited review by courts. Arbitrators can award the same damages
              and relief that a court can award. Our agreement to arbitrate
              disputes includes, but is not limited to all claims arising out of
              or relating to any aspect of these Terms, whether based in
              contract, tort, statute, fraud, misrepresentation or any other
              legal theory, and regardless of whether the claims arise during or
              after the termination of these Terms. YOU UNDERSTAND AND AGREE
              THAT, BY ENTERING INTO THESE TERMS, YOU AND COMPANY ARE EACH
              WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
              ACTION.. Exceptions. Notwithstanding Section 20(a), we both agree
              that nothing herein will be deemed to waive, preclude, or
              otherwise limit either of our right to (i) bring an individual
              action in small claims court; (ii) pursue enforcement actions
              through applicable federal, state, or local agencies where such
              actions are available; (iii) seek injunctive relief in a court of
              law; or (iv) to file suit in a court of law to address
              intellectual property infringement claims. Arbitrator. Any
              arbitration between you and Company will be governed by the
              Commercial Dispute Resolution Procedures and the Supplementary
              Procedures for Consumer Related Disputes (collectively, "AAA
              Rules") of the American Arbitration Association ("AAA"), as
              modified by these Terms, and will be administered by the AAA. The
              AAA Rules and filing forms are available online at www.adr.org, by
              calling the AAA at 1-800-778-7879, or by contacting Company.
              Notice; Process. A party who intends to seek arbitration must
              first send a written notice of the dispute to the other, by
              certified mail or Federal Express (signature required), or in the
              event that we do not have a physical address on file for you, by
              electronic mail ("Notice"). Company's address for Notice is:
              Sleekride LLC at 3265 C Street, Suite 203, San Diego, CA 92102
              415.316.4402 feedback@sleekride.co. The Notice must (i) describe
              the nature and basis of the claim or dispute; and (ii) set forth
              the specific relief sought ("Demand"). We agree to use good faith
              efforts to resolve the claim directly, but if we do not reach an
              agreement to do so within 30 days after the Notice is received,
              you or Company may commence an arbitration proceeding. During the
              arbitration, the amount of any settlement offer made by you or
              Company shall not be disclosed to the arbitrator until after the
              arbitrator makes a final decision and award, if any. In the event
              our dispute is finally resolved through arbitration in your favor,
              Company shall pay you (i) the amount awarded by the arbitrator, if
              any, (ii) the last written settlement amount offered by Company in
              settlement of the dispute prior to the arbitrators award; or (iii)
              $1,000.00, whichever is greater. Fees. In the event that you
              commence arbitration in accordance with these Terms, Company will
              reimburse you for your payment of the filing fee, unless your
              claim is for greater than $10,000, in which case the payment of
              any fees shall be decided by the AAA Rules. Any arbitration
              hearings will take place at a location to be agreed upon in San
              Francisco, California, provided that if the claim is for $10,000
              or less, you may choose whether the arbitration will be conducted
              (i) solely on the basis of documents submitted to the arbitrator;
              (ii) through a non-appearance based telephonic hearing; or (iii)
              by an in-person hearing as established by the AAA Rules in the
              county (or parish) of your billing address. If the arbitrator
              finds that either the substance of your claim or the relief sought
              in the Demand is frivolous or brought for an improper purpose (as
              measured by the standards set forth in Federal Rule of Civil
              Procedure 11(b)), then the payment of all fees will be governed by
              the AAA Rules. In such case, you agree to reimburse Company for
              all monies previously disbursed by it that are otherwise your
              obligation to pay under the AAA Rules. Regardless of the manner in
              which the arbitration is conducted, the arbitrator shall issue a
              reasoned written decision sufficient to explain the essential
              findings and conclusions on which the decision and award, if any,
              are based. The arbitrator may make rulings and resolve disputes as
              to the payment and reimbursement of fees or expenses at any time
              during the proceeding and upon request from either party made
              within 14 days of the arbitrator's ruling on the merits. No Class
              Actions . YOU AND COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST
              THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING . Enforceability . If Subsection 20(f) is found to be
              unenforceable or if the entirety of this Section 20 is found to be
              unenforceable, then the entirety of this Section 20 shall be null
              and void and, in such case, the parties agree that the exclusive
              jurisdiction and venue described in Section 21 shall govern any
              action arising out of or related to these Terms.
            </p>
          </div>
        </div>

        <div className="my-4 ">
          <p className="font-bold text-lg">21. Miscellaneous.</p>
          <div className="p-6">
            <p className="my-4">
              These Terms, together with the Privacy Policy and Guidelines, are
              the entire agreement between the parties on the subject matter
              hereof. The heading references herein are for convenience purposes
              only, do not constitute part of these Terms, and shall not limit
              or affect any provision hereof. The relationship of the parties
              hereunder is that of independent contractors, and these Terms will
              not be construed to imply that either party is the agent,
              employee, or joint venturer of the other. In the event that any
              provision of these Terms is held to be unenforceable, these Terms
              will continue in full force and effect without said provision and
              will be interpreted to reflect the original intent of the parties.
              These Terms will be governed by the laws of California, without
              regard to its conflict of laws principles. To the extent that any
              lawsuit or court proceeding is permitted hereunder, the parties
              consent to the personal and exclusive jurisdiction of the state
              and federal courts of San Francisco County, California. You may
              not assign these Terms without the prior written consent of
              Company, and any prohibited assignment will be null and void.
              Company may assign these Terms without restriction and without any
              notice to you. Waiver by either party of a breach of any provision
              of these Terms or the failure by either party to exercise any
              right hereunder will not operate or be construed as a waiver of
              any subsequent breach of that right or as a waiver of any other
              right. YOU AGREE THAT ANY CAUSE OF ACTION BROUGHT BY YOU ARISING
              OUT OF OR RELATED TO THE SERVICE MUST COMMENCE WITHIN ONE YEAR
              AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION
              IS PERMANENTLY BARRED.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
