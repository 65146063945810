import React from "react";
import clsx from "clsx";

function Btn({ handleSubmit, loading, text, disabled = false, className }) {
  return (
    <button
      disabled={disabled}
      onClick={handleSubmit}
      className={clsx(
        "bg-[#105E26] p-3 text-white w-full mt-2 rounded shadow-md shadow-[#105E26]",
        {
          ["bg-gray-400 shadow-none"]: disabled,
          [className]: !!className,
        }
      )}
    >
      {loading ? "Loading..." : text}
    </button>
  );
}

export default Btn;
