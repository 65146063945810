import React from 'react'
import { Review3 } from 'assets/icons/dealerhome'
import { FormattedMessage } from 'react-intl'

const paymentMethod = () => {
  return (
    <div className="   my-3 flex flex-col justify-center w-1/2 md:w-full relative sm:p-6">
      <div className="p-10 sm:p-0 relative w-full h-full flex flex-col justify-center  ">
        <p className="text-2xl text-[#105E26] font-semibold my-4">
          <FormattedMessage id="for_dealer.what_they_say" />
        </p>
        <div className="">
          <p className="text-6xl font-bold my-4 sm:text-3xl">
            Tom Gregg,
          </p>
          <div className=" my-2 sm:block hidden">
            <Review3 width={200} />
          </div>
          <div className=" my-2 sm:hidden">
            <Review3 />
          </div>
        </div>
        <div className="my-8 space-y-3">
          <p className=" text-[#84879E] text-2xl">
            <FormattedMessage id="for_dealer.work_at" />
          </p>
          <p className=" text-[#84879E] text-2xl">
            Toyota of Naperville
          </p>
        </div>
      </div>
    </div>
  )
}

export default paymentMethod
