import React from "react";

function QuotationMark({ fill = "#ffffff", ...rest }) {
  return (
    <svg width="41" height="45" viewBox="0 0 41 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.3281 0.0112393C20.1942 0.024841 20.063 0.0562308 19.9375 0.10506C15.1472 2.02325 10.0823 3.9244 1.45481 4.19823C1.06524 4.21044 0.695899 4.37402 0.424566 4.65373C0.153571 4.93379 0.00186012 5.30836 0.00186012 5.69827C-0.00232515 7.36682 0.00186012 16.7705 0.00186012 18.3062C0.00220886 26.8241 2.60957 32.9141 6.47003 37.1167C10.3301 41.3186 15.3479 43.6129 20.0939 44.9438C20.3596 45.0187 20.6407 45.0187 20.9061 44.9438C25.6518 43.6129 30.6693 41.3186 34.53 37.1167C38.3901 32.9144 40.9978 26.8248 40.9981 18.3062C40.9981 16.7706 41.0023 7.367 40.9981 5.69827C40.9981 5.30835 40.8468 4.93377 40.5754 4.65373C40.3041 4.37402 39.9347 4.21045 39.5452 4.19823C30.9185 3.9241 25.8535 2.02334 21.0625 0.10506C20.8296 0.0129893 20.577 -0.0194479 20.3284 0.0112393H20.3281ZM20.5 3.12014C24.9335 4.88039 30.1775 6.62876 37.9979 7.07277C38 9.86748 37.9979 16.9316 37.9979 18.3057C37.9975 26.2252 35.6751 31.4404 32.3266 35.085C29.0816 38.6173 24.7768 40.6618 20.5 41.9125C16.2228 40.6622 11.918 38.6174 8.67349 35.085C5.32498 31.44 3.00253 26.2253 3.00217 18.3057C3.00217 16.9312 3.00008 9.86721 3.00217 7.07277C10.8225 6.62879 16.0662 4.88039 20.5 3.12014ZM29.9213 13.9941V13.9945C29.5327 14.0178 29.1683 14.1915 28.9057 14.4786L17.0479 27.2427L12.1422 21.5247C11.8837 21.2202 11.5147 21.0312 11.1168 20.9994C10.7185 20.9677 10.3244 21.096 10.021 21.3559C9.71763 21.6153 9.53033 21.9854 9.50034 22.3834C9.47035 22.7816 9.60009 23.1754 9.86132 23.4778L15.8608 30.4769H15.8604C16.1384 30.8006 16.5408 30.9907 16.9671 30.9994C17.3936 31.0085 17.8034 30.8355 18.0946 30.524L31.0935 16.5251C31.3715 16.2328 31.5197 15.8411 31.5054 15.4383C31.4911 15.0351 31.315 14.6549 31.0171 14.3833C30.7193 14.1116 30.3245 13.9714 29.9219 13.9941L29.9213 13.9941Z" fill="black"/>
</svg>

  );
}

export default QuotationMark;
