import { TermsIcon, ThumbsUpIcon } from 'assets/icons/settingIcons'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APIEndpoints } from 'staticStore'

import http from '../../http'
import { toast } from 'react-toastify'
import { setUserData } from 'redux/user'

const ListingManagement = () => {
  const navigate = useNavigate()

  const getListings = async (userID) => {
    setLoading(true)
    await http
      .get(`${APIEndpoints.USER_LISTINGS}?endUserID=${userID}`)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          setPendingListing(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const dispatch = useDispatch()
  const markCarAsSold = async (listingID) => {
    await http
      .post(`${APIEndpoints.SELL}`, {
        id: listingID,
      })
      .then((res) => {
        console.log('_________Res', res)
        if (res.status === 200) {
          // toast.success('Congrats! Your car has been marked as sold.')
          getListings(endUserID)
          if (res.data.accessToken) {
            localStorage.setItem('accessToken', res.data.accessToken)
          }
          if (res.data.user) {
            dispatch(
              setUserData({
                ...res.data.user,
                endName: res.data.user.endName,
                endEmail: res.data.user.endEmail,
                endImage: res.data.user.endImage,
                endPhone: res.data.user.endPhone,
                city: res.data.user.city,
                gender: res.data.user.gender,
                hasInventory: res.data.user.hasInventory,
              })
            )
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const user = useSelector((state) => state.user?.data)
  const endUserID = useSelector((state) => state.user?.data?._id)
  const hasInventory = useSelector((state) => state.user?.data?.hasInventory)
  const isDealer = useSelector((state) => state.user?.data?.isDealer)
  const [pendingListing, setPendingListing] = useState([])
  const [loading, setLoading] = useState(false)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)

  useEffect(() => {
    if (user && loggedIn && !isLoading) {
      getListings(endUserID)
    }
  }, [user, loggedIn, isLoading, navigate])

  const [listing, setListing] = useState({})
  useEffect(() => {
    console.log(pendingListing)
    if (pendingListing) {
      setListing(pendingListing)
    }
  }, [pendingListing])

  return (
    <>
      {!loading && (
        <div className="tab-pane fade show active" id="tabs-homeVertical" role="tabpanel" aria-labelledby="tabs-home-tabVertical">
          <div
            onClick={() => {
              if (isDealer) {
                // toast.error("You can't edit a listing here as a dealer")
                return
              }
              if (!listing?.id) {
                // toast.error("You don't have a listing yet")
                return
              }
              markCarAsSold(listing?.id)
            }}
            className="mb-6 px-2 py-4 border-b border-b-black cursor-pointer  flex space-x-3 items-center">
            <ThumbsUpIcon />
            <p className="text-lg font-bold">
              <FormattedMessage id="support.sold" />
            </p>
          </div>
          <div
            onClick={() => {
              // navigate('/terms-of-service')
              if (isDealer) {
                // toast.error("You can't edit a listing here as a dealer")
                return
              }
              if (!listing?.id) {
                // toast.error("You don't have a listing yet")
                return
              }
              navigate('/create-listing/' + listing?.id)
              window.scrollTo(0, 0)
            }}
            className="mb-6 px-2 py-4 border-b border-b-black cursor-pointer flex space-x-3 items-center">
            <TermsIcon />
            <p className="text-lg font-bold">
              <FormattedMessage id="support.edit" />
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default ListingManagement
