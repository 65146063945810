import React, { useEffect } from "react";

const CheckYourEmail = () => {
  return (
    <>

      <div className="my-8">
        <div className="flex flex-col gap-6 justify-center items-center border-1 rounded-md">
          <h1 className="text-2xl font-bold text-center underline">
            Check your email for further instructions
          </h1>
          <p className="text-center text-lg">
            Thank you. <br />
            If your payment was successful, you will receive an email with
            further instructions.
            <br />
            Please check your email.
          </p>
          <a className="underline" href="/">Home</a>

        </div>


      </div>
    </>
  );
};
export default CheckYourEmail;
