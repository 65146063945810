import React from 'react'
import contactus from '../../assets/images/contactus.png'
import './style.css'
import Form from '../DealerContact/Form'
import DownloadBannerSecondary from 'Components/DownloadBannerSecondary'
import SecondaryBannerMobile1 from 'assets/images/SecondaryBannerMobile1.png'
import { FormattedMessage, useIntl } from 'react-intl'

const DealerContact = () => {
  const intl = useIntl()
  return (
    <div className="p-0">
      {/* container */}
      <div className=" flex flex-col relative h-full w-full   xxl:min-h-[100vh] ">
        <div className="  flex w-full flex-col items-center text-white p-20 lg:pt-5 sm:p-4">
          <div className="w-1/2 my-8 md:mt-1 sm:w-full">
            <p className="text-3xl font-bold text-center md:text-xl">
              <FormattedMessage id="for_dealer.contact.headline" />
            </p>
          </div>
          <div className="w-[70%] lg:w-[80%] md:w-4/5 sm:w-full ">
            <Form />
          </div>
        </div>

        <div className="my-1">
          <DownloadBannerSecondary image={SecondaryBannerMobile1} />
        </div>

        <div className="absolute bg-black xxl:h-[60%]  h-[62%] md:h-[40%] flex xxl:items-baseline md:items-end w-[100%]   -z-10 ">
          <img className="xxl:h-[100%] xxl:w-[90%] h-[600px] md:mt-20 md:h-[300px]  " style={{ margin: '0 auto' }} src={contactus} />
        </div>
      </div>
    </div>
  )
}

export default DealerContact
