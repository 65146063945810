import Btn from 'Components/Buttons/Btn'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forwardStep, setListing } from 'redux/listing'
import Checkbox from '@mui/material/Checkbox'
import RightDiv from './RightDiv'
import { seating } from '../../Resources/Data/seating'
import { roof } from '../../Resources/Data/roof'
import { wheels } from '../../Resources/Data/wheels'
import { safety } from '../../Resources/Data/safety'
import { technology } from '../../Resources/Data/technology'
import { option } from '../../Resources/Data/options'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import './style.css'
import { capitalizeFirstLetter } from 'Utils/Parse'
import { useIntl } from 'react-intl'

const Step2 = ({ pendingListing, handleSubmit, loading }) => {
  const dispatch = useDispatch()

  const handleRadioChange = (e, category) => {
    const name = e.target.name
    const checked = e.target.checked

    const wanted = ['heatedSeats', 'memorySeats', 'powerSeats']

    const returnOnlyWantedObjects = (obj) => {
      let newObj = {}
      for (const key in obj) {
        if (wanted.includes(key)) {
          newObj[key] = obj[key]
        }
      }
      return newObj
    }

    dispatch(
      setListing({
        ...pendingListing,
        [category]: {
          ...returnOnlyWantedObjects(pendingListing[category]),
          [name]: checked,
        },
      })
    )
  }

  const handleCheckboxChange = (e, category) => {
    const name = e.target.name
    const checked = e.target.checked

    dispatch(
      setListing({
        ...pendingListing,
        [category]: {
          ...pendingListing[category],
          [name]: checked,
        },
      })
    )
  }
  const intl = useIntl()

  const renderRadioButtons = (data, category) => {
    const exempt = ['heatedSeats', 'memorySeats', 'powerSeats']

    let _data = [...data]

    if (category === 'seating') {
      _data = _data.filter((item) => !exempt.includes(item.name))
    }
    return (
      <div className="checkbox-section">
        {' '}
        {/* <-- Here's the new class */}
        <h1 className="font-semibold">{intl.formatMessage({ id: category.toLowerCase() })}</h1> {/* capitalize the category name */}
        <RadioGroup className="radio-grid">
          {_data.map((item) => (
            <FormControlLabel
              key={item._id}
              control={
                <Checkbox
                  name={item.name}
                  checked={pendingListing[category]?.[item.name] || false}
                  onChange={(e) => handleRadioChange(e, category)}
                />
              }
              label={
                intl.formatMessage({
                  id: `${item.translation}`,
                }) /* <-- Here's the new class */
              }
            />
          ))}
        </RadioGroup>
      </div>
    )
  }

  const renderCheckboxes = (data, category) => {
    const wantedSeats = ['heatedSeats', 'memorySeats', 'powerSeats']

    let _data = [...data]

    if (category === 'seating') {
      _data = _data.filter((item) => wantedSeats.includes(item.name))
    }

    return (
      <div className="checkbox-section">
        {/* <-- Here's the new class */}
        {category !== 'seating' && <h1 className="font-semibold">{intl.formatMessage({ id: category.toLowerCase() })}</h1>}
        {/* capitalize the category name */}
        <div className="checkbox-grid">
          {_data.map((item) => (
            <FormControlLabel
              key={item._id}
              control={
                <Checkbox
                  name={item.name}
                  checked={pendingListing[category]?.[item.name] || false}
                  onChange={(e) => handleCheckboxChange(e, category)}
                />
              }
              label={
                intl.formatMessage({
                  id: `${item.translation}`,
                }) /* <-- Here's the new class */
              }
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="flex space-x-3 sm:flex-col sm:space-x-0">
      {/* left */}
      <div className="step2-container rounded h-fit w-3/5 border-2  p-10 sm:w-full sm:p-8 sm:px-3">
        <div className="w-4/5 my-2 sm:w-full ">
          <p className="sm:text-sm">
            {
              intl.formatMessage({
                id: 'create_listing.steps.2.title',
              }) /* <-- Here's the new class */
            }
          </p>
        </div>
        <div className="w-full my-2 sm:w-full">
          {renderRadioButtons(seating, 'seating')}
          {renderCheckboxes(seating, 'seating')}
        </div>
        <div className="w-full my-2 sm:w-full">{renderRadioButtons(roof, 'roof')}</div>
        <div className="w-full my-2 sm:w-full">{renderRadioButtons(wheels, 'wheels')}</div>
        <div className="w-full my-2 sm:w-full">{renderCheckboxes(technology, 'technology')}</div>
        <div className="w-full my-2  sm:w-full">{renderCheckboxes(safety, 'safety')}</div>
        <div className="w-full my-2  sm:w-full">{renderCheckboxes(option, 'option')}</div>
        <div className="w-full my-2 sm:w-full">
          <Btn text={intl.formatMessage({ id: 'continue' })} className="continue-btn w-2/5 sm:w-3/5" handleSubmit={handleSubmit} />
        </div>
      </div>
      <RightDiv loading={loading} />
    </div>
  )
}

export default Step2
