import React, { useEffect } from 'react'
import SlickSlider from '../../Components/SlickSlider/SlickSlider'
import Intro from './Intro'
import About from './About'
import HomeCardCarousel from './Slider/HomeCardCarousel'
import HomeCarTypeCardCarousel from './Slider/HomeCarTypeCardCarousel'
import DownloadBannerPrimary from 'Components/DownloadBannerPrimary'
import DownloadBannerSecondary from 'Components/DownloadBannerSecondary'
import SecondaryBannerMobile from 'assets/images/SecondaryBannerMobile.png'
import useFetchOptions from 'redux/hooks/useFetchOptions'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

const Home = () => {
  const { makes, models, cities } = useSelector((state) => state.options)
  return (
    <div className=" ">
      {/* container */}
      <div className=" pb-4">
        <Intro />
      </div>
      <div className="logo-wrapper flex flex-col !m-0 p-10  md:p-4  ">
        <p className=" lg:-sm:text-[32px] sm:text-2xl xl:-lg:text-[48px] text-[48px] font-[700] pb-4 ">
          {<FormattedMessage id="home.popular_brands" />}
        </p>
        {makes.length ? <SlickSlider data={makes.filter((item) => item.makeLogo)} /> : <div className="text-center">No Brands to show</div>}
        {/* <WhatIsIncludeInCarInspection /> */}
      </div>

      <div className="py-8 sm:hidden">
        <DownloadBannerPrimary />
        {/* <img src={downloadbanner1} /> */}
      </div>
      <div className="py-4  ">
        <About />
      </div>

      <div className=" py-4 ">
        <HomeCardCarousel />
      </div>
      <div className="pt-0  sm:py-2 ">
        <HomeCarTypeCardCarousel />
      </div>
      <div className="py-32 sm:py-4 sm:hidden">
        <DownloadBannerSecondary image={SecondaryBannerMobile} />
      </div>
    </div>
  )
}

export default Home
