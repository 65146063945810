import React from "react";

const QuestionMark = ({ fill, className = "" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="9" cy="9" r="9" fill={fill || "#105E26"} />
      <path
        d="M8.66319 10.9631C8.65943 10.8277 8.65755 10.7261 8.65755 10.6584C8.65755 10.2597 8.71398 9.91551 8.82682 9.62587C8.90958 9.4077 9.04311 9.18764 9.22743 8.96571C9.36285 8.80396 9.60547 8.56887 9.9553 8.26042C10.3089 7.94821 10.5383 7.69994 10.6437 7.51563C10.749 7.33131 10.8016 7.13006 10.8016 6.91189C10.8016 6.51693 10.6474 6.17086 10.339 5.8737C10.0305 5.57277 9.65249 5.42231 9.20486 5.42231C8.77228 5.42231 8.41117 5.55773 8.12153 5.82856C7.83189 6.09939 7.64193 6.52257 7.55165 7.09809L6.50781 6.97396C6.60185 6.20284 6.88021 5.61227 7.34288 5.20226C7.80932 4.79225 8.42433 4.58724 9.18793 4.58724C9.99667 4.58724 10.6418 4.80729 11.1233 5.2474C11.6047 5.6875 11.8455 6.21976 11.8455 6.84418C11.8455 7.2053 11.7609 7.53819 11.5916 7.84288C11.4223 8.14757 11.0913 8.51808 10.5985 8.95443C10.2675 9.24783 10.0512 9.46412 9.94965 9.6033C9.84809 9.74248 9.77286 9.90234 9.72396 10.0829C9.67506 10.2635 9.64685 10.5569 9.63932 10.9631H8.66319ZM8.60113 13V11.8433H9.75781V13H8.60113Z"
        fill="white"
      />
    </svg>
  );
};

export default QuestionMark;
