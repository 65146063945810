import React, { Fragment } from 'react'
import { Button, Popover } from '@mui/material'
import { DropDownIcon } from '../../../assets/icons/navbarIcons'
import suv from '../../../assets/images/bodytype/suv.png'
import sedan from '../../../assets/images/bodytype/sedan.png'
import coupe from '../../../assets/images/bodytype/coupe.png'
import truck from '../../../assets/images/bodytype/truck.png'
import van from '../../../assets/images/bodytype/van.png'
import convertible from '../../../assets/images/bodytype/convertible.png'
import sports from '../../../assets/images/bodytype/sports.png'
import hatchback from '../../../assets/images/bodytype/hatchback.png'
import c_suv from '../../../assets/images/bodytype/c_suv.png'



export const bodyType = [
  { name: 'Sedan', image: sedan },
  { name: 'SUV', image: suv },
  { name: 'Coupe', image: coupe },
  { name: 'Truck', image: truck },
  { name: 'MiniVan', image: van },
  { name: 'Convertible', image: convertible },
  { name: 'Sports', image: sports },
  { name: 'HatchBack', image: hatchback },
  { name: 'C_SUV', image: c_suv },

]
const SearchResultDropDown = ({ title }: any) => {
  const [anchorE2, setAnchorE2] = React.useState(null)
  const makeOpen = Boolean(anchorE2)
  const handleMakeClose = () => {
    setAnchorE2(null)
  }

  const handleMakeClick = (event: any) => {
    setAnchorE2(event.currentTarget)
  }

  return (
    <>
      <button
        onClick={handleMakeClick}
        className="flex items-center space-x-3  md:justify-center"
      >
        <span
          className={`tracking-widest ${makeOpen ? 'text-[#105e26]' : 'text-[#c4c4c4]'
            } md:text-xs md: tracking-wide `}
        >
          BODY TYPE
        </span>
        <DropDownIcon stroke={`${makeOpen ? '#105e26' : '#c4c4c4'}  `} />
      </button>
      <Popover
        open={makeOpen}
        anchorEl={anchorE2}
        onClose={handleMakeClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginY: '10px' }}
      >
        <ul className={' text-grey text-white rounded-none   flex sm:flex-col'}>
          {/* <li className="flex items-center p-4  bg-[#105e26] space-x-3 border-b-[1px] border-[#A1A1A1]   ">
          <button onClick={handleMakeClose}>
              <SearchIcon fill={"#fff"} />
            </button>
           <input type="text" placeholder="Origin" className="bg-transparent outline-none"/>
            
          </li> */}

          {bodyType.map((e, index) => {
            return (
              <li
                key={index}
                className="flex flex-col justify-center  p-2  cursor-pointer  bg-[#105e26] hover:bg-[#106F2B]    "
              >
                <img className="body-type" src={e.image} />
                <p>{e.name}</p>
              </li>
            )
          })}
        </ul>
      </Popover>
    </>
  )
}

export default SearchResultDropDown
