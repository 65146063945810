import { createSlice } from '@reduxjs/toolkit'

interface monthlyCalculator {
  interestRate: number
  loanDuration: number
  downPayment: number
  isPercentage: boolean
}

interface EndUserData {
  firstName: string
  id: string
  isDealer: boolean
  language: 'vn'
  _id: string
  uid: string
  createdAt?: string
  endEmail: string
  endPassword: string
  endName: string
  endPhone?: string
  isRegistered: boolean
  endImage?: string
  city?: string
  gender?: string
  hasInventory: boolean
  isVerified: boolean
  status: number
  updatedAt?: string
  __v?: number
  monthlyCalculator?: monthlyCalculator
}

interface EndUser {
  loggedIn: boolean
  data: EndUserData
  verificationMessage: string | null
  loading: boolean
}

const initialState: EndUser = {
  loggedIn: false,
  data: {} as EndUserData,
  verificationMessage: null,
  loading: true,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload
    },
    setUserData: (state, action) => {
      state.data = action.payload
    },
    setVerificationMessage: (state, action) => {
      state.verificationMessage = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoggedIn, setUserData, setVerificationMessage, setLoading } = userSlice.actions

export default userSlice.reducer
