import React from "react";
import upArrow from "../../top-right-arrow.png";

function GoodDealIcon({ fill = "#ffffff", ...rest }) {
  return (
    <div className="bg-[#51913B] w-6 h-6 flex items-center justify-center rounded-full">
      <img src={upArrow} />
    </div>
  );
}

export default GoodDealIcon;
