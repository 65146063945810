import React, { useEffect, useState } from 'react'

import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import CustomInput from 'Components/CustomInput'
import { isEmail } from 'utils'
import { useDispatch } from 'react-redux'
import { forwardStep, setSignupData, toggleEmailSent } from 'redux/signup'
import Btn from 'Components/Buttons/Btn'
import { toast } from 'react-toastify'
import TinnySpinner from 'Components/TinnySpinner'
import TickIcon from '@mui/icons-material/FileDownloadDone'
import ErrorIcon from '@mui/icons-material/Error'
import './style.css'
import { useIntl } from 'react-intl'
import SocialSignup from '../../Components/SocialSignup'

const Left = () => {
  const dispatch = useDispatch()
  const [error, setError] = useState({})
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  })

  useEffect(() => {
    console.log(state)
  }, [state])
  const [signup, { loading }] = useMutation({
    endPoint: APIEndpoints.SIGNUP,
    method: 'post',
  })

  const [checkEmail, { loading: checkEmailLoading, error: checkEmailError, data: checkEmailData }] = useMutation({
    method: 'post',
    endPoint: APIEndpoints.CHECK_EMAIL,
  })

  const emailFeedback = isEmail(state.email) ? (
    error.email ? (
      <ErrorIcon htmlColor="red" />
    ) : isEmail(state.email) ? (
      <TickIcon htmlColor="#105E26" className="absolute  top-[17px] right-3" />
    ) : null
  ) : null

  const isValidNumber = (value) => {
    const regex = /^\d{8,11}$/ // Matches numbers with 8 to 11 digits
    return regex.test(value)
  }

  const handleChange = (ev) => {
    const { name, value } = ev.target
    setState({
      ...state,
      [name]: value,
    })
    if (name === 'phone') {
      if (isValidNumber(value)) {
        setError({ phone: null })
      } else {
        setError({ phone: 'Invalid phone number format' })
      }
    }
  }

  const handleSubmit = () => {
    if (canSubmit()) {
      if (error.email) {
        // toast.error('Email already exists')
        return
      }
      signup({
        firstName: state.firstName,
        lastName: state.lastName,
        endEmail: state.email,
        endPhone: state.phone,
        endPassword: state.password,
        confirmPassword: state.confirmPassword,
      })
        .then((data) => {
          if (data && data.data) {
            dispatch(setSignupData(data.data))
            dispatch(forwardStep())
          }
        })
        .catch((error) => {
          // toast.error('Error', { description: error })
        })
    }
  }

  const canSubmit = () => {
    const noErrors = !Object.values(error).some((e) => e) // True if there are no errors
    return Object.values(state).every((el) => !!el) && isEmail(state.email) && state.password === state.confirmPassword && noErrors
  }

  const intl = useIntl()

  return (
    <div className=" p-10  my-3 rounded-xl border-0 bg-[#FFF]  text-black w-full sm:p-4 shadow-md flex-col">
      {/* Heading */}
      <div className="my-5 xxl:my-8  ">
        <h1 className="text-3xl font-bold text-center xxl:text-[36px]"> {intl.formatMessage({ id: 'login.create_an_account' })}</h1>
      </div>

      {/* inputs */}
      <div className="my-8 xxl:my-12 flex space-x-4 sm:space-x-0 sm:flex-col sm:space-y-4 sm:my-4 ">
        <CustomInput
          name="firstName"
          value={state.firstName}
          handleChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'sign_up.first_name',
          })}
        />
        <CustomInput
          name="lastName"
          value={state.lastName}
          handleChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'sign_up.last_name',
          })}
        />
      </div>

      <div className="input-2x1 my-8 xxl:my-12 flex space-x-4 sm:space-x-0 sm:flex-col sm:space-y-4 sm:my-4 ">
        <div className="relative w-full">
          <CustomInput
            name="email"
            value={state.email}
            handleChange={handleChange}
            placeholder={intl.formatMessage({ id: 'sign_up.email' })}
            type="email"
            onEnter={handleSubmit}
            error={error.email}
            autoComplete="email"
          />
        </div>
        <CustomInput
          name="phone"
          value={state.phone}
          handleChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'sign_up.phone_number',
          })}
          type="tel"
          onEnter={handleSubmit}
          error={error.phone}
          maxLength={11}
          autoComplete="tel"
        />
      </div>

      <div className="input-2x1 marker:my-8 xxl:my-12 flex space-x-4 sm:space-x-0 sm:flex-col sm:space-y-4  sm:my-4 ">
        <CustomInput
          name="password"
          value={state.password}
          handleChange={handleChange}
          placeholder={intl.formatMessage({ id: 'sign_up.password' })}
          type="password"
          autoComplete="new-password"
        />
        <CustomInput
          name="confirmPassword"
          value={state.confirmPassword}
          handleChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'sign_up.confirm_password',
          })}
          type="password"
          autoComplete="new-password"
        />
      </div>

      {/* button */}
      <div className=" my-8 xxl:my-12  mt-16">
        <Btn handleSubmit={handleSubmit} loading={loading} text={intl.formatMessage({ id: 'sign_up.sign_up' })} disabled={!canSubmit()} />
      </div>

      <div className="xl:my-5 my-8 ">
        <h1 className="text-center text-md font-bold my-2">{intl.formatMessage({ id: 'sign_up.social' })} </h1>
        <SocialSignup />
        <p className="text-md text-[#707070] text-center my-8 sm:text-sm sm:mt-4">
          {intl.formatMessage({ id: 'login.terms_mention' })}{' '}
          <a href="/privacy-policy" className="text-[#105e26]">
            {intl.formatMessage({ id: 'login.statement' })}{' '}
          </a>
          {intl.formatMessage({ id: 'login.and' })}{' '}
          <a href="/terms-of-service" className="text-[#105e26]">
            {intl.formatMessage({ id: 'login.terms_of_service' })}.
          </a>
        </p>
      </div>
    </div>
  )
}

export default Left
