import React from "react";
import sellcar from '../../../assets/images/sellcar1.png'
const Left = () => {
  return (
    <div className=" p-10  my-3 flex justify-center items-center w-1/2 md:w-full ">

    <div className=" h-full w-full xl:max-h-[600px] xl:max-w-[600px] xxl:max-h-[900px] xxl:max-w-[900px]  ">
      <img className="h-full w-full rounded-full" src={sellcar} />
    </div>

    </div>
  );
};

export default Left;
