import React from 'react'
// import "./style.css";
import Left from './Left'
import Right from './Right'
import { FormattedMessage } from 'react-intl'

const ListingProcess = () => {
  return (
    <>
      <div className="flex flex-col items-center">
        <p className=" font-semibold mt-4 mb-2 text-[37px]">
          <FormattedMessage id="listing_process" />
        </p>
        <p className="text-[18px] text-center w-1/2 my-4">
          <FormattedMessage id="listing_process.description" />{' '}
        </p>
      </div>
      <div className="p-10 pr-0  sm:p-4 sm:pr-0 flex md:flex-col">
        {/* left */}
        <Left />
        {/* right */}
        <Right />
      </div>
    </>
  )
}

export default ListingProcess
