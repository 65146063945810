import React from 'react'

import gplaystore from '../../assets/images/gplaystore1.png'
import appstore from '../../assets/images/appstore1.png'
import DownloadBannerPrimaryDots from 'assets/icons/DownloadBannerPrimaryDots'
import { FormattedMessage, useIntl } from 'react-intl'

const DownloadBannerSecondary = ({ image }) => {
  const intl = useIntl()
  return (
    <div
      className="   relative h-96  flex items-center my-10  sm:my-0 sm:text-center sm:h-64 sm:p-4  w-[85%] m-auto rounded-lg "
      // style={{backgroundImage: `url(${require('../../assets/images/bgbannerprimary.png')}`}}
    >
      <div
        style={
          {
            // backgroundImage: `url(${bgbannerprimary})`,
            // width: "100%",
            // backgroundSize: " cover",
            // backgroundPosition: "center center",
            // backgroundRepeat: "no-repeat",
          }
        }
        className="bannerContainer bg-black  h-64 w-full flex sm:rounded-md    items-center rounded-2xl ">
        <div className="w-4/6 xl:w-3/6 xxl:w-3/6   p-6 md:p-10 md:pr-0 sm:p-4 sm:w-full    ">
          <p className="my-4 text-3xl  font-bold text-white sm:text-center sm:text-2xl leading-[1]">
            {intl.formatMessage({ id: 'home.outro.title' })}
          </p>
          <p className="my-4 text-xl   text-white sm:text-center sm:text-md">
            <FormattedMessage id="home.intro.headline" />
          </p>

          <div className="space-x-10   flex my-4 sm:space-x-3 sm:justify-center items-center  ">
            <img
              className="h-full  w-60   xl:h-16  xl:w-40 lg:h-[60%] lg:w-[55%] box-content   object-contain drop-shadow-[1px_2px_10px_rgba(0,255,0,0.6)]  hover:scale-110 cursor-pointer "
              src={gplaystore}
              alt="gplaystore"
            />
            <img
              className="h-full  w-60   xl:h-12 xl:w-40 lg:h-[60%] lg:w-[55%]  box-content   object-contain drop-shadow-[1px_2px_10px_rgba(0,255,0,0.6)] hover:scale-110 cursor-pointer"
              src={appstore}
              alt="appstore"
            />
          </div>
        </div>
        <div className="w-2/6 xl:w-3/6 xxl:w-3/6  flex justify-center items-center sm:hidden   h-52">
          <div className=" flex justify-center items-center relative h-full sm:hidden z-10  ">
            <img
              className="w-[80%]  xl:w-[60%] lg:w-[40%]    drop-shadow-[0px_2px_5px_rgba(245,233,0,0.7)]"
              src={image}
            />
          </div>
          <DownloadBannerPrimaryDots className="h-24 absolute z-0 " />
        </div>
      </div>
    </div>
  )
}

export default DownloadBannerSecondary

// import React from "react";
// import bgbannerprimary from "../../assets/images/bgbannerprimary.png";

// import gplaystore from "../../assets/images/gplaystore.png";
// import appstore from "../../assets/images/appstore1.png";
// import DownloadBannerPrimaryDots from "assets/icons/DownloadBannerPrimaryDots";
// import SecondaryBannerMobile from "assets/images/SecondaryBannerMobile.png";

// const DownloadBannerPrimary = () => {
//   return (
//     <div
//       className="   relative h-[480px] md:h-[380px] flex items-center my-0 sm:my-0 sm:text-center sm:h-64 p-10 md:p-4 sm:p-4 "
//       // style={{backgroundImage: `url(${require('../../assets/images/bgbannerprimary.png')}`}}
//     >
//       <div className="absolute  right-[12%] md:right-[8%] top-2 h-full sm:hidden  ">
//         <img
//         // drop-shadow-[0px_2px_5px_rgba(245,233,0,0.7)]
//           className="h-full "
//           src={SecondaryBannerMobile}
//         />
//       </div>
//       <div className="absolute  right-[25%] md:right-[8%] top-[40%] h-full sm:hidden  ">
//       <DownloadBannerPrimaryDots />
//       </div>

//       <div
//         // style={{ backgroundImage: `url(${bgbannerprimary})` }}
//         className="bannerContainer h-64 w-full flex items-center  sm:rounded-md bg-black text-white rounded-xl   "
//       >

//         <div className="w-3/6  p-20  md:p-6 md:w-3/6  sm:w-full sm:p-4 ">
//           <p className="my-4 text-3xl font-bold text-white sm:text-center md:text-2xl sm:text-2xl">
//             Get the Top-Rated App
//           </p>
//           <p className="my-4 text-xl md:my-0 md:mt-4 text-white sm:text-center md:text-sm sm:text-md">
//             Over 1 Million Downloads
//           </p>
//           <div className="space-x-3  flex my-8 md:my-4   sm:justify-evenly">
//             <img className="h-10 w-30 cursor-pointer sm:w-28" src={gplaystore} />{" "}
//             <img className="h-10 w-30 cursor-pointer sm:w-28" src={appstore} />{" "}
//           </div>
//         </div>
//          <div className="w-3/6  flex justify-center  items-center    sm:hidden  ">

//         </div>
//       </div>
//     </div>
//   );
// };

// export default DownloadBannerPrimary;
