import React, { Fragment, useState, useEffect } from 'react'
import { Button, Popover, listClasses } from '@mui/material'
import {
  DeleteIcon,
  DropDownIcon,
} from '../../assets/icons/navbarIcons'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  capitalizeFirstLetter,
  capitalizeAllFirstLetter,
} from '../../Utils/Parse'
import { DropDownSearch } from 'Components/Search'
import List from './searchResultDropdown/List'
import { cleanSearch, filterBySearch } from 'Utils/Search/Filter'
import { useIntl } from 'react-intl'

export interface CustomDropDownPropTypes {
  title: string
  overrideTitle?: boolean // if true, title will be overriden by state, otherwise it will always be title irrespective of selected state
  shown?: number
  state: Object
  setState: Function
  enabledSearch?: boolean
  api?: string
  extraDropDown?: Object
  property?: string
  deps?: string
  disabled?: boolean
  cache?: boolean
  optionProps?: string[]
  style?: Object
  className?: string
  children?: any
  listClasses?: string
  translate?: boolean
}

type Distance = string

interface Render {
  _id: string
  name: string
}
type Option = Render | Distance
const DynamicDropDown: React.FC<CustomDropDownPropTypes> = ({
  title,
  overrideTitle = true,
  shown = 5,
  state,
  setState,
  enabledSearch = true,
  api,
  property,
  deps = '',
  disabled = false,
  cache = true,
  optionProps = [],
  style,
  className = '',
  children = null,
  listClasses = '',
  translate = false,
}: any) => {
  const [anchorE2, setAnchorE2] = React.useState(null)
  const [fetched, setFetched] = useState<Render[]>([])
  const [options, setOptions] = useState<Render[] | Option[]>([])
  const [name, setName] = useState(title)
  const makeOpen = Boolean(anchorE2)
  const { search } = useLocation()
  const handleMakeClose = () => {
    setAnchorE2(null)
  }
  useEffect(() => {
    // console.log('OPTION PROPS: ', deps)
    if (deps) {
      fetchData()
    }
  }, [search, state.make])

  // if user scroll down, close the dropdown
  useEffect(() => {
    window.addEventListener('scroll', handleMakeClose)
    return () => window.removeEventListener('scroll', handleMakeClose)
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const searchName = urlParams.get(title.toLowerCase())
    if (searchName) {
      setName(searchName)
      setState((prev: any) => ({
        ...prev,
        [title.toLowerCase()]: searchName,
      }))
    }
  }, [])

  const handleMakeClick = async (e: any) => {
    setAnchorE2(e.currentTarget)
    if (deps || (cache && options.length)) return
    if (!api) {
      setFetched(optionProps)
      setOptions(optionProps.slice(0, shown))
      return
    }
    await fetchData()
  }

  const fetchData = async () => {
    const res = await fetch(api)
    const data: Render[] = await res.json()
    const tmp = data.map((e: any) => {
      return {
        _id: e._id,
        name: e[property],
      }
    })
    if (state[title.toLowerCase()]) {
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].name.toLowerCase() === state.make.toLowerCase()) {
          setState((prev: any) => ({
            ...prev,
            makerid: tmp[i]._id,
          }))
          break
        }
      }
    }

    setFetched(tmp)
    setOptions(tmp.slice(0, shown))
    // console.log(data)
  }
  const handleOnChange = (e: { target: { value: string } }) => {
    const search = cleanSearch(e.target.value)
    const filtered = filterBySearch(fetched, search)
    setOptions(filtered)
  }
  // when state name changes, update state

  useEffect(() => {
    if (!state[title.toLowerCase()]) setName(title.toLowerCase())
    else setName(state[title.toLowerCase()])
  }, [state])

  const intl = useIntl()

  return (
    <>
      <button
        onClick={handleMakeClick}
        className={clsx(
          `flex items-center lg:p-0 lg:py-2 -lg:p-2 transition-all ease-in-out duration-300 ${
            makeOpen ? 'text-[#105e26]' : 'text-black'
          } `,
          {
            'text-black': name.toLowerCase() !== title.toLowerCase(),
            'bg-[#343434] text-white  pointer-events-none cursor-default':
              disabled,
            [className]: !!className,
          }
        )}
        style={style}
      >
        {overrideTitle
          ? capitalizeAllFirstLetter(name)
          : intl.formatMessage({
              id: 'search.' + title.toLowerCase(),
            })}
        <div className=" ml-2 ">
          {/* If the name changes and override title is allowed, show the selected item name, otherwise be as you were. */}
          {name.toLowerCase() !== title.toLowerCase() &&
          overrideTitle ? (
            <div
              onClick={(e) => {
                e.stopPropagation()
                setState((prev: any) => {
                  delete prev[title.toLowerCase()]
                  if (title === 'MAKE') {
                    delete prev['makerid']
                    delete prev['model']
                  }
                  return {
                    ...prev,
                  }
                })
                setName(title)
              }}
              className="flex justify-center cursor-pointer  items-center w-6 h-6 rounded-full bg-white text-[#105e26]"
            >
              {/* <DeleteIcon stroke={` ${makeOpen ? '#105e26' : '#c4c4c4'}`} /> */}
            </div>
          ) : (
            <DropDownIcon
              stroke={` ${makeOpen ? '#105e26' : '#000'}`}
            />
          )}
        </div>
      </button>
      <Popover
        open={makeOpen}
        anchorEl={anchorE2}
        onClose={handleMakeClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          overflow: 'hidden ',
        }}
        classes={{ paper: 'force-rounded-xl' }}
      >
        {enabledSearch && (
          <DropDownSearch
            placeholder={
              name.toLowerCase() !== title.toLowerCase()
                ? capitalizeFirstLetter(name)
                : `Find ${capitalizeFirstLetter(
                    title.toLowerCase()
                  )}...`
            }
            handleOnChange={handleOnChange}
          />
        )}
        <div className="block top-3 z-10 overflow-hidden">
          <List
            classes={clsx(listClasses)}
            options={options}
            translate={translate}
            handleOnClick={(e: {
              _id: any
              name: string
              toLowerCase: () => any
            }) => {
              const name = e.name || e
              setState((prev: any) => {
                if (title === 'MAKE') {
                  prev['makerid'] = e._id
                  delete prev['model']
                }
                return {
                  ...prev,
                  [title.toLowerCase()]: name.toLowerCase(),
                }
              })
              setName(name)
              handleMakeClose()
            }}
            active={name}
          />
        </div>
      </Popover>

      {children}
    </>
  )
}

export default DynamicDropDown
