import { IntlProvider } from 'react-intl'

import { defaultLocale, locales } from '../Resources/i18n'
import { useState } from 'react'
import { LocaleContext } from './LocaleContext'
// Import the configuration we created earlier
const I18n = (props) => {
  const [locale, setLocale] = useState(defaultLocale)
  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        //  IntlProvider needs to be fed the active `locale`
        //     as well as the translation `messages` of the
        //     active locale. The `defaultLocale` is a
        //     fallback when there is a missing translation.
        locale={locale}
        defaultLocale={defaultLocale}
        messages={locales[locale].messages}
      >
        {props.children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export default I18n
