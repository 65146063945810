import { capitalizeAllFirstLetter } from 'Utils/Parse'
import React from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
interface ListPropTypes {
  classes?: string
  optionClasses?: string
  options: any[]
  translate?: boolean
  handleOnClick: Function
  nonActiveColor?: string
  active: string
}
const List: React.FC<ListPropTypes> = ({
  classes = '',
  optionClasses = 'w-full text-left p-2 pl-6 transition-all ease-in-out duration-100  cursor-pointer border-y-2 border-transparent  hover:border-[#A1A1A1]',
  options,
  translate = false,
  handleOnClick,
  nonActiveColor = '',
  active,
}) => {
  const intl = useIntl()
  return (
    <>
      <ul className={'w-full min-w-[12rem] justify-center text-black rounded-none   min-h-max   overflow-y-hidden box-content ' + classes}>
        {options.length ? (
          options.map((e: any) => {
            return (
              <li
                key={e._id || e + Math.random() * 100000000}
                onClick={() => {
                  handleOnClick(e)
                }}
                className={clsx(
                  ' ',
                  {
                    // if the option is selected, then highlght it and don't allow it to be clicked
                    [nonActiveColor]: active.toLowerCase() !== (e.name || e).toLowerCase(),
                    'bg-[#10A43A40] text-black pointer-events-none cursor-default': active.toLowerCase() === (e.name || e).toLowerCase(),
                    'flex flex-col justify-center  items-center ': e.image,
                  },
                  optionClasses
                )}>
                {e.image && <img src={e.image} className=" w-full h-[50px] mb-2 object-center  object-contain" alt="" />}
                <p>
                  {translate
                    ? intl.formatMessage({
                        id: !e.name ? e.toLowerCase() : e.name.toLowerCase(),
                      })
                    : !e.name
                    ? e
                    : capitalizeAllFirstLetter(e.name)}
                </p>
              </li>
            )
          })
        ) : (
          <li className="flex justify-center items-center w-full p-4 transition-all ease-in-out duration-200  cursor-pointer  bg-[#105e26]   ">
            <p>No results found</p>
          </li>
        )}
      </ul>
    </>
  )
}

export default List
