import SvgIcon from '@mui/material/SvgIcon';

const HeartIcon = ({ isWishlisted, ...props}) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 512 512"
    >
      <path
        d="M352.92,80C288,80,256,144,256,144s-32-64-96.92-64C106.32,80,64.54,124.14,64,176.81c-1.1,109.33,86.73,187.08,183,252.42a16,16,0,0,0,18,0c96.26-65.34,184.09-143.09,183-252.42C447.46,124.14,405.68,80,352.92,80Z"
        fill={isWishlisted ? "#105e26" : "transparent"}
        stroke={props.stroke || "#fff"}
        strokeWidth={props.strokeWidth || "20.0303"}
      />
    </SvgIcon>
  );
};

export default HeartIcon;
