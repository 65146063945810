import React from "react";

function ComprehensiveReport({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM17.0461 9.41484C17.0531 9.525 17.0531 9.63984 17.0531 9.75234C17.0531 13.193 14.4328 17.1562 9.64453 17.1562C8.16797 17.1562 6.79922 16.7273 5.64609 15.9891C5.85703 16.0125 6.05859 16.0219 6.27422 16.0219C7.49297 16.0219 8.61328 15.6094 9.50625 14.9109C8.3625 14.8875 7.40156 14.1375 7.07344 13.1063C7.47422 13.1648 7.83516 13.1648 8.24766 13.0594C7.65873 12.9397 7.12939 12.6199 6.74957 12.1542C6.36974 11.6885 6.16286 11.1056 6.16406 10.5047V10.4719C6.50859 10.6664 6.91406 10.7859 7.33828 10.8023C6.98166 10.5647 6.6892 10.2427 6.48682 9.86491C6.28445 9.48715 6.17841 9.06528 6.17813 8.63672C6.17813 8.15156 6.30469 7.70859 6.53203 7.32422C7.18571 8.12891 8.0014 8.78705 8.92609 9.25586C9.85078 9.72466 10.8638 9.99364 11.8992 10.0453C11.5312 8.27578 12.8531 6.84375 14.4422 6.84375C15.1922 6.84375 15.8672 7.15781 16.343 7.66406C16.9312 7.55391 17.4937 7.33359 17.9953 7.03828C17.8008 7.64062 17.393 8.14922 16.8516 8.47031C17.3766 8.41406 17.8828 8.26875 18.3516 8.06484C17.9977 8.58516 17.5547 9.04688 17.0461 9.41484Z"
        fill={fill}
      />
    </svg>
  );
}

export default ComprehensiveReport;
