import React, { useEffect } from 'react'
import { ReactComponent as FacebookIcon } from '../assets/icons/facebookIcon.svg'
import { ReactComponent as GoogleIcon } from '../assets/icons/googleIcon.svg'
import { ReactComponent as AppleIcon } from '../assets/icons/appleIcon.svg'
import { FacebookAuthProvider, GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithPopup, OAuthProvider } from 'firebase/auth'
import { auth } from 'SDK/Firebase'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { useNavigate } from 'react-router-dom'
import { setLoggedIn, setUserData } from 'redux/user'
import { useDispatch } from 'react-redux'
function SocialSignup() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleAfterLogin = (data) => {
    console.log('HANDLE AFTER LOGIN DATA: >>>> ', { data })
    if (data?.accessToken) {
      localStorage.setItem('accessToken', data.accessToken)
    }
    if (data?.data) {
      // alert("loggedIn");
      dispatch(setUserData(data.data))
      dispatch(setLoggedIn(true))
      navigate('/')
    }
  }

  const [signInWithSocial] = useMutation({
    endPoint: APIEndpoints.SOCIAL_SIGNUP,
    method: 'post',
  })
  const handleSignUp = (res) => {
    console.log(res)
    console.log(res._tokenResponse.idToken)
    //redirect to finish signup page
    const idToken = res._tokenResponse.idToken
    const data = {
      idToken,
      tmpEmail: res.user.email ?? res.user.providerData[0].email,
    }
    console.log(res.user.email ?? res.user.providerData[0].email)
    signInWithSocial(data)
      .then(handleAfterLogin)
      .catch((error) => {
        console.log(error)
      })
  }

  const signUpWithGoogle = () => {
    console.log('sign in with google')
    const provider = new GoogleAuthProvider()
    provider.addScope('email')
    signInWithPopup(auth, provider)
      .then(handleSignUp)
      .catch((error) => {
        console.log(error)
      })
  }
  const signUpWithFacebook = () => {
    console.log('sign in with facebook')
    const provider = new FacebookAuthProvider()
    provider.addScope('email')
    signInWithPopup(auth, provider)
      .then(handleSignUp)
      .catch((error) => {
        console.log(error)
      })
  }
  const signUpWithApple = () => {
    console.log('sign in with apple')
    const provider = new OAuthProvider('apple.com')
    provider.addScope('email')
    provider.addScope('name')
    signInWithPopup(auth, provider)
      .then(handleSignUp)
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <div className="flex justify-center space-x-4 my-2">
      <div className="cursor-pointer" onClick={signUpWithFacebook}>
        <FacebookIcon />
      </div>
      <div className="cursor-pointer" onClick={signUpWithGoogle}>
        <GoogleIcon />
      </div>
      <div className="cursor-pointer" onClick={signUpWithApple}>
        <AppleIcon />
      </div>
    </div>
  )
}

export default SocialSignup
