import React, { Fragment, useEffect } from 'react'
import { Button, Popover } from '@mui/material'
import { DropDownIcon } from '../../../assets/icons/navbarIcons'
import CrossIcon from '../../../assets/icons/crossIcon.jsx'
import DropdownRightArrow from 'assets/icons/dropdownrightarrow'
import SearchIcon from 'assets/icons/searchicon'
import MultiRangeSlider from 'Components/slider/MultiRangeSlider'
import { USDtoVND } from 'Utils/Parse/currency'
import { FormattedMessage } from 'react-intl'
const SearchResultDropDown = ({ setSearchParams }: any) => {
  const [anchorE2, setAnchorE2] = React.useState(null)
  const makeOpen = Boolean(anchorE2)
  const handleMakeClose = () => {
    setAnchorE2(null)
  }

  const handleMakeClick = (event: any) => {
    setAnchorE2(event.currentTarget)
  }
  const priceMin = 0,
    priceMax = 10000000000
  const [curMin, setCurMin] = React.useState(priceMin)
  const [curMax, setCurMax] = React.useState(priceMax)

  // url from useLocation might have min and max
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const min = urlParams.get('min')
    const max = urlParams.get('max')
    if (min && max) {
      setCurMin(parseInt(min))
      setCurMax(parseInt(max))
    }
  }, [])
  return (
    <>
      <button
        onClick={handleMakeClick}
        className="flex items-center space-x-3  md:justify-center"
      >
        <span
          className={`tracking-widest ${
            makeOpen ? 'text-[#105e26]' : 'text-black'
          } md:text-xs md: tracking-wide `}
        >
          <FormattedMessage id="search.price" />
        </span>
        <DropDownIcon stroke={`${makeOpen ? '#105e26' : '#000'}  `} />
      </button>
      <Popover
        open={makeOpen}
        anchorEl={anchorE2}
        onClose={handleMakeClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginY: '10px', borderRadius: '10px' }}
        classes={{ paper: 'force-rounded-xl' }}
      >
        <ul className="px-3 py-4 text-white">
          <li className={``}>
            <div className="flex flex-col relative">
              {/* <h6 className='text-center font-semibold text-[#105e26]'>
                Price Range
              </h6> */}
              <MultiRangeSlider
                sliderAdditionalClass="border border-grey"
                min={priceMin}
                max={priceMax}
                curMin={curMin}
                curMax={curMax}
                type="price"
                onChange={({
                  min,
                  max,
                }: {
                  min: number
                  max: number
                }) => {
                  if (!(min === priceMin && max === priceMax)) {
                    setSearchParams((prev: any) => ({
                      ...prev,
                      min,
                      max,
                    }))
                  } else {
                    setSearchParams((prev: any) => ({
                      ...prev,
                      min: '',
                      max: '',
                    }))
                  }
                  setCurMin(min)
                  setCurMax(max)
                  console.log(`min = ${min}, max = ${max}`)
                }}
              />
            </div>
          </li>
        </ul>
      </Popover>
    </>
  )
}

export default SearchResultDropDown
