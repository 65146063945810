import { Options } from '../Types/common';

export const option: Options[] = [
    {
        _id: '1',
        name: 'technology',
        label: 'Technology Package',
        translation: 'options_technology',
    },
    {
        _id: '2',
        name: 'selfDriving',
        label: 'Self Driving Package',
        translation: 'options_self_driving',
    },
    {
        _id: '3',
        name: 'towing',
        label: 'Towing Package',
        translation: 'options_towing',
    },
    {
        _id: '4',
        name: 'premium',
        label: 'Premium Package',
        translation: 'options_premium',
    },
    {
        _id: '5',
        name: 'coldWeather',
        label: 'Cold Weather Package',
        translation: 'options_cold_weather',
    },
    {
        _id: '6',
        name: 'advancedSafety',
        label: 'Advanced Safety Package',
        translation: 'options_advanced_safety',
    },
]

