import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { VinData } from '../../Resources/Types/vin'
import { ReactComponent as ChatIcon } from '../../assets/icons/chatIcon.svg'
import { ReactComponent as CallIcon } from '../../assets/icons/callIcon.svg'
import { useIntl } from 'react-intl'

interface RightDivProps {
  loading: boolean
}

const RightDiv: React.FC<RightDivProps> = ({ loading }) => {
  const pendingListing = useSelector((state: RootState) => state.listing.pendingListing)
  let vinData = pendingListing.vin.data as VinData

  const intl = useIntl()

  return (
    <div className="rounded  w-2/5  sm:w-full sm:mt-10 ">
      <div className="bg-[#F0F0F0] p-8  rounded shadow ">
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.title' })}</h1>
        {/* Add vehicle summary details using vinData here if needed */}
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.city' })}</h1>
        <p className="text-[#707070]">{pendingListing?.city || 'N/A'}</p>
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.VIN' })}</h1>
        <p className="text-[#707070]">{vinData?.vin || 'N/A'}</p>
        <h1 className="font-semibold mt-2">
          {intl.formatMessage({
            id: 'create_listing.right.car_info',
          })}
        </h1>
        <p className="text-[#707070]">
          {vinData?.year || 'N/A'}, {vinData?.make || 'N/A'}, {vinData?.model || 'N/A'}
        </p>
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.trim' })}</h1>
        <p className="text-[#707070]">{vinData?.trim_level || 'N/A'}</p>
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.mileage' })}</h1>
        <p className="text-[#707070]">
          {vinData?.city_mileage || 'N/A'}{' '}
          {intl.formatMessage({
            id: 'create_listing.right.city_mileage',
          })}{' '}
          / {vinData?.highway_mileage || 'N/A'}{' '}
          {intl.formatMessage({
            id: 'create_listing.right.highway_mileage',
          })}
        </p>
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.made_in' })}</h1>
        <p className="text-[#707070]">{vinData?.made_in || 'N/A'}</p>
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.style' })}</h1>
        <p className="text-[#707070]">{vinData?.style || 'N/A'}</p>
        <h1 className="font-semibold mt-2">{intl.formatMessage({ id: 'create_listing.right.anti_brake_system' })}</h1>
        <p className="text-[#707070]">{vinData?.anti_brake_system || 'N/A'}</p>
      </div>
      <div className="py-8">
        <h1 className="font-semibold mt-2 text-3xl">{intl.formatMessage({ id: 'create_listing.right.support' })}</h1>
        <div>
          <a href="mailto:support@sleekride.vn">
            <button className="bg-[#105E26] p-3 text-white w-full  mt-2 rounded  flex space-x-4 items-center">
              <ChatIcon />
              <span className="">
                {intl.formatMessage({
                  id: 'create_listing.right.chat',
                })}
              </span>{' '}
            </button>
          </a>
        </div>
        <div>
          <a href="tel:+84-867979149">
            <button className="bg-[#105E26] p-3 text-white w-full  mt-2 rounded  flex space-x-4 items-center">
              <CallIcon />
              <span className="  ">
                {intl.formatMessage({
                  id: 'create_listing.right.call',
                })}
              </span>{' '}
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default RightDiv
