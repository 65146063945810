import React from "react";
import ResponsiveAppBar from "Components/AppBar";
import CustomizedSteppers from "Components/Stepper";
// import "./style.css";
import Left from './Left'
import Right from './Right'
import Footer from "Components/Footer";


const CheckAvailability = () => {
  return (
   <div className=" w-full  flex items-center pl-10  md:p-10 sm:p-4 bg-[#F7FAF8]   ">
      <div className=" flex items-center w-full  md:flex-col      ">

        <Left />
        <Right />

      </div>
    </div>
  );
};

export default CheckAvailability;

