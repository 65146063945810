export const APIEndpoints = {
  CHECK_EMAIL: '/auth/validateEmail',
  LOGIN: '/auth/enduserlogin',
  LOGOUT: '/auth/enduserlogout',
  SIGNUP: '/auth/endusersignup',
  SOCIAL_SIGNUP: '/usr/auth/socialsignup',
  VERIFY_CODE: '/auth/enduserverifycode',
  VALIDATE_RESET_TOKEN: '/auth/validate-reset-token/:token',
  SET_PASSWORD: '/auth/endusersetpass',
  LOGGED_IN_USER: '/auth/enduserloggedin',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  CREATE_LISTING: '/vce/endusr/vinCreate/:vin',
  CREATE_INSPECTION: '/vce/endusr/inspections',
  UPDATE_LISTING: '/vce/endusr/update-listing/:id',
  SELL: '/vce/endusr/sold',
  UPLOAD_IMAGES: '/vce/endusr/upload-images/:id',
  UPLOAD_USER_IMAGE: '/vce/endusr/upload-users-image/:id',
  GET_STATES: '/vce/endusr/activeregstate',
  PENDING_POST: '/vce/endusr/pendingpostlisting',
  USER_LISTINGS: '/vce/endusr/pendingPostListing',
  FETCH_LISTINGS: '/vce/view/approved-listings',
  GET_LISTING: '/vce/endusr/listing/:id',
  GET_COLORS: '/vce/endusr/allcolor',
  GET_TRANSMISSIONS: '/vce/view/transmissions',
  GET_DRIVE_TYPE: '/vce/endusr/activewheeldrivetype',
  GET_ENGINE_TYPES: '/vce/endusr/activeenginetype',
  GET_SEAT_TYPES: '/vce/endusr/activeseattype',
  GET_ROOF_TYPES: '/vce/endusr/activerooftype',
  GET_EXT_PARTS: '/vce/endusr/activeextparts',
  GET_WHEEL_TYPES: '/vce/endusr/activewheeltype',
  GET_MAKES: '/vce/endusr/vehicle-makes',
  GET_CITIES: '/vce/view/cities',
  ISSUES: '/vce/endusr/activeelectricalissues',
  EXT_DAMAGES: '/vce/endusr/activeexteriordamages',
  INT_DAMAGES: '/vce/endusr/activeinteriordamages',
  POPULAR_BRANDS: '/vce/view/activebrand',
  // POPULAR_MAKES: '/vce/view/car-makes',
  AFTER_GOOGLE_LOGIN: '/auth/googleSignIn',
  ALL_MAKES: '/vce/view/makes',
  FIND_MAKES_AND_MODELS: '/vce/view/makesModels',
  FIND_MODELS_BY_MAKE: '/vce/view/models?',
  ADD_WISHLIST: '/vce/endusr/wishlist/add',
  REMOVE_WISHLIST: '/vce/endusr/wishlist/remove',
  GET_WISHLIST: '/vce/endusr/wishlist',
  PAY: '/vce/endusr/pay',
  SEND_CHECK_AVAILABILITY_MESSAGE: '/endusr/sendmessage/:id',
  SUBMIT_REVIEW: '/endusr/add_rat_rev',
  SUBMIT_CAR_REVIEW: '/vce/endusr/createReview',
  MARK_AS_PAID: '/vce/endusr/paid/:id',
  SUBMIT_CONTACT_US: '/endusr/submit-contact-us',

  // saved search
  USER_SAVED_SEARCH: '/endusr/savedsearchfilters',
  UPDATE_CALCULATOR: '/usr/endusr/addmonthly_calculator',
  UPDATE_INFO: '/usr/endusr/update',

  SERVICE_CENTER: '/vce/admin/service_center',

  SEND_FOR_DEALER_FORM: '/usr/become-dealer/create',

  // ADMIN SETTINGS (READ, UPDATE)
  LISTING_FEES: '/vce/admin/settings/listing-fee',
}
export const MicroServices = {
  GATEWAY: process.env.REACT_APP_GATEWAY_API_URL,
}
