import React, { useState } from 'react'

import { ReactComponent as FacebookIcon } from '../../assets/icons/facebookIcon.svg'
import { ReactComponent as GoogleIcon } from '../../assets/icons/googleIcon.svg'
import { ReactComponent as AppleIcon } from '../../assets/icons/appleIcon.svg'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

const Left = () => {
  const [lookupType, setLookupType] = useState('licensePlate')
  const navigate = useNavigate()

  const intl = useIntl()
  return (
    <div className="dealer_right  p-12 -md:lg:p-6  flex items-center  border-0  text-black w-full h-full md:flex-col-reverse md:items-start  relative ">
      <div className="md:pl-0 pl-20 pt-20 md:p-0 -lg:h-[300px] ">
        <h1 className=" text-6xl  -lg:xl:text-4xl lg:text-3xl font-black   my-1 w-2/3 sm:text-3xl text-[#105e26] md:w-[200px] ">
          {intl.formatMessage({
            id: 'for_dealer.intro.headline.right',
          })}
        </h1>
      </div>
      <button
        onClick={() => {
          window.location.href = 'https://dealer.sleekride.vn/login'
        }}
        className="bg-[#105E26] p-3 text-white w-40 rounded   absolute md:mb-[5%] md:relative md:top-0 md:right-0 top-10 right-10 ">
        {intl.formatMessage({
          id: 'for_dealer.dealer_sign_in',
        })}
      </button>
    </div>
  )
}

export default Left
