import clsx from "clsx";

function FormSelectBtn({ text, isActive, onClick }) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "bg-[#f0f0f0]  p-3 w-1/3 text-[#105E26] font-semibold rounded text-xs sm:p-1 sm:py-3 ",
        {
          ["!bg-[#105E26] !text-white"]: isActive,
        }
      )}
    >
      {text}
    </button>
  );
}

export default FormSelectBtn;
