import { getListingDetail } from 'Pages/Listing'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import SleekRideLogoIcon from 'assets/icons/SleekRideLogoIcon'
import { useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const listingCarText = (listing) => {
  if (!listing || !listing.vin) return ''
  const { origin, transmission } = listing
  const { make, model, year } = listing.vin.data
  const modelName = model.split(' ').slice(0, 1).join(' ')

  return `${year}-${make} ${modelName}, ${transmission.toUpperCase()}, ${origin.toUpperCase()}`
}
function CompletedInspection() {
  const { id } = useParams()

  //get listing from id
  const [listing, setListing] = useState({})
  const [loading, setLoading] = useState('error')

  const [inspectionProcess, setInspectionProcess] = useState([])
  const [inspectionAnswers, setInspectionAnswers] = useState({})

  useEffect(() => {
    if (loading === 'start' && !Object.keys(listing).length) setLoading('error')
  }, [loading])
  useEffect(() => {
    setLoading('start')
    getListingDetail(id).then((data) => {
      console.log(data)
      setLoading('done')
      setListing(data)
    })
  }, [])
  useEffect(() => {
    if (!Object.keys(listing).length) return
    const processes = listing.inspection.report.processes
    // turn obj into array
    const processesArray = Object.keys(processes).map((key) => {
      return processes[key]
    })
    console.log(processesArray)
    console.log(listing.inspection.report.answers)
    setInspectionProcess(processesArray)
    setInspectionAnswers(listing.inspection.report.answers)
  }, [listing])

  const isIpad = useMediaQuery('(max-width:1024px)')
  let start = 1
  return (
    <>
      {loading === 'start' ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="text-4xl font-semibold">Loading...</p>
        </div>
      ) : loading === 'error' ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="text-4xl font-semibold">No Inspection Detail Available</p>
        </div>
      ) : (
        <div className="bg-white mx-[10%]  xl:mx-auto text-black flex justify-center items-center p-4">
          <div className=" w-full h-full flex justify-center item xl:space-x-[5%] space-x-[2%] ">
            <div className="w-full h-full">
              <div className="p-2 border flex justify-between items-center border-black h-[80px]">
                <div className="">{<SleekRideLogoIcon fill="#000" />}</div>
                <h1 className="  lg:text-lg   text-3xl font-semibold">
                  <FormattedMessage id="150_inspection_report" />
                </h1>
              </div>
              <div className="p-2 border flex justify-end items-center border-black h-[30px]">
                <p>
                  <FormattedMessage id="scheduled_inspection" />:{' '}
                  {listing.inspection ? `${new Date(listing.inspection.appointmentDate).toLocaleDateString('en-GB')}` : 'Unknown'}
                </p>
              </div>
              <div className="p-2 border border-black  h-[75px]">
                <p className=" font-semibold">{listingCarText(listing)}</p>

                <p>
                  <span className=" font-semibold">VIN: </span>
                  {listing?.vin?.data?.vin}
                </p>
              </div>
              <div className="w-full h-full pb-8">
                {inspectionProcess.map((process, processIndex) => {
                  const children = []
                  const children2 = []

                  process.break.forEach((end, idx) => {
                    while (start < end) {
                      console.log(start)
                      if (!children[idx]) children[idx] = []
                      children[idx].push(inspectionBox(start, inspectionAnswers))
                      children2.push(inspectionBox(start, inspectionAnswers))
                      start++
                    }
                  })

                  for (let i = 0, len = children.length; i < len; i++) {
                    while (children[i].length < children[0].length) {
                      children[i].push(emptyBox())
                    }
                  }

                  return (
                    <div className="flex flex-col border border-black justify-center " key={processIndex}>
                      <div className="flex flex-row justify-center">
                        {isIpad ? (
                          <>
                            <div className="w-full h-full">
                              <div className="bg-[#71c157] h-[50px] flex justify-between items-center ">
                                <h1 className="text-base  p-2">
                                  <FormattedMessage id={transformToKeyFormat(process.name)} />
                                </h1>
                                <div className="  flex flex-row justify-center items-center self-end">
                                  {/* create 1 div with border for passed/fixed */}
                                  <div className="flex flex-row justify-center items-center border-r border-l p-2 border-black h-[50px]  w-[100px] ">
                                    <p className="xl:text-sm text-base">
                                      <FormattedMessage id="passed" />
                                    </p>
                                  </div>
                                  <div className="flex flex-row justify-center items-center border-r border-l p-2 border-black h-[50px]  w-[100px] ">
                                    <p className="xl:text-sm text-base leading-6">
                                      <FormattedMessage id="fixed_or_replaced" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {children2.length &&
                                children2.map((child, idx) => {
                                  return <>{child}</>
                                })}
                            </div>
                          </>
                        ) : (
                          <>
                            {children.length &&
                              children.map((child, idx) => {
                                return (
                                  <div className="w-full h-full" key={idx}>
                                    <div className="bg-[#71c157] h-[50px] flex justify-between items-center ">
                                      <h1 className="text-base  p-2">
                                        <FormattedMessage id={transformToKeyFormat(process.name)} />
                                      </h1>
                                      <div className="  flex flex-row justify-center items-center self-end">
                                        {/* create 1 div with border for passed/fixed */}
                                        <div className="flex flex-row justify-center items-center border-r border-l p-2 border-black h-[50px]  w-[100px] ">
                                          <p className="xl:text-sm text-base">
                                            <FormattedMessage id="passed" />
                                          </p>
                                        </div>
                                        <div className="flex flex-row justify-center items-center border-r border-l p-2 border-black h-[50px]  w-[100px] ">
                                          <p className="xl:text-sm text-base leading-6">
                                            <FormattedMessage id="fixed_or_replaced" />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {child}
                                  </div>
                                )
                              })}
                          </>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function transformToKeyFormat(inputString) {
  const cleanedString = inputString
    // removed all possible special characters and spaces, with the exception of the dash
    .toLowerCase()
    .replace('’', '')
    .replace('&', 'and')
    .replace('a/c', 'ac')
    .replace(/[^a-zA-Z0-9]/g, '_')
    .replace(/ /g, '_')
    .replace(/-/g, '_')
    // there might be multiple underscores in a row, so we need to replace those with a single underscore
    // this is done by using a regular expression that matches all instances of 2 or more underscores
    // and replaces them with a single underscore
    // eslint-disable-next-line no-useless-escape
    .replace(/_+/g, '_')
    // no underscores at the beginning or end of the string
    .replace(/^_/, '')
    .replace(/_$/, '')

  // Add a prefix to the key to make it more distinctive
  return cleanedString.toLowerCase()
}

const inspectionBox = (childNumber, inspectionAnswers) => {
  const selection = inspectionAnswers[childNumber] // Default to 'No' if not selected
  return (
    <div className=" border-black   flex flex-row justify-between w-full lg:h-full lg:max-h-fit h-[50px] " key={childNumber}>
      <p className="p-2 border border-black flex justify-center items-center lg:w-[75px] w-[100px] h-full lg:h-auto ">{childNumber}</p>
      <p className="p-2 border border-black flex justify-start  items-center w-full h-full ">
        <FormattedMessage id={transformToKeyFormat(selection.question)} />
      </p>
      <div className=" flex flex-row ">
        <label className={`flex justify-center items-center border  border-black h-full  w-[100px]  `}>
          {selection.answer === 'Passed' ? (
            <FontAwesomeIcon icon={faCheck} fixedWidth={true} className="w-full h-full text-green-700" />
          ) : selection.answer === 'Failed' ? (
            <FontAwesomeIcon icon={faTimes} fixedWidth={true} className="w-full h-full text-rose-500" />
          ) : (
            ''
          )}
        </label>
        <label className={`flex justify-center items-center border  border-black h-full  w-[100px]`}>
          {selection.answer === 'Fixed' && <FontAwesomeIcon icon={faCheck} fixedWidth={true} className=" w-full h-full text-green-700" />}
        </label>
      </div>
    </div>
  )
}

const emptyBox = () => {
  return (
    <div className=" border-black   flex flex-row justify-between w-full h-[50px]" key={Math.random() * 100000}>
      <p className="p-2 border border-black flex justify-center items-center w-[100px] h-full "></p>
      <p className="p-2 border border-black flex justify-center items-center  w-full h-full"></p>
      <div className=" flex flex-row ">
        <label className={`flex justify-center items-center border  border-black h-full  w-[100px]   `}></label>
        <label className={`flex justify-center items-center border  border-black h-full  w-[100px] `}></label>
      </div>
    </div>
  )
}

export default CompletedInspection
