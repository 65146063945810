import { createSlice } from '@reduxjs/toolkit'

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    emailSent: false,
    step: 0,
    data: {},
  },
  reducers: {
    setSignupData: (state, action) => {
      // console.log({ state, action })
      state.data = action.payload
    },
    forwardStep: (state, action) => {
      // console.log('Forward Step: ', { state, action })
      state.step++
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSignupData, forwardStep, updateVerifyCode } =
  signupSlice.actions

export default signupSlice.reducer
