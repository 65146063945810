import { City } from '../Types/common'

export const cities: City[] = [
  {
    _id: '1',
    name: 'hoChiMinhCity',
    label: 'Ho Chi Minh City',
    vnLabel: 'TP HCM',
  },
  { _id: '2', name: 'hanoi', label: 'Ha Noi', vnLabel: 'Hà Nội' },
  { _id: '3', name: 'daNang', label: 'Da Nang', vnLabel: 'Đà Nẵng' },
  { _id: '4', name: 'hue', label: 'Hue', vnLabel: 'Huế' },
  { _id: '5', name: 'canTho', label: 'Can Tho', vnLabel: 'Cần Thơ' },
  {
    _id: '6',
    name: 'haiPhong',
    label: 'Hai Phong',
    vnLabel: 'Hải Phòng',
  },
  {
    _id: '7',
    name: 'bienHoa',
    label: 'Bien Hoa',
    vnLabel: 'Biên Hòa',
  },
  {
    _id: '8',
    name: 'nhaTrang',
    label: 'Nha Trang',
    vnLabel: 'Nha Trang',
  },
  {
    _id: '9',
    name: 'vungTau',
    label: 'Vung Tau',
    vnLabel: 'Vũng Tàu',
  },
  {
    _id: '10',
    name: 'quyNhon',
    label: 'Quy Nhon',
    vnLabel: 'Quy Nhơn',
  },
  {
    _id: '11',
    name: 'buonMaThuot',
    label: 'Buon Ma Thuot',
    vnLabel: 'Buôn Ma Thuột',
  },
  {
    _id: '12',
    name: 'haiDuong',
    label: 'Hai Duong',
    vnLabel: 'Hải Dương',
  },
  {
    _id: '13',
    name: 'thaiNguyen',
    label: 'Thai Nguyen',
    vnLabel: 'Thái Nguyên',
  },
  { _id: '14', name: 'daLat', label: 'Da Lat', vnLabel: 'Đà Lạt' },
  {
    _id: '15',
    name: 'phanThiet',
    label: 'Phan Thiet',
    vnLabel: 'Phan Thiết',
  },
  { _id: '16', name: 'vinh', label: 'Vinh', vnLabel: 'Vinh' },
  {
    _id: '17',
    name: 'ninhBinh',
    label: 'Ninh Binh',
    vnLabel: 'Ninh Bình',
  },
]

// create function takes name and return city label
export const getCityLabel = (name: string, intl: any) => {
  const city = cities.find(
    (city) =>
      city.name.toLowerCase() === name.toLowerCase() ||
      city.label.toLowerCase() === name.toLowerCase()
  )
  if (!city) return name
  let vn = intl ? intl.locale === 'vn' : false
  return vn ? city.vnLabel : city.label
}

export const cityOptions = cities.map((city) => ({
  id: Math.random() * 1000000,
  name: city.label,
  label: city.label,
  vnLabel: city.vnLabel,
}))
