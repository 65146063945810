import React from 'react'
import { TermsIcon, ThumbsUpIcon } from 'assets/icons/settingIcons'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const Support = () => {
  const navigate = useNavigate()

  return (
    <div
      className="tab-pane fade show active"
      id="tabs-homeVertical"
      role="tabpanel"
      aria-labelledby="tabs-home-tabVertical"
    >
      <div
        onClick={() => {
          navigate('/leave-review/')
          window.scrollTo(0, 0)
        }}
        className="mb-6 px-2 py-4 border-b border-b-black cursor-pointer  flex space-x-3 items-center"
      >
        <ThumbsUpIcon />
        <p className="text-lg font-bold">
          <FormattedMessage id="support.give_feedback" />
        </p>
      </div>
      <div
        onClick={() => {
          navigate('/terms-of-service')
          window.scrollTo(0, 0)
        }}
        className="mb-6 px-2 py-4 border-b border-b-black cursor-pointer flex space-x-3 items-center"
      >
        <TermsIcon />
        <p className="text-lg font-bold">
          <FormattedMessage id="terms_of_service" />
        </p>
      </div>
      <div
        onClick={() => {
          navigate('/privacy-policy')
          window.scrollTo(0, 0)
        }}
        className="mb-6 px-2 py-4 border-b border-b-black cursor-pointer flex space-x-3 items-center"
      >
        <TermsIcon />
        <p className="text-lg font-bold">
          <FormattedMessage id="privacy_policy" />
        </p>
      </div>
    </div>
  )
}

export default Support
