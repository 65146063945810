import React from 'react';

const SeeInspectionListIcon = ({ color }) => {
  const fillColor = color === 'grey' ? '#7a7a7a' : '#105E26'; // Default green, grey if 'grey' is passed

  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
      <path d="M17.074 11.9385C16.3618 11.9385 15.7838 12.5192 15.7838 13.2297V13.6058C15.7838 15.2642 14.4351 16.6155 12.7757 16.6155L5.58572 16.6157C3.92758 16.6157 2.5786 15.2642 2.5786 13.6059V6.41748C2.5786 4.75902 3.92726 3.40774 5.58572 3.40774H5.95376C6.66598 3.40774 7.24399 2.83235 7.24399 2.11751C7.24399 1.407 6.66598 0.831604 5.95376 0.831604H5.58572C2.50485 0.831604 0 3.33463 0 6.41732V13.6058C0 16.6849 2.50489 19.1923 5.58572 19.1923H12.7757C15.8577 19.1923 18.3623 16.6848 18.3623 13.6058V13.2297C18.3624 12.5193 17.7862 11.9383 17.0739 11.9383L17.074 11.9385Z" fill={fillColor}/>
      <path d="M17.387 5.37844V7.6211L22.0004 3.81063L17.387 0V1.80147C12.3091 1.80147 8.17773 5.93284 8.17773 11.0163C8.17773 11.5707 8.23445 12.186 8.34479 12.7441C9.23707 8.55714 12.9244 5.37868 17.3871 5.37868L17.387 5.37844Z" fill={fillColor}/>
    </svg>
  );
};

export default SeeInspectionListIcon;
