import React from "react";

function QuotationMark({ fill = "#ffffff", ...rest }) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.42858 15.7143C1.8074 15.7143 2.17093 15.5638 2.43878 15.296C2.70664 15.0281 2.85715 14.6646 2.85715 14.2858V7.14288C2.85715 6.00606 3.30868 4.91618 4.11226 4.11226C4.91615 3.30868 6.00606 2.85715 7.14288 2.85715H14.2858C14.7963 2.85715 15.2679 2.58483 15.523 2.14286C15.7781 1.70089 15.7781 1.15625 15.523 0.714288C15.2679 0.272327 14.7963 0 14.2858 0H7.14288C5.24842 0 3.43176 0.752557 2.09217 2.09217C0.752573 3.43178 0 5.24842 0 7.14288V14.2858C0 14.6646 0.150515 15.0281 0.418368 15.296C0.686222 15.5638 1.04975 15.7143 1.42858 15.7143Z" fill="black"/>
    <path d="M32.8585 0H25.7157C25.2051 0 24.7335 0.272319 24.4784 0.714288C24.2233 1.15626 24.2233 1.7009 24.4784 2.14286C24.7335 2.58482 25.2052 2.85715 25.7157 2.85715H32.8585C33.9954 2.85715 35.0852 3.30868 35.8892 4.11226C36.6927 4.91615 37.1443 6.00606 37.1443 7.14288V14.2858C37.1443 14.7963 37.4166 15.2679 37.8586 15.523C38.3005 15.7781 38.8452 15.7781 39.2871 15.523C39.7291 15.2679 40.0014 14.7963 40.0014 14.2858V7.14288C40.0014 5.24842 39.2489 3.43176 37.9092 2.09217C36.5696 0.752574 34.753 0 32.8585 0Z" fill="black"/>
    <path d="M7.14288 40.0002H14.2858C14.7963 40.0002 15.2679 39.7279 15.523 39.2859C15.7781 38.8439 15.7781 38.2993 15.523 37.8573C15.2679 37.4154 14.7963 37.143 14.2858 37.143H7.14288C6.00606 37.143 4.91618 36.6915 4.11226 35.8879C3.30868 35.084 2.85715 33.9941 2.85715 32.8573V25.7144C2.85715 25.2039 2.58483 24.7323 2.14286 24.4772C1.70089 24.2221 1.15625 24.2221 0.714288 24.4772C0.272327 24.7323 0 25.2039 0 25.7144V32.8573C0 34.7518 0.752557 36.5684 2.09217 37.908C3.43178 39.2476 5.24842 40.0002 7.14288 40.0002Z" fill="black"/>
    <path d="M38.5728 24.2859C38.194 24.2859 37.8305 24.4364 37.5626 24.7043C37.2948 24.9721 37.1443 25.3356 37.1443 25.7145V32.8573C37.1443 33.9942 36.6927 35.084 35.8892 35.888C35.0853 36.6915 33.9954 37.1431 32.8585 37.1431H25.7157C25.2051 37.1431 24.7335 37.4154 24.4784 37.8574C24.2233 38.2993 24.2233 38.844 24.4784 39.2859C24.7335 39.7279 25.2052 40.0002 25.7157 40.0002H32.8585C34.753 40.0002 36.5696 39.2477 37.9092 37.908C39.2488 36.5684 40.0014 34.7518 40.0014 32.8573V25.7145C40.0014 25.3356 39.8509 24.9721 39.583 24.7043C39.3152 24.4364 38.9517 24.2859 38.5728 24.2859Z" fill="black"/>
    <path d="M5.26945 19.5008C5.15498 19.8244 5.15498 20.1771 5.26945 20.5008C6.38708 23.5058 8.39729 26.0971 11.03 27.9268C13.6626 29.7565 16.7921 30.7371 19.9981 30.7371C23.2042 30.7371 26.3336 29.7565 28.9663 27.9268C31.5991 26.0971 33.6092 23.5059 34.7268 20.5008C34.8413 20.1771 34.8413 19.8244 34.7268 19.5008C33.6092 16.4957 31.599 13.9044 28.9663 12.0747C26.3337 10.245 23.2042 9.2644 19.9981 9.2644C16.7921 9.2644 13.6627 10.245 11.03 12.0747C8.39721 13.9044 6.38708 16.4956 5.26945 19.5008ZM19.9985 25.7151C18.4829 25.7151 17.0294 25.113 15.958 24.0413C14.8862 22.9698 14.2842 21.5164 14.2842 20.0008C14.2842 18.4852 14.8862 17.0317 15.958 15.9603C17.0294 14.8885 18.4829 14.2865 19.9985 14.2865C21.5141 14.2865 22.9675 14.8885 24.039 15.9603C25.1107 17.0317 25.7128 18.4852 25.7128 20.0008C25.7128 21.5164 25.1107 22.9698 24.039 24.0413C22.9675 25.113 21.5141 25.7151 19.9985 25.7151ZM8.15599 20.0008C9.40472 17.0636 11.701 14.6959 14.5989 13.3579C12.6024 14.9857 11.4442 17.4248 11.4442 20.0008C11.4442 22.5767 12.6024 25.0158 14.5989 26.6436C11.701 25.3056 9.40472 22.9379 8.15599 20.0008ZM31.8418 20.0008C30.593 22.9379 28.2968 25.3056 25.3988 26.6436C27.3953 25.0158 28.5535 22.5767 28.5535 20.0008C28.5535 17.4248 27.3953 14.9857 25.3988 13.3579C28.2968 14.6959 30.593 17.0636 31.8418 20.0008Z" fill="black"/>
    </svg>
    
  );
}

export default QuotationMark;
