import React, { useEffect, useState } from 'react'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import './styles.css'
import { Autocomplete, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Rating from 'react-rating-stars-component' // Import the star rating component
import { getListingDetail } from 'Pages/Listing'
import { useIntl } from 'react-intl'

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({}))

const noUserFeedbackTypes = ['Sleekride website feedback']

const FeedbackTypes = ['Sleekride website feedback', 'Dealer review', 'A car make and model review']
const FeedbackTypesIntl = ['review.left.feedback_about.type.1', 'review.left.feedback_about.type.2', 'review.left.feedback_about.type.3']

const Left = () => {
  const { id } = useParams()
  const [options, setOptions] = useState([])
  const [formData, setFormData] = useState({
    message: '',
    feedbacktype: 'Sleekride website feedback',
    headline: '',
    rating: 0, // Initialize rating as 0
  })

  const [carData, setCarData] = useState(null)

  useEffect(() => {
    getListingDetail(id).then((data) => {
      if (!data) {
        setOptions(noUserFeedbackTypes)

        return
      }
      setOptions(FeedbackTypes)
      setCarData(data)
    })
  }, [])

  useEffect(() => {
    console.log('carData:', carData)
    console.log('carData?.vin?.data:', carData?.vin?.data)
    if (!carData) {
      setFormData((prev) => ({
        ...prev,
        make: '',
        model: '',
        year: '',
        dealerID: '',
      }))
      return
    }
    const { make, model, year } = carData.vin.data
    const { _id } = carData.endUser
    setFormData((prev) => ({
      ...prev,
      make,
      model,
      year,
      dealerID: _id,
    }))
  }, [carData])

  const [submitReview] = useMutation({
    endPoint: formData.feedbacktype === 'A car make and model review' ? `${APIEndpoints.SUBMIT_CAR_REVIEW}` : `${APIEndpoints.SUBMIT_REVIEW}`,
    method: 'post',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleRatingChange = (newRating) => {
    // Update the rating when the user selects a star rating
    setFormData((prev) => ({ ...prev, rating: newRating }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Validation checks
    if (formData.rating === 0) {
      // toast.error('Please select a star rating.')
      return
    }

    if (formData.message.trim() === '') {
      // toast.error('Please add a written review.')
      return
    }
    if (formData.headline.trim() === '') {
      // toast.error('Please add a headline.')
      return
    }

    var data = new FormData()
    if (formData.feedbacktype === 'Dealer review') {
      data.append('dealerID', formData.dealerID)
      data.append('platform_type', 'Dealer feedback')
    } else if (formData.feedbacktype === 'A car make and model review') {
      data.append('make', formData.make)
      data.append('model', formData.model)
      data.append('year', formData.year)
      // data.append('platform_type', 'A car make and model review')
    } else {
      data.append('platform_type', 'Sleekride website feedback')
    }
    data.append('rating', formData.rating)
    data.append('topic_feature', formData.headline)
    data.append('reviews', formData.message)
    // If all fields are filled, proceed with form submission
    submitReview(data, {
      headers: {
        'Auth-Token': `${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        console.log('Response:', res)
        if (res) {
          console.log('SUCCESS')
          // toast.success('Review sent successfully!')
          setFormData({
            ...formData,
            message: '',
            feedbacktype: 'Sleekride website feedback',
            headline: '',
            rating: 0,
          })
        }
      })
      .catch((error) => {
        console.error('Error submitting the form:', error)
        // toast.error('Error submitting the form.')
      })
  }

  useEffect(() => {
    console.log('ID:', id)
    console.log('formData:', formData)
  }, [formData])

  const intl = useIntl()

  return (
    <div className="h-full w-full flex flex-col justify-center items-center p-10 my-3 rounded-xl border-0 bg-[#FFF] text-black  sm:p-4 shadow-md">
      <div className="my-2 xxl:my-4">
        <h1 className="text-3xl font-bold text-center xxl:text-[36px]">
          {intl.formatMessage({
            id: 'review.left.title',
          })}
        </h1>
      </div>
      <div className="my-2 xxl:my-4 flex flex-col justify-center items-center">
        <p className="p-2 text-lg font-bold text-[#132743">
          {intl.formatMessage({
            id: 'review.left.stars',
          })}
        </p>
        {/* Star rating component */}
        <Rating
          count={5} // Number of stars
          size={40} // Size of stars
          value={formData.rating} // Selected rating value
          onChange={handleRatingChange} // Handle rating change
          a11y={true} // Enable accessibility
          isHalf={false} // Allow half-star ratings
        />
      </div>
      <div className="w-[70%] h-full">
        <div className="mb-3 xxl:mb-6 sm:mb-4">
          <p className="p-2 text-lg font-bold text-[#132743">
            {intl.formatMessage({
              id: 'review.left.add_review',
            })}
          </p>
          <textarea
            name="message"
            className="outline-none max-h-[17.5rem] h-[12.5rem] min-h-[12.5rem] p-4 border-0 bg-[#F0F0F0] w-full rounded-md shadow"
            placeholder={intl.formatMessage({
              id: 'review.left.add_review.placeholder',
            })}
            value={formData.message}
            onChange={handleChange}
            type="text"></textarea>
        </div>
        <div className="my-3 xxl:my-6 sm:my-4">
          <p className="p-2 text-lg font-bold text-[#132743">
            {intl.formatMessage({
              id: 'review.left.feedback_about',
            })}
          </p>
          <StyledAutocomplete
            options={options}
            getOptionLabel={(option) =>
              intl.formatMessage({
                id: FeedbackTypesIntl[FeedbackTypes.indexOf(option)],
              })
            }
            value={formData.feedbacktype ?? 'Sleekride website feedback'}
            renderInput={(params) => (
              <TextField
                {...params}
                onClick={() => {
                  if (!carData) {
                    // toast.error('Can not leave a review for a listing nor for a dealer.')
                    return
                  }
                }}
                label={intl.formatMessage({
                  id: 'review.left.feedback_about.type',
                })}
                variant="outlined"
                className="outline-none h-full p-4 border-0 bg-[#F0F0F0] w-full rounded-md shadow"
              />
            )}
            onChange={(_, value) => {
              if (!carData) return
              handleChange({
                target: { name: 'feedbacktype', value },
              })
            }}
          />
        </div>
        <div className="my-3 xxl:my-6 sm:my-4">
          <p className="p-2 text-lg font-bold text-[#132743">
            {intl.formatMessage({
              id: 'review.left.add_headline',
            })}
          </p>
          <input
            name="headline"
            type="text"
            className="outline-none h-full p-4 border-0 bg-[#F0F0F0] w-full rounded-md shadow"
            placeholder={intl.formatMessage({
              id: 'review.left.add_headline.placeholder',
            })}
            value={formData.headline}
            onChange={handleChange}
          />
        </div>
        <div className="my-3 xxl:my-6 sm:my-4">
          <button onClick={handleSubmit} className="bg-[#105E26]  p-3 text-white w-full mt-2 rounded shadow-md shadow-[#105E26]">
            {intl.formatMessage({
              id: 'review.left.submit',
            })}
          </button>
        </div>
        <div className="w-full flex justify-center items-center">
          <p className="text-[#9FA7AB]">
            {intl.formatMessage({
              id: 'review.left.required_fields',
            })}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Left
