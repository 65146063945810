import React, { useContext, useEffect, useState } from 'react'
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { db } from 'SDK/Firebase'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  useMediaQuery,
  Slide,
} from '@mui/material'
import clsx from 'clsx'
import './styles.css'
import chatJPG from './chat.png'
import { CheckAvailabilityContext } from 'App'
import { toast } from 'react-toastify'
import Collapse from '@mui/material/Collapse'
function Chat() {
  const userId = useSelector((state) => state.user?.data?._id)

  const user = useSelector((state) => state.user?.data)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)

  const [chats, setChats] = useState(null)
  const [open, setOpen] = useState(false) // State for the dialog
  const [activeChat, setActiveChat] = useState(null)
  const [updateBoolean, setUpdateBoolean] = useState(false)
  const { CheckAvailabilityPost, setCheckAvailabilityPost } =
    useContext(CheckAvailabilityContext)

  const handleNewChatOnCheckAvailability = async () => {
    const newChat = {
      participants: [
        {
          id: userId,
          endName: user.isDealer
            ? user.dealer.dealershipName
            : user.endName,
          image: user?.image ?? '',
          latestSeenTimestamp: new Date(),
        },
        {
          id: CheckAvailabilityPost.endUser._id,
          endName: CheckAvailabilityPost.endUser.isDealer
            ? CheckAvailabilityPost.endUser.dealer.dealershipName
            : CheckAvailabilityPost.endUser.endName,
          image: CheckAvailabilityPost.endUser?.dealer
            ? CheckAvailabilityPost.endUser.dealer.logo
            : CheckAvailabilityPost.endUser?.image ?? '',
        },
      ],
      messages: [],
    }

    // Add new chat to chats state

    // Add new chat to firestore
    const docRef = await doc(collection(db, 'chats'))
    await setDoc(docRef, newChat)

    setChats((prev) => [
      {
        id: docRef.id,
        ...newChat,
      },
      ...prev,
    ])
    setActive(docRef.id)
  }

  useEffect(() => {
    if (!chats) {
      handleSnapShot()
      return
    }
    console.log('CheckAvailabilityPost', CheckAvailabilityPost)
    if (!CheckAvailabilityPost) return
    if (CheckAvailabilityPost.endUser._id === userId) {
      setCheckAvailabilityPost(null)
      // toast.error('You cannot message yourself')
      return
    }

    // check first if chat already exists betwwen 2 user
    const chatExists = chats.filter(
      (chat) =>
        chat.participants.filter(
          (participant) => participant.id === userId
        ).length > 0 &&
        chat.participants.filter(
          (participant) =>
            participant.id === CheckAvailabilityPost.endUser._id
        ).length > 0
    )
    console.log('chats', userId)
    console.log('chats', CheckAvailabilityPost.endUser._id)
    console.log('chats', chats)
    console.log('chatExists', chatExists)

    if (chatExists.length > 0) {
      // if chat exists, set active chat to that chat
      setActive(chatExists[0].id)
    } else {
      // if chat does not exist, create new chat
      // create new chat
      handleNewChatOnCheckAvailability()
    }

    // set user input to check availability post message
    setUserInput({
      image: CheckAvailabilityPost.images[0].original,
      message:
        `Inquiry: ${CheckAvailabilityPost.vin.data.year} ${CheckAvailabilityPost.vin.data.make} ${CheckAvailabilityPost.vin.data.extractedModel}` +
        ` VIN ${CheckAvailabilityPost.vin.data.vin}`,
    })

    setOpen(true)
    setMessageWindow(true)

    setCheckAvailabilityPost(null)
  }, [CheckAvailabilityPost, chats])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleScroll = () => {
    // Close the chat when the user scrolls
    setOpen(false)
  }

  const handleSnapShot = async () => {
    const q = query(collection(db, 'chats'))

    try {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const usrChat = []
        console.log('querySnapshot', querySnapshot)
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          console.log('data', data)
          if (!data) return
          if (
            data.participants.filter(
              (participant) => participant.id === userId
            ).length > 0 &&
            data.participants.filter(
              (participant) =>
                participant.endName ===
                (user.isDealer
                  ? user.dealer.dealershipName
                  : user.endName)
            ).length > 0
          ) {
            usrChat.push({
              id: doc.id,
              ...data,
            })
          }
        })
        console.log('UusrChatsr', usrChat)
        if (usrChat.length === 0) {
          setChats([])
          return
        }

        console.log('usrChat222', usrChat)

        const sortedChat = usrChat.sort((a, b) => {
          if (!a.messages.length || !b.messages.length) {
            // find in paricipants array latestSeenTimestamp
            const aParticipant = a.participants.filter(
              (participant) => participant.id === userId
            )[0]
            const bParticipant = b.participants.filter(
              (participant) => participant.id === userId
            )[0]

            const aTimestamp =
              aParticipant.latestSeenTimestamp?.seconds ?? 0
            const bTimestamp =
              bParticipant.latestSeenTimestamp?.seconds ?? 0
            return bTimestamp - aTimestamp
          }
          const aLatest = a.messages[a.messages.length - 1]
          const bLatest = b.messages[b.messages.length - 1]

          return (
            bLatest.timestamp?.seconds - aLatest.timestamp?.seconds
          )
        })
        console.log('sortedChat', sortedChat)
        setUpdateBoolean(true)

        setChats(sortedChat)
      })

      // If you need to unsubscribe from the snapshot listener (e.g., when the component unmounts), you can use the returned unsubscribe function.
      // This ensures that the listener is removed when the component is no longer in use.
      return () => unsubscribe()
    } catch (error) {
      console.error('Error getting chats:', error)
    }
  }

  useEffect(() => {
    console.log('chats Changed', chats)
  }, [chats])

  useEffect(() => {
    console.log('activeChat Changed', activeChat)
  }, [activeChat])

  useEffect(() => {
    if (!(user && loggedIn && !isLoading)) {
      setChats(null)
      setActive(null)
      // setActiveChat((prev) => prev ?? null)
      setMessageWindow(false)
      setOpen(false)

      return
    }
    if (!chats) handleSnapShot()

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [user, loggedIn, isLoading])
  useEffect(() => {
    if (isMobile) return
    if (open) {
      // Add scroll event listener when the chat is open
      window.addEventListener('scroll', handleScroll)
    }

    // Cleanup event listener on chat close
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [open])

  const [messageWindow, setMessageWindow] = useState(false)

  const handleMessageWindow = () => {
    setMessageWindow((prev) => !prev)
  }

  const [active, setActive] = useState(null)

  const handleSetActive = (chat) => {
    setUserInput({
      image: '',
      message: '',
    })
    setActive(chat)
  }

  useEffect(() => {
    if (!chats) return
    // console.log('active', active)
    setMessageWindow(!!active)

    if (active) {
      // console.log('chats', chats)
      // console.log('active', active)
      const activeChat = chats.filter((chat) => chat.id == active)[0]
      // console.log('activeChat', {
      //   id: activeChat?.id,
      //   participants: [...activeChat.participants],
      //   otherParticipant: activeChat.participants.filter(
      //     (participant) => participant.id !== userId
      //   )[0],
      //   messages: [...activeChat.messages],
      // })
      // update latest seen timestamp
      const usrParicipant = activeChat.participants.filter(
        (participant) => participant.id === userId
      )[0]
      const otherParticipant = activeChat.participants.filter(
        (participant) => participant.id !== userId
      )[0]

      // update chat

      const chatRef = doc(db, 'chats', activeChat.id)

      updateDoc(chatRef, {
        participants: [
          {
            ...usrParicipant,
            latestSeenTimestamp: new Date(),
          },
          {
            ...otherParticipant,
          },
        ],
      })

      setActiveChat({
        id: activeChat?.id,
        participants: [...activeChat.participants],
        otherParticipant: activeChat.participants.filter(
          (participant) => participant.id !== userId
        )[0],
        messages: [...activeChat.messages],
      })
    }
  }, [active])

  useEffect(() => {
    console.log('chats for active', chats)
    if (!chats || !chats.length || !activeChat || !updateBoolean)
      return
    console.log('chats_______________', chats)
    // if chat is updated, then update active chat
    const newActiveChat = chats.filter(
      (chat) => chat.id === activeChat.id
    )[0]
    setActiveChat({
      id: newActiveChat.id,
      participants: [...newActiveChat.participants],
      otherParticipant: newActiveChat.participants.filter(
        (participant) => participant.id !== userId
      )[0],
      messages: [...newActiveChat.messages],
    })
    setUpdateBoolean(false)
  }, [chats, updateBoolean, activeChat])

  const [userInput, setUserInput] = useState({
    image: '',
    message: '',
  })

  const inputRef = React.useRef(null)

  const handleSendMessage = (e) => {
    e.preventDefault()
    // Send message to chat
    if (userInput.message.trim() !== '' && activeChat) {
      const newMessage = {
        senderId: userId,
        image: userInput.image,
        text: userInput.message.trim(),
        timestamp: new Date(),
      }

      // find other user
      const usrParicipant = activeChat.participants.filter(
        (participant) => participant.id === userId
      )[0]
      const otherParticipant = activeChat.participants.filter(
        (participant) => participant.id !== userId
      )[0]

      // update chat

      const chatRef = doc(db, 'chats', activeChat.id)

      updateDoc(chatRef, {
        participants: [
          {
            ...usrParicipant,
            latestSeenTimestamp: new Date(),
          },
          {
            ...otherParticipant,
          },
        ],
        messages: [...activeChat.messages, newMessage],
      })

      // Add message to active chat
      setActiveChat((prev) => ({
        ...prev,
        messages: [...prev.messages, newMessage],
      }))
    }
    // Clear user input
    setUserInput({
      image: '',
      message: '',
    })

    // Scroll to bottom of chat
    inputRef.current.scrollIntoView({ behavior: 'smooth' })

    // focus on input
    inputRef.current.focus()
  }

  const isMobile = useMediaQuery('(max-width:768px)')

  return (
    <>
      {user && loggedIn && !isLoading && (
        <div className="fixed bottom-[32px] cursor-pointer  right-[32px] p-4 z-50 ">
          <h1 onClick={handleClickOpen}>
            {/* Chat Icon */}
            {open ? (
              // show x icon when chat is open
              <div
                className={clsx(
                  'w-[75px] h-[75px] rounded-full  cursor-pointer flex justify-center items-center  bg-blue-100  transition-all duration-200 ease-in-out  ',
                  {
                    'opacity-100': open,
                  }
                )}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 cursor-pointer  relative  w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : (
              <img
                src={chatJPG}
                alt="chat"
                className={clsx(
                  'w-[75px] h-[75px]  cursor-pointer rounded-full object-cover object-center  bg-white  transition-all duration-200 ease-in-out   ',
                  {
                    'opacity-100': !open,
                  }
                )}
              />
            )}
          </h1>
          <Dialog
            className=" !w-full !max-w-screen !h-full !max-h-screen"
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{
              style: {
                padding: 0,
                margin: 0,
                position: isMobile ? 'relative' : 'absolute',
                right: isMobile ? 0 : 96,
                bottom: isMobile ? 0 : 96,
                borderRadius: 12,
                fullWidth: isMobile ? true : false,
                maxWidth: messageWindow
                  ? isMobile
                    ? 600
                    : 600
                  : isMobile
                  ? 600
                  : 225,
                height: 500,
                overflow: 'hidden',
                // make transition smoother
                transition: isMobile
                  ? 'all 1s ease-in-out'
                  : messageWindow
                  ? 'all 0.5s ease-in-out'
                  : 'all 0.5s ease-in-out',
              },
            }}>
            <div className="w-full h-full flex flex-col ">
              <div className=" h-[50px] flex flex-row justify-between items-center p-2 border-b border-black  border-opacity-20 drop-shadow-md">
                <DialogTitle className="flex  !text-sm   !p-2  justify-center  items-center">
                  Inbox
                </DialogTitle>
                <div
                  className="flex flex-row justify-center items-center space-x-2"
                  data-cy="minichat-header-operator-wrapper"
                  bis_skin_checked="1">
                  <div
                    onClick={handleMessageWindow}
                    className=" w-[16px] h-[16px] cursor-pointer"
                    bis_skin_checked="1">
                    <div bis_skin_checked="1">
                      {!messageWindow ? (
                        <i className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            className="">
                            <path d="M14 1a1 1 0 011 1v12a1 1 0 01-1 1H9v-1h5V2H9V1h5zM2 13v1h1v1H2a1 1 0 01-.993-.883L1 14v-1h1zm6 1v1H4v-1h4zM2 3.999V12H1V3.999h1zm4.975 1.319a.5.5 0 01.707.707L5.707 8h4.621a.5.5 0 010 1h-4.62l1.974 1.975a.5.5 0 01-.707.707L4.146 8.854a.5.5 0 010-.708zM3 1v1H2v.999H1V2a1 1 0 01.883-.993L2 1h1zm5 0v1H4V1h4z"></path>
                          </svg>
                        </i>
                      ) : (
                        <i className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            className="">
                            <path d="M14 1a1 1 0 011 1v12a1 1 0 01-1 1H9v-1h5V2H9V1h5zM2 13v1h1v1H2a1 1 0 01-.993-.883L1 14v-1h1zm6 1v1H4v-1h4zM2 3.999V12H1V3.999h1zm5.854 1.319l2.828 2.828a.5.5 0 010 .708l-2.828 2.828a.5.5 0 11-.708-.707L9.121 9H4.5a.5.5 0 010-1h4.621L7.146 6.025a.5.5 0 11.708-.707zM3 1v1H2v.999H1V2a1 1 0 01.883-.993L2 1h1zm5 0v1H4V1h4z"></path>
                          </svg>
                        </i>
                      )}
                    </div>
                  </div>
                  {/* <div className=" w-[16px] h-[16px] cursor-pointer" data-cy="minichat-header-operator-item-wrapper" bis_skin_checked="1">
                    <div bis_skin_checked="1">
                      <i className="">
                        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="">
                          <path d="M14 1a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h12zm0 1H2v12h12V2zm-2.904 5.268l-2.828 2.828a.5.5 0 01-.707 0L4.732 7.268a.5.5 0 11.707-.707l2.475 2.475L10.39 6.56a.5.5 0 11.707.707z"></path>
                        </svg>
                      </i>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className=" flex-1 flex flex-row w-full h-full  ">
                <DialogContent
                  className={` !w-full !max-w-[${
                    messageWindow ? '225' : isMobile ? '600' : '225'
                  }px]   h-full !overflow-y-auto !overflow-x-hidden !box-content`}
                  sx={{
                    padding: 0,
                    margin: 0,
                  }}>
                  <List
                    className={` !p-0 !m-0  !w-full !max-w-[${
                      messageWindow ? '225' : isMobile ? '600' : '225'
                    }px]   h-full border-r border-black  border-opacity-20`}>
                    {chats &&
                      chats.map((chat) => {
                        if (!userId) return
                        const cur = chat.participants.filter(
                          (participant) => participant.id === userId
                        )[0]
                        if (!cur) return
                        const otherParticipant =
                          chat.participants.filter(
                            (participant) => participant.id !== userId
                          )[0]
                        const latestMessage = chat.messages.length
                          ? chat.messages[chat.messages.length - 1]
                          : null
                        //only get date of latest message, localdatestring shows month/day/year, change to day/month/year
                        let latestTimeStamp =
                          new Date(
                            cur?.latestSeenTimestamp?.seconds * 1000
                          ).toLocaleDateString('pt-PT') ?? null
                        let read = !cur.latestSeenTimestamp
                          ? true
                          : false
                        let limitedMessage = ''
                        let message = ''

                        // console.log('latestMessage', latestMessage)
                        // console.log(
                        //   'cur.latestSeenTimestamp',
                        //   cur.latestSeenTimestamp
                        // )
                        // console.log('read', read)

                        if (
                          latestMessage &&
                          cur.latestSeenTimestamp
                        ) {
                          // console.log(
                          //   'latestMessage22',
                          //   latestMessage.timestamp
                          // )
                          // console.log(
                          //   'cur.latestSeenTimestamp22',
                          //   cur.latestSeenTimestamp
                          // )
                          read =
                            latestMessage?.timestamp?.seconds >
                            cur?.latestSeenTimestamp?.seconds
                          // console.log('read', read)
                        }

                        if (latestMessage) {
                          latestTimeStamp = new Date(
                            latestMessage.timestamp?.seconds * 1000
                          ).toLocaleDateString('pt-PT')
                          limitedMessage =
                            latestMessage.text.length > 20
                              ? latestMessage.text.substring(0, 20) +
                                '...'
                              : latestMessage.text

                          // if you sent the message, show : You: message
                          // if you received the message, show : message
                          // if you received the message and read it, show : normal message
                          // if you received the message and not read it, show : green message

                          message =
                            cur.id === latestMessage.senderId
                              ? `You: ${limitedMessage}`
                              : limitedMessage
                        }
                        return (
                          <ListItem
                            onClick={() => handleSetActive(chat.id)}
                            className={clsx(
                              ' cursor-pointer  !w-full  hover:bg-gray-200 flex justify-between !p-2',
                              {
                                '!max-w-[600px]':
                                  isMobile && !messageWindow,
                                '!max-w-[225px]':
                                  !isMobile || messageWindow,

                                'bg-[#105E26]': read,
                                '!bg-gray-200': active === chat.id,
                              }
                            )}
                            key={Math.random() * 100000 + chat.id}>
                            <ListItemAvatar
                              className={clsx(
                                ' !min-w-[5px] !w-full !max-w-[35px] m-2  flex justify-center items-center',
                                {
                                  '!max-w-[25px]': isMobile,
                                }
                              )}>
                              <Avatar
                                src={otherParticipant?.image}
                                alt="avatar"
                                sx={{
                                  //center image
                                  objectPosition: 'center',

                                  width: isMobile ? 25 : 35,
                                  height: isMobile ? 25 : 35,
                                }}
                              />
                            </ListItemAvatar>
                            <div className="flex-1">
                              <div className="flex flex-row justify-between  items-center">
                                <ListItemText
                                  primaryTypographyProps={{
                                    sx: {
                                      color:
                                        read && active !== chat.id
                                          ? 'white'
                                          : 'black',
                                      fontSize: isMobile ? 11 : 14,
                                    },
                                  }}
                                  primary={
                                    // cut pariticipant name
                                    otherParticipant.endName.length >
                                    7
                                      ? otherParticipant.endName.substring(
                                          0,
                                          7
                                        ) + '...'
                                      : otherParticipant.endName
                                  }
                                />
                                <ListItemText
                                  secondaryTypographyProps={{
                                    sx: {
                                      color:
                                        read && active !== chat.id
                                          ? 'white'
                                          : 'black',
                                      fontSize: isMobile ? 8 : 10,
                                      textAlign: 'right',
                                    },
                                  }}
                                  secondary={latestTimeStamp}
                                />
                              </div>
                              <ListItemText
                                secondaryTypographyProps={{
                                  sx: {
                                    color:
                                      read && active !== chat.id
                                        ? 'white'
                                        : 'black',
                                    fontSize: isMobile ? 9 : 10,
                                  },
                                }}
                                secondary={message}
                              />
                            </div>
                          </ListItem>
                        )
                      })}
                  </List>
                </DialogContent>
                <Slide
                  direction="left"
                  in={messageWindow}
                  mountOnEnter
                  unmountOnExit
                  easing={{
                    enter: 'ease-in-out',
                    exit: 'ease-in-out',
                  }}
                  timeout={{
                    enter: isMobile ? 100 : 250,
                    exit: isMobile ? 100 : 250,
                  }}>
                  <div
                    className={` max-h-full w-full box-border max-w-[calc(600px-${
                      isMobile ? '150' : '225'
                    }px)] flex flex-col items-center bg-gray-200 `}>
                    {activeChat && (
                      <>
                        <div className="w-full h-[30px] box-border flex justify-center items-center  text-sm bg-white p-2 border-b border-black  border-opacity-20 drop-shadow-md">
                          <p>{activeChat.otherParticipant.endName}</p>
                        </div>
                        <ul className="flex flex-col-reverse  pb-3 w-full h-[calc(420px-100px)] box-border overflow-y-auto">
                          {activeChat.messages
                            .toReversed()
                            .map((message) => (
                              <li
                                key={
                                  Math.random() * 100000 +
                                  message.text
                                }
                                className={clsx(
                                  'w-auto p-2 mr-auto ml-2 mt-2 mb-auto text-sm bg-white rounded-lg',
                                  {
                                    '!mr-2 !ml-auto':
                                      message?.senderId === userId,
                                  }
                                )}>
                                {message.image && (
                                  <div className="flex justify-center items-center w-full max-w-[200px] p-2 object-cover object-center ">
                                    <img
                                      src={message.image}
                                      alt="image"
                                    />
                                  </div>
                                )}
                                <p className=" w-full max-w-[200px]  ">
                                  {message.text}
                                </p>
                              </li>
                            ))}
                        </ul>
                        <div
                          className={clsx(
                            '  w-full h-[100px] box-border flex flex-row   bg-white ',
                            {}
                          )}>
                          {/* show userInput.image if there is any */}
                          <div className="w-[90%] h-full flex-1">
                            <textarea
                              placeholder="Type your message..."
                              ref={inputRef}
                              value={userInput.message}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSendMessage(e)
                                  // reset target value
                                }
                              }}
                              onChange={(e) =>
                                setUserInput((prev) => {
                                  return {
                                    ...prev,
                                    message: e.target.value,
                                  }
                                })
                              }
                              className="h-full max-h-[60px] w-full text-sm  overflow-hidden resize-none  p-1 "
                            />
                          </div>
                          <div className=" flex flex-col justify-between items-center">
                            {userInput.image && (
                              <div className=" flex justify-center items-center w-full max-w-[100px] h-full max-h-[50px] p-2 m-2 box-border object-cover object-center ">
                                <img
                                  src={userInput.image}
                                  alt="image"
                                />
                              </div>
                            )}
                            <div
                              onClick={handleSendMessage}
                              className="w-[32px]  flex flex-col mt-auto h-[32px] p-2 ml-auto hover:bg-gray-200 rounded-full cursor-pointer mr-3 my-1">
                              <i>
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4 14.497v3.724L18.409 12 4 5.779v3.718l10 2.5-10 2.5zM2.698 3.038l18.63 8.044a1 1 0 010 1.836l-18.63 8.044a.5.5 0 01-.698-.46V3.498a.5.5 0 01.698-.459z"></path>
                                </svg>
                              </i>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Slide>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  )
}

export default Chat
