import React, { useEffect, useState } from 'react'
import './style.css'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import StepperIcon1 from '../../assets/icons/steppericon1.jsx'
import StepperIcon2 from '../../assets/icons/steppericon2.jsx'
import StepperIcon3 from '../../assets/icons/steppericon3.jsx'
import StepperIcon4 from '../../assets/icons/steppericon4.jsx'
import StepperIcon5 from '../../assets/icons/steppericon5.jsx'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import { useDispatch, useSelector } from 'react-redux'
import { forwardStep, setListing, setStep } from 'redux/listing'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function HorizontalNonLinearStepper({ id, edit }: { id: string; edit: boolean }) {
  const dispatch = useDispatch()
  const [completed, setCompleted] = useState<{
    [key: number]: boolean
  }>({})
  const step = useSelector((state: any) => state.listing.step)

  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem('step', step)
  }, [step])

  const pendingListing = useSelector((state: any) => state.listing.pendingListing)
  const endUser = useSelector((state: any) => state.user?.data)

  const [getListing, { loading: listingLoading }]: any = useMutation({
    endPoint: APIEndpoints.GET_LISTING.replace(':id', id),
    method: 'get',
  })

  const [createListing, { loading }]: any = useMutation({
    endPoint: APIEndpoints.CREATE_LISTING,
  })

  const [updateListing]: any = useMutation({
    endPoint: APIEndpoints.UPDATE_LISTING,
    method: 'put',
  })

  function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  useEffect(() => {
    // Fetch the listing initially
    getListing()
      .then(async (response: any) => {
        let updatedPendingListing = response
        // If endUser isn't set on the fetched listing, add it
        if (updatedPendingListing && !updatedPendingListing.endUser) {
          updatedPendingListing = {
            ...updatedPendingListing,
            endUser: {
              endEmail: endUser.endEmail,
              endName: endUser.endName,
              _id: endUser._id,
            },
          }
        }
        const sold = updatedPendingListing?.status === 'sold'
        if (sold) {
          // toast.error('This listing has been sold')
          await sleep(2000)
          window.location.href = '/listing/' + id
          return
        }

        if (updatedPendingListing) {
          dispatch(setListing(updatedPendingListing))
        }
      })
      .catch((error: any) => {
        console.error('Error fetching the listing:', error)
      })
  }, [endUser])
  const intl = useIntl()

  const editFields = [
    {
      label: intl.formatMessage({ id: 'create_listing.steps.1' }),
      Icon: <StepperIcon2 className="stepperIcon" selected={step == 0 ? true : false} />,
    },
    {
      label: intl.formatMessage({ id: 'create_listing.steps.2' }),
      Icon: <StepperIcon3 className="stepperIcon" selected={step == 1 ? true : false} />,
    },
    {
      label: intl.formatMessage({ id: 'create_listing.steps.3' }),
      Icon: <StepperIcon4 className="stepperIcon" selected={step == 2 ? true : false} />,
    },
  ]
  const createFields = [
    {
      label: intl.formatMessage({ id: 'create_listing.steps.4' }),
      Icon: <StepperIcon5 className="stepperIcon" selected={step == 3 ? true : false} />,
    },
    {
      label: intl.formatMessage({ id: 'create_listing.steps.5' }),
      Icon: <StepperIcon1 className="stepperIcon" selected={step == 4 ? true : false} />,
    },
  ]
  let steps = [...editFields]

  if (!edit) {
    steps = [...steps, ...createFields]
  }

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return step === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleBack = () => {
    setStep((prevActiveStep: any) => prevActiveStep - 1)
  }

  const handleStep = (step: any) => () => {
    dispatch(setStep(step))
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[step] = true
    setCompleted(newCompleted)
    // handleNext();
  }

  const handleReset = () => {
    setStep(0)
    setCompleted({})
  }

  const handleSubmit = () => {
    console.log('handleSubmit__________', pendingListing)
    const id = pendingListing._id
    if (pendingListing.status !== 'approved') {
      console.log("Update if status isn't approved")
      const updateListingEndPoint = APIEndpoints.UPDATE_LISTING.replace(':id', id)
      updateListing(pendingListing, updateListingEndPoint, 'put')
    }

    if (edit && step >= 2) {
      window.location.href = '/listing/' + id
      return
    }
    dispatch(forwardStep(pendingListing))
  }

  const STEPS = []

  if (!listingLoading) {
    STEPS.push(<Step1 key="Step1" pendingListing={pendingListing} handleSubmit={handleSubmit} loading={loading} />)
    STEPS.push(<Step2 key="Step2" pendingListing={pendingListing} handleSubmit={handleSubmit} loading={loading} />)
    STEPS.push(<Step3 key="Step3" pendingListing={pendingListing} handleSubmit={handleSubmit} loading={loading} />)
    STEPS.push(<Step4 key="Step4" pendingListing={pendingListing} handleSubmit={handleSubmit} loading={loading} />)
    STEPS.push(<Step5 key="Step5" pendingListing={pendingListing} handleSubmit={handleSubmit} loading={loading} />)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={step}
        connector={null}
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'baseline',
          padding: '20px 0px',
          marginTop: '30px',
          borderRadius: '10px',
          // border: "2px solid #fff",
          boxShadow: '0px 0px 1px 1px rgba(136,136,136,0.3)',
          // border: "2px solid red",

          '& .MuiStepLabel-root': {
            flexDirection: { xs: 'column', md: 'row' },
          },
          '& .MuiStepLabel-label': {
            fontSize: { xs: '8px', sm: '10px', md: '14px' },
          },
          '& .MuiStepLabel-iconContainer': {
            paddingRight: { xs: '0px', md: '8px' },
            // border: "2px solid blue", a
          },
          '& .MuiStepper-horizontal': {
            // border: "2px solid red",
          },
        }}
        className="">
        {steps.map((el, index) => (
          <Step key={el.label} completed={completed[index]} className="flex sm:w-16 ">
            <StepButton icon={el.Icon} color="inherit" onClick={handleStep(index)}>
              {el.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="mt-10 sm:w-[95%] sm:mx-auto">{STEPS[step]}</div>
          </React.Fragment>
        )}
      </div>
    </Box>
  )
}
