import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUserData, setLoggedIn, setVerificationMessage } from 'redux/user'
import { motion } from 'framer-motion'
import EmailIcon from '../../assets/icons/envelope-icon.png'
import Logo from '../../assets/images/pngLogo.png'
import { APIEndpoints } from 'staticStore'
import http from '../../http'
import { toast } from 'react-toastify'
import Footer from 'Components/Footer'

const VerifyEmail = () => {
  const [status, setStatus] = useState('Verifying...')
  const [showFooter, setShowFooter] = useState(true) // Add this state
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useParams()

  useEffect(() => {
    if (!token) {
      setShowFooter(true)
      setStatus('No verification token found')
    } else {
      // axios
      // .get(`${APIEndpoints.VEHICLE}/auth/verify-email?token=${token}`)
      setShowFooter(false)
      http
        .get(`/auth/verify-email?token=${token}`)
        .then((response) => {
          setStatus(response.data.message)
          console.log('AXIOS RESPONSE DATA: >>>> ', response.data)
          dispatch(setUserData(response.data.user)) // Set user data
          dispatch(setLoggedIn(true)) // Set user as logged in
          dispatch(setVerificationMessage(response.data.message)) // Store the message
          localStorage.setItem('accessToken', response.data.token) // Store token to local storage
          console.log('Redirecting to home page...')
          setTimeout(() => {
            navigate('/')
          }, 3000)
        })
        .catch((error) => {
          if (error.response) {
            // toast.error(error.response.data.message)
            setStatus(error.response.data.message)
          } else if (error.request) {
            // The request was made but no response was received
            // toast.error('Server Error. Please contact support.')
            setStatus('No response received from server. Please check your network connection.')
          }
        })
    }
  }, [location.search, dispatch, navigate])

  return (
    <>
      {status === 'Email verified successfully' ? (
        <div className="my-8 xxl:my-0 xxl:mt-16">
          <h1 className="text-4xl xxl:text-[48px] font-bold text-center md:text-2xl xxl:leading-snug">Email verified successfully</h1>
          <p className="mt-5 md:text-sm text-md xxl:text-xl text-center xxl:leading-snug">Redirecting to home page...</p>
        </div>
      ) : (
        <div className="flex flex-col items-center align-middle my-8 xxl:my-0 xxl:mt-16">
          <img className="h-20 w-32 m-5" src={Logo} />
          <h1 className="text-4xl xxl:text-[48px] font-bold text-center md:text-2xl xxl:leading-snug">Check Your Email to Complete Registration!</h1>
          <p className="mt-5 md:text-sm text-md xxl:text-xl text-center xxl:leading-snug">
            We've sent you a verification email. Please check your inbox and click on the link to verify your account.
            <br />
            Once you've verified, you're ready to enjoy the best car dealer platform ever!
          </p>
          <div className="mt-5 flex justify-center items-center">
            <motion.img
              className="w-32 h-32"
              src={EmailIcon}
              animate={{ y: ['10%', '-10%', '10%'] }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                times: [0, 0.5, 1],
                loop: Infinity,
                repeatDelay: 1,
              }}
            />
          </div>
        </div>
      )}

      {showFooter && <Footer id="mainFooter" />}
    </>
  )
}
export default VerifyEmail
