import React from 'react'
import ResponsiveAppBar from 'Components/AppBar'
import CustomizedSteppers from 'Components/Stepper'

// import "./style.css";
import Left from './Left'
import Right from './Right'

const Intro = () => {
  return (
    <div>
      {/* container */}

      <div className="flex lg:flex-col-reverse w-full h-full">
        {/* left */}
        <Left />
        {/* right */}
        <Right />
      </div>
    </div>
  )
}

export default Intro
