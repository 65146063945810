import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CarCard from 'Components/Cards/CarCard'
import { GoodDealIcon } from '../../assets/icons/pricingToolsIcon/dealIcon'
import './style.css'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import TestinomialCarousel from 'Components/Carousel/TestinomialCarousel'
import { ReactComponent as WriteReviewIcon } from '../../assets/icons/write-review.svg'
import { APIEndpoints, MicroServices } from 'staticStore'
import { formatCurrency } from 'Utils/Parse/currency'
import useMutation from 'redux/hooks/useMutation'
import { getCityLabel } from 'Resources/Data/cities'
import { useIntl } from 'react-intl'

const Browse = () => {
  const { search } = useLocation()
  const [state, setState] = useState({})
  const [listings, setListings] = useState([])

  const [fetchListings] = useMutation({
    endPoint: APIEndpoints.FETCH_LISTINGS,
    method: 'get',
  })

  useEffect(() => {}, [search])

  useEffect(() => {
    fetchListings().then((data) => {
      if (data.length === 0) setListings([])
      setListings(
        data.map((post) => {
          post.images = post.images.map((img) => {
            let IMG = img.data.url

            return {
              original: IMG,
              thumbnail: IMG,
              originalHeight: '100px',
            }
          })
          return post
        })
      )
    })
  }, [state])

  useEffect(() => {}, [listings])

  const intl = useIntl()

  return (
    <div className="w-[95%] xxl:w-[90%]  m-auto sm:w-[95%] ">
      <div className="p-5  sm:p-4 sm:px-0  ">
        <div className="mt-8 xxl:my-0 xxl:mt-8 w-full flex lg:flex-wrap justify-center sm:[&>div]:mb-4  sm:overflow-scroll  sm:mb-5  sm:[&>div]:!mr-[0px]">
          {!listings?.length && (
            <div className="w-full flex justify-center items-center">
              <p className="text-[#707070] text-2xl">No results found</p>
            </div>
          )}
          {listings?.map((e, index) => {
            return (
              <CarCard
                id={e._id}
                key={index}
                images={e.images}
                overview={e.overview}
                mileage={e.odometer}
                city={getCityLabel(e.city, intl)}
                daysinmarket={'15'}
                dealType={'High Price'}
                Icon={GoodDealIcon}
                // autoscale width on multiple devices
                className="w-[90%] flex flex-col lg:flex-row md:flex-col  lg:max-w-full  box-border "
              />
            )
          })}
        </div>
        {!!listings?.length && (
          <div className="flex  justify-center m-auto ">
            <Stack
              sx={{
                backgroundColor: '#F0F0F0',
                padding: { xs: '2px', md: '5px' },
                // paddingY: {sm:'5px',},
                borderRadius: '100px',
                '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': { backgroundColor: '#105e26', color: '#fff' },
                '& .MuiPaginationItem-text': { color: '#707070' },
                '& .MuiPaginationItem-root': {
                  margin: 0,
                  padding: 0,
                },
                '& .MuiButtonBase-root': {
                  margin: { xs: 0, md: '4px' },
                  padding: 0,
                },
                '& .MuiPaginationItem-sizeMedium': {
                  margin: 0,
                  padding: 0,
                },
                '& .MuiPaginationItem-circular': {
                  minWidth: { xs: '28px', sm: '32px' },
                  minHeight: { xs: '28px', sm: '32px' },
                  margin: 0,
                  padding: 0,
                },
                '& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
                  margin: 0,
                  padding: 0,
                },
              }}
              spacing={0}>
              <Pagination count={10} />
            </Stack>
          </div>
        )}
        <div className="mt-20  xl:my-24 xxl:my-28 w-full ">
          <TestinomialCarousel />
          <button className="bg-[#105E26] ml-auto mr-44 mt-4 font-semibold p-3 text-white w-[160px]  rounded shadow-md shadow-[#105E26] flex text-sm">
            Write a review <WriteReviewIcon className="ml-auto" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Browse
