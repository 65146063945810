import React from "react";

const overviewIcon = ({color}) => {
  return (

      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.78464 3.73016H9.83874C10.0654 4.56319 10.6479 5.25387 11.4307 5.61777C12.2137 5.98165 13.1172 5.98165 13.9001 5.61777C14.6831 5.25388 15.2655 4.56322 15.4921 3.73016H17.2152C17.4955 3.73016 17.7546 3.58057 17.8949 3.33778C18.0349 3.09498 18.0349 2.7958 17.8949 2.55301C17.7546 2.31022 17.4955 2.16062 17.2152 2.16062H15.4857C15.259 1.32744 14.6766 0.636758 13.8936 0.272903C13.1107 -0.0909521 12.2072 -0.090983 11.4243 0.272903C10.6415 0.636788 10.0589 1.32744 9.83225 2.16062H0.784717C0.504377 2.16062 0.245285 2.31022 0.105071 2.55301C-0.0350237 2.7958 -0.0350237 3.09499 0.105071 3.33778C0.245316 3.58057 0.504404 3.73016 0.784717 3.73016H0.78464ZM12.6751 1.57039C13.0396 1.57265 13.3881 1.71953 13.6443 1.97875C13.9005 2.23783 14.0433 2.58814 14.0413 2.9525C14.0395 3.31698 13.8929 3.66565 13.6341 3.92214C13.3752 4.17866 13.0251 4.32179 12.6607 4.32024C12.2962 4.31873 11.9474 4.17261 11.6906 3.914C11.4339 3.65537 11.2904 3.30535 11.2914 2.94103C11.2932 2.57579 11.44 2.22621 11.6993 1.9691C11.9589 1.71214 12.3097 1.56873 12.675 1.57039H12.6751Z"
          fill={color}
        />
        <path
          d="M17.2154 8.64188H8.15894C7.9335 7.80823 7.35124 7.11681 6.56829 6.75249C5.78534 6.38818 4.88143 6.38816 4.09844 6.75249C3.31545 7.11683 2.7334 7.80827 2.50779 8.64188H0.784717C0.504377 8.64188 0.245285 8.79147 0.105071 9.03426C-0.0350237 9.27705 -0.0350237 9.57624 0.105071 9.81903C0.245316 10.0618 0.504404 10.2114 0.784717 10.2114H2.51427C2.73987 11.0451 3.32197 11.7365 4.10493 12.1008C4.88788 12.4651 5.79178 12.4651 6.57478 12.1008C7.35777 11.7365 7.93998 11.045 8.16543 10.2114H17.2153C17.4956 10.2114 17.7547 10.0618 17.8949 9.81903C18.035 9.57623 18.035 9.27705 17.8949 9.03426C17.7547 8.79147 17.4956 8.64188 17.2153 8.64188H17.2154ZM5.32493 10.8016C4.96074 10.7994 4.61239 10.6527 4.35617 10.3939C4.10011 10.135 3.95699 9.78509 3.95865 9.42093C3.96016 9.05674 4.10598 8.70808 4.36432 8.45144C4.62264 8.19477 4.97219 8.05091 5.3364 8.05179C5.70058 8.05255 6.04955 8.19762 6.30682 8.45549C6.56409 8.71321 6.70854 9.06248 6.70854 9.42664C6.70794 9.79264 6.56166 10.1434 6.30215 10.4013C6.04246 10.6593 5.69095 10.8033 5.32497 10.8016H5.32493Z"
          fill={color}
        />
        <path
          d="M17.2152 15.1234H15.4857C15.259 14.2904 14.6766 13.5997 13.8936 13.2358C13.1107 12.8719 12.2072 12.8719 11.4243 13.2358C10.6415 13.5997 10.0589 14.2903 9.83225 15.1234H0.784717C0.504377 15.1234 0.245285 15.273 0.105071 15.5158C-0.0350237 15.7586 -0.0350237 16.0578 0.105071 16.3006C0.245316 16.5434 0.504404 16.6929 0.784717 16.6929H9.83882C10.0655 17.5261 10.648 18.2168 11.4308 18.5807C12.2138 18.9446 13.1173 18.9446 13.9002 18.5807C14.6832 18.2168 15.2656 17.5261 15.4922 16.6929H17.2153C17.4956 16.6929 17.7547 16.5434 17.8949 16.3006C18.035 16.0578 18.035 15.7586 17.8949 15.5158C17.7547 15.273 17.4956 15.1234 17.2153 15.1234H17.2152ZM12.6752 17.2853C12.31 17.2877 11.9587 17.1445 11.6992 16.8875C11.4397 16.6305 11.293 16.2808 11.2916 15.9154C11.2903 15.5502 11.4344 15.1994 11.692 14.9405C11.9497 14.6815 12.2999 14.5359 12.6651 14.5354C13.0303 14.5351 13.3808 14.68 13.6391 14.9384C13.8973 15.1967 14.0421 15.5473 14.0416 15.9125C14.0394 16.2746 13.8947 16.6213 13.6391 16.8777C13.3835 17.1342 13.0373 17.2797 12.6752 17.2832L12.6752 17.2853Z"
          fill={color}
        />
      </svg>

  );
};

export default overviewIcon;
