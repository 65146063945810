import { QuestionMark as QuestionMarkIcon } from 'assets/icons/listingIcons'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { capitalizeFirstLetter } from 'Utils/Parse'
import { USDtoVND, VNDshorten, formatCurrency, loanEsimationVND_monthlyCalculator } from 'Utils/Parse/currency'

import { listingCarText, listingConditionText, listingOriginANDTransmissionText, listingOriginText, listingTransmissionText } from 'Utils/Parse/Text'
import { useDispatch, useSelector } from 'react-redux'
import { openCheckAvailability } from 'redux/modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import locationJPG from '../Cards/CarCard/location.png'
import odometerJPG from '../Cards/CarCard/odometer.png'
import { ReactComponent as locationSVG } from './location.svg'
import { ReactComponent as odometerSVG } from './odometer.svg'
function CarInfo({ className = '', height = '', post, title, mileage, city, price, condition, transmission, status }) {
  const dispatch = useDispatch()

  const formatstr = (str, type) => {
    let strTemp = ''
    let strString = str ? str.toString() : ''
    for (let i = strString.length - 1; i >= 0; i--) {
      strTemp = strString[i] + strTemp
      //every three digits, this function will add "," to seperate these digits
      if ((strString.length - i) % 3 === 0 && i !== 0) {
        if (type === 'meter') {
          strTemp = ',' + strTemp
        } else if (type === 'currency') {
          strTemp = '.' + strTemp
        }
      }
    }
    return strTemp
  }

  const navigate = useNavigate()

  const user = useSelector((state) => state.user)
  const userData = user.data

  const intl = useIntl()

  const sold = status === 'sold'

  return (
    <div
      className={clsx('w-full h-full flex flex-col justify-between p-4 pb-8 px-8  lg:px-3 lg:pb-3  ', {
        [className]: !!className,
      })}>
      <div className="w-full h-full flex justify-around space-y-5 xl:space-y-3   flex-col">
        <span className="text-[#747474] flex flex-row space-x-3">
          <p>
            {intl.formatMessage({
              id: 'listing.condition.' + post.condition,
            })}
          </p>
          <p>|</p>
          <p>{intl.formatMessage({ id: post.origin })}</p>
          <p>|</p>
          <p>{intl.formatMessage({ id: post.transmission })}</p>
        </span>
        <div className={clsx('  w-full !m-0 ')}>
          <p
            className={clsx(' font-[700]  ', {
              'text-2xl': height <= 300,
              'text-3xl': height > 300 && height <= 400,
              'text-4xl': height > 400 && height <= 500,
              'text-5xl': height > 500 && height <= 600,
            })}>
            <span className="notranslate">{listingCarText(post)}</span>
          </p>
          {/* <p
            className={clsx(' font-[700]  ', {
              ['text-base']: height <= 300,
              ['text-lg']: height > 300 && height <= 400,
              ['text-xl']: height > 400 && height <= 500,
              ['text-2xl']: height > 500 && height <= 600,
            })}
          >
            {listingOriginANDTransmissionText(post)}
          </p> */}
        </div>
        <div className=" flex flex-row items-center space-x-3 text-[#747474] ">
          <p className=" flex flex-row items-center space-x-2">
            <img src={odometerJPG} alt="" className=" bg-transparent w-6 h-6 mr-2 object-contain" />
            {mileage}km
          </p>
          <p>|</p>
          <p className=" flex flex-row items-center space-x-2">
            <img src={locationJPG} alt="" className=" bg-transparent w-6 h-6 mr-2 object-contain" />
            {city}
          </p>
        </div>
      </div>
      <div className="w-full h-full  my-[5%] xl:my-[3%]  max-h-[0.1rem] m-auto bg-gray-300 border border-gray-300 "></div>
      <div className="w-full h-full flex justify-around  flex-col">
        <div className="w-full h-full flex flex-col justify-around pt-2">
          {post.originalPrice !== post.price && (
            <h1
              className={clsx(' text-gray-500 text-md line-through ', {
                'text-xs': height <= 300,
                'text-sm': height > 300 && height <= 400,
                'text-base': height > 400 && height <= 500,
                'text-lg': height > 500 && height <= 600,
              })}>
              {VNDshorten(post.originalPrice)}
            </h1>
          )}
          <div className="flex items-center justify-between mt-2 mb-[3%]">
            <h1
              className={clsx('text-[#105E26]  font-bold sm:text-sm ', {
                'text-xl': height <= 300,
                'text-2xl': height > 300 && height <= 400,
                'text-3xl': height > 400 && height <= 500,
                'text-4xl': height > 500 && height <= 600,
              })}>
              {VNDshorten(price)}
            </h1>

            <a className="text-[#105E26] text-base xl:text-sm">
              <FormattedMessage id="need_financing" />?
            </a>
          </div>
          {/* <p
            className={clsx(
              'text-[#105E26] lg:text-base xl:text-2xl   xxl:text-[36px] font-bold sm:text-sm ',
              {
                ['text-xl']: height <= 300,
                ['text-2xl']: height > 300 && height <= 400,
                ['text-3xl']: height > 400 && height <= 500,
                ['text-4xl']: height > 500 && height <= 600,
              }
            )}
          >
            {formatCurrency(price, 'VND', 'vi-VN')}
          </p> */}
          <h1
            onClick={() => navigate('/setting/1')}
            className={clsx('text-[#105E26] cursor-pointer  underline flex items-center gap-2 mb-[10%] lg:mb-[5%] ', {
              'text-xs': height <= 300,
              'text-sm': height > 300 && height <= 400,
              'text-base': height > 400 && height <= 500,
              'text-lg': height > 500 && height <= 600,
            })}>
            {`Est. ${loanEsimationVND_monthlyCalculator(price, userData?.monthlyCalculator)} ${intl.locale == 'vn' ? 'Triệu/Tháng' : 'Trieu/Thang'}`}
            <QuestionMarkIcon />
          </h1>
        </div>

        <button
          onClick={() => {
            if (sold) {
              // toast.info('This car is sold')
              return
            }
            dispatch(openCheckAvailability(post))
          }}
          className="w-full py-3 px-4 text-center text-[#105e26] border border-[#105e26]  hover:text-[#fff] hover:bg-[#105e26]   ">
          {sold ? 'Sold' : <FormattedMessage id="checkavailability" />}
        </button>
      </div>
    </div>
  )
}

export default CarInfo
