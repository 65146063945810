import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import carforsale from '../../assets/images/carforsale.png'

import { ReactComponent as FeatureIcon1 } from '../../assets/icons/featuresIcon1.svg'
import { ReactComponent as FeatureIcon2 } from '../../assets/icons/featuresIcon2.svg'
import { ReactComponent as FeatureIcon3 } from '../../assets/icons/featuresIcon3.svg'
import { ReactComponent as FeatureIcon4 } from '../../assets/icons/featuresIcon4.svg'
import { ReactComponent as FeatureIcon5 } from '../../assets/icons/featuresIcon5.svg'
import { ReactComponent as FeatureIcon6 } from '../../assets/icons/featuresIcon6.svg'
import { ReactComponent as Phone } from '../../assets/icons/phoneicon.svg'
import { ReactComponent as Email } from '../../assets/icons/emailicon.svg'
import { ReactComponent as Website } from '../../assets/icons/websiteicon.svg'
import { ReactComponent as Share } from '../../assets/icons/shareicon.svg'
import { ReactComponent as MessageIcon } from '../../assets/icons/messageicon.svg'
import { ReactComponent as DealerLogo } from '../../assets/icons/premierdealerlogo.svg'

import OverviewIcon from '../../assets/icons/overviewIcon.jsx'
import FeaturesOptionsIcon from '../../assets/icons/featuresoptionIcon.jsx'
import SellerInfoIcon from '../../assets/icons/sellerinfoIcon.jsx'
import dealerLogo from '../../assets/images/dealerLogo.png'
import ListItem from './ListItem'
import { MicroServices } from 'staticStore'
import { useNavigate } from 'react-router-dom'

import DealerStat from 'Components/DealerStat'
import useQuery from 'redux/hooks/useQuery'
import { Popover, createTheme } from '@mui/material'
import { FacebookShareButton, TwitterShareButton, EmailShareButton, FacebookIcon, TwitterIcon, EmailIcon } from 'react-share'
import { listingCarText } from 'Utils/Parse/Text'
import { FormattedMessage, useIntl } from 'react-intl'
import { CheckAvailabilityContext } from 'App'

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 426,
    },
  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs({ postID, post, endUser, dealer, overview, roof, seating, option, safety, wheels, technologies }) {
  const navigate = useNavigate()

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue)
  }
  const formatStr = (str) => {
    return `${str[0].toUpperCase()}${str
      .slice(1)
      .split(/(?=[A-Z])/)
      .join(' ')}`
  }

  const mappingFeatures = (features) => {
    if (features) {
      console.log('____________features______________', features)
      return Object.keys(features).map((key, i) => {
        if (features[key]) {
          if (features === wheels) {
            return (
              <ListItem>
                <FormattedMessage
                  id={`${key
                    .split(/(?=[A-Z])/)
                    .join('_')
                    .toLowerCase()}_wheels`}
                />
              </ListItem>
            )
          } else
            return (
              <ListItem>
                <FormattedMessage
                  id={`${key
                    .split(/(?=[A-Z])/)
                    .join('_')
                    .toLowerCase()}`}
                />
              </ListItem>
            )
        } else {
          return
        }
      })
    } else return
  }
  // const [getRating] = useQuery({
  //   endPoint: `/endusr/active_rat_rev?platform_type=Dealer%20feedback&dealerID=${dealer._id}`,
  //   method: 'get',
  // })

  const [dealerRating, setDealerRating] = React.useState(null)
  React.useEffect(() => {
    // use this api to get dealer rating   /api/endusr/active_rat_rev
    const getRating = async () => {
      const data = await fetch(`${MicroServices.GATEWAY}/endusr/active_rat_rev?platform_type=Dealer%20feedback&dealerID=${endUser._id}`)
      const res = await data.json()
      return res
    }

    getRating().then((data) => {
      console.log('____________dealer rating______________', data)
      setDealerRating(data)
    })
  }, [])

  React.useEffect(() => {
    console.log('____________dealer rating______________')
    console.log(dealerRating)
  }, [dealerRating])

  const shareUrl = `https://sleekride.com/listing/${postID}`
  const title = `${listingCarText(post)}`

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  window.onscroll = function () {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const intl = useIntl()

  const { setCheckAvailabilityPost } = React.useContext(CheckAvailabilityContext)
  return (
    <Box sx={{ width: '10{dealerRating.listPercentage[2]}%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              background: '#105E26',
              color: '#105E26',
            },
          }}
          sx={{
            // '& .MuiTabs-indicator': { backgroundColor: 'red' },
            '& .MuiTab-root': { color: '#747474' },
            '& .Mui-selected': { color: '#105E26 !important' },
            // '& .MuiButtonBase-root': { fontSize: { xs: '0.5rem', md: '1rem' } },
          }}>
          {/* <Tab label="FEATURES & OPTIONS" {...a11yProps(1)} /> */}
          <Tab
            icon={<OverviewIcon color={value == 0 ? '#105E26' : ' #747474'} />}
            iconPosition="start"
            label={intl.formatMessage({
              id: 'listing.tabs.overview',
            })}
            {...a11yProps(0)}
            // MuiButtonBase-root
            sx={{
              gap: '10px',
              [theme.breakpoints.down('sm')]: {
                fontSize: '0',
              },
            }}
          />
          <Tab
            icon={<FeaturesOptionsIcon color={value == 1 ? '#105E26' : ' #747474'} />}
            iconPosition="start"
            label={intl.formatMessage({
              id: 'listing.tabs.features',
            })}
            {...a11yProps(1)}
            sx={{
              gap: '10px',
              [theme.breakpoints.down('sm')]: {
                fontSize: '0',
              },
            }}
            // small screen then font size 0.5rem
          />
          <Tab
            icon={<SellerInfoIcon color={value == 2 ? '#105E26' : ' #747474'} />}
            iconPosition="start"
            label={intl.formatMessage({
              id: 'listing.tabs.seller',
            })}
            {...a11yProps(2)}
            sx={{
              gap: '10px',
              [theme.breakpoints.down('sm')]: {
                fontSize: '0',
              },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} style={{ paddingTop: '40px' }} index={0}>
        <p className="my-2 text-sm">{overview}</p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="grid grid-cols-3 mt-10 lg:gap-4   -lg:[&>div]:w-[32%] -lg:[&>div]:mb-3  ">
          {/* left */}
          <div className=" min-h-[200px]  h-auto max-h-[400px] ">
            <div className="rounded-xl bg-[#f3f3f3]  h-12 w-12 flex justify-center items-center ">
              <FeatureIcon1 />
            </div>
            <h1 className=" mt-1">
              {intl.formatMessage({
                id: 'listing.tabs.features.1',
              })}
            </h1>
            <ul className="mt-2">{mappingFeatures(seating)}</ul>
          </div>
          <div className=" min-h-[200px]  h-auto max-h-[400px] ">
            <div className="rounded-xl bg-[#f3f3f3]  h-12 w-12 flex justify-center items-center ">
              <FeatureIcon5 />
            </div>
            <h1 className=" mt-1">
              {intl.formatMessage({
                id: 'listing.tabs.features.2',
              })}
            </h1>
            <ul className="mt-2 ">{mappingFeatures(technologies)}</ul>
          </div>
          <div className=" min-h-[200px]  h-auto max-h-[400px] ">
            <div className="rounded-xl bg-[#f3f3f3]  h-12 w-12 flex justify-center items-center ">
              <FeatureIcon6 />
            </div>
            <h1 className=" mt-1">
              {intl.formatMessage({
                id: 'listing.tabs.features.3',
              })}
            </h1>
            <ul className="mt-2">{mappingFeatures(roof)}</ul>
          </div>
          <div className="min-h-[200px]  h-auto max-h-[400px]">
            <div className="rounded-xl bg-[#f3f3f3]  h-12 w-12 flex justify-center items-center ">
              <FeatureIcon2 />
            </div>
            <h1 className=" mt-1">
              {intl.formatMessage({
                id: 'listing.tabs.features.4',
              })}
            </h1>
            <ul className="mt-2">{mappingFeatures(wheels)}</ul>
          </div>

          {/* middle */}
          <div className="min-h-[200px]  h-auto max-h-[400px]  ">
            <div className="rounded-xl bg-[#f3f3f3]  h-12 w-12 flex justify-center items-center ">
              <FeatureIcon4 />
            </div>
            <h1 className=" mt-1">
              {intl.formatMessage({
                id: 'listing.tabs.features.5',
              })}
            </h1>
            <ul className="mt-2">{mappingFeatures(safety)}</ul>
          </div>

          {/* right */}

          <div className="min-h-[200px]  h-auto max-h-[400px]  ">
            <div className="rounded-xl bg-[#f3f3f3]  h-12 w-12 flex justify-center items-center ">
              <FeatureIcon3 />
            </div>
            <h1 className=" mt-1">
              {intl.formatMessage({
                id: 'listing.tabs.features.6',
              })}
            </h1>
            <ul className="mt-2">
              {option &&
                Object.keys(option).map((key, i) => {
                  if (!option[key]) return
                  return (
                    <ListItem>
                      <FormattedMessage
                        id={`options_${key
                          .split(/(?=[A-Z])/)
                          .join('_')
                          .toLowerCase()}`}
                      />
                    </ListItem>
                  )
                })}
            </ul>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="">
          <div className=" mt-[2%] sm:m-0 flex  sm:flex-col ">
            <div className=" w-2/3 sm:w-full   ">
              <div className="h-[80%] border-r-2">
                <div className=" flex pb-2 items-center sm:flex-col">
                  {dealer && <img src={dealer.logo} className="w-[200px] h-[150px] sm:w-full sm:h-full " />}
                  <p className="text-[#105e26] text-xl ml-5 sm:ml-0 sm:p-4 font-bold">{dealer ? dealer.dealershipName : endUser?.endName}</p>
                </div>
                <h1 className="font-semibold text-lg text-[#747474]    sm:my-4 sm:border-r-0 sm:border-b-2 pr-5">{dealer?.headline}</h1>
                <p className="text-xs pt-4 text-[#747474]   sm:my-4 sm:border-r-0 sm:border-b-2 pr-5">{dealer?.description}</p>
              </div>
            </div>

            <div className=" ml-[2%] sm:ml-0 w-1/3 sm:flex sm:flex-col sm:w-full">
              <div className=" sm:p-4 h-[fit]  ">
                <ul className=" space-y-5">
                  <li className="flex items-center space-x-2">
                    {dealer ? (
                      <>
                        <DealerLogo />
                        <h1 className="text-[24px] font-bold text-[#105e26]">
                          {intl.formatMessage({
                            id: 'listing.tabs.seller.dealer',
                          })}
                        </h1>
                      </>
                    ) : (
                      <>
                        <h1 className="text-[24px] font-bold text-[#105e26]">
                          {intl.formatMessage({
                            id: 'listing.tabs.seller.private',
                          })}
                        </h1>
                      </>
                    )}
                  </li>
                  <li
                    className="flex items-center space-x-2  cursor-pointer"
                    onClick={() => {
                      if (!endUser?.endPhone) return

                      console.log(
                        'Phone Lead Detected',
                        window.gtag('event', 'phone_lead', {
                          phone_lead__dealerId: endUser._id,
                          phone_lead__dealerPhone: endUser.endPhone,
                        })
                      )
                      // open phone for user
                      window.location.href = `tel:+${endUser.endPhone}`
                    }}>
                    <Phone />
                    <h1 className="text-[#747474]"> {endUser?.endPhone}</h1>
                  </li>
                  <li
                    className="flex items-center space-x-2  cursor-pointer"
                    onClick={() => {
                      if (!endUser?.endEmail) return
                      console.log(
                        'Email Lead Detected',
                        window.gtag('event', 'email_lead', {
                          email_lead__dealerId: endUser._id,
                          email_lead__dealerEmail: endUser.endEmail,
                        })
                      )
                      // open email for user
                      window.location.href = `mailto:${endUser?.endEmail}`
                    }}>
                    <Email />
                    <h1 className="text-[#747474]">{endUser?.endEmail}</h1>
                  </li>
                  {dealer && (
                    <>
                      <li className="flex items-center space-x-2">
                        <Website />
                        <h1 className="text-[#747474]">{dealer?.website}</h1>
                      </li>
                    </>
                  )}
                  <li className="flex items-center space-x-2">
                    <div className="flex  flex-row justify-start  items-center space-x-2 cursor-pointer " onClick={handleClick}>
                      <Share />{' '}
                      <h1 className="text-[#747474]">
                        {intl.formatMessage({
                          id: 'listing.tabs.seller.share',
                        })}
                      </h1>
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}>
                      <div className="Demo__container flex flex-row justify-center  items-center space-x-2 p-2">
                        <div className="Demo__some-network flex justify-center  items-center">
                          <FacebookShareButton url={shareUrl} quote={title} className="Demo__some-network__share-button">
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                        </div>

                        <div className="Demo__some-network flex justify-center  items-center">
                          <TwitterShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                        </div>

                        <div className="Demo__some-network flex justify-center  items-center">
                          <EmailShareButton
                            url={shareUrl}
                            subject={title}
                            body="Check out this vehicle on Sleekride! "
                            className="Demo__some-network__share-button">
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                        </div>
                        {/* add a box for copying the url to the page */}
                        {/* <div className="Demo__some-network">
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(shareUrl)
                          }}
                          >
                          <svg viewBox="0 0 64 64" width="32" height="32">
                          <circle
                          cx="32"
                          cy="32"
                          r="31"
                          fill="#7f7f7f"
                          ></circle>
                          <FontAwesomeIcon
                              className="text-white text-2xl "
                              icon={faLink}
                              />
                              </svg>
                              </button>
                            </div> */}
                      </div>
                    </Popover>
                  </li>
                  {/* <li className="flex items-center space-x-2">
                  </li> */}
                  <button
                    onClick={() => {
                      setCheckAvailabilityPost(post)
                      // dispatch(openCheckAvailability(post))
                    }}>
                    <li className="flex flex-row items-center space-x-2 ">
                      <MessageIcon />{' '}
                      <h1 className="text-[#747474]">
                        <FormattedMessage id="listing.tabs.seller.message" />
                      </h1>
                    </li>
                  </button>
                </ul>
              </div>
              <DealerStat dealerRating={dealerRating} endUserID={endUser._id} postID={postID} />
            </div>
          </div>
        </div>
      </TabPanel>
    </Box>
  )
}
