import {
  capitalizeAllFirstLetter,
  capitalizeFirstLetter,
} from './Capitalize'

export const listingCarText = (listing) => {
  if (!listing) return ''
  const { make, extractedModel, year } = listing.vin.data

  return `${year}-${make} ${extractedModel}`
}

export const listingOriginANDTransmissionText = (listing) => {
  if (!listing) return ''
  const { origin, transmission } = listing
  // translate origin and transmission from english to vietnamese
  // const originText = origin === 'domestic' ? 'Trong nước' : 'Nhập khẩu'
  // const transmissionText =
  //   transmission === 'automatic' ? 'Số tự động' : 'Số sàn'
  // return `${transmissionText} • ${originText}`
  return `${transmission} • ${origin}`
}

export const listingConditionText = (listing) => {
  if (!listing) return ''
  const { condition } = listing
  // translate condition from english to vietnamese
  const conditionText = condition === 'used' ? 'Cũ' : 'Mới'
  return `Xe ${conditionText}`
}

export const listingOriginText = (listing) => {
  if (!listing) return ''
  const { origin } = listing
  // translate origin from english to vietnamese
  const originText =
    origin === 'domestic' ? 'Trong nước' : 'Nhập khẩu'
  return `${originText}`
}

export const listingTransmissionText = (listing) => {
  if (!listing) return ''
  const { transmission } = listing
  // translate transmission from english to vietnamese
  const transmissionText =
    transmission === 'automatic' ? 'Số tự động' : 'Số sàn'
  return `${transmissionText}`
}
