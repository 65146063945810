import { Wheels } from '../Types/common';

export const wheels: Wheels[] = [
  {
    _id: '1',
    name: 'alloy',
    label: 'Alloy Wheels',
    translation: 'alloy_wheels',
  },
  {
    _id: '2',
    name: 'steel',
    label: 'Steel Wheels',
    translation: 'steel_wheels',
  },
  {
    _id: '3',
    name: 'chrome',
    label: 'Chrome Wheels',
    translation: 'chrome_wheels',
  },
]
