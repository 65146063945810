import { Technology } from '../Types/common'

export const technology: Technology[] = [
  {
    _id: '1',
    name: 'adaptiveCruise',
    label: 'Adaptive Cruise',
    translation: 'adaptive_cruise',
  },
  {
    _id: '2',
    name: 'androidAuto',
    label: 'Android Auto',
    translation: 'android_auto',
  },
  {
    _id: '3',
    name: 'appleCarPlay',
    label: 'Apple CarPlay',
    translation: 'apple_carplay',
  },
  {
    _id: '4',
    name: 'aux',
    label: 'Auxiliary Audio Input',
    translation: 'auxiliary_audio_input',
  },
  {
    _id: '5',
    name: 'bluetooth',
    label: 'Bluetooth',
    translation: 'bluetooth',
  },
  {
    _id: '6',
    name: 'keylessStart',
    label: 'Keyless Start',
    translation: 'keyless_start',
  },
  {
    _id: '7',
    name: 'navigation',
    label: 'Navigation',
    translation: 'navigation',
  },
  {
    _id: '8',
    name: 'parkAssist',
    label: 'Park Assist',
    translation: 'park_assist',
  },
  { _id: '9', name: 'radio', label: 'Radio', translation: 'radio' },
  {
    _id: '10',
    name: 'remoteStart',
    label: 'Remote Start',
    translation: 'remote_start',
  },
  {
    _id: '11',
    name: 'steeringWheelControls',
    label: 'Steering Wheel Controls',
    translation: 'steering_wheel_controls',
  },
  { _id: '12', name: 'usb', label: 'USB', translation: 'usb' },
  { _id: '13', name: 'wifi', label: 'WiFi', translation: 'wifi' },
  {
    _id: '14',
    name: 'wirelessPhoneCharging',
    label: 'Wireless Phone Charging',
    translation: 'wireless_phone_charging',
  },
]
