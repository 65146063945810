import { Yt, Fb, Tiktok, Twitter } from 'assets/icons/FooterIcons'
import React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Logo from '../../assets/icons/FooterLogo'
import gplaystore from '../../assets/images/gplaystore.png'
import appstore from '../../assets/images/appstore1.png'
import { useIntl } from 'react-intl'
import { cities } from 'Resources/Data/cities'

const Footer = () => {
  const navigate = useNavigate()

  const intl = useIntl()
  return (
    <div className="bg-[#105e26] text-white">
      <div className="p-10 md:p-8 sm:p-4">
        <div className="flex justify-between  overflow-hidden md:flex-wrap">
          <div className="col-span-2  md:flex md:flex-col md:items-center md:mb-5 w-[40%] md:w-full">
            <div className=" ml-5 mt-4">
              <Logo />
            </div>
            <p className="text-sm mt-8  pr-10 leading-6 md:pr-0  md:text-center ">
              {intl.formatMessage({
                id: 'footer.description',
              })}
            </p>
            <div className="flex space-x-2 ml-5 mt-8">
              <Fb /> <Twitter /> <Tiktok /> <Yt />
            </div>
          </div>
          <div className="hidden sm:flex  w-full justify-around">
            <img className="h-16 w-36 sm:h-12 sm:w-28 xxl:h-28 xxl:w-64 object-contain" src={gplaystore} />{' '}
            <img className="h-16 w-36 sm:h-12 sm:w-28 xxl:h-28 xxl:w-64 object-contain" src={appstore} />
          </div>
          <div className="grid grid-cols-4 gap-6 md:grid-cols-2 sm:grid-cols-2 sm:gap-0 ">
            <div className="list-none">
              <li className="font-semibold text-lg my-3">
                {' '}
                {intl.formatMessage({
                  id: 'footer.company',
                })}
              </li>
              <li
                onClick={() => {
                  navigate('/')
                  window.scrollTo(0, 0)
                }}
                className="my-4 cursor-pointer">
                {intl.formatMessage({
                  id: 'footer.about',
                })}
              </li>
              <li
                onClick={() => {
                  navigate('/')
                  window.scrollTo(0, 0)
                }}
                className="my-4 cursor-pointer">
                {intl.formatMessage({
                  id: 'footer.engineering',
                })}
              </li>
              <li
                onClick={() => {
                  navigate('/contact')
                  window.scrollTo(0, 0)
                }}
                className="my-4 cursor-pointer">
                {' '}
                {intl.formatMessage({
                  id: 'footer.contact-us',
                })}
              </li>
              {/* <li
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
                className="my-4 cursor-pointer"
              >
                Careers
              </li>
              <li
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
                className="my-4 cursor-pointer"
              >
                Our Team
              </li> */}
            </div>
            <div className="list-none">
              <li className="font-semibold text-lg my-3">
                {intl.formatMessage({
                  id: 'footer.locations',
                })}
              </li>
              <li
                onClick={() => {
                  navigate('/search-result?condition=all&location=ho+chi+minh+city')
                  window.scrollTo(0, 0)
                }}
                className="my-4 cursor-pointer">
                {intl.locale === 'vn'
                  ? cities.find((c) => c.label.toLowerCase() === 'Ho Chi Minh City'.toLowerCase()).vnLabel
                  : cities.find((c) => c.label.toLowerCase() === 'Ho Chi Minh City'.toLowerCase()).label}
              </li>
              <li
                onClick={() => {
                  navigate('/search-result?condition=all&location=ha+noi')
                  window.scrollTo(0, 0)
                }}
                className="my-4 cursor-pointer">
                {intl.locale === 'vn'
                  ? cities.find((c) => c.label.toLowerCase() === 'Ha Noi'.toLowerCase()).vnLabel
                  : cities.find((c) => c.label.toLowerCase() === 'Ha Noi'.toLowerCase()).label}
              </li>
            </div>
            <div className="list-none">
              <li className="font-semibold text-lg my-3">
                {' '}
                {intl.formatMessage({
                  id: 'footer.terms',
                })}{' '}
              </li>
              <li
                onClick={() => {
                  navigate('/terms-of-service')
                  window.scrollTo(0, 0)
                }}
                className="my-4  cursor-pointer">
                {intl.formatMessage({
                  id: 'footer.terms_of_service',
                })}
              </li>
              <li
                onClick={() => {
                  navigate('/privacy-policy')
                  window.scrollTo(0, 0)
                }}
                className="my-4 cursor-pointer">
                {intl.formatMessage({ id: 'footer.privacy_policy' })}
              </li>
              {/* <li
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
                className="my-4 cursor-pointer"
              >
                Do Not Sell My Personal Data
              </li> */}
            </div>
            <div className="list-none">
              <li className="font-semibold text-lg my-3">{intl.formatMessage({ id: 'footer.contact' })}</li>
              <li
                onClick={() => {
                  navigate('/')
                  window.scrollTo(0, 0)
                }}
                className="cursor-pointer">
                <span className="notranslate">+(84) 334 586 488</span>
              </li>
              <li
                onClick={() => {
                  navigate('/')
                  window.scrollTo(0, 0)
                }}
                className="cursor-pointer">
                <span className="notranslate">hello@sleekride.vn</span>
              </li>
              <li
                onClick={() => {
                  navigate('/')
                  window.scrollTo(0, 0)
                }}
                className="cursor-pointer">
                74 Hai Ba Trung, Ben Nghe, District 1, Ho Chi Minh City
              </li>
            </div>
          </div>
        </div>
      </div>

      <div className="h-14 w-full bg-black text-white flex justify-center items-end p-2">
        <p className="text-center ">{intl.formatMessage({ id: 'footer.copyright' })}</p>
      </div>
    </div>
  )
}

export default Footer
