import { Color } from '../Types/common';

export const colors: Color[] = [
  { _id: '1', name: 'black', label: 'Black', translation: 'black' },
  { _id: '2', name: 'blue', label: 'Blue', translation: 'blue' },
  { _id: '3', name: 'brown', label: 'Brown', translation: 'brown' },
  { _id: '4', name: 'gold', label: 'Gold', translation: 'gold' },
  { _id: '5', name: 'green', label: 'Green', translation: 'green' },
  {
    _id: '6',
    name: 'orange',
    label: 'Orange',
    translation: 'orange',
  },
  { _id: '7', name: 'pink', label: 'Pink', translation: 'pink' },
  {
    _id: '8',
    name: 'purple',
    label: 'Purple',
    translation: 'purple',
  },
  { _id: '9', name: 'red', label: 'Red', translation: 'red' },
  {
    _id: '10',
    name: 'silver',
    label: 'Silver',
    translation: 'silver',
  },
  { _id: '11', name: 'teal', label: 'Teal', translation: 'teal' },
  { _id: '12', name: 'white', label: 'White', translation: 'white' },
  {
    _id: '13',
    name: 'yellow',
    label: 'Yellow',
    translation: 'yellow',
  },
]
