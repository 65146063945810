import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIEndpoints } from '../staticStore';
import http from '../http';

export const fetchUserWishlist = createAsyncThunk(
  'options/fetchUserWishlist',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(APIEndpoints.GET_WISHLIST, {
        headers: {
          'auth-token': localStorage.getItem("accessToken")
        }
      });
      const data = response.data;
      const transformedData = data.map(post => ({
        ...post,
        images: post.images.map(img => ({
          original: img.data.url,
          thumbnail: img.data.url,
          originalHeight: '100px',
        }))
      }));
      return transformedData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Handle setting the wishlist items directly
    setWishlistItems: (state, action) => {
      state.items = action.payload;
    },
    // Handle the loading state when fetching, adding, or removing
    startLoading: (state) => {
      state.status = 'loading';
    },
    // Handle the success state when fetching, adding, or removing
    hasSucceeded: (state) => {
      state.status = 'succeeded';
      state.error = null;
    },
    // Handle the error state when fetching, adding, or removing
    hasFailed: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    // Handle adding an item to the wishlist
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    // Handle removing an item from the wishlist
    removeItem: (state, action) => {
      state.items = state.items.filter(item => item._id !== action.payload); // Remove item by _id
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserWishlist.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserWishlist.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add wishlist items to the state array
        state.items = action.payload;
      })
      .addCase(fetchUserWishlist.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {
  setWishlistItems, startLoading, hasSucceeded, hasFailed, addItem, removeItem
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
