import { createSlice } from "@reduxjs/toolkit";

type ModalState = {
  isCheckAvailabilityOpen: boolean;
  selectedPost: any;
};

const initialState: ModalState = {
  isCheckAvailabilityOpen: false,
  selectedPost: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openCheckAvailability: (state, action) => {
      console.log("OPENING MODAL: ", action.payload)
      state.isCheckAvailabilityOpen = true;
      state.selectedPost = action.payload;
    },
    closeCheckAvailability: (state) => {
      console.log("CLOSING MODAL: ")
      state.isCheckAvailabilityOpen = false;
      state.selectedPost = null;
    },
  },
});

export const { openCheckAvailability, closeCheckAvailability } = modalSlice.actions;

export default modalSlice.reducer;
