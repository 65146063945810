import React from 'react'
import { ReactComponent as UploadImage } from '../../assets/icons/uploadImage.svg' // Ensure you import the necessary components and dependencies
import { useIntl } from 'react-intl'

const UploadSection = ({ inputRef, handleUploadFile }) => {
  const intl = useIntl()
  return (
    <div className="w-full p-8 border-dotted border border-black rounded my-5 flex flex-col items-center justify-center sm:p-4 min-h-[35vh]">
      <UploadImage />
      {/* 
      <p className="my-2 sm:text-sm ">
        Drag and Drop file Jpeg, Png, Gif
      </p>
      <p className="my-2 ">OR</p> */}

      <button
        onClick={() => inputRef.current.click()}
        className="bg-[#105E26] p-3 text-white mt-2 rounded shadow-md shadow-[#105E26] w-2/5 sm:w-4/5"
      >
        {intl.formatMessage({
          id: 'create_listing.steps.3.upload_photos',
        })}
      </button>

      <input
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        multiple
        accept="image/*"
        onChange={handleUploadFile}
      />
    </div>
  )
}

export default UploadSection
