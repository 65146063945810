export const cleanSearch = (search) => {
  return search.replace(/[^a-zA-Z0-9 ]/g, '')
}

export const filterBySearch = (data, search) => {
  return data
    .filter((e) => {
      const name =  e.name || e
      return (
        name?.trim().toLowerCase().search(search?.trim().toLowerCase()) > -1
      )
    })
    .slice(0, 5)
}
