import React, { useEffect } from 'react'
import ResponsiveAppBar from 'Components/AppBar'
import CustomizedSteppers from 'Components/Stepper'
import Left from './Left'
import Right from './Right'
import Footer from 'Components/Footer'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from 'Components/LoadingSpinner'

const LeaveReview = () => {
  const user = useSelector((state) => state.user?.data)
  const loggedIn = useSelector((state) => state.user?.loggedIn)
  const isLoading = useSelector((state) => state.user?.loading)
  const navigate = useNavigate()
  useEffect(() => {
    if (!user && !loggedIn && !isLoading) {
      navigate('/user-login', { replace: true })
    }
  }, [user, loggedIn, isLoading, navigate])

  if (isLoading) {
    // You can render a loading spinner or any other UI while waiting for the data
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className=" w-full h-full  flex items-center pl-10  md:p-10 sm:p-4 bg-[#F7FAF8]   ">
      <div className=" flex justify-center items-center w-full h-full pb-[2rem]  md:flex-col      ">
        <Left />
        <Right />
      </div>
    </div>
  )
}

export default LeaveReview
