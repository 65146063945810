const inspectionProcessPoints = {
  '150_inspection_report': 'Báo cáo kiểm tra 150 Điểm Chi Tiết',
  scheduled_inspection: 'Lịch kiểm tra',
  passed: 'Đạt',
  fixed_or_replaced: 'Đã sửa / thay thế',
  from_the_vin: 'Từ Mã VIN',
  correct_vin_plate_and_anti_theft_labels: 'Biển mã VIN đúng và nhãn chống trộm',
  clean_history: 'Lịch sử sạch sẽ',
  no_reported_accidents_flood_salvage: 'Không có tai nạn/bão/làm hư hại bản thân bảo hiểm',
  under_the_hood: 'Dưới nắp động cơ',
  battery_condition_fluid_level_load_test: 'Bình ắc quy (tình trạng, mức nước, kiểm tra tải)',
  alternator_belt_condition_tension_charging: 'Máy phát điện (tình trạng dây đai, căng và sạc)',
  water_pump_noise_leaks: 'Bơm nước (tiếng ồn, rò rỉ)',
  ignition_system_operation: 'Hệ thống đánh lửa hoạt động',
  fuel_system_operation_connections_leakage: 'Hệ thống nhiên liệu (kết nối, rò rỉ)',
  fuel_filter_replace_per_maintenance_schedule: 'Bộ lọc nhiên liệu (thay theo lịch bảo dưỡng)',
  radiator_leaks_road_hazard_damage: 'Bình tản nhiệt (rò rỉ, hư hại do va chạm đường bộ)',
  coolant_recovery_tank_condition_fluid_level_sensor_operation: 'Bình đựng dung dịch làm mát (tình trạng, mức nước, cảm biến, hoạt động)',
  ac_condenser_corrosion_road_hazard_damage: 'Bình ngưng gió điều hòa (ăn mòn, hư hại do va chạm đường bộ)',
  cooling_fan_operation: 'Quạt làm mát hoạt động',
  coolant_replace_if_discolored_insufficient_protection: 'Dung dịch làm mát (thay nếu màu biến đổi, bảo vệ không đủ)',
  coolant_hoses_check_condition: 'Ống dẫn nước làm mát (kiểm tra tình trạng)',
  power_steering_fluid_level_belt_condition_tension: 'Hệ thống lái trợ lực (mức nước, tình trạng đai và căng)',
  ac_compressor_proper_cycling_belt_condition_tension: 'M compressor điều hòa (chu kỳ làm việc đúng, tình trạng đai và căng)',
  heater_valve_adjustment: 'Van điều chỉnh bình nóng',
  engine_valve_noise: 'Tiếng ồn của van động cơ',
  engine_transmission_mount_condition: 'Tình trạng gắn động cơ/vô lăng',
  engine_condition_visual_verification_of_oil_change_maintenance: 'Tình trạng động cơ (kiểm tra hình ảnh về việc thay dầu bảo dưỡng)',
  brake_master_cylinder_and_booster_operation_leaks: 'Xi lanh chính và bộ tăng áp của hệ thống phanh (hoạt động và rò rỉ)',
  tire_tread_depth_record_tire_tread_depth_of_at_least_5_32: 'Độ sâu rãnh lốp - Ghi lại độ sâu rãnh lốp ít nhất 5/32 inch',
  tires_condition_wear_pattern_correct_pressure: 'Lốp/ tình trạng/ mô hình mòn/ áp suất đúng',
  tires_check_for_uneven_wear_or_sidewall_cracking: 'Lốp (kiểm tra mòn không đều hoặc nứt vách bên)',
  wheels_rims_check_for_damage: 'Bánh xe/ Mâm xe (kiểm tra hư hại)',
  valve_stem_condition: 'Tình trạng van xăm',
  wheel_lug_nuts_all_present_and_properly_torqued: 'Đinh ốc bánh xe (tất cả có mặt và được siết chặt đúng cách)',
  spare_tire_condition_pressure: 'Lốp dự phòng (tình trạng/ áp suất)',
  ac_system_leaks_lines_hoses_condenser: 'Hệ thống điều hòa không khí rò rỉ (đường ống/ ống dẫn/ bình ngưng gió)',
  engine_oil_leaks: 'Rò rỉ dầu động cơ',
  transmission_leaks: 'Rò rỉ dầu hộp số',
  coolant_leaks: 'Rò rỉ dung dịch làm mát',
  cv_joint_boots_drive_shaft_universal_joint_cracks_leakage: 'Bộ khớp nối CV/ ống bọc/ trục lái/ khớp nối đa hướng (nứt, rò rỉ)',
  brake_pad_thickness_at_least_5mm: 'Độ dày bố thắng ít nhất 5mm',
  brake_rotor_thickness: 'Độ dày đĩa phanh',
  brake_caliper_leakage: 'Rò rỉ của kẹp phanh',
  hydraulic_brake_hose_line_leakage_and_free_movement: 'Rò rỉ ống dẫn phanh thủy lực và di chuyển tự do',
  suspension_springs_proper_ride_height: 'Hệ thống treo/ Lò xo (độ cao lái đúng)',
  mcpherson_strut_shock_absorber_leakage: 'Phuộc McPherson/ Ống giảm xóc rò rỉ',
  bushing_condition: 'Tình trạng gối đỡ',
  ball_joint_and_tie_rod_end_tightness: 'Khớp cầu và đầu gối nối bít kín',
  power_steering_rack_leakage: 'Rò rỉ hệ thống trợ lực lái',
  entire_exhaust_system_condition_leaks_damage_noise_catalytic_converter_and_converter_shield:
    'Hệ thống xả toàn bộ (tình trạng, rò rỉ, hư hại, tiếng ồn, bộ chuyển đổi và lá chắn bảo vệ)',
  emissions_meet_state_local_requirements: 'Khí thải (đáp ứng yêu cầu của bang/địa phương)',
  vehicle_exterior: 'Bên ngoài xe',
  headlights_high_low_operation_and_headlight_aim_surface_condition: 'Đèn pha (hoạt động cao/thấp và đặt đúng, tình trạng bề mặt)',
  brake_lights: 'Đèn phanh',
  fog_lamps_if_applicable: 'Đèn sương mù (nếu có)',
  daytime_running_lights_if_applicable: 'Đèn ban ngày (nếu có)',
  license_plate_light: 'Đèn biển số',
  tail_lights_operation_lens_condition: 'Đèn đuôi (hoạt động, tình trạng ống kính)',
  backup_lamps_operation_lens_condition: 'Đèn hậu (hoạt động, tình trạng ống kính)',
  emergency_flasher: 'Đèn cảnh báo khẩn cấp',
  vehicle_interior: 'Bên trong xe',
  includes_owners_manual: 'Bao gồm Hướng dẫn Sử dụng',
  includes_set_s_of_vehicle_keys: 'Bao gồm bộ chìa khóa xe',
  water_leaks_look_for_evidence_of_water_intrusion: 'Rò rỉ nước (tìm dấu vết của sự xâm nhập nước)',
  fuel_gauge_operation: 'Hoạt động của đồng hồ nhiên liệu',
  temperature_gauge_operation: 'Hoạt động của đồng hồ nhiệt độ',
  all_warning_lights_illuminated_with_ignition_on: 'Tất cả đèn cảnh báo sáng khi khởi động',
  dash_light_operation: 'Hoạt động đèn trên bảng đồng hồ',
  windshield_wiper_washer_operation: 'Hoạt động của cảm biến lau kính và bơm nước',
  audio_system_radio_cd_telephone_audio_inputs_speaker_operation: 'Hệ thống âm thanh - Radio/CD/Đầu vào âm thanh điện thoại, hoạt động loa',
  bluetooth_operations_if_equipped: 'Hoạt động Bluetooth (nếu có)',
  audio_steering_wheel_controls_if_equipped: 'Điều khiển âm thanh trên vô lăng (nếu có)',
  navigation_operation_if_equipped: 'Hoạt động định vị (nếu có)',
  rear_camera_if_equipped: 'Camera sau (nếu có)',
  telematics_operation_if_equipped_service_not_provided: 'Hoạt động telematics (nếu có) (dịch vụ không được cung cấp)',
  clock_functions: 'Chức năng đồng hồ',
  steering_wheel_tilt_lock: 'Vô lăng có thể nghiêng/khóa',
  sunroof_operation_if_applicable: 'Hoạt động cửa sổ trời (nếu có)',
  sunshade_operation_condition: 'Hoạt động/ Tình trạng rèm chắn nắng',
  headliner_condition: 'Tình trạng trần nhà',
  cigarette_lighter_power_outlets_if_equipped: 'Ổ cắm điện/ Ổ điện (nếu có)',
  alarm_theft_system_operation_check_remote_if_equipped: 'Hoạt động hệ thống báo động/ chống trộm (kiểm tra điều khiển từ xa, nếu có)',
  srs_airbags_exterior_condition_check_warning_lamp_self_check: 'Túi khí SRS (tình trạng bên ngoài/kiểm tra tự kiểm tra đèn cảnh báo)',
  outside_mirror_operation_left_and_right: 'Hoạt động gương ngoại vi (trái và phải)',
  rearview_mirror_operation_day_night_operation: 'Hoạt động gương chiếu hậu (hoạt động ban ngày/đêm)',
  window_operation_noise_speed_and_full_travel: 'Hoạt động của cửa sổ (tiếng ồn, tốc độ và hành trình đầy đủ)',
  door_locks_check_manual_and_or_power_operation_and_child_safety_function:
    'Khóa cửa (kiểm tra hoạt động bằng tay và/hoặc bằng điện và chức năng an toàn trẻ em)',
  power_sliding_doors_if_equipped: 'Cửa trượt điện (nếu có)',
  seat_belts_condition_proper_latching_and_retraction_operation: 'Dây an toàn (tình trạng, đóng kín và hoạt động co lại đúng cách)',
  seat_upholstery_leather_condition_front_and_rear: 'Ghế ngồi/ Tình trạng da ghế (phía trước và phía sau)',
  power_seat_operation_if_equipped: 'Hoạt động ghế điện (nếu có)',
  seat_heaters_if_equipped_and_check_for_proper_operation: 'Ghế có chức năng sưởi (nếu có và kiểm tra hoạt động đúng cách)',
  headrest_movement_if_equipped: 'Chuyển động của đầu ghế (nếu có)',
  rear_defrost_operation: 'Hoạt động sưởi kính sau',
  inside_mirror_vanity_light_operation: 'Hoạt động đèn nơi gương chiếu hậu và gương trang điểm',
  interior_lights_switch_door_operation_warning_chimes: 'Đèn nội thất (hoạt động công tắc/cửa, cảnh báo chuông báo)',
  glove_box_glove_box_light_operation: 'Hộp đựng đồ/glove box (hoạt động đèn)',
  luggage_compartment_jack_and_tool_present: 'Khoang hành lý (có cặp đinh và dụng cụ)',
  trunk_lid_release_trunk_lid_light_operation: 'Mở nắp cốp xe/mở đèn nắp cốp',
  liftgate_operation_manual_power_functions_if_equipped: 'Hoạt động cửa hậu (chức năng bằng tay/điện — nếu có)',
  fuel_door_release: 'Mở cửa nắp xăng',
  hood_release_operation: 'Hoạt động nắp capô',
  how_it_drives: 'Cách lái xe',
  engine_start_up_cold_hot: 'Khởi động động cơ khi lạnh/khi nóng',
  abnormal_noises_engine_running: 'Tiếng ồn bất thường khi động cơ hoạt động',
  engine_idle_smooth_idle_and_acceleration_from_idle: 'Động cơ chạy êm và gia tốc từ tình trạng đứng yên',
  instrument_pack_gauges_for_normal_operation: 'Bảng đồng hồ đo cho hoạt động bình thường',
  fluid_leaks_while_engine_is_running: 'Rò rỉ chất lỏng khi động cơ đang hoạt động',
  trip_computer_if_equipped: 'Máy tính hành trình (nếu có)',
  cruise_control_if_equipped: 'Điều khiển hành trình (nếu có)',
  automatic_manual_transmission_shift_paddle_shift_feature: 'Chuyển số tự động/số thủ công, tính năng chuyển số trên vô-lăng',
  clutch_operation_engagement: 'Hoạt động và gắn kết của côn',
  abnormal_wind_noise: 'Tiếng ồn gió bất thường',
  engine_performance: 'Hiệu suất động cơ',
  vibration_drivetrain_and_wheels: 'Rung động của hệ dẫn động và bánh xe',
  straight_line_stability_pull: 'Ổn định khi đi thẳng/kéo',
  steering_wheel_alignment: 'Sự cân chỉnh bánh lái',
  braking_system_including_abs: 'Hệ thống phanh bao gồm ABS',
  parking_brake: 'Phanh đỗ',
  vehicle_handling: 'Xử lý của xe',
  safety_belts: 'Dây an toàn',
  blind_spot_monitoring_if_equipped: 'Hệ thống giám sát điểm mù (nếu có)',
  navigation_system_compass_if_fitted: 'Hệ thống định vị/la bàn (nếu được lắp đặt)',
  how_it_looks: 'Ngoại thất',
  exterior_lamp_reflector: 'Đèn/đèn phản xạ ngoại thất',
  sunroof_glass_condition_if_equipped: 'Tình trạng kính cửa sổ trời (nếu có)',
  wipers_condition_of_mechanism: 'Lưỡi gạt nước/điều kiện của cơ cấu',
  wipers_condition_of_blades: 'Lưỡi gạt nước/điều kiện của lưỡi',
  side_rear_window_condition: 'Tình trạng cửa sổ bên/đuôi',
  underhood_clean_engine_compartment: 'Dưới nắp cốp sạch sẽ (khu vực động cơ)',
  grille_condition_trim_emblems: 'Lưới tản nhiệt/tình trạng viền và biểu tượng',
  hood_condition: 'Tình trạng nắp cáp',
  drivers_side_front_rear: 'Bên lái (trước/sau)',
  passenger_side_front_rear: 'Bên hành khách (trước/sau)',
  front_bumper_condition: 'Tình trạng c bumper trước',
  rear_bumper_condition: 'Tình trạng bumper sau',
  trunk_lid_liftgate_condition: 'Tình trạng nắp cốp/ cửa hậu',
  roof_condition: 'Tình trạng mái nhà',
  exterior_mirrors_condition: 'Tình trạng gương ngoại thất',
  fit_condition_of_glove_box_console_box_and_trim: 'Vừa vặn và tình trạng của hộp găng tay, hộp trung tâm và viền',
  stowage_compartment: 'Ngăn chứa đồ',
  sun_visor_and_vanity_mirror_operation: 'Hoạt động của cánh che nắng và gương trang điểm',
  glove_box_and_door_lamps: 'Đèn hộp găng tay và đèn cửa',
  load_space_trunk_condition: 'Tình trạng không gian chứa đồ/nắp cốp',
  all_mirrors_condition_operation: 'Tình trạng và hoạt động của tất cả gương',
  trim_carpets_condition: 'Tình trạng và thảm',
  dash_and_instrument_panels_condition: 'Tình trạng bảng đồng hồ và bảng điều khiển',
  console_center_armrest_cup_holders_condition: 'Tình trạng của hộp đựng đồ, tay nắm trung tâm và giữ cốc',
  front_doors_controls_trim_condition: 'Tình trạng của cửa trước (điều khiển, viền)',
  rear_doors_quarter_trim_controls_trim_condition: 'Tình trạng của cửa sau/viền phân khúc sau (điều khiển, viền)',
  luggage_compartment_mat_trim_carpet_condition: 'Tình trạng của khoang hành lý (thảm, viền, thảm lót)',
  includes_standard_set_of_floor_mats: 'Bao gồm bộ thảm sàn tiêu chuẩn',
  detailing: 'Chi tiết',
  complete_premium_detail_including_wash_wax_and_high_speed_buff: 'Dịch vụ chi tiết cao cấp bao gồm: rửa xe, wax, và làm bóng tốc độ cao',
  complete_premium_interior_detail_including_odor_test: 'Dịch vụ chi tiết nội thất cao cấp bao gồm kiểm tra mùi hôi',
  apply_tire_dressing_to_all_4_tires: 'Chải dầu bóng lốp cho tất cả (4) bánh xe',
  clean_engine_compartment: 'Làm sạch khoang động cơ',
  clean_all_windows: 'Làm sạch tất cả cửa sổ',
}
const reviewPage = {
  'review.left.title': 'Để lại đánh giá',
  'review.left.stars': 'Nhấn vào các ngôi sao để đánh giá *',
  'review.left.add_review': 'Viết đánh giá *',
  'review.left.add_review.placeholder': 'Bạn thích hoặc không thích điều gì? Trải nghiệm của bạn như thế nào',
  'review.left.feedback_about': 'Phản hồi của bạn về điều gì? *',
  'review.left.feedback_about.type': 'Chọn loại phản hồi',
  'review.left.feedback_about.type.1': 'Phản hồi về trang web Sleekride',
  'review.left.feedback_about.type.2': 'Đánh giá đại lý',
  'review.left.feedback_about.type.3': 'Đánh giá về một hãng và mẫu xe ô tô',
  'review.left.add_headline': 'Thêm một tiêu đề *',
  'review.left.add_headline.placeholder': 'Điều quan trọng nhất cần biết',
  'review.left.submit': 'Gửi Đánh giá',
  'review.left.required_fields': '* Các trường bắt buộc',

  'review.right.title': 'Cảm ơn bạn đã đánh giá',
  'review.right.description':
    'Đánh giá của bạn sẽ giúp chúng tôi cải thiện đội ngũ và đối tác đại lý với chất lượng tốt nhất về sản phẩm và dịch vụ. Chúng tôi rất đánh giá cao ý kiến phản hồi của bạn và mong muốn phục vụ bạn trong tương lai.',
}

const nav_bar = {
  'nav_bar.home': 'Trang Chủ',
  'nav_bar.wish_list': 'Yêu Thích',
  'nav_bar.sell_my_car': 'Đăng Tin Bán Xe',
  'nav_bar.Research': 'Tin Tức Thị Trường',
  'nav_bar.for_dealer': 'Kênh Đại Lý',

  'nav_bar.sign_in': 'Đăng Nhập',
  'nav_bar.my_account': 'Tài Khoản Của Tôi',
  'nav_bar.log_out': 'Đăng Xuất',
}

const footer = {
  'footer.description':
    'Sàn giao dịch xe hơi mới & cũ đáng tin cậy với mọi thông tin đánh giá minh bạch, khách quan từ chính người tiêu dùng. Chúng tôi cung cấp thông tin giá cả, uy tín của người bán cũng như các thông tin hữu ích khác hỗ trợ người mua tìm kiếm "Ưu Đãi Lớn từ các Đại Lý Bán Xe uy tín hàng đầu".',
  'footer.company': 'Công Ty',
  'footer.about': 'Về Chúng Tôi',
  'footer.engineering': 'Kỹ Thuật',
  'footer.contact-us': 'Trò Chuyện Với Chúng Tôi',

  'footer.locations': 'Khu Vực',
  'footer.terms': 'Điều Khoản & Chính Sách',
  'footer.terms_of_service': 'Điều Khoản Dịch Vụ',
  'footer.privacy_policy': 'Chính Sách Bảo Mật',
  'footer.contact': 'Liên Hệ',
  'footer.copyright': '© 2024 Sleekride. Tất cả các quyền được bảo lưu.',
}

const home = {
  'home.intro.right.starting_at': 'Giá khởi điểm',
  'home.intro.right.discover': 'KHÁM PHÁ NGAY',
  'home.intro.headline': 'Tìm ngay chiếc xe hoàn hảo nhất dành cho bạn',
  'home.intro.sub_headline': 'Sàn giao dịch mua & bán uy tín dành cho Xe Mới và Xe Đã Qua Sử Dụng',
  'home.popular_brands': 'Thương Hiệu Phổ Biến',
  'home.about': 'VỀ CHÚNG TÔI',
  'home.about.description':
    'SLEEKRIDE là một sàn giao dịch Ô tô trực tuyến toàn cầu kết nối người mua và người bán. Chúng tôi sử dụng công nghệ độc quyền, ứng dụng các thuật toán nâng cao và trí tuệ nhân tạo giúp tìm kiếm, phân tích dữ liệu nhằm đem lại hiệu quả tối ưu nhất cho người dùng. Xây dựng một sàn giao dịch Ô tô Mới & Đã Qua Sử Dụng uy tín, minh bạch là sứ mệnh mà SLEEKRIDE muốn hướng tới nhằm đem đến những trải nghiệm tìm kiếm Ô tô khác biệt cho khách hàng.',
  'home.about.title1': 'GIÁ CẢ MINH BẠCH',
  'home.about.description1':
    'Chúng tôi tổng hợp kho xe từ Người Bán và Đại Lý sau đó áp dụng công nghệ trí tuệ nhân tạo phân tích dữ liệu độc quyền của mình để đề xuất ra Xếp Hạng Tin Bán theo thông số: Giá Tốt Nhất, Giá Tốt, Giá Phù Hợp hoặc Giá Cao.',
  'home.about.title2': 'AN TÂM',
  'home.about.description2':
    'Báo cáo kiểm tra 150 Điểm Chi Tiết bởi các kỹ thuật viên có kinh nghiệm của chúng tôi giúp khách hàng có thể hoàn toàn yên tâm khi giao dịch.',
  'home.about.title3': 'TIN TƯỞNG',
  'home.about.description3':
    'Thông qua hệ thống dữ liệu và thuật toán phân tích nâng cao bằng trí tuệ nhân tạo, chúng tôi nỗ lực cung cấp các thông tin minh bạch chính xác giúp khách hàng mua được xe giá tốt đồng thời tránh các rủi ro về gian lận lừa đảo.',
  'home.top_deals': 'Giá Tốt Nhất',
  'home.trending_body': 'Kiểu Dáng Thân Xe Được Yêu Thích',
  'home.outro.title': 'Tải Ứng Dụng Ngay',
  'home.outro.sub_headline': 'Hơn 1 Triệu Lượt Tải',
}

const search = {
  search_title: 'Kết quả tìm kiếm cho',
  'search.no_result': 'Không tìm thấy kết quả',
  'search.all': 'Tất Cả',
  'search.placeholder': 'Hãy để SLEEKRIDE giúp bạn tìm kiếm chiếc xe ưng ý nhất...',
  saved_search: 'Tìm kiếm đã lưu',
  saved_searches: 'Tìm kiếm đã lưu',

  save_search: 'Lưu tìm kiếm',

  'search.price': 'GIÁ',
  'search.make': 'HÃNG XE',
  'search.model': 'DÒNG XE',
  'search.body': 'THÂN XE',
  'search.year_mileage': 'NĂM & SỐ DẶM',
  'search.gear': 'HỘP SỐ',
  'search.origin': 'XUẤT XỨ',
}

const for_dealer = {
  'for_dealer.intro.headline.left': 'Chúng tôi sẽ giúp bạn thúc đẩy doanh thu bán hàng một cách hiệu quả với chi phí tối ưu nhất',
  'for_dealer.contact_us': 'Liên hệ ngay',
  'for_dealer.intro.headline.right': 'THÚC ĐẨY DOANH SỐ BÁN VỚI TÍNH NĂNG ĐẠI LÝ NÂNG CAO',
  'for_dealer.dealer_sign_in': 'Đăng Nhập Đại Lý',

  'for_dealer.description': 'Hiển thị thông tin bán xe tới đúng khách hàng mục tiêu mà bạn tìm kiếm',
  'for_dealer.feature1': 'Định giá xe tối ưu bằng trí tuệ nhân tạo',
  'for_dealer.description1':
    'Công cụ định giá giúp bạn kiểm tra giá xe trong danh sách bán của mình và cân nhắc những thay đổi có thể giúp cải thiện xếp hạng trên sàn giao dịch giúp thu hút người mua.',
  'for_dealer.feature2': 'Tiếp cận người mua tiềm năng nhất ',
  'for_dealer.description2':
    'Bằng cách cung cấp các thông tin hữu ích cho người tiêu dùng dựa trên dữ liệu minh bạch như Thứ Hạng, Lịch sử Giá Bán, Thời Gian Tin Đăng, Lịch Sử Xe, chúng tôi tin rằng người mua xe trên nền tảng của chúng tôi là những khách hàng mua sắm có hiểu biết và sẵn sàng mua.',
  'for_dealer.feature3': 'Thúc đẩy doanh số bán hàng',
  'for_dealer.description3':
    'Bằng cách kết nối người bán với khách hàng, chúng tôi tin rằng SLEEKRIDE cung cấp cho người bán một kênh thu hút khách hàng hiệu quả, đem lại doanh thu và lợi nhuận hấp dẫn so với chi phí quảng cáo trên nền tảng của chúng tôi.',

  'for_dealer.offers': 'Ưu đãi',
  'for_dealer.offers.headline': 'Sleekride cung cấp',
  'for_dealer.offers.sub_headline': 'Sleekride cung cấp cho các Đại Lý nhiều lựa chọn đăng tin VIP và quảng cáo trực tuyến ',
  'for_dealer.offers.feature1': 'Danh sách kho hàng',
  'for_dealer.offers.description1':
    'Thống kê, quản lý và kiểm soát danh sách kho hàng của bạn với Bảng Điều Khiển Đại Lý Sleekride. Đây là nơi mà bạn có thể xem danh sách xếp hạng các tin đã đăng bán của mình khi so sánh với những người khác trên cùng thị trường. ',
  'for_dealer.offers.feature2': 'Giải pháp hệ thống quản lý nâng cao cho Đại Lý',
  'for_dealer.offers.description2':
    'Trọng tâm tài khoản Sleekride của bạn là cho phép truy cập vào các số liệu chính về hiệu suất đại lý của bạn, cách người mua hàng tương tác với tin đăng bán và tiếp cận trực tiếp tới đối tương khách hàng tiềm năng để tăng doanh số bán hàng. ',
  'for_dealer.offers.feature3': 'Dịch vụ tiếp thị',
  'for_dealer.offers.description3':
    'Nền tảng Sleekride cung cấp cho các công ty sản xuất ô tô và các công ty kinh doanh ngành nghề liên quan dịch vụ mua quảng cáo hiển thị trên trang của chúng tôi để thực hiện các chiến lược tiếp thị giúp tăng cường độ nhận diện thương hiệu.',

  'for_dealer.what_they_say': 'Họ nói gì?',
  'for_dealer.work_at': 'Giám Đốc Internet Tại Dan Wolf Automotive:',

  'for_dealer.contact.intro': 'Trò Chuyện Ngay',
  'for_dealer.contact.headline': 'Liên Hệ Với Chúng Tôi',

  'for_dealer.contact.dealership_name': 'Tên Đại lý',
  'for_dealer.contact.first_name': 'Tên',
  'for_dealer.contact.last_name': 'Họ',
  'for_dealer.contact.email': 'Email',
  'for_dealer.contact.phone': 'Số Điện Thoại',
  'for_dealer.contact.zip': 'Mã Bưu Điện',
  'for_dealer.contact.used_inventory_size': 'Dự kiến số lượng hàng trong kho',

  'for_dealer.contact.optional': 'Tùy chọn: Điền thêm thông tin để đăng ký được xử lý nhanh hơn.',
  'for_dealer.contact.get_started': 'Bắt đầu',
  'contact.send': 'Gửi',
}

const login = {
  'login.welcome_back': 'Xin chào',
  'login.new_to_sleekride': 'Người dùng mới của Sleekride',
  'login.create_an_account': 'Tạo Tài Khoản',
  'login.dealer_question': 'Bạn là ',
  'login.dealer': 'Đại lý',
  'login.enter_your_email': 'Nhập email của bạn',
  'login.enter_your_password': 'Nhập mật khẩu của bạn',
  'login.login': 'ĐĂNG NHẬP',
  'login.forgot_password': 'Quên mật khẩu',
  'login.terms_mention': 'Bằng cách đăng nhập vào tài khoản của bạn, bạn đồng ý với',
  'login.statement': 'Cam Kết Bảo Mật',
  'login.and': 'và',
  'login.terms_of_service': 'Điều Khoản Dịch Vụ',
  'login.welcome': 'Chào mừng bạn đến với sàn giao dịch mua bán xe ô tô tốt nhất',
  'login.invite': '3k+ người đã tham gia cùng chúng tôi, còn bạn thì sao?',
}

const signin = {
  'sign_up.first_name': 'Tên',
  'sign_up.last_name': 'Họ',
  'sign_up.email': 'Địa Chỉ Email',
  'sign_up.phone_number': 'Số Điện Thoại',
  'sign_up.password': 'Mật Khẩu',
  'sign_up.confirm_password': 'Xác Nhận Mật Khẩu',
  'sign_up.sign_up': 'ĐĂNG KÝ',
  'sign_up.social': 'Hoặc kết nối với tài khoản mạng xã hội',
  'sign_up.welcome': 'Chào mừng bạn đến với Sleekride',
  'sign_up.description':
    'Tại Sleekride, chúng tôi sử dụng công nghệ trí tuệ nhân tạo để giúp các giao dịch mua bán xe ô tô trở nên dễ dàng, hiệu quả và công bằng. Hàng ngàn người đã tin tưởng chúng tôi cho nhu cầu mua và bán xe của họ. ',
  'sign_up.invite': '3k+ người đã tham gia cùng chúng tôi, còn bạn thì sao?',
}

const createListing = {
  'create_listing.headline': 'Đăng tin bán xe dễ dàng với 5 bước',
  'create_listing.steps.1': 'Thông tin về xe',
  'create_listing.steps.2': 'Tính năng đặc biệt',
  'create_listing.steps.3': 'Tình trạng và Lịch sử',
  'create_listing.steps.4': 'Đặt lịch kiểm tra 150 điểm',
  'create_listing.steps.5': 'Thanh toán',

  'edit_listing.headline': 'Chỉnh sửa thông tin',

  overview: 'Tổng quan',
  'create_listing.steps.1.overview': 'Mô tả ngắn gọn về chiếc xe của bạn (200 ký tự)',
  exterior_color: 'Màu ngoại thất',
  interior_color: 'Màu nội thất',
  transmission: 'Hộp số',
  drivetrain: 'Hệ thống dẫn động',
  origin: 'Xuất xứ',
  fuel: 'Nhiên liệu',
  odometer: 'Số công tơ mét (Odo)',
  'create_listing.steps.1.choice': 'Bán Ngay hoặc Đổi Cũ Lấy Mới',
  'create_listing.steps.1.choice.headline': 'Bán Xe Ngay cho chúng tôi hoặc Đổi Cũ Lấy Mới. Chúng tôi sẽ hướng dẫn bạn thực hiện',
  'create_listing.steps.1.choice.1': 'Tôi tự bán',
  'create_listing.steps.1.choice.2': 'Đổi cũ lấy mới',
  'create_listing.steps.1.choice.3': 'Bán cho Đại Lý',
  continue: 'Tiếp tục',

  black: 'Màu đen',
  blue: 'Màu xanh da trời',
  brown: 'Màu nâu',
  gold: 'Màu ánh vàng',
  green: 'Màu xanh lá',
  orange: 'Màu da cam',
  pink: 'Màu hồng',
  purple: 'Màu tím',
  red: 'Màu đỏ',
  silver: 'Màu bạc',
  teal: 'Màu xanh ngọc',
  white: 'Màu trắng',
  yellow: 'Màu vàng',

  'create_listing.automatic': 'Tự động (AT)',
  'create_listing.manual': 'Số sàn (MT)',
  continuous: 'Liên tục (CVT)',
  unknown: 'Không rõ',

  fwd: 'FWD',
  rwd: 'RWD',
  awd: 'AWD',
  four_wd: '4WD',

  export: 'Xuất khẩu',

  gas: 'Xăng',
  diesel: 'Dầu diesel',
  electric: 'Điện',
  hydrogen: 'Hydrogen',

  'create_listing.right.title': 'THÔNG TIN TÓM TẮT',
  'create_listing.right.city': 'Thành phố',
  'create_listing.right.VIN': 'Mã VIN',
  'create_listing.right.car_info': 'Năm sản xuất, Hãng & Dòng xe',
  'create_listing.right.trim': 'Phiên bản',
  'create_listing.right.mileage': 'Số km đã đi',
  'create_listing.right.made_in': 'Xuất xứ',
  'create_listing.right.style': 'Kiểu dáng',
  'create_listing.right.anti_brake_system': 'Hệ thống phanh chống bó cứng',
  'create_listing.right.city_mileage': 'km thành phố',
  'create_listing.right.highway_mileage': 'km đường cao tốc',

  'create_listing.right.support': 'Hỗ trợ khách hàng',
  'create_listing.right.chat': 'Trò chuyện với chúng tôi',
  'create_listing.right.call': 'Gọi điện',

  'create_listing.steps.2.title': 'Vui lòng xem danh sách dưới đây và chọn các tính năng tùy chọn của xe',
  seating: 'Chỗ ngồi',
  roof: 'Nóc',
  wheels: 'Mâm xe',
  safety: 'An toàn',
  option: 'Tùy chọn',
  technology: 'Công nghệ',

  leather_seats: 'Ghế da',
  cloth_seats: 'Ghế vải',
  suede_seats: 'Ghế da lộn',
  vinyl_seats: 'Ghế vải nhựa vinyl',
  heated_seats: 'Ghế có sưởi',
  memory_seats: 'Ghế có cảm biến nhớ vị trí',
  power_seats: 'Ghế chỉnh điện',
  sunroof: 'Nóc mở',
  moonroof: 'Nóc kính',
  panoramic_roof: 'Nóc toàn cảnh',
  convertible: 'Xe mui trần',
  alloy_wheels: 'Mâm hợp kim',
  steel_wheels: 'Mâm thép',
  chrome_wheels: 'Mâm chrome',
  anti_lock_brakes: 'Hệ thống phanh chống bó cứng',
  backup_camera: 'Camera lùi',
  blind_spot_monitoring: 'Hệ thống cảnh báo điểm mù',
  brake_assist: 'Hệ thống hỗ trợ phanh',
  child_safety_locks: 'Khóa an toàn trẻ em',

  adaptive_cruise: 'Điều khiển hộ trợ theo luồng xe',
  android_auto: 'Kết nối Android Auto',
  apple_carplay: 'Kết nối Apple CarPlay',
  apple_car_play: 'Kết nối Apple CarPlay',
  auxiliary_audio_input: 'Đầu vào âm thanh phụ',
  aux: 'Đầu vào âm thanh phụ',
  bluetooth: 'Kết nối Bluetooth',
  keyless_start: 'Khởi động không cần khóa',
  navigation: 'Hệ thống định vị',
  park_assist: 'Hệ thống hỗ trợ đỗ xe',
  radio: 'Đài phát thanh',
  remote_start: 'Khởi động từ xa',
  steering_wheel_controls: 'Điều khiển trên vô lăng',
  usb: 'Cổng USB',
  wifi: 'Kết nối WiFi',
  wireless_phone_charging: 'Sạc không dây',

  options_technology: 'Gói Công nghệ',
  options_self_driving: 'Gói Tự lái',
  options_towing: 'Gói Kéo',
  options_premium: 'Gói Premium',
  options_cold_weather: 'Gói Thời tiết lạnh',
  options_advanced_safety: 'Gói An toàn Nâng cao',

  'create_listing.steps.3.title': 'Hãy chọn các tùy chọn mô tả tình trạng xe của bạn một cách chính xác nhất.',
  vehicle_accidents: 'Va chạm xe',
  'vehicle_accidents.none': 'Không',

  vehicle_drivable: 'Xe của bạn có đang chạy bình thường không?',
  'vehicle_drivable.yes': 'Có',
  'vehicle_drivable.no': 'Không',

  smoked_in: 'Xe của bạn có bị hút thuốc lá không?',
  'smoked_in.yes': 'Đã hút thuốc lá',
  'smoked_in.no': 'Không hút thuốc',

  hasModifications: 'Xe của bạn có được sửa đổi không?',
  'hasModifications.yes': 'Đã sửa đổi',
  'hasModifications.no': 'Không sửa đổi',

  mechanical_electrical_issues: 'Vấn đề Cơ và Điện',
  'mechanical_electrical_issues.engine': 'Động cơ',
  'mechanical_electrical_issues.transmission': 'Hộp số',
  'mechanical_electrical_issues.airConditioning': 'Điều hòa không khí',
  'mechanical_electrical_issues.electricalSystem': 'Hệ thống điện',
  'mechanical_electrical_issues.tirePressure': 'Áp suất lốp',
  'mechanical_electrical_issues.noMechanicalAndElectricalIssues': 'Không có vấn đề cơ và điện',

  interior_damage: 'Thiệt hại Nội thất',
  'interior_damage.wear': 'Mòn và Hỏng',
  'interior_damage.functional': 'Hoạt động bình thường',
  'interior_damage.dashWarningLights': 'Đèn cảnh báo trên bảng đồng hồ',
  'interior_damage.interiorWarningLights': 'Thiệt hại đèn nội thất',
  'interior_damage.acDamage': 'Thiệt hại máy lạnh',
  'interior_damage.buttonsDamage': 'Thiệt hại nút điều khiển',
  'interior_damage.noInteriorDamage': 'Không có thiệt hại nội thất',
  'interior_damage.seatDamage': 'Thiệt hại ghế',

  exterior_damage: 'Thiệt hại Ngoại thất',
  'exterior_damage.faded': 'Sơn bị phai màu',
  'exterior_damage.rust': 'Gỉ sét',
  'exterior_damage.damage': 'Thiệt hại thân xe',
  'exterior_damage.dentOrScrapes': 'Vết lõm hoặc trầy',
  'exterior_damage.windshieldCracksOrChips': 'Rạn hoặc vỡ kính chắn gió',
  'exterior_damage.noExteriorDamage': 'Không có thiệt hại ngoại thất',

  'create_listing.steps.3.condition_question': 'Cách tốt nhất để mô tả tình trạng của xe của bạn là gì?',
  'create_listing.steps.3.photos_note':
    'Hình ảnh của xe của bạn rất quan trọng đối với bài đăng của bạn và thu hút người dùng, vì vậy chúng tôi khuyến nghị hình ảnh có tỷ lệ khung hình 4:3 hoặc 3:2, như 1200 x 900 pixel, 1500 x 1000 pixel, hoặc 2000 x 1333 pixel. Tuy nhiên, kích thước được khuyến nghị để có một bức ảnh chất lượng cao là ít nhất 1024 x 1024 pixel.',
  vehicle_keys: 'Chìa khóa xe',

  'create_listing.steps.3.price': 'Giá (VND)',

  'create_listing.steps.3.add_photos': 'Thêm hình ảnh (Phía trước, Phía sau, Bên phải, Bên trái, Nội thất)',

  'create_listing.steps.3.upload_photos': 'Tải ảnh lên',
  'create_listing.steps.3.upload_more': 'Tải thêm',

  'create_listing.steps.4.title': 'Đặt lịch kiểm tra 150 điểm',

  'create_listing.steps.4.description':
    'Bán xe đã qua sử dụng với giá cao nhất cùng tính năng Kiểm Tra 150 Điểm của Sleekride. Thông qua báo cáo này, những người mua thực sự tiềm năng sẽ quan tâm tới xe của bạn hơn và đó cũng là cách mà bạn có thể bán được xe cũ ở mức giá tốt nhất.',

  appointment_date: 'Chọn ngày hẹn',
  select_service_center: 'Chọn Trung Tâm Dịch vụ',

  'create_listing.steps.4.why.title': 'Tại sao chúng tôi cần kiểm tra',
  'create_listing.steps.4.why.description':
    'Sleekride nỗ lực cung cấp một trải nghiệm mua sắm ô tô thú vị và đáng tinn cậy. Chúng tôi mong muốn cung cấp cho những khách hàng mua xe ô tô cũ các dữ liệu hữu ích để họ có thể đưa ra quyết định mua xe đúng đắn. Đối với người bán, chúng tôi đem đến các công cụ hỗ trợ để bạn có thể bán xe nhanh chóng và nhận được mức giá tốt nhất. Đây là một giao dịch Win-Win cho cả người mua và người bán.',
  'create_listing.steps.4.why.1.title': 'Bán xe nhanh',
  'create_listing.steps.4.why.1.description':
    'Báo cáo kiểm tra kỹ thuật số chi tiết bao gồm đánh giá nội thất, ngoại thất, kiểm tra đường trường và động cơ của xe sẽ thu hút người mua.',
  'create_listing.steps.4.why.2.title': 'Bán được xe với giá cao nhất',
  'create_listing.steps.4.why.2.description':
    'Mục tiêu của bạn là bán được xe với giá cao nhất? Với Báo Cáo Kiểm Tra 150 Điểm, bạn có thể chủ động định giá chiếc xe đã qua sử dụng của mình!. Những chiếc xe có đi kèm Báo Cáo Kiểm Tra luôn được bán với giá tốt hơn là xe không có.',
  'create_listing.steps.4.why.3.title': 'Xây dựng lòng tin',
  'create_listing.steps.4.why.3.description':
    'Với Báo Cáo Kiểm Tra 150 Điểm, người mua sẽ tin tưởng vào những gì mà họ thấy trực tuyến, do đó bạn sẽ có cơ hội nhận được mức giá cao nhất cho chiếc xe đã qua sử dụng của mình.',
  'create_listing.steps.4.why.4.title': 'Tính minh bạch nâng cao',
  'create_listing.steps.4.why.4.description':
    'Việc Kiểm Tra 150 Điểm giúp người bán có được mức giá mong muốn cho chiếc xe của mình bằng cách cung cấp các thông tin chi tiết xác thực.',
  'create_listing.steps.4.how.title': 'Quy trình Kiểm Tra 150 Điểm của Sleekride hoạt động như thế nào?',
  'create_listing.steps.4.how.1': 'Đặt lịch hẹn dịch vụ Kiểm Tra 150 Điểm',
  'create_listing.steps.4.how.2': 'Chuyên viên của chúng tôi sẽ liên hệ với bạn để xác nhận lịch hẹn',
  'create_listing.steps.4.how.3': 'Mang xe của bạn đến trung tâm dịch vụ của Sleekride và thực hiện kiểm tra',
  'create_listing.steps.4.how.4': 'Báo cáo chi tiết Kiểm Tra 150 Điểm của xe sẽ được đăng tải trên tin đăng bán xe',

  'create_listing.steps.5.title': 'Thanh toán',
  'create_listing.steps.5.description': 'Chọn phương thức thanh toán phù hợp',

  'create_listing.steps.5.complete': 'Hoàn tất',
  'create_listing.steps.5.complete_purchase': 'Hoàn tất đơn hàng',

  'create_listing.steps.5.summary.title': 'Tóm tắt danh sách',
  'create_listing.steps.5.summary.description': 'Bạn có 01 mục trong giỏ hàng của bạn',
  'create_listing.steps.5.summary.list': 'Đăng trên sleekride.vn',
  'create_listing.steps.5.summary.sub_total': 'Tổng phụ',
  'create_listing.steps.5.summary.tax': 'Thuế dịch vụ',
  'create_listing.steps.5.summary.promo': 'Mã khuyến mãi',
  'create_listing.steps.5.summary.total': 'Tổng cộng',
}

const listing = {
  'listing.condition.new': 'Xe mới',
  'listing.condition.used': 'Xe cũ',

  'listing.tabs.overview': 'TỔNG QUAN',
  'listing.tabs.features': 'TÍNH NĂNG & TÙY CHỌN',
  'listing.tabs.features.1': 'Nội Thất',
  'listing.tabs.features.2': 'Công Nghệ',
  'listing.tabs.features.3': 'Mái Xe Và Kính',
  'listing.tabs.features.4': 'Bánh Xe Và Lốp Xe',
  'listing.tabs.features.5': 'An Toàn',
  'listing.tabs.features.6': 'Khác',
  'listing.tabs.seller': 'THÔNG TIN NGƯỜI BÁN',

  'listing.tabs.seller.private': 'Người bán cá nhân',
  'listing.tabs.seller.dealer': 'Đại lý uy tín',
  'listing.tabs.seller.share': 'Chia sẻ',
  'listing.tabs.seller.message': 'Nhắn tin',

  'listing.tabs.seller.reviews.title': 'Đánh giá Đại Lý',
  'listing.tabs.seller.reviews.rate': 'trên 5',
  'listing.tabs.seller.reviews.global': 'điểm đánh giá toàn cầu',
  'listing.tabs.seller.reviews.star': 'sao',
  'listing.tabs.seller.reviews.review': 'Đánh giá',
  'listing.tabs.seller.reviews.add': 'Thêm đánh giá của bạn',

  wishlist: 'Danh sách yêu thích',
  no_wishlist: 'Không có mục nào trong danh sách yêu thích',
  'listing_detail.vehicle.title': 'THÔNG TIN XE',
  'listing_detail.vehicle.trim': 'Phiên bản',
  'listing_detail.vehicle.made_in': 'Xuất xứ',
  'listing_detail.vehicle.VIN': 'Mã VIN',
  'listing_detail.vehicle.exterior': 'Màu ngoại thất',
  'listing_detail.vehicle.condition': 'Tình trạng',
  'listing_detail.vehicle.gas': 'Mức tiêu hao nhiên liệu',
  'listing_detail.vehicle.doors': 'Số cửa',
  'listing_detail.vehicle.transmission': 'Hộp số',
  'listing_detail.vehicle.drivetrain': 'Hệ thống truyền động',
  'listing_detail.vehicle.fuel': 'Loại nhiên liệu',
  'listing_detail.vehicle.interior': 'Màu nội thất',
  'listing_detail.vehicle.engine': 'Động cơ',
  'listing_detail.vehicle.body': 'Kiểu dáng',

  'listing_detail.intelligence.title': 'Phân tích dữ liệu mua bán',
  'listing_detail.intelligence.days': 'Số ngày đã đăng tin',
  'listing_detail.intelligence.lower': 'Thấp hơn so với tin đăng tương tự',
  'listing_detail.intelligence.higher': 'Cao hơn so với các tin đăng tương tự',
  'listing_detail.intelligence.change': 'Lịch sử thay đổi giá',

  'listing_detail.inspection.title': 'Kiểm tra 150 điểm',
  'listing_detail.inspection.description': 'Chúng tôi đã tiến hành kiểm tra 150 điểm xe trên để bạn có thể yên tâm về chất lượng xe được bán ra.',
  'listing_detail.inspection.missing': 'Xe này chưa được kiểm tra. Vui lòng quay lại sau.',
  'listing_detail.inspection.unknown': 'Không xác định',
  'listing_detail.inspection.model': 'Mẫu',

  'listing_detail.inspection.1': 'Lịch sử sạch sẽ',
  'listing_detail.inspection.2': 'Máy nén điều hòa',
  'listing_detail.inspection.3': 'Rò rỉ hộp số',
  'listing_detail.inspection.4': 'Lốp xe',
  'listing_detail.inspection.5': 'Hệ thống phanh bao gồm ABS',
  'listing_detail.inspection.6': 'Rò rỉ nước',
  'listing_detail.inspection.7': 'Hệ thống treo',
  'listing_detail.inspection.8': 'Đèn pha',
  'listing_detail.inspection.9': 'Không có tai nạn/ ngập lụt/ xe hỏng',
  'listing_detail.inspection.10': 'XEM TOÀN BỘ DANH SÁCH KIỂM TRA',
  'listing_detail.inspection.11': 'BÁO CÁO KIỂM TRA KHÔNG CÓ SẴN',

  'listing_detail.inspection.failed': 'Trượt',
  'listing_detail.inspection.passed': 'Đạt',

  'listing_detail.similar_vehicles': 'Bạn có thể sẽ thích',
}
export const VIETNAMESE = {
  ...inspectionProcessPoints,

  ...reviewPage,

  ...nav_bar,

  ...footer,

  ...home,

  ...search,

  ...for_dealer,

  ...createListing,

  ...listing,

  find: 'TÌM KIẾM',
  select_condition: 'Chọn tình trạng',
  all: 'Tất Cả',
  new: 'Mới',
  like_new: 'Như mới',
  good: 'Tốt',
  fair: 'Ổn',
  used: 'Cũ',
  make: 'Hãng xe',
  model: 'Dòng xe',
  location: 'Nơi bán',
  distance: 'Khoảng cách',

  automatic: 'Số tự động',
  manual: 'Số sàn',
  ev: 'EV',
  hybrid: 'Hybrid',
  import: 'Nhập khẩu',
  domestic: 'Trong nước',
  filter_location: 'Nơi Bán',

  greatdeal: 'Giá Tốt Nhất',
  gooddeal: 'Giá Tốt',
  fairdeal: 'Giá Hợp Lý',
  highprice: 'Giá Cao',
  pricedrop: 'Giảm Giá',
  need_financing: 'Hỗ Trợ Tài Chính',
  checkavailability: 'Nhắn người bán',
  days_on_market: 'Ngày trên thị trường',

  'reviews.title': 'Cảm nhận của khách hàng về chúng tôi',
  'reviews.none': 'Không có đánh giá nào',
  'reviews.write_a_review': 'Viết đánh giá',

  'research.title1': 'Kinh Nghiệm Mua Xe Đã Qua Sử Dụng',
  see_all: 'Xem tất cả',
  'research.title2': 'Kinh Nghiệm Mua Xe Mới',

  'research.title3': 'Đánh Giá Của Chuyên Gia',

  'research.title4': 'Tin Đã Xem',

  'research.title5': 'Mua & Thương Lượng',

  'reasearch.download_app.headline': 'Tải Ứng Dụng Ngay',
  'reasearch.download_app.sub_headline': 'Hơn 1 triệu lượt tải về',

  ...login,

  ...signin,

  'account.settings': 'Cài Đặt',
  'account.listing': 'Tin Đăng Của Tôi',
  'account.my_info': 'Thông Tin Của Tôi',
  'account.montly_payment': 'Thanh Toán Hàng Tháng',
  'account.support': 'Hỗ Trợ',
  'account.signout': 'Đăng Xuất',

  full_name: 'Họ Tên',
  email: 'Email',
  phone_number: 'Số Điện Thoại',
  city: 'Thành Phố',
  gender: 'Giới Tính',
  'my_info.changed': 'Thông tin của bạn đã được thay đổi? Liên hệ đội hỗ trợ Sleekride để cập nhật thông tin của bạn.',

  interest_rate: 'Lãi Suất',
  loan_duration: 'Thời Gian Vay',
  months: 'Tháng',
  down_payment: 'Tiền Trả Trước',
  save: 'Lưu',

  'support.give_feedback': 'Phản Hồi Với Chúng Tôi',
  'support.sold': 'Đã Bán',
  'support.edit': 'Chỉnh Sửa',
  terms_of_service: 'Điều Khoản Dịch vụ',
  privacy_policy: 'Chính Sách Bảo Mật',

  'sell_my_car.continue': 'Chào mừng trở lại. Sẵn sàng hoàn thành tin đăng bán xe',
  'sell_my_car.continue2': 'Tiếp tục từ nơi tôi đã dừng lại',

  'sell_my_car.headline': 'Bán Xe Trực Tuyến 100%',
  'sell_my_car.sub_headline': 'Nhận ngay ưu đãi tốt nhất từ hàng nghìn Đại Lý',

  'sell_my_car.enter_vin': 'Nhập Số VIN Của Bạn',
  select: 'Chọn',
  'sell_my_car.what_is_my_vin': 'Số VIN của tôi là gì?',
  'sell_my_car.get_started': 'Bắt Đầu',

  listing_process: 'Quy Trình Xử Lý Tin Đăng',
  'listing_process.description':
    'Chúng tôi sẽ giúp bạn hiệu chỉnh giá bán phù hợp thực tế thị trường, bán hàng nhanh chóng và nhận thanh toán an toàn. Mọi thao tác đều dễ dàng cho dù bạn đang ở bất cứ đâu.',

  'listing_process.benefits.1': 'Hỗ trợ thủ tục giấy tờ',
  'listing_process.benefits.2': 'Hiển thị tốt hơn',

  'listing_process.benefits.3': 'Tư vấn về giá cả',
  'listing_process.benefits.4': 'Xác minh danh tính người mua',
  'listing_process.benefits.5': 'Chỉ 120,000vnd để đăng tin',
  'listing_process.benefits.6': 'Thanh toán an toàn',
  'listing_process.list': 'Đăng Tin Bán Xe Ngay',

  site_name: 'sleekride.vn',
  introduction:
    'Công ty TNHH Sleekride cung cấp trang web, ứng dụng di động và các công nghệ khác, cho phép khách hàng của chúng tôi đăng tin và/hoặc định vị ô tô, xe tải và các loại xe ô tô khác để bán. Chính sách bảo mật ("Privacy Policy") được thiết kế để giúp bạn hiểu cách chúng tôi thu thập, sử dụng và chia sẻ thông tin cá nhân của bạn cũng như hỗ trợ bạn thực hiện các quyền bảo mật của mình.',
  scope: 'Phạm vi',
  scope_description:
    'Chính sách bảo mật này áp dụng cho thông tin cá nhân do chúng tôi xử lý trong hoạt động kinh doanh của chúng tôi, bao gồm trên nền tảng trang web, ứng dụng di động, và các dịch vụ trực tuyến hoặc ngoại tuyến khác (gọi chung là "Dịch vụ").',
  personal_information: '1. THÔNG TIN CÁ NHÂN CHÚNG TÔI THU THẬP',
  personal_information_description:
    'Các loại thông tin cá nhân mà chúng tôi thu thập tuỳ thuộc vào việc bạn là khách hàng, người dùng, người đăng ký, khách truy cập và các yêu cầu từ cơ quan pháp luật hiện hành.',
  information_provided: 'Thông Tin Bạn Cung Cấp Cho Chúng Tôi',
  account_creation:
    'Tạo Tài Khoản: Khi bạn tạo một tài khoản người dùng, chúng tôi có thể thu thập thông tin cá nhân như tên và họ, địa chỉ email, số điện thoại, mã bưu chính, tên người dùng và mật khẩu.',
  communications_with_us:
    'Cuộc trò chuyện của bạn với chúng tôi. Chúng tôi thu thập thông tin cá nhân từ bạn như tên và họ, địa chỉ email, số điện thoại và địa chỉ gửi thư khi bạn yêu cầu thông tin về Dịch vụ của chúng tôi, đăng ký nhận bản tin của chúng tôi, yêu cầu hỗ trợ khách hàng hoặc hỗ trợ kỹ thuật, nộp đơn xin việc làm hoặc giao tiếp với chúng tôi theo cách khác.',
  requesting_financing:
    'Yêu cầu Tài Chính. Khi bạn yêu cầu tài chính mua xe thông qua trang web của chúng tôi, chúng tôi có thể thu thập thông tin cá nhân để xác định khả năng tín dụng của bạn, bao gồm tên và họ, địa chỉ email, số điện thoại, ngày sinh, số An sinh xã hội, thông tin về nơi cư trú hiện tại và trước đây, bao gồm chi phí thuê hoặc trả tiền mua nhà, thông tin về công việc hiện tại và trước đây, bao gồm thu nhập, thông tin về lịch sử tài chính của bạn; và thông tin liên quan đến yêu cầu tài chính của bạn, chẳng hạn như giá trị của xe bạn đang giao dịch, số tiền đặt cọc của bạn và thời hạn tài chính bạn đang yêu cầu.',
  surveys:
    'Cuộc Khảo sát. Chúng tôi có thể liên hệ với bạn để tham gia vào các cuộc khảo sát. Nếu bạn quyết định tham gia, bạn có thể được yêu cầu cung cấp một số thông tin bao gồm thông tin cá nhân.',
  social_media_content:
    'Nội dung trên Mạng xã hội. Chúng tôi có thể cung cấp diễn đàn, blog hoặc trang mạng xã hội. Bất kỳ nội dung nào bạn cung cấp trên các kênh này sẽ được xem xét là "công cộng" và không được bảo vệ theo quyền riêng tư.',
  registration_sweepstakes_contests:
    'Đăng ký tham gia Cuộc thi hoặc Cuộc thi. Chúng tôi có thể tổ chức cuộc thi và cuộc thi. Thông tin liên hệ bạn cung cấp có thể được sử dụng để liên hệ với bạn về cuộc thi hoặc cuộc thi và cho các mục đích quảng cáo, tiếp thị và kinh doanh khác, nếu được phép theo luật. Ở một số khu vực, chúng tôi có nghĩa vụ công khai chia sẻ thông tin của người chiến thắng.',
  information_collected_automatically: 'Thông Tin Tự Động Thu Thập hoặc Từ Những Người Khác',
  automatic_data_collection:
    'Thu thập Dữ liệu Tự Động. Chúng tôi có thể tự động thu thập một số thông tin khi bạn sử dụng Dịch vụ. Thông tin này có thể bao gồm địa chỉ giao thức Internet (IP), cài đặt người dùng, địa chỉ MAC, các bộ xác định cookie, nhà cung cấp di động, quảng cáo trên di động và các bộ xác định duy nhất khác, chi tiết về trình duyệt của bạn, hệ điều hành hoặc thiết bị, thông tin vị trí, nhà cung cấp dịch vụ Internet, các trang web bạn truy cập trước, trong và sau khi sử dụng Dịch vụ, thông tin về các liên kết bạn nhấp vào và thông tin khác về cách bạn sử dụng Dịch vụ. Thông tin mà chúng tôi thu thập có thể được liên kết với tài khoản và các thiết bị khác.',
  data_collection_continued:
    'Ngoài ra, chúng tôi có thể tự động thu thập dữ liệu liên quan đến việc bạn sử dụng Dịch vụ của chúng tôi, chẳng hạn như các loại nội dung bạn tương tác và tần suất cũng như thời gian bạn thực hiện các hoạt động của bạn. Chúng tôi có thể kết hợp thông tin của bạn với thông tin mà những người khác cung cấp khi họ sử dụng Dịch vụ của chúng tôi, bao gồm thông tin về bạn khi họ đánh dấu bạn.',
  cookies_pixel_tags_web_beacons:
    'Cookies, Pixel Tags/Web Beacons, Thông tin về Phân Tích và Công nghệ Quảng cáo Dựa trên Sở Thích. Chúng tôi, cũng như bên thứ ba cung cấp nội dung, quảng cáo hoặc các chức năng khác trên Dịch vụ, có thể sử dụng cookies, pixel tags, bộ nhớ địa phương và các công nghệ khác ("Công nghệ") để tự động thu thập thông tin qua Dịch vụ. Công nghệ này cơ bản là các tệp dữ liệu nhỏ được đặt trên máy tính, máy tính bảng, điện thoại di động hoặc thiết bị khác cho phép chúng tôi và đối tác của chúng tôi ghi lại một số mảng thông tin cụ thể mỗi khi bạn truy cập hoặc tương tác với Dịch vụ của chúng tôi.',
  technologies_description:
    'Chúng tôi, cũng như bên thứ ba cung cấp nội dung, quảng cáo hoặc các chức năng khác trên Dịch vụ, có thể sử dụng cookies, pixel tags, bộ nhớ địa phương và các công nghệ khác ("Công nghệ") để tự động thu thập thông tin qua Dịch vụ. Công nghệ này cơ bản là các tệp dữ liệu nhỏ được đặt trên máy tính, máy tính bảng, điện thoại di động hoặc thiết bị khác cho phép chúng tôi và đối tác của chúng tôi ghi lại một số mảng thông tin cụ thể mỗi khi bạn truy cập hoặc tương tác với Dịch vụ của chúng tôi.',
  cookies:
    'Cookies. Cookies là các tệp văn bản nhỏ được đặt trong trình duyệt máy tính của khách hàng để lưu trữ các tùy chọn của họ. Hầu hết trình duyệt cho phép bạn chặn và xóa cookies. Tuy nhiên, nếu bạn làm như vậy, Dịch vụ có thể không hoạt động đúng cách.',
  pixel_tags_web_beacons:
    'Pixel Tags/Web Beacons. Pixel tag (còn được gọi là web beacon) là một đoạn mã nhúng trong Dịch vụ thu thập thông tin về tương tác của người dùng trên trang web đó. Việc sử dụng pixel cho phép chúng tôi ghi lại ví dụ, người dùng đã truy cập một trang web cụ thể hoặc nhấp vào quảng cáo cụ thể.',
  analytics_information:
    'Phân tích dữ liệu. Chúng tôi cũng có thể sử dụng Google Analytics và các nhà cung cấp dịch vụ khác để thu thập thông tin về hành vi của người truy cập và đặc điểm nhóm người truy cập trên Dịch vụ của chúng tôi. Để biết thêm thông tin về Google Analytics, vui lòng truy cập www.google.com/policies/privacy/partners/. Bạn có thể tắt việc thu thập và xử lý dữ liệu do Google tạo ra thông qua việc sử dụng Dịch vụ bằng cách truy cập http://tools.google.com/dlpage/gaoptout.',

  information_from_other_sources:
    'Thông tin từ các Nguồn Khác. Chúng tôi có thể thu thập thông tin về bạn từ các nguồn khác, bao gồm thông qua các dịch vụ và tổ chức của bên thứ ba để bổ sung thông tin do bạn cung cấp. Ví dụ, nếu bạn truy cập Dịch vụ của chúng tôi thông qua ứng dụng của bên thứ ba, chẳng hạn như cửa hàng ứng dụng, dịch vụ đăng nhập bên thứ ba hoặc trang mạng xã hội, chúng tôi có thể thu thập thông tin về bạn từ ứng dụng của bên thứ ba mà bạn đã công khai thông qua cài đặt quyền riêng tư của bạn. Thông tin mà chúng tôi thu thập thông qua các dịch vụ này có thể bao gồm tên của bạn, số nhận dạng người dùng của bạn, tên người dùng, vị trí, giới tính, ngày sinh, địa chỉ email, hình đại diện và danh bạ liên hệ của bạn lưu trữ trong dịch vụ đó. Thông tin bổ sung này cho phép chúng tôi xác minh thông tin bạn đã cung cấp và nâng cao khả năng cung cấp thông tin về doanh nghiệp, sản phẩm và Dịch vụ của chúng tôi cho bạn.',
  how_we_use_information: 'CÁCH CHÚNG TÔI SỬ DỤNG THÔNG TIN CỦA BẠN.',
  how_we_use_information_description: 'Chúng tôi sử dụng thông tin của bạn cho nhiều mục đích kinh doanh, bao gồm:',
  how_we_use_information_promise: 'Thực hiện hợp đồng với bạn và cung cấp Dịch vụ của chúng tôi, chẳng hạn như:',

  managing_your_information_and_accounts: 'Quản lý thông tin và tài khoản của bạn;',
  providing_access_and_communicating:
    'Cung cấp quyền truy cập vào một số khu vực, chức năng và tính năng cụ thể của Dịch vụ của chúng tôi; Giao tiếp với bạn về tài khoản của bạn, hoạt động trên Dịch vụ của chúng tôi và thay đổi chính sách;',
  activities_to_verify_quality_or_safety: 'Thực hiện các hoạt động để xác minh hoặc duy trì chất lượng hoặc an toàn của một dịch vụ hoặc thiết bị;',
  processing_financial_information: 'Xử lý thông tin tài chính của bạn và các phương thức thanh toán khác cho sản phẩm hoặc Dịch vụ đã mua;',
  providing_advertising_analytics_marketing_services: 'Cung cấp dịch vụ quảng cáo, phân tích và tiếp thị;',
  processing_applications_and_transactions: 'Xử lý các đơn xin và giao dịch; và',
  allowing_you_to_register_for_events: 'Cho phép bạn đăng ký tham gia sự kiện.',
  analyzing_and_improving_services_pursuant_to_legitimate_interest:
    'Phân tích và cải thiện Dịch vụ của chúng tôi theo quyền lợi hợp pháp của chúng tôi, chẳng hạn như:',
  detecting_security_incidents_and_protecting:
    'Phát hiện các sự kiện về bảo mật, bảo vệ khỏi các hoạt động độc hại, gian lận, gian lận hoặc hành vi bất hợp pháp và truy cứu người chịu trách nhiệm về hành vi đó;',
  measuring_interest_and_engagement:
    'Đo lường sự quan tâm và tương tác trên Dịch vụ của chúng tôi và sử dụng ngắn hạn, tạm thời như tùy chỉnh ngữ cảnh quảng cáo;',
  undertaking_research_for_technological_development: 'Thực hiện nghiên cứu để phát triển và thử nghiệm công nghệ;',
  procedures_to_improve_performance: 'Các quy trình để cải thiện hiệu suất, độ bền, đáng tin cậy hoặc hiệu quả;',
  improving_upgrading_or_enhancing_services_or_device: 'Cải thiện, nâng cấp hoặc tăng cường sản phẩm, dịch vụ, tiếp thị hoặc bảo mật của chúng tôi;',
  developing_new_products_and_services: 'Phát triển sản phẩm và dịch vụ mới;',
  ensuring_internal_quality_control: 'Đảm bảo kiểm soát chất lượng nội bộ;',
  verifying_your_identity_and_preventing_fraud: 'Xác minh danh tính của bạn và ngăn chặn gian lận;',
  debugging_to_identify_and_repair_errors:
    'Gỡ lỗi để xác định và sửa lỗi ảnh hưởng đến chức năng dự định hiện tại; Thực hiện các điều khoản và chính sách của chúng tôi; và',
  complying_with_legal_obligations:
    'Tuân thủ các nghĩa vụ pháp lý của chúng tôi, bảo vệ lợi ích quan trọng của bạn hoặc khi được yêu cầu vì lợi ích công cộng.',

  provide_additional_content_and_services: 'Cung cấp cho bạn nội dung và Dịch vụ bổ sung, chẳng hạn như:',
  furnishing_customized_materials_and_auditing:
    'Cung cấp cho bạn nội dung được tùy chỉnh về các ưu đãi, sản phẩm và Dịch vụ có thể bạn quan tâm, bao gồm nội dung hoặc Dịch vụ mới; Kiểm toán liên quan đến tương tác, giao dịch và các hoạt động tuân thủ khác; và Các mục đích khác mà bạn đồng ý, được thông báo hoặc được tiết lộ khi bạn cung cấp thông tin cá nhân.',

  auditing_and_other_purposes:
    'Kiểm toán liên quan đến tương tác, giao dịch và các hoạt động tuân thủ khác; và Các mục đích khác mà bạn đồng ý, được thông báo hoặc được tiết lộ khi bạn cung cấp thông tin cá nhân.',

  use_of_deidentified_and_aggregated_information:
    'Sử dụng Thông tin được vô danh và được tổng hợp. Chúng tôi có thể sử dụng thông tin cá nhân và dữ liệu khác về bạn để tạo thông tin được vô danh và được tổng hợp, chẳng hạn như thông tin nhân khẩu được vô danh, thông tin vị trí được vô danh, thông tin về máy tính hoặc thiết bị từ đó bạn truy cập Dịch vụ của chúng tôi, hoặc các phân tích khác mà chúng tôi tạo ra.',

  share_content_with_friends_or_colleagues:
    'Chia sẻ nội dung với bạn bè hoặc đồng nghiệp. Dịch vụ của chúng tôi có thể cung cấp nhiều công cụ và chức năng khác nhau. Ví dụ: chúng tôi có thể cho phép bạn cung cấp thông tin về bạn bè của mình thông qua các dịch vụ giới thiệu của chúng tôi. Dịch vụ giới thiệu của chúng tôi có thể cho phép bạn chuyển tiếp hoặc chia sẻ nội dung nhất định với bạn bè hoặc đồng nghiệp, chẳng hạn như email mời bạn bè của bạn sử dụng Dịch vụ của chúng tôi. Cách chúng tôi sử dụng công nghệ thu thập tự động. Chúng tôi, cũng như các bên thứ ba cung cấp nội dung, quảng cáo hoặc chức năng khác trên Dịch vụ, có thể sử dụng cookie, thẻ pixel, bộ nhớ cục bộ và các công nghệ khác để tự động thu thập thông tin thông qua Dịch vụ. Việc sử dụng các Công nghệ này của chúng tôi thuộc các loại chung sau: Cần thiết về mặt vận hành. Điều này bao gồm các Công nghệ cho phép bạn truy cập vào Dịch vụ, ứng dụng và công cụ cần thiết của chúng tôi để xác định hành vi bất thường trên trang web, ngăn chặn hoạt động gian lận và cải thiện bảo mật hoặc cho phép bạn sử dụng chức năng của chúng tôi; Liên quan đến hiệu suất. Chúng tôi có thể sử dụng Công nghệ để đánh giá hiệu suất Dịch vụ của mình, bao gồm cả một phần của hoạt động phân tích nhằm giúp chúng tôi hiểu cách khách truy cập sử dụng Dịch vụ;',
  functionality_related:
    'Liên quan đến chức năng. Chúng tôi có thể sử dụng các Công nghệ cho phép chúng tôi cung cấp cho bạn chức năng nâng cao khi truy cập hoặc sử dụng Dịch vụ của chúng tôi. Điều này có thể bao gồm việc nhận dạng bạn khi bạn đăng nhập vào Dịch vụ của chúng tôi hoặc theo dõi các tùy chọn, mối quan tâm cụ thể hoặc các mục đã xem trong quá khứ của bạn;',
  advertising_or_targeting_related:
    'Liên quan đến Quảng cáo hoặc Mục tiêu. Chúng tôi có thể sử dụng Công nghệ của bên thứ nhất hoặc bên thứ ba để cung cấp nội dung, bao gồm quảng cáo liên quan đến sở thích của bạn, trên Dịch vụ của chúng tôi hoặc trên các trang web của bên thứ ba. Theo dõi Trên Nhiều Thiết bị. Hoạt động duyệt của bạn có thể được theo dõi trên các trang web và các thiết bị hoặc ứng dụng khác nhau. Chẳng hạn, chúng tôi có thể cố gắng so khớp hoạt động duyệt của bạn trên thiết bị di động của bạn với hoạt động duyệt trên máy tính xách tay của bạn. Để thực hiện điều này, đối tác công nghệ của chúng tôi có thể chia sẻ dữ liệu như các mẫu duyệt của bạn, vị trí địa lý và các bộ xác định thiết bị, và sẽ khớp thông tin của trình duyệt và thiết bị có vẻ được sử dụng bởi cùng một người. Thông báo Về Trang web của Bên Thứ Ba, Nền tảng Truyền thông Xã hội và Bộ công cụ Phát triển Phần mềm. Dịch vụ của chúng tôi có thể chứa liên kết đến trang web khác, và các trang web khác có thể tham khảo hoặc liên kết đến trang web của chúng tôi hoặc các Dịch vụ khác. Những trang web khác này không được chúng tôi kiểm soát. Chúng tôi khuyên người dùng của mình đọc chính sách bảo mật của mỗi trang web và ứng dụng mà họ tương tác. Chúng tôi không ủng hộ, kiểm duyệt hoặc phê duyệt và không chịu trách nhiệm về thực hành bảo mật hoặc nội dung của các trang web hoặc ứng dụng khác nhau này. Việc truy cập những trang web hoặc ứng dụng khác nhau này nằm trong rủi ro của riêng bạn.',

  publicly_accessible_blogs_forums_social_media:
    'Dịch vụ của chúng tôi có thể bao gồm các blog, diễn đàn, trang truyền thông xã hội và tính năng gửi tin nhắn riêng tư có sẵn cho công chúng. Bằng cách sử dụng các Dịch vụ như vậy, bạn chấp nhận rủi ro rằng thông tin cá nhân được cung cấp bởi bạn có thể được xem xét và sử dụng bởi bên thứ ba cho bất kỳ mục đích nào. Ngoài ra, các nút truyền thông xã hội như Facebook, Twitter và LinkedIn (có thể bao gồm các tiện ích như nút "chia sẻ" này hoặc các mini chương trình tương tác khác) có thể xuất hiện trên trang web của chúng tôi. Các tính năng này có thể thu thập địa chỉ IP của bạn, trang bạn đang xem trên trang web của chúng tôi và có thể đặt một cookie để kích hoạt tính năng hoạt động đúng cách. Các tính năng truyền thông xã hội này hoặc do bên thứ ba cung cấp hoặc được cung cấp trực tiếp trên trang web của chúng tôi. Các tương tác của bạn với các tính năng này ngoài việc truy cập trang web của chúng tôi sẽ tuân theo chính sách bảo mật của công ty cung cấp nó.',

  use_of_third_party_apis_and_sdks:
    'Chúng tôi có thể sử dụng API và bộ phát triển phần mềm của bên thứ ba (SDK) là một phần của chức năng của Dịch vụ của chúng tôi. API và SDK có thể cho phép bên thứ ba bao gồm đối tác phân tích và quảng cáo thu thập thông tin cá nhân của bạn cho các mục đích khác nhau, bao gồm cung cấp dịch vụ phân tích và nội dung có liên quan hơn đối với bạn. Để biết thêm thông tin về việc chúng tôi sử dụng API và SDK, vui lòng liên hệ với chúng tôi theo như đã đề ra dưới đây.',
}
