import React from "react";
import car1 from "../../assets/images/car1.png";
import { useNavigate } from "react-router-dom";

const ResearchCard = ({ id, title, description, image }) => {
  const navigate = useNavigate();
  const navigateDetailPage = () => {
    navigate(`/research/detail/${id}`);
  };

  return (
    <div className=" w-[380px] cursor-pointer" onClick={navigateDetailPage}>
      <img className="rounded-xl  h-4/6 w-full" src={image} />
      <div>
        <div className="my-3">
          <p className="font-bold text-xl">{title}</p>
          <p className="text-xs ">{description} </p>
        </div>
      </div>
    </div>
  );
};

export default ResearchCard;
