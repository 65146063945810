import React from "react";
// import "./style.css";
import Left from "./Left";
import Right from "./Right";
const Checkout = () => {
  return (
    <div className="p-10  sm:p-4  flex md:flex-col bg-[#E7E7E7] ">
      {/* left */}

      <Left />

      {/* right */}

      <Right />
    </div>
  );
};

export default Checkout;
