import { FindArrow } from 'assets/icons/HomeIcons'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dots from '../../../assets/icons/find-card-dots.svg'
import SearchBar from 'Components/Search/SearchBar.jsx'
import { getParams } from 'Utils/Search/Handler'
import Autocomplete from '@mui/material/Autocomplete'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCarModels } from 'redux/options'
import index from './index.css'
import { FormattedMessage, useIntl } from 'react-intl'
import { cityOptions } from 'Resources/Data/cities'

const DISTANCE_OPTIONS = ['10km', '20km', '30km', '40km', '50km']

const Left = () => {
  const navigate = useNavigate()
  const [state, setState] = useState({ condition: 'all' })
  const [params, setParams] = useState({ condition: 'all' })
  const [isModelDisabled, setIsModelDisabled] = useState(true)
  const { makes, models, cities } = useSelector((state) => state.options)

  console.log('cities', cities)
  const dispatch = useDispatch()
  const theme = useTheme()

  useEffect(() => {
    if (state.make) {
      console.log('Setting car models : MAKE: ', state.make)
      dispatch(fetchCarModels(state.make)).then((response) => {
        // If response does not have data or there is an error
        // set models to 'all' or null
        console.log('Response', response)
        if (!response.payload || response.error) {
          console.log('Disable models')
          handleSetState('modelid', '')
          setIsModelDisabled(true)
        } else {
          console.log('Enable models')
          setIsModelDisabled(false)
        }
      })
    }
  }, [state.make, dispatch])

  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({}))

  const handleSetState = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }))
    setParams((prev) => ({ ...prev, [key]: value }))
  }

  const handleOnClickSearch = (item) => {
    // navigate to search page with query params
    const params = getParams(item)
    navigate(`/search-result?` + params)
  }

  const handleNewSearch = () => {
    const queryParams = new URLSearchParams(params).toString()
    navigate(`/search-result?` + queryParams)
    window.scrollTo(0, 0)
  }

  const intl = useIntl()

  const conditions = {
    all: intl.formatMessage({
      id: 'all',
    }),
    new: intl.formatMessage({
      id: 'new',
    }),
    used: intl.formatMessage({
      id: 'used',
    }),
  }

  return (
    <div className=" flex flex-col items-center p-4 px-5 sm:px-5 sm:p-1  lg:rounded-none border-0 bg-[#FFF]  text-black w-full [&>div]:w-[85%]  [&>div]:mx-auto ">
      <div className="left-title">
        <p className="xxl:text-6xl text-4xl sm:text-2xl lLaptop:mb-2 font-[700] xxl:mb-3">
          <FormattedMessage id="home.intro.headline" />
        </p>
        <p className="my-2 text-[14px] xxl:text-lg">
          <FormattedMessage id="home.intro.sub_headline" />
        </p>
      </div>

      <div className="optionsTopWrapper">
        <div className="optionsShadowWrapper">
          <div className="optionsWrapper">
            <div className="optionsRowWrapper">
              {/* Condition */}
              <StyledAutocomplete
                sx={{
                  flexGrow: '2.9 !important',
                }}
                options={['all', 'new', 'used']}
                getOptionLabel={(option) => conditions[option] || ''}
                value={state.condition || 'all'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'select_condition',
                    })}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => handleSetState('condition', value)}
              />

              {/* Car Makes */}
              <StyledAutocomplete
                options={makes}
                getOptionLabel={(option) => option.makeName || option}
                value={params.make ? makes.find((make) => make.makeName === params.make) : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'make',
                    })}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => {
                  handleSetState('make', value ? value.makeName : '')
                  setIsModelDisabled(!value)
                }}
              />
            </div>

            <div className="optionsRowWrapper">
              {/* Car Models */}
              <StyledAutocomplete
                disabled={isModelDisabled}
                options={models}
                getOptionLabel={(option) => option.modelName || option}
                value={params.model ? models.find((model) => model.modelName === params.model) : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'model',
                    })}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => handleSetState('model', value ? value.modelName : '')}
              />

              {/* Cities */}
              <StyledAutocomplete
                options={cities}
                getOptionLabel={(option) =>
                  option.label
                    ? intl.locale == 'vn'
                      ? cityOptions.find((c) => c.label.toLowerCase() === option.label.toLowerCase()).vnLabel
                      : cityOptions.find((c) => c.label.toLowerCase() === option.label.toLowerCase()).label
                    : option
                }
                value={
                  params.location
                    ? intl.locale === 'vn'
                      ? cityOptions.find((c) => c.label.toLowerCase() === params.location.toLowerCase()).vnLabel
                      : cityOptions.find((c) => c.label.toLowerCase() === params.location.toLowerCase()).label
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'location',
                    })}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => handleSetState('location', value ? value.label : '')}
              />

              {/* Distance */}
              <StyledAutocomplete
                options={DISTANCE_OPTIONS}
                getOptionLabel={(option) => option}
                value={params.distance || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'distance',
                    })}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => handleSetState('distance', value)}
              />
            </div>
          </div>
          {/* Find Button */}
          <div
            className="findButton md:flex-row  md:space-x-2 text-white bg-[#105E26] duration-200 hover:bg-[#106F2B] w-1/4 xxl:w-  flex flex-col  justify-center items-center rounded-lg sm:w-full sm:rounded sm:flex-row sm:py-2 cursor-pointer xxl:w-24"
            onClick={handleNewSearch}>
            <p className="text-md md:mb-0 mb-2">
              <FormattedMessage id="find" />
            </p>
            <FindArrow className="sm:hidden" />
          </div>
        </div>
        <img src={dots} alt="" className="ml-[-25px] mt-[-15px]   w-20 " />
      </div>
    </div>
  )
}

export default Left
