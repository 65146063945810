import React from "react";

import upArrow from "../../up-arrow.png";

function GreatDealIcon({ fill = "#ffffff", ...rest }) {
  return (
    <div className="bg-[#105E26] w-6 h-6 flex items-center justify-center rounded-full">
      <img src={upArrow} />
    </div>
  );
}

export default GreatDealIcon;
