import React, { useState } from 'react'
import './style.css'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useLocation, useNavigate } from 'react-router-dom'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { setLoggedIn, setUserData } from 'redux/user'
import { useDispatch } from 'react-redux'
import MyInfo from './MyInfo'
import PaymentCalculator from './PaymentCalculator'
import EmailNotifications from './EmailNotifications'
import Listing from './Listing'
import Support from './Support'
import { useIntl } from 'react-intl'
import { resetListing } from 'redux/listing'

export default function UserSettings({ user, id = 0 }) {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [value, setValue] = useState(id ?? 0)
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const dispatch = useDispatch()
  const [logout] = useMutation({
    endPoint: APIEndpoints.LOGOUT,
    method: 'post',
  })

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken')
    logout({}, { headers: { 'auth-token': accessToken } }).then(() => {
      console.log('logout')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('step')

      dispatch(setUserData({}))

      dispatch(resetListing())
      dispatch(setLoggedIn(false))
      navigate('/')
    })
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const tabs = [
    'My Info',
    'Monthly payment Calculator',
    // 'Email Notification',
    'Support',
    'My Listing',
  ]

  const intl = useIntl()

  return (
    <div className="flex  items-start  md:flex-col  w-full h-full ">
      {/* side bar web */}
      <div className=" border-b-0 py-10 pl-0 shadow-lg  h-full  flex flex-col justify-between  w-[30vw]  md:hidden">
        <div className="h-full">
          <h1 className="font-bold text-xl my-5 pl-16  sm:pl-5">{intl.formatMessage({ id: 'account.settings' })}</h1>
          <ul className="my-10 sm:flex ">
            <li className=" " role="presentation">
              <button
                onClick={() => setValue(0)}
                className={`text-left w-full pl-16 py-5 font-bold text-md  ${
                  value == 0
                    ? 'text-white  bg-[#105e26] shadow shadow-[#105e26]'
                    : ' hover:bg-[#105e2b] hover:text-white  hover:opacity-90 duration-200  text-[#105e26]  bg-white'
                }  sm:pl-5`}>
                {intl.formatMessage({ id: 'account.my_info' })}
              </button>
            </li>
            <li className=" " role="presentation">
              <button
                onClick={() => setValue(1)}
                className={`text-left w-full pl-16 py-5 font-bold text-md  ${
                  value == 1
                    ? 'text-white  bg-[#105e26] shadow shadow-[#105e26]'
                    : ' hover:bg-[#105e2b] hover:text-white  hover:opacity-90 duration-200  text-[#105e26]  bg-white'
                } sm:pl-5 `}>
                {intl.formatMessage({ id: 'account.montly_payment' })}
              </button>
            </li>
            {/* 
            <li className=" " role="presentation">
              <button onClick={() => setValue(2)} className={`text-left w-full pl-16 py-5 font-bold text-md  ${value == 2 ? 'text-white  bg-[#105e26] shadow shadow-[#105e26]' : ' text-[#105e26]  bg-white'} sm:pl-5 `}>
                Email Notifications
              </button>
            </li> */}
            <li className=" " role="presentation">
              <button
                onClick={() => setValue(2)}
                className={`text-left w-full pl-16 py-5 font-bold text-md  ${
                  value == 2
                    ? 'text-white  bg-[#105e26] shadow shadow-[#105e26]'
                    : ' hover:bg-[#105e2b] hover:text-white  hover:opacity-90 duration-200  text-[#105e26]  bg-white'
                } sm:pl-5 `}>
                {intl.formatMessage({ id: 'account.support' })}
              </button>
            </li>
            <li className=" " role="presentation">
              <button
                onClick={() => setValue(3)}
                className={`text-left w-full pl-16 py-5 font-bold text-md  ${
                  value == 3
                    ? 'text-white  bg-[#105e26] shadow shadow-[#105e26]'
                    : ' hover:bg-[#105e2b] hover:text-white  hover:opacity-90 duration-200  text-[#105e26]  bg-white'
                } sm:pl-5 `}>
                {intl.formatMessage({ id: 'account.listing' })}
              </button>
            </li>
          </ul>
        </div>
        <div className="flex justify-center ">
          <button className="p-2 xl:p-4 xxl:p-6 text-[18px]  bg-[#105E26] text-white w-1/2 rounded-md" onClick={handleLogout}>
            {intl.formatMessage({ id: 'account.signout' })}
          </button>
        </div>
      </div>

      {/* sidebar mobile view */}

      <Box className="flex flex-row m-3 justify-between items-center  -md:hidden">
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit">
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          className=" -md:hidden">
          {tabs.map((tab, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setValue(index)
                handleCloseNavMenu()
              }}>
              <button>{tab}</button>
              {/* <Typography style={{ color: "black" }} textAlign="center">
                  </Typography> */}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {/* right */}
      <div className="tab-content w-full flex justify-center items-center px-10 py-20 pb-10 sm:p-5">
        <div className=" w-full max-w-[50vw] md:max-w-[100%]">
          {value === 0 && <MyInfo />}
          {value === 1 && <PaymentCalculator />}
          {/* {value === 2 && <EmailNotifications />} */}
          {value === 2 && <Support />}
          {value === 3 && <Listing />}
        </div>
      </div>
    </div>
  )
}
