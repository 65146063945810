import React from "react";

function QuotationMark({ fill = "#ffffff", ...rest }) {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.5174 16.614L17.9024 17.4431L17.6661 18.5412L18.9677 18.7775C19.8143 18.9809 19.9827 19.285 19.7989 20.1315L17.6661 30.1437C17.1083 32.7316 17.9724 33.95 20.0002 33.95C21.5752 33.95 23.3996 33.2237 24.2286 32.224L24.4824 31.0231C23.9071 31.5306 23.0605 31.734 22.5027 31.734C21.7086 31.734 21.4221 31.1762 21.6233 30.194L24.5174 16.614ZM24.7165 10.5875C24.7165 11.3533 24.4122 12.0877 23.8707 12.6292C23.3292 13.1707 22.5948 13.475 21.8289 13.475C21.0631 13.475 20.3287 13.1707 19.7872 12.6292C19.2457 12.0877 18.9414 11.3533 18.9414 10.5875C18.9414 9.82164 19.2457 9.08719 19.7872 8.54568C20.3287 8.00417 21.0631 7.69995 21.8289 7.69995C22.5948 7.69995 23.3292 8.00417 23.8707 8.54568C24.4122 9.08719 24.7165 9.82164 24.7165 10.5875Z" fill="black"/>
    <rect x="2" y="2" width="38.0001" height="38.0001" rx="10" stroke="black" strokeWidth="4"/>
    </svg>
    
  );
}

export default QuotationMark;
