import { useContext, useEffect, useState } from 'react'
import { locales } from 'Resources/i18n'
import { LocaleContext } from './LocaleContext'
import ProfileDropdownIcon from 'assets/icons/ProfileDropdownIcon'
import { Popover } from '@mui/material'

const LangSwitcher = () => {
  const { locale, setLocale } = useContext(LocaleContext)

  const [selectedLanguage, setSelectedLanguage] = useState(locales[locale].name)
  const [selectedImage, setSelectedImage] = useState(locales[locale].image)
  const [anchorEl, setAnchorEl] = useState(null)

  const profileOpen = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    // check local storage for language
    const localLang = localStorage.getItem('lang')
    if (localLang) {
      setLocale(localLang)
      setSelectedLanguage(locales[localLang].name)
      setSelectedImage(locales[localLang].image)
    } else {
      localStorage.setItem('lang', 'vn')
      setLocale('vn')
      setSelectedLanguage(locales['vn'].name)
      setSelectedImage(locales['vn'].image)
    }
  }, [])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <button onClick={handleClick} className="flex  items-center space-x-2 ">
        <div className="flex flex-row-reverse justify-center items-center">

        <p className="text-[#464646] text-sm sm:text-xs">{selectedLanguage}</p>
        <img className="h-7 w-7 mr-3" src={selectedImage} />
        </div>
        <ProfileDropdownIcon />
      </button>
      <Popover
        open={profileOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ borderRadius: '100%' }}>
        <ul className={'  p-4 text-grey '}>
          {Object.keys(locales).map((loc) => (
            <li key={loc} className={` border-grey border-b border-opacity-30`}>
              <button
                onClick={() => {
                  setAnchorEl(null)
                  setLocale(loc)
                  setSelectedLanguage(locales[loc].name)
                  setSelectedImage(locales[loc].image)
                  localStorage.setItem('lang', loc)
                }}
                className="p-2 ">
                <p className=" "> {locales[loc].name}</p>
              </button>
            </li>
          ))}
        </ul>
      </Popover>
    </>
  )
}
export default LangSwitcher
