import React from "react";

function RightArrowIcon({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.3048 4.83157C17.3046 4.83135 17.3045 4.8311 17.3043 4.83088L13.9236 1.0686C13.6703 0.78675 13.2607 0.787799 13.0086 1.07106C12.7565 1.35428 12.7575 1.81237 13.0108 2.09425L15.2805 4.6202H1.57863C1.2213 4.6202 0.931641 4.94411 0.931641 5.34371C0.931641 5.74331 1.2213 6.06723 1.57863 6.06723H15.2805L13.0108 8.59317C12.7575 8.87505 12.7566 9.33315 13.0086 9.61637C13.2607 9.89966 13.6704 9.90064 13.9236 9.61883L17.3043 5.85654C17.3045 5.85632 17.3046 5.85607 17.3049 5.85585C17.5583 5.57303 17.5575 5.11345 17.3048 4.83157Z"
        fill={fill}
      />
    </svg>
  );
}

export default RightArrowIcon;
