import React, { useEffect, useState } from 'react'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData } from 'redux/user'
import { FormattedMessage } from 'react-intl'

const PaymentCalculator = () => {
  const user = useSelector((state) => state.user)
  console.log('user', user)

  const dispatch = useDispatch()
  const [interestRate, setInterestRate] = useState(user.data?.monthlyCalculator?.interestRate.toString() ?? '7')
  const [loanDuration, setLoanDuration] = useState(user.data?.monthlyCalculator?.loanDuration.toString() ?? '36')
  const [downPayment, setDownPayment] = useState(user.data?.monthlyCalculator?.downPayment.toString() ?? '0')
  const [isPercentage, setIsPercentage] = useState(user.data?.monthlyCalculator?.isPercentage ?? false)
  const [savedSettings, setSavedSettings] = useState(null)

  const [isFormValid, setIsFormValid] = useState(false) // Track form validation
  const handleDownPaymentChange = (value) => {
    if (!value) setDownPayment('') // If the value is empty, set the state to empty

    // Ensure the value is a number or a percentage
    const numericValue = value.replace(/[^0-9,.]/g, '') // Remove non-numeric characters and allow a decimal point (or comma)
    const parsedValue = parseFloat(numericValue.replace(/,/g, ''))

    if (isNaN(parsedValue)) return

    if (isPercentage) {
      // If in percentage mode, limit the value to be between 0 and 100
      if (parsedValue < 0) {
        setDownPayment('0')
      } else if (parsedValue > 100) {
        setDownPayment('100')
      } else {
        setDownPayment(numericValue)
      }
    } else {
      // If not in percentage mode, reformat with commas and append VND
      // turn floats into integers
      const intValue = Math.round(parsedValue)

      const formattedValue = new Intl.NumberFormat('en-US').format(intValue)
      setDownPayment(formattedValue)
    }
  }

  useEffect(() => {
    handleDownPaymentChange(downPayment, isPercentage)
    // Check form validation whenever any input changes
  }, [isPercentage])

  useEffect(() => {
    validateForm()
  }, [interestRate, loanDuration, downPayment, isPercentage, user.data.monthlyCalculator])

  const [updateCalculator] = useMutation({
    endPoint: `${APIEndpoints.UPDATE_CALCULATOR}`,
    method: 'post',
  })

  // Validate form inputs
  const validateForm = () => {
    // Define regular expressions for validation
    const rateRegex = /^\d+(\.\d+)?$/ // Allows numbers with an optional decimal point
    const durationRegex = /^\d+$/ // Allows only whole numbers
    const vndRegex = /^(\d{1,3}(\,\d{3})*|(\d+))$/ // Allows numbers with commas

    // Test the input against the regular expressions
    const isRateValid = rateRegex.test(interestRate)
    const isDurationValid = durationRegex.test(loanDuration)
    const isDownPaymentValid = isPercentage ? rateRegex.test(downPayment) : vndRegex.test(downPayment)

    const isNotUserRate = parseFloat(interestRate) !== parseFloat(user.data?.monthlyCalculator?.interestRate)
    const isNotUserDuration = parseInt(loanDuration) !== parseInt(user.data?.monthlyCalculator?.loanDuration)
    const isNotUserDownPayment = parseFloat(downPayment) !== parseFloat(user.data?.monthlyCalculator?.downPayment)
    const isNotUserPercentage = isPercentage !== user.data?.monthlyCalculator?.isPercentage
    const isNotDefaultRate = parseFloat(interestRate) !== 7
    const isNotDefaultDuration = parseInt(loanDuration) !== 36
    const isNotDefaultDownPayment = parseFloat(downPayment) !== 0
    const isNotDefaultPercentage = isPercentage !== false

    // Set the form validation state
    setIsFormValid(
      isRateValid &&
        isDurationValid &&
        isDownPaymentValid &&
        (isNotUserRate || isNotUserDuration || isNotUserDownPayment || isNotUserPercentage) &&
        (isNotDefaultRate || isNotDefaultDuration || isNotDefaultDownPayment || isNotDefaultPercentage)
    )
  }

  useEffect(() => {
    console.log('Local :', {
      interestRate,
      loanDuration,
      downPayment,
      isPercentage,
    })
    console.log('User : ', user)
    console.log('Is Form Valid: ', isFormValid)
  })

  const handleSave = async () => {
    if (!isFormValid) {
      // Don't save if the form is not valid
      return
    }

    // Save the user's input settings
    const settings = {
      interestRate,
      loanDuration,
      downPayment,
      isPercentage,
    }
    setSavedSettings(settings)
    updateCalculator(settings, {
      headers: {
        'Auth-Token': `${localStorage.getItem('accessToken')}`,
      },
    }).then((res) => {
      console.log('res', res)
      if (res.data) {
        setInterestRate(res.data.monthlyCalculator.interestRate.toString())
        setLoanDuration(res.data.monthlyCalculator.loanDuration.toString())
        setDownPayment(res.data.monthlyCalculator.downPayment.toString())
        setIsPercentage(res.data.monthlyCalculator.isPercentage)
        localStorage.setItem('accessToken', res.accessToken)
        dispatch(
          setUserData({
            ...user.data,
            monthlyCalculator: {
              interestRate: res.data.monthlyCalculator.interestRate,
              loanDuration: res.data.monthlyCalculator.loanDuration,
              downPayment: res.data.monthlyCalculator.downPayment,
              isPercentage: res.data.monthlyCalculator.isPercentage,
            },
          })
        )
      }
    })
  }

  return (
    <div className="tab-pane fade show active" id="tabs-homeVertical" role="tabpanel" aria-labelledby="tabs-home-tabVertical">
      <div
        className="mb-6 text-gray-900 border border-[#105e2675]
 bg-[#F8FBFE] rounded-lg p-2 space-y-3">
        <label htmlFor="interest-rate" className="block text-xs text-[#84879E]">
          <FormattedMessage id="interest_rate" />
          (%)
        </label>
        <input
          type="text"
          id="interest-rate"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          className="block w-full outline-none border-0 bg-transparent"
        />
      </div>
      <div className="mb-6 text-gray-900 border border-[#105e2675] bg-[#F8FBFE] rounded-lg p-2 space-y-3">
        <label htmlFor="loan-duration" className="block text-xs text-[#84879E]">
          <FormattedMessage id="loan_duration" /> (
          <FormattedMessage id="months" />)
        </label>
        <input
          type="text"
          id="loan-duration"
          value={loanDuration}
          onChange={(e) => setLoanDuration(e.target.value)}
          className="block w-full outline-none border-0 bg-transparent"
        />
      </div>
      <div className="mb-6 text-gray-900 border border-[#105e2675] bg-[#F8FBFE] rounded-lg p-2 space-y-3 flex justify-between">
        <div className="w-4/5 flex flex-col justify-between">
          <label htmlFor="down-payment" className="block text-xs text-[#84879E]">
            <FormattedMessage id="down_payment" />
          </label>
          <input
            type="text"
            id="down-payment"
            value={downPayment}
            onChange={(e) => handleDownPaymentChange(e.target.value, isPercentage)}
            className="block w-full outline-none border-0 bg-transparent"
          />
        </div>
        <div className="flex rounded-xl w-1/5 shadow">
          <button
            className={`p-2 ${isPercentage ? 'bg-[#F5F6F6] text-black' : 'bg-[#105E26] text-white'}  w-1/2 rounded-lg shadow-inner`}
            onClick={() => setIsPercentage(false)}>
            VND
          </button>
          <button
            className={`p-2 ${isPercentage ? 'bg-[#105E26] text-white' : 'bg-[#F5F6F6] text-black'}  w-1/2 rounded-r-xl shadow-inner`}
            onClick={() => setIsPercentage(true)}>
            %
          </button>
        </div>
      </div>

      <div className="mb-6 flex justify-end">
        <button
          className={`p-2 bg-[#105E26] text-white w-1/3 rounded-md shadow-sm shadow-[#105E26] ${isFormValid ? '' : 'opacity-50 cursor-not-allowed'}`}
          onClick={handleSave}
          disabled={!isFormValid}>
          <FormattedMessage id="save" />
        </button>
      </div>
    </div>
  )
}

export default PaymentCalculator
