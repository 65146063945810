import React from 'react'
import ResponsiveAppBar from 'Components/AppBar'
import CustomizedSteppers from 'Components/Stepper'
// import "./style.css";
import Left from './Left'
import Right from './Right'
import Footer from 'Components/Footer'
import GoogleTranslate from '../../SDK/GoogleTranslate'
import LangSwitcher from 'i18n/LangSwitcher'
const UserLogin = () => {
  return (
    // <div className=" xxl:h-screen w-full  flex items-center    pl-10  md:p-10 sm:p-4    bg-[#F7FAF8]">

    <div className="h-fit w-full   flex flex-col justify-center items-center -md:h-full   -md:overflow-hidden     bg-[#F7FAF8]">
      <div className="w-full flex justify-end items-center p-5">
        <LangSwitcher />
      </div>

      {/* container */}
      <div className=" flex justify-center items-center w-full h-full  md:flex-col       ">
        <div className="-md:pl-10  md:p-10 w-full h-full flex justify-center items-center">
          <Left />
        </div>

        <Right />
      </div>
    </div>
  )
}

export default UserLogin
