import React, { useState } from 'react'
import CarTypeCard from 'Components/Cards/CarTypeCard'
import { GoodDealIcon } from 'assets/icons/pricingToolsIcon/dealIcon'
import {
  CorouselArrowLeft,
  CorouselArrowRight,
} from '../../../assets/icons/HomeIcons'
import DraggableList from 'Components/Carousel/DraggableList/DraggableList'
import { useNavigate } from 'react-router-dom'
import { bodyType } from 'Components/dropdown/searchResultDropdown/BodyTypeDropDown'
import { useIntl } from 'react-intl'

const handleScroll = (name, direction) => {
  const slider = document.getElementById(name)
  if (slider) {
    let scrollAmount = direction === 'left' ? 200 : -200
    slider.scrollLeft += scrollAmount
  }
}

const HomeCarTypeCardCorousel = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const navigate = useNavigate()

  const intl = useIntl()
  return (
    <div className="p-10 sm:p-4">
      <p className="font-bold text-4xl text-center sm:text-2xl">
        {intl.formatMessage({ id: 'home.trending_body' })}
      </p>
      <div className="my-4 relative ">
        <DraggableList name="cartypelist">
          {bodyType?.map((e, index) => (
            <div
              key={index}
              onClick={() =>
                navigate(
                  `/search-result?body=${e.name.toLowerCase()}`
                )
              }
            >
              <CarTypeCard
                image={e.image}
                name={e.name}
                Icon={GoodDealIcon}
              />
            </div>
          ))}
        </DraggableList>
      </div>
      <div className="flex justify-between px-20 sm:px-5 sm:hidden">
        <div
          onClick={() => handleScroll('cartypelist', 'right')}
          className="px-5 py-2 rounded-full bg-[#105e26] cursor-pointer"
        >
          <CorouselArrowLeft />
        </div>
        <div className="flex justify-center items-center ">
          {bodyType?.slice(0, 3).map((_, idx) => (
            <button
              key={idx}
              onClick={() => setSelectedIndex(idx)}
              className={`rounded-xl border-2 ${
                selectedIndex === idx
                  ? 'border-[#105e26] w-8'
                  : 'border-[#C4C4C4] w-2'
              } h-1 mx-[2px]`}
            ></button>
          ))}
        </div>
        <button
          onClick={() => handleScroll('cartypelist', 'left')}
          className="px-5 py-2 rounded-full bg-[#105e26] cursor-pointer"
        >
          <CorouselArrowRight />
        </button>
      </div>
    </div>
  )
}

export default HomeCarTypeCardCorousel
