import { Drive } from '../Types/common';

export const drives: Drive[] = [
  { _id: '1', name: 'fwd', label: 'FWD', translation: 'fwd' },
  { _id: '2', name: 'rwd', label: 'RWD', translation: 'rwd' },
  { _id: '3', name: 'awd', label: 'AWD', translation: 'awd' },
  { _id: '4', name: 'fourWd', label: '4WD', translation: 'four_wd' },
  {
    _id: '5',
    name: 'unknown',
    label: 'Unknown',
    translation: 'unknown',
  },
]
