import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCarMakers, fetchCarModels, fetchCities, fetchListingFees } from '../options';

const useFetchOptions = () => {
  const dispatch = useDispatch();
  const { makes, models, cities, listingFees, status, error } = useSelector((state) => state.options);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCarMakers());
      dispatch(fetchCarModels());
      dispatch(fetchCities());
      dispatch(fetchListingFees());
    }
  }, [status, dispatch]);

  const loading = status === 'loading';

  return { makes, models, cities, listingFees, loading, error };
};

export default useFetchOptions;
