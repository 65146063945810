import axios from 'axios'
import { MicroServices } from 'staticStore'

export default axios.create({
  baseURL: MicroServices.GATEWAY,
  // baseURL: "http://localhost:3007/api",

  headers: {
    'Content-type': 'application/json',
    'auth-token': localStorage.getItem('accessToken'),
  },
  // timeout: 4000,
})
