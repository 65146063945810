import React, { useState, useEffect, useCallback } from 'react'
import CustomInput from 'Components/CustomInput'
import clsx from 'clsx'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import { Link, useParams, useNavigate } from 'react-router-dom'

const ResetPassword = () => {
  const { token } = useParams()
  const [isValidToken, setIsValidToken] = useState(null)
  const navigate = useNavigate()
  const [validateToken] = useMutation({
    method: 'get',
    endPoint: `${APIEndpoints.VALIDATE_RESET_TOKEN}`.replace(':token', token),
  })
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
  })

  const [error, setError] = useState({})

  const [setNewPassword, { loading, error: resetError }] = useMutation({
    method: 'post',
    endPoint: APIEndpoints.RESET_PASSWORD,
  })

  const handleChange = (ev) => {
    const { name, value } = ev.target
    setState({ ...state, [name]: value })
  }

  const canSubmit = () => state.password && state.password === state.confirmPassword

  const handleSubmit = () => {
    if (canSubmit()) {
      setNewPassword({ token: token, password: state.password })
        .then((data) => {
          if (data.message === 'Password successfully reset!') {
            console.log('Password reset successful:', data)
            navigate('/user-login')
          }
        })
        .catch((err) => {
          console.error('Password reset error:', err)
        })
    } else {
      setError({ mismatch: 'Passwords do not match.' })
    }
  }

  const validateTokenMemoized = useCallback(validateToken, []) // Prevents infinity loop in useEffect

  useEffect(() => {
    validateTokenMemoized({ token })
      .then((response) => {
        if (response.message === 'Token is valid.') {
          setIsValidToken(true)
        } else {
          setIsValidToken(false)
        }
      })
      .catch((error) => {
        console.error('Token validation error:', error)
        setIsValidToken(false)
      })
  }, [token, validateTokenMemoized])

  return (
    <div className="h-full flex flex-col justify-center p-12 my-5 rounded-2xl border-0 bg-[#FFF] text-black w-full sm:p-6 shadow-[0px_0px_10px_rgba(0,0,0,0.3)]">
      <h1 className="text-3xl my-5 font-bold text-center">Reset Password</h1>

      {isValidToken === null && <p className="text-center my-5">Validating token...</p>}

      {isValidToken === false && (
        <>
          <p className="text-md text-[#707070] text-center my-5">Invalid or expired token. Please request a new password reset.</p>
          <div className="mt-2 text-center">
            <Link to="/forgot-password" className="text-[#105E26]">
              Forgot Password
            </Link>
          </div>
        </>
      )}

      {isValidToken && (
        <>
          <p className="text-md text-[#707070] text-center my-2">Enter your new password and confirm it.</p>

          <div className="xl:my-5 my-8">
            <CustomInput name="password" handleChange={handleChange} value={state.password} placeholder="New Password" type="password" />
          </div>

          <div className="xl:my-5 my-8">
            <CustomInput
              name="confirmPassword"
              handleChange={handleChange}
              value={state.confirmPassword}
              placeholder="Confirm Password"
              type="password"
            />
            {error.mismatch && <span className="text-red-500">{error.mismatch}</span>}
          </div>

          <div className="xl:my-5 my-8 mt-16">
            <button
              onClick={handleSubmit}
              className={clsx('bg-[#105E26] p-3 text-white text-[21px] w-full mt-2 rounded shadow-md shadow-[#105E26] cursor-pointer', {
                ['bg-gray-400 shadow-none cursor-no-drop']: !canSubmit(),
              })}
              disabled={!canSubmit()}>
              Reset Password
            </button>

            <div className="mt-2 text-center">
              <Link to="/login" className="text-[#105E26]">
                Return to login
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ResetPassword
