import React, { useEffect } from 'react'

export interface DraggableListProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name: string
}
const DraggableList: React.FunctionComponent<DraggableListProps> = ({
  children,
  style,
  name,
}): JSX.Element => {
  const handleDrag = () => {
    const slider = document.getElementById(name)
    let isDown = false
    let startX: number
    let scrollLeft: number

    if (slider) {
      slider.addEventListener('mousedown', (e: MouseEvent) => {
        isDown = true
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
      })
      slider.addEventListener('mousemove', (e: MouseEvent) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 1.5 //scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    }
  }
  useEffect(() => {
    handleDrag()
  }, [])

  return (
    <div className="w-full overflow-x-hidden " style={{ ...style }}>
      {/* cardContainer class of parent style.css */}
      <div
        className="w-full flex no-wrap overflow-auto py-1 no-scroll cardContainer"
        id={name}
      >
        {children}
      </div>
    </div>
  )
}

export default DraggableList
