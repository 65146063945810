import React from "react";

function MasterCardIcon({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="58"
      height="6"
      viewBox="0 0 58 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26819 2.91302C5.26819 1.72145 4.30223 0.755488 3.11066 0.755488C1.91909 0.755488 0.953125 1.72145 0.953125 2.91302C0.953125 4.1046 1.91909 5.07056 3.11066 5.07056C4.30223 5.07056 5.26819 4.1046 5.26819 2.91302Z"
        fill="white"
      />
      <path
        d="M13.8971 2.91302C13.8971 1.72145 12.9311 0.755488 11.7396 0.755488C10.548 0.755488 9.58203 1.72145 9.58203 2.91302C9.58203 4.1046 10.548 5.07056 11.7396 5.07056C12.9311 5.07056 13.8971 4.1046 13.8971 2.91302Z"
        fill="#969696"
      />
      <path
        d="M22.526 2.91302C22.526 1.72145 21.56 0.755488 20.3685 0.755488C19.1769 0.755488 18.2109 1.72145 18.2109 2.91302C18.2109 4.1046 19.1769 5.07056 20.3685 5.07056C21.56 5.07056 22.526 4.1046 22.526 2.91302Z"
        fill="#969696"
      />
      <path
        d="M31.1588 2.91302C31.1588 1.72145 30.1929 0.755488 29.0013 0.755488C27.8097 0.755488 26.8437 1.72145 26.8437 2.91302C26.8437 4.1046 27.8097 5.07056 29.0013 5.07056C30.1929 5.07056 31.1588 4.1046 31.1588 2.91302Z"
        fill="#969696"
      />
      <path
        d="M39.7877 2.91302C39.7877 1.72145 38.8218 0.755488 37.6302 0.755488C36.4386 0.755488 35.4727 1.72145 35.4727 2.91302C35.4727 4.1046 36.4386 5.07056 37.6302 5.07056C38.8218 5.07056 39.7877 4.1046 39.7877 2.91302Z"
        fill="#969696"
      />
      <path
        d="M48.4166 2.91302C48.4166 1.72145 47.4507 0.755488 46.2591 0.755488C45.0675 0.755488 44.1016 1.72145 44.1016 2.91302C44.1016 4.1046 45.0675 5.07056 46.2591 5.07056C47.4507 5.07056 48.4166 4.1046 48.4166 2.91302Z"
        fill="#969696"
      />
      <path
        d="M57.0494 2.91302C57.0494 1.72145 56.0835 0.755488 54.8919 0.755488C53.7003 0.755488 52.7344 1.72145 52.7344 2.91302C52.7344 4.1046 53.7003 5.07056 54.8919 5.07056C56.0835 5.07056 57.0494 4.1046 57.0494 2.91302Z"
        fill="#969696"
      />
    </svg>
  );
}

export default MasterCardIcon;
