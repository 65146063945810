import React from 'react'
// import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Logo from '../../assets/images/pngLogo.png'
import People from '../../assets/images/people.png'
import LoginCar from '../../assets/images/logincar.png'
import { FormattedMessage } from 'react-intl'
const paymentMethod = () => {
  return (
    <div className="  my-3  flex flex-col justify-center items-center h-full  text-black w-full  md:p-10 sm:p-4 relative">
      <div className="flex items-center justify-center my-8">
        <img className="h-20 w-32" src={Logo} />
      </div>
      <div className="my-8 flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold text-center  md:text-2xl">
          <FormattedMessage id="review.right.title" />
        </h1>
        <p className="my-4  font-extralight  w-[350px]">
          <FormattedMessage id="review.right.description" />
        </p>
      </div>

      <div className="my-8 h-64  md:h-32  ">
        <img className=" absolute right-0 w-4/5" src={LoginCar} />
      </div>
    </div>
  )
}

export default paymentMethod
