import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import logo from '../../assets/icons/logo.svg'
import { useNavigate } from 'react-router-dom'
import ProfileDropDown from 'Components/dropdown/ProfileDropDown'
import LanguageDropDown from 'Components/dropdown/LanguageDropDown'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { setLoggedIn, setUserData } from 'redux/user'
import AppBarWishlistIcon from 'assets/icons/AppBarWishlistIcon'
import { useDispatch, useSelector } from 'react-redux'
import useMutation from 'redux/hooks/useMutation'
import { APIEndpoints } from 'staticStore'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useIntl } from 'react-intl'
import GoogleTranslate from '../../SDK/GoogleTranslate'
import LangSwitcher from 'i18n/LangSwitcher'
import { resetListing } from 'redux/listing'
const settings = ['Profile', 'Account', 'Dashboard', 'Logout']

const ResponsiveAppBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const loggedIn = useSelector((state) => state.user.loggedIn)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState('English')
  const [logout] = useMutation({
    endPoint: APIEndpoints.LOGOUT,
    method: 'post',
  })

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken')
    logout({}, { headers: { 'auth-token': accessToken } }).then(() => {
      console.log('logout')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('step')

      dispatch(setUserData({}))

      dispatch(resetListing())
      dispatch(setLoggedIn(false))
      navigate('/')
    })
  }

  const handleLanguageChange = (event, newLanguage) => {
    console.log('newLanguage', newLanguage)
    setSelectedLanguage(newLanguage)
  }

  const desktopPages = [
    { text: 'nav_bar.home', to: '/' },
    { text: 'nav_bar.wish_list', to: '/wishlist' },
    { text: 'nav_bar.sell_my_car', to: '/sell-my-car' },
    { text: 'nav_bar.Research', to: '/research' },
    { text: 'nav_bar.for_dealer', to: '/dealer-home' },
  ]

  const mobilePages = [...desktopPages, ...(!loggedIn ? [{ text: 'nav_bar.sign_in', to: '/user-login' }] : [])]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleMenuItemClick = (page) => {
    if (page.action === 'logout') {
      handleLogout()
    } else if ((page.to === '/sell-my-car' || page.to === '/wishlist') && !loggedIn) {
      navigate('/user-login')
    } else {
      navigate(page.to)
      window.scrollTo(0, 0)
      setMobileOpen(false) // Close the drawer after navigating
    }
  }

  const intl = useIntl()

  return (
    <div className="flex lg:justify-between   sticky top-0  bg-white   p-5 py-3 md:px-5 xxl:px-20 xxl:py-2 lLaptop:py-2   items-center  shadow-md   z-50   ">
      {/* Desktop Menu Items */}
      <div onClick={() => navigate(`/`)} className="flex  p-0  cursor-pointer xxl:px-14 lLaptop:px-5 ">
        <Logo className="w-20" />
      </div>
      <Box className="  lg:block hidden    xs:w-[50px]">
        <IconButton size="large" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className="xs:flex lg:hidden">
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '.MuiDrawer-paper': {
              padding: '10px 20px 30px 20px',
              display: 'flex',
              justifyContent: 'start',
              borderRadius: '0px',
            },
          }}>
          <List>
            {loggedIn && (
              <MenuItem>
                <div className="flex space-x-5 items-start">
                  <div className="flex ">
                    <ProfileDropDown className=" !p-0" reversedName={true} handleLogout={handleLogout} />
                  </div>
                </div>
              </MenuItem>
            )}
            {/* <GoogleTranslate /> */}
            {/* <LanguageDropDown /> */}
            <MenuItem>
              <LangSwitcher />
            </MenuItem>
            {mobilePages.map((page, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(page)}
                sx={{
                  ':active': {
                    backgroundColor: '#105E26',
                    color: 'white',
                  },
                }}>
                {intl.formatMessage({ id: page.text })}
              </MenuItem>
            ))}

            {/* END Signin button and Language */}
          </List>
          {/* <Box className="flex justify-center items-center ">
            <ToggleButtonGroup
              value={selectedLanguage}
              exclusive
              onChange={handleLanguageChange}
              aria-label="language"
            >
              <ToggleButton
                value="English"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#105E26',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#105E26',
                    },
                  },
                }}
              >
                English
              </ToggleButton>
              <ToggleButton
                value="Tieng Viet"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#105E26',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#105E26',
                    },
                  },
                }}
              >
                Tiếng Việt
              </ToggleButton>
            </ToggleButtonGroup>
          </Box> */}
        </Drawer>
      </Box>

      {/* Desktop Menu Items */}

      <ul className="list-none flex item-center justify-evenly    lg:hidden lg:ml-5 xl:ml-10   xxl:ml-0 lLaptop:ml-10  ">
        {desktopPages.map((page, index) => (
          <li
            key={index}
            onClick={() => {
              if ((page.to === '/sell-my-car' || page.to === '/wishlist') && !loggedIn) {
                navigate('/user-login')
              } else {
                navigate(page.to)
                window.scrollTo(0, 0)
                setAnchorElNav(null)
              }
            }}
            className="mx-2  xxl:mx-5 lLaptop:mx-3  cursor-pointer  lg:text-sm xl:text-lg  xxl:text-sm lLaptop:text-sm font-[600]  hover:text-[#105e26]  p-2  ">
            {intl.formatMessage({ id: page.text })}
          </li>
        ))}
      </ul>

      {/* START Signin button and Language */}
      <div className=" lg:hidden  p-0   ml-auto space-x-5  lg:right-[2%] right-[4%]  flex justify-end items-center  ">
        {loggedIn ? (
          <div className="flex space-x-5 items-center">
            <div className="flex space-x-5">
              <ProfileDropDown handleLogout={handleLogout} />
            </div>
          </div>
        ) : (
          <div className="flex items-center  ">
            <button onClick={() => navigate(`/user-login`)} className="text-xs text-white sm:text-xs  ">
              <div className="bg-[#105e26] rounded-md  p-2 flex justify-center sm:w-[80px] md:w-[100px] xl:w-[150px] xxl:w-[180px] lLaptop:w-[180px]  mr-6  sm:px-2  sm:py-2 sm:mr-1  sm:flex sm:justify-center">
                {intl.formatMessage({ id: 'nav_bar.sign_in' })}
              </div>
            </button>
          </div>
        )}
        {/* <GoogleTranslate /> */}
        {/* <LanguageDropDown /> */}
        <LangSwitcher />
        {/* END Signin button and Language */}
      </div>
    </div>
  )
}
export default ResponsiveAppBar
