import React from "react";

function QuotationMark({ fill = "#ffffff", ...rest }) {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.972555 11.7689C0.340628 12.4008 0.340628 13.4254 0.972555 14.0573L11.2704 24.3551C11.9023 24.9871 12.9269 24.9871 13.5588 24.3551C14.1907 23.7232 14.1907 22.6987 13.5588 22.0667L4.40517 12.9131L13.5588 3.75944C14.1907 3.12752 14.1907 2.10296 13.5588 1.47103C12.9269 0.839107 11.9023 0.839107 11.2704 1.47103L0.972555 11.7689ZM29.0859 11.2949L2.11676 11.2949V14.5312L29.0859 14.5312V11.2949Z"
        fill="#979797"
      />
    </svg>
  );
}

export default QuotationMark;
