import React from 'react'
import bgbannerprimary from '../../assets/images/bgbannerprimary.png'

import gplaystore from '../../assets/images/gplaystore1.png'
import appstore from '../../assets/images/appstore1.png'
import DownloadBannerPrimaryDots from 'assets/icons/DownloadBannerPrimaryDots'
import PrimaryBannerMobile from 'assets/images/PrimaryBannerMobile.png'
import { FormattedMessage } from 'react-intl'

const DownloadBannerPrimary = () => {
  return (
    <div
      className="   relative h-2/3  flex items-center  sm:my-0 sm:text-center sm:h-64 sm:p-4 "
      // style={{backgroundImage: `url(${require('../../assets/images/bgbannerprimary.png')}`}}
    >
      <div
        style={{
          backgroundImage: `url(${bgbannerprimary})`,
          width: '100%',
          height: '100%',
          backgroundSize: ' cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
        className="bannerContainer   h-96 w-full flex sm:rounded-md    items-center bg-red-900 ">
        <div className="w-2/6 flex justify-center items-center sm:hidden  ">
          <div className=" flex justify-center items-center relative h-full sm:hidden z-10  ">
            <img
              className=" z-10 left-20  md:left-5 relative max-h-full max-w-2/3  drop-shadow-[-5px_2px_25px_rgba(245,233,0,0.7)]"
              src={PrimaryBannerMobile}
            />
          </div>
          <DownloadBannerPrimaryDots className="absolute z-0 " />
        </div>
        <div className="w-4/6   p-12 sm:w-full sm:p-4 ">
          <p className="my-4  text-7xl lg:text-4xl   font-bold text-white sm:text-center sm:text-4xl">
            {<FormattedMessage id="home.outro.title" />}
          </p>
          <p className="my-4 pl-2 text-2xl lg:text-xl font-thin  text-white sm:text-center sm:text-md">
            <FormattedMessage id="home.intro.headline" />
          </p>
          <div className=" flex md:flex-col flex-row  pt-10 md:space-y-4 gap-8  w-[80%] h-32 justify-start md:justify-center items-center">
            <img
              className="h-full   w-60  md:h-32  md:w-[18rem]  box-content  object-contain drop-shadow-[1px_2px_10px_rgba(255,255,255,0.6)] hover:scale-110 cursor-pointer"
              src={gplaystore}
            />
            <img
              className=" h-full  w-60  md:h-32  md:w-[16rem]   box-content  object-contain drop-shadow-[1px_2px_10px_rgba(255,255,255,0.6)] hover:scale-110 cursor-pointer"
              src={appstore}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DownloadBannerPrimary
